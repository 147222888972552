
.v-autocomplete {
  .v-autocomplete-list {
    z-index: 1;
    width: 100%;
    text-align: left;
    border: none;
    border-top: none;
    max-height: 400px;
    overflow-y: auto;
    border-top: 2px solid $cBrand;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
    padding: 3.2rem;
    background-color: #fff;

    .vac-search__form & {
      box-sizing: border-box;
      padding: 2.4rem;
    }

    .v-autocomplete-list-item {
      cursor: pointer;
      background-color: #fff;
      padding: 0.8rem;
      @include mix-font-size(24px, 24px);
      transition: color 0.3s;

      .vac-search__form & {
        color: $cLightGrey;

        span {
          color: $cBlack;
        }
      }
    }
  }
}
