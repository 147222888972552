.form__switcher {
  display: flex;
  align-items: center;
  overflow: hidden;

  input[type=checkbox] {
    visibility: hidden;
  }

  input[type=checkbox] ~ label {
    padding-left: 0;
    @include mix-font-size(16px);
    display: block;
    transition: color 0.3s;

    &:before, &:after {
      display: none;
    }

    &:hover {
      cursor: pointer;
    }
  }

  input[type=checkbox] ~ label {
    color: $cBlack;
    @include theme-monochrome-accent(color);
  }

  input[type=checkbox] + label {
    color: #7B7B7B;
    @include theme-monochrome-accent(color);
  }

  input[type=checkbox]:checked ~ label {
    color: #7B7B7B;
    @include theme-monochrome-accent(color);
  }

  input[type=checkbox]:checked + label {
    color: $cBlack;
    @include theme-monochrome-accent(color);
  }

  .form-switcher__switch {
    display: block;
    width: 4rem;
    height: 2.4rem;
    border-radius: 1.2rem;
    background: $cWhite;
    position: relative;
    transition: background-color 0.3s;
    margin-left: 0.8rem;
    margin-right: 0.8rem;
    border: 1px solid #D9D9D9;
    box-sizing: border-box;
    @include theme-monochrome-ground(background);
    @include theme-monochrome-ground(border-color);

    &:before {
      content: '';
      width: 1.6rem;
      height: 1.6rem;
      box-sizing: border-box;
      background-color: $cBrand;
      position: absolute;
      border-radius: 50%;
      left: 0.4rem;
      top: 0.3rem;
      transform: translateX(1.4rem);
      transition: transform 0.3s, background-color 0.3s;
      @include theme-monochrome-accent(background-color);
    }

    &:hover {
      cursor: pointer;
    }
  }

  input[type=checkbox] ~ .form-switcher__switch {
    &:before {
      display: block;
    }
  }

  input[type=checkbox]:checked ~ .form-switcher__switch {
    &:before {
      transform: translateX(0);
    }
  }
}