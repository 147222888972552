.rate-content {
  text-align: center;
}

.rate-header1 {
  @include meta;
  color: #262626;
  text-transform: uppercase;
  margin-bottom: 24px;
  @include mix-font-size(16px, 24px);
}

.rate-header2 {
  @include pf;
  @include mix-font-size(40px, 40px);
  color: #595959;
  text-align: center;
  margin-bottom: 24px;
}

.rate-header3 {
  @include meta;
  color: #595959;
  @include mix-font-size(16px, 24px);
}

.rate-vote {
  margin-top: 40px;
}

.rv__row {
  + .rv__row {
    margin-top: 24px;
  }
}

.rv__header {
  @include metam;
  color: #262626;
  margin-bottom: 24px;
  @include mix-font-size(17px, 24px)
}

.rv__rating {

  input[type=radio] {
    + label {
      padding-left: 0;
      line-height: inherit;
      font-size: inherit;
      min-height: inherit;

      &:before, &:after {
        display: none;
      }
    }
  }

  .vue-stars {
    label {
      width: 40px;
      padding-left: 8px;
      padding-right: 8px;
    }

  }
}

.rv__nums {
  @include meta;
  color: #A0A0A0;
  @include mix-font-size(14px, 24px);
}

.rate-success-content {
  margin-top: 112px;
}

.rate-success-icon {
  margin-bottom: 16px;
}

@media screen and (max-width: $breakpoint) {
  .wrapper--thin {
    width: 100%;
  }
  .rate-header2 {
    @include mix-font-size(30px, 30px);
  }
  .rv__rating .vue-stars label {
    width: 20px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
  .rv__row + .rv__row {
    margin-top: 1.6rem;
  }

  .rate-success-content {
    margin-top: 6rem;
  }
}
