.contacts-cols {
  display: flex;

  .col {
    width: 240px;
  }

  .col + .col {
    margin-left: 72px;
  }

  .col:first-child, .col:last-child {
    width: 344px;
  }
}

.contact-header {
  @include metam;
  color: #262626;
  letter-spacing: -0.22px;
  margin-bottom: 16px;
  @include mix-font-size(18px, 20px);
  @include theme-monochrome-accent(color);

}

.contact-row {
  + .contact-row {
    margin-top: 16px;
  }

  p + p {
    margin-top: 8px;
  }

  + .contact-header {
    margin-top: 32px;
  }
}


.contact-action {
  margin-top: 32px;
}

.contact-map {
  margin-top: 48px;
}

@media screen and (max-width: $breakpoint) {
  .contacts-cols {
    flex-direction: column;

    .col {
      + .col {
        margin-left: 0;
        margin-top: 2.4rem;
      }

      &:last-child, &:first-child {
        width: 100%;
      }
    }
  }
}
