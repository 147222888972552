.extra-info {
  display: flex;
  flex-direction: row;
  padding-top: 4rem;
  padding-bottom: 4rem;
  padding-right: 3.2rem;
  padding-left: 4.2rem;
  align-items: center;
  margin-top: 4rem;
  border: 1px solid #DEDEDE;
  box-sizing: border-box;
  max-width: 64.8rem;
  transition: opacity 0.6s 0.4s, transform 0.6s 0.4s;
  opacity: 0;
  transform: translateY(2rem);

  &.view-in {
    opacity: 1;
    transform: translateY(0);
  }
}

.extra-info--centered {
  margin-left: auto;
  margin-right: auto;
}

.extra-info__left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 4.3rem;

  svg {
    display: block;
  }
}

.extra-info__right {
  border-left: 1px solid #E8E8E8;
  padding-left: 3.2rem;
}

.extra-info__header {
  letter-spacing: -0.4px;
  @include mix-font-size(32px, 32px)
}

.extra-info__text {
  margin-top: 1.6rem;
  @include mix-font-size(15px);
}

.extra-info__action {
  margin-top: 1.6rem;
}

@media screen and (max-width: $breakpoint) {
  .extra-info {
    border: none;
    padding: 0;
    margin-top: 5.6rem;
    margin-bottom: 5.6rem;
    display: block;
  }
  .extra-info__left {
    padding-right: 0;
  }
  .extra-info__right {
    border-left: 0;
    padding-left: 0;
    margin-top: 2.2rem;
  }
  .extra-info__header {
    @include mix-font-size(24px, 24px);
    letter-spacing: -0.03rem;
  }
}
