fieldset:not([class]) {
  legend {
    margin-bottom: 1.6rem;
  }

  + fieldset:not([class]) {
    margin-top: 3.2rem;
  }
}

.form__row,
.input-field {
  position: relative;
  margin-top: 0;

  + .form__row {
    margin-top: 1.6rem;
  }

  &:disabled {
    &:after {
      content: '';
      width: 40px;
      height: 40px;
      position: absolute;
      background-color: #fff;
      right: 0;
      top: 24px;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxOCIgdmlld0JveD0iMCAwIDE0IDE4Ij4gIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMSAxKSI+ICAgIDxwYXRoIHN0cm9rZT0iI0Q5RDlEOCIgc3Ryb2tlLXdpZHRoPSIyIiBkPSJNOS4xNzY0NzA1OSAxNkwyLjgyMzUyOTQxIDE2QzEuMjY0MTM3MTggMTYgMy4xMzQ3NDczNmUtMTYgMTQuNzU0MTgzNyAwIDEzLjIxNzM5MTNMMCA2LjI2MDg2OTU3IDEyIDYuMjYwODY5NTcgMTIgMTMuMjE3MzkxM0MxMiAxNC43NTQxODM3IDEwLjczNTg2MjggMTYgOS4xNzY0NzA1OSAxNnpNMi40NzA1ODgyNCA2LjI2MDg2OTU3TDIuNDcwNTg4MjQgMy40NzgyNjA4N0MyLjQ3MDU4ODI0IDEuNTU3MjcwNDQgNC4wNTA3NTk3MSAzLjA4OTMxNjI0ZS0xNiA2IDBMNiAwQzcuOTQ5MjQwMjkgNy43MjMyOTA2MWUtMTcgOS41Mjk0MTE3NiAxLjU1NzI3MDQ0IDkuNTI5NDExNzYgMy40NzgyNjA4N0w5LjUyOTQxMTc2IDYuMjYwODY5NTciLz4gICAgPHBhdGggZmlsbD0iI0Q5RDlEOCIgZmlsbC1ydWxlPSJub256ZXJvIiBkPSJNNiwxMi44Njk1NjUyIEw2LDEyLjg2OTU2NTIgQzUuNzgzMjAxOTYsMTIuODcxNzcxIDUuNTkyNjQ2ODksMTIuNzI4NDE3MiA1LjUzNzY0NzA2LDEyLjUyMTczOTEgTDQuOTY5NDExNzYsMTAuNjUwNDM0OCBDNC45MDM2MzA4OCwxMC4zNDM1NDUgNC45ODE0MTkxMSwxMC4wMjM3NzI4IDUuMTgxMjQzNjQsOS43Nzk2MzU0MyBDNS4zODEwNjgxNyw5LjUzNTQ5ODAxIDUuNjgxNzI5MzEsOS4zOTI4OTYyOSA2LDkuMzkxMzA0MzUgTDYsOS4zOTEzMDQzNSBDNi4zMTU5OTUyMSw5LjM5MzUyNTQxIDYuNjE0NDY4MzgsOS41MzQ3MjQ4NCA2LjgxMzg2NjIxLDkuNzc2MzIyMDEgQzcuMDEzMjY0MDQsMTAuMDE3OTE5MiA3LjA5Mjc0MzU4LDEwLjMzNDY2MDEgNy4wMzA1ODgyNCwxMC42NCBMNi40NjIzNTI5NCwxMi41MjE3MzkxIEM2LjQwNzM1MzExLDEyLjcyODQxNzIgNi4yMTY3OTgwNCwxMi44NzE3NzEgNiwxMi44Njk1NjUyIFoiLz4gIDwvZz48L3N2Zz4=');
      background-position: 50% 50%;
      background-repeat: no-repeat;
      @include theme-monochrome-ground(background-color);
    }
  }
}

.form__row--date {
  .input-button {
    position: absolute;

  }
}

.form__row--inline {
  display: flex;
  align-items: center;

  .form__row {
    width: 40%;
    margin-right: 4rem;
  }

  .form__radio + .form__radio {
    margin-top: 0;
    margin-left: 5.6rem;
  }
}


.form__label {
  @include meta;
  @include mix-font-size(16px, 24px);
  color: $cBlack;
  display: block;
  position: absolute;
  padding-bottom: 1rem;
  left: 0;
  top: 3.2rem;
  transition: top 0.3s, font-size 0.3s, line-height 0.3s;
  @include theme-monochrome-accent(color);
  pointer-events: none;
}

.form__row--hidden {
  display: none;
}

.form__action {
  margin-top: 2rem;

  .secondary-action {
    text-align: center;
    margin-top: 1.6rem;
  }
}

.form__sign {
  display: flex;
  justify-content: space-between;
}

.input__helper, .input__error {
  color: $cLightGrey;
  margin-top: 0.8rem;
  @include mix-font-size(14px, 16px);
  @include theme-monochrome-accent(color);

  a {
    color: $cBrand;
    @include theme-monochrome-accent(color);

    &.disabled {
      opacity: 0.5;
    }
  }

  .helper__tooltip {
    border: none;
    position: relative;
    display: inline-block;
    padding-left: 2.7rem;
    line-height: 1.8rem;
    transition: color 0.3s;

    &:before {
      content: '';
      width: 2rem;
      height: 2rem;
      position: absolute;
      left: 0;
      top: -0.2rem;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIj4gIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjQTBBMEEwIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMSAxKSI+ICAgIDxjaXJjbGUgY3g9IjkiIGN5PSI5IiByPSI5Ii8+ICAgIDxwYXRoIGQ9Ik05IDEzTDkgOU05IDVMOSA1Ii8+ICA8L2c+PC9zdmc+);
      background-position: 50% 50%;
      background-repeat: no-repeat;
    }

    &:hover {
      cursor: pointer;
      color: $cBlack;
      @include theme-monochrome-accent(color);
    }
  }

  a.lk__action {
    border-bottom: none;

    &.lk__action--active {
      color: $cBrand;
      @include theme-monochrome-accent(color);
    }
  }
}

.input__error {
  color: $cError;
  display: none;
  @include theme-monochrome-accent(color);

  .show-error & {
    display: block;
  }
}

.show-error {
  .form__label {
    color: $cError;
    @include theme-monochrome-accent(color);
  }

  input[data-validate] {
    color: #FB969D;
    @include theme-monochrome-accent(color);
  }
}

.form__group {
  padding-top: 3.2rem;
  padding-bottom: 3.2rem;
  border-bottom: 1px solid rgba(#A0A0A0, 0.24);
  @include theme-monochrome-accent(border-bottom-color);

  legend {
    display: block;
    float: left;
    width: 100%;
    position: static;
    color: #262626;
    letter-spacing: -0.3px;
    padding: 0;
    z-index: 1;
    @include mix-font-size(24px, 24px);
    @include theme-monochrome-accent(color);

    + .form__row {
      clear: both;
      padding-top: 2.4rem;

    }

    + .attachment-zone {
      border-top: 0;
    }
  }

  &:first-child {
    padding-top: 0;
  }
}

/*.form__group {
  padding-top: 5.6rem;
  padding-bottom: 3.2rem;
  border-bottom: 1px solid rgba(#A0A0A0, 0.24);
  position: relative;
  legend {
    display: block;
    position: absolute;
    top: 3.2rem;
    width: 100%;
    margin: 0;
    font-size: 24px;
    color: #262626;
    letter-spacing: -0.3px;
    line-height: 24px;
    padding: 0;
    z-index: 1;
  }
}*/

@media screen and (max-width: $breakpoint) {
  .form__row--inline {
    display: block;

    .form__row {
      width: 100%;
      margin-right: 0;
      margin-bottom: 2rem;
    }
  }
  .form__label {
    @include mix-font-size(14px);
  }

  .form__group {
    padding-top: 3.6rem;

    legend {
      + .attachment-zone {
        clear: both;
      }
    }
  }

  .form__group legend {
    position: relative;
    @include mix-font-size(18px, 18px);
    margin-top: 1.6rem;
  }
  .form__row--inline .form__radio + .form__radio {
    margin-left: 0;
    margin-top: 2.4rem;
  }
}

[data-v-1ad2436f].fade-enter, [data-v-1ad2436f].slide-fade-enter {
  opacity: 1 !important;
}