.agents__search {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  input[type=text] {
    width: 300px;
    border: 1px solid rgba(#a0a0a0, 0.48);
    padding: 0;
    border-radius: 2px;
    padding-top: 10px;
    padding-bottom: 6px;
    padding-right: 42px;
    padding-left: 10px;
    &::-webkit-input-placeholder {
      opacity: 1;
    }
    &:-moz-placeholder {
      opacity: 1;
    }
    &:-ms-input-placeholder {
      opacity: 1;
    }
  }
  button[type=submit].isb__submit {
    height: 42px;
    width: 42px;
  }
}

.agents-content {

  .vgt-table{
    font-size: 12px;
  }

  .vgt-global-search {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .vgt-input {
    width: 300px;
    padding: 10px 32px 8px 10px;
    height: auto;
    box-sizing: content-box;
    &::-webkit-input-placeholder {
      opacity: .6;
    }
    &:-moz-placeholder {
      opacity: .6;
    }
    &:-ms-input-placeholder {
      opacity: .6;
    }
  }
  .vgt-global-search__input {
    display: flex;
    justify-content: flex-end;
  }
  .vgt-global-search__input .input__icon {
    right: 0;
    left: auto;
    top: 50%;
    transform: translateY(-50%);
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxkZWZzPjxwYXRoIGQ9Ik05OTcgMzU5Ljk3YzAtNC40MDIgMy41MzItNy45NyA3Ljg5LTcuOTdzNy44OSAzLjU2OCA3Ljg5IDcuOTdjMCA0LjQwMi0zLjUzMiA3Ljk3LTcuODkgNy45N3MtNy44OS0zLjU2OC03Ljg5LTcuOTd6IiBpZD0iYSIvPjxwYXRoIGQ9Ik0xMDE1LjMxMyAzNzEuMzA0bC00Ljg1My00LjkwNCIgaWQ9ImIiLz48L2RlZnM+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTk5NiAtMzUxKSI+PHVzZSB4bGluazpocmVmPSIjYSIgZmlsbC1vcGFjaXR5PSIwIiBmaWxsPSIjZmZmIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZT0iI2YzNmMyMSIgc3Ryb2tlLW1pdGVybGltaXQ9IjUwIiBzdHJva2Utd2lkdGg9IjIiLz48Zz48dXNlIHhsaW5rOmhyZWY9IiNiIiBmaWxsLW9wYWNpdHk9IjAiIGZpbGw9IiNmZmYiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlPSIjZjM2YzIxIiBzdHJva2UtbWl0ZXJsaW1pdD0iNTAiIHN0cm9rZS13aWR0aD0iMiIvPjwvZz48L2c+PC9zdmc+) 50% 50% no-repeat;

    div {
      display: none;
    }
  }
  .vgt-table th{
    font-weight: normal;
    @include metam
  }
  .vgt-table th.sorting{
    &:hover {
      &:after {
        border-bottom-color: $cBrand;
      }
    }
    &:after {
      border-bottom-color: $cBrand;
    }
  }
  .vgt-table tbody td ul{
    margin-left: 20px;
    list-style: square outside;
  }
  .vgt-table tbody td ul li{
    list-style: square outside;
  }
  .vgt-table thead th.sorting-desc{
    &:hover {
      &:after {
        border-top-color: $cBrand;
      }
    }
    &:after {
      border-top-color: $cBrand;
    }
  }
}

.agents-table {
  &.vgt-table {
  }
}

@media screen and (max-width: $breakpoint) {
  .agents-content {
    .vgt-input {
      box-sizing: border-box;
      width: 100%;
    }
    .vgt-global-search__input {
      padding-left: 12px;
    }
  }

}