h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5 {
  font-weight: normal;
  transition: opacity 0.6s, transform 0.6s;

  &[data-inview] {
    opacity: 0;
    transform: translateY(10px);

    &.view-in {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

h1:not(.custom), .h1 {
  font-size: 6rem;
  color: $cBlack;
  letter-spacing: -0.75px;
  padding-bottom: 40px;
  border-bottom: 1px solid $cBtnSec;
  margin-bottom: 40px;
  @include mix-font-size(60px, 56px);
  @include theme-monochrome-accent(color);
  @include theme-monochrome-ground(border-bottom-color);
  @include respond-to(600) {
    font-weight: 400;
    margin-bottom: 24px;
    padding-bottom: 0;
    border-bottom: none;
    @include mix-font-size(32px, 32px);
  }
  + .subheader {
    @include metam;
    margin-top: -32px;
    margin-bottom: 40px;
    @include mix-font-size(17px);
  }

  &.noborder {
    padding-bottom: 0;
    border-bottom: 0;
    @include respond-to(600) {
      color: $cBlack;
      letter-spacing: -0.425px;
      @include mix-font-size(34px, 47px);
      margin-bottom: 24px;
    }
  }
  &.noborder--mobile {
    @include respond-to(600) {
      margin-bottom: 0;
    }
  }
}

h2:not(.custom), .h2 {
  color: $cBlack;
  letter-spacing: -1px;
  margin-bottom: 32px;
  @include mix-font-size(48px, 48px);
  @include theme-monochrome-accent(color);
  @include respond-to(600) {
    @include mix-font-size(30px, 30px);
  }
  + .subheader {
    @include metam;
    margin-top: -24px;
    margin-bottom: 32px;
    @include mix-font-size(17px);
  }

  ~ h4 {
    margin-top: -8px;
  }
}

h3:not(.custom), .h3 {
  letter-spacing: -0.4px;
  color: $cBlack;
  margin-bottom: 24px;
  @include mix-font-size(32px, 32px);
  @include theme-monochrome-accent(color);
  @include respond-to(600) {
    @include mix-font-size(32px, 40px);
  }
}

h4:not(.custom), .h4 {
  letter-spacing: -0.3px;
  color: $cBlack;
  margin-bottom: 24px;
  @include mix-font-size(24px, 24px);
  @include theme-monochrome-accent(color);
  @include respond-to(600) {
    margin-bottom: 16px;
    @include mix-font-size(20px, 24px);
  }
  + p.text--smaller {
    margin-top: -16px;
  }
}

h5:not(.custom), .h5 {
  text-transform: uppercase;
  color: $cBlack;
  @include mix-font-size(16px, 24px);
  @include theme-monochrome-accent(color);
}

.styled {
  color: #595959;
  @include theme-monochrome-accent(color);

  table {
    width: calc(100% + 32px);
    margin-left: -16px;
    @include respond-to(800) {
      display: block;
      overflow-x: scroll;
    }
    th {
      text-align: left;
      font-weight: normal;
      padding: 16px;
      box-shadow: inset 0 -2px 0 0 #E8E8E8;
    }

    td {
      font-size: 16px;
      color: $cGrey;
      padding: 16px;
      @include theme-monochrome-accent(color);
    }

    tbody {
      tr {
        &:nth-child(odd) {
          td {
            background: rgba(#F2F2F2, 0.5);
            @include theme-monochrome-ground(background);
          }
        }
      }
    }
  }

  ul {
    li {
      list-style: disc;
      margin-left: 20px;
      @include mix-font-size(16px);

      + li {
        margin-top: 8px;
      }
    }
  }

  .floated-left {
    float: left;
    margin-right: 64px;
    margin-bottom: 32px;
    @include respond-to(800) {
      display: block;
      float: none;
      margin-right: 0;
      margin-left: 0;
      margin-bottom: 24px;
      margin-top: 24px;
      width: 100%;
    }
  }

  .floated-right {
    float: right;
    margin-left: 64px;
    margin-bottom: 32px;
    @include respond-to(800) {
      display: block;
      float: none;
      margin-right: 0;
      margin-left: 0;
      margin-bottom: 24px;
      margin-top: 24px;
      width: 100%;
    }
  }

  h5 + ul {
    margin-top: 24px;
  }
}

p + p {
  margin-top: 16px;
}

p + ul {
  margin-top: 16px;
}

strong {
  @include metam;
  color: $cBlack;
  @include theme-monochrome-accent(color);
}

blockquote {
  @include pf;
  color: $cGrey;
  margin-top: 30px;
  margin-bottom: 56px;
  @include mix-font-size(40px, 40px);
  @include theme-monochrome-accent(color);

  &:first-child {
    margin-top: 0;
  }
}

cite {
  @include meta;
  font-style: normal;
  text-transform: uppercase;
  display: block;
  margin-top: 24px;
  @include mix-font-size(16px, 24px);
}

.bq-extra {
  text-transform: none;
  color: $cLightGrey;
  letter-spacing: -0.2px;
  @include theme-monochrome-accent(color);
}

.text--larger {
  @include mix-font-size(17px);
}

.text--smaller {
  @include mix-font-size(14px, 16px);
}

.text--smallest {
  @include mix-font-size(12px, 16px);
}

.text--darken {
  color: $cBlack;
  @include theme-monochrome-accent(color);
}

.text--grey {
  color: $cGrey;
  @include theme-monochrome-accent(color);
}

.text--lighten {
  color: #7b7b7b;
  @include theme-monochrome-accent(color);
}

.text--lightest {
  color: $cLightGrey;
  @include theme-monochrome-accent(color);
}


.text--success {
  color: $cGreen;
  @include theme-monochrome-accent(color);
}

.text--error {
  color: $cError;
  @include theme-monochrome-accent(color);
}

.text--no-mb {
  margin-bottom: 0;
}

a {
  transition: color 0.3s;
  @include theme-monochrome-accent(color) {
    text-decoration: underline;
  }

  &:hover {
    color: $cBrand;
    @include theme-monochrome-accent(color) {
      text-decoration-style: wavy;
    }
  }

  &[href^="tel:"] {
    white-space: nowrap;
  }
}

.link {
  color: $cBrand;
  transition: color 0.3s;
  cursor: pointer;
  padding: 0;
  background: transparent;
  border: 0;
  &_new {
    color: #E58324;
    &:focus {
      color: #3C3C3B;
      outline: none;
    }
    &::-moz-focus-inner {
      border: 0;
    }
  }
  svg {
    stroke: $cBrand;
    margin-left: 0.6rem;
    transition: stroke 0.3s, transform 0.2s;
  }

  @include theme-monochrome-accent(color);

  &:hover {
    color: $cBlack;
    @include theme-monochrome-accent(color);

    svg {
      stroke: $cBlack;
      transform: translateX(0.6rem);
    }

  }
}

.annotation {
  @include meta;
  letter-spacing: -0.4px;
  margin-bottom: 24px;
  @include mix-font-size(24px, 32px);
  @include respond-to(800) {
    @include mix-font-size(20px, 22px);
  }
  &._width-small {
    max-width: 656px;
    &--bank {
      max-width: 100%;
      @include respond-to(800) {
        border-top: 1px solid #f1f1f1;
        padding-top: 24px;
        letter-spacing: -0.3px;
        @include mix-font-size(24px, 32px);
      }
    }
  }
}
