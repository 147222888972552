.documents-group {
  + .documents-group {
    margin-top: 48px;
  }
}

.documents-group__header {
  color: #262626;
  letter-spacing: -0.4px;
  @include mix-font-size(32px, 32px);
  @include theme-monochrome-accent(color);
  @include respond-to(800) {
    @include mix-font-size(24px);
    .lk__action {
      font-size: inherit;
    }
  }
}

.documents-group__content {
  margin-top: 24px;
}

.documents-group__section {
  margin-top: 40px;
  border-bottom: 1px solid rgba(160, 160, 160, 0.24);
  @include theme-monochrome-accent(border-bottom-color);
  @include respond-to(800) {
    border-bottom: none;
  }
}

.documents-group__section:last-child {
  border-bottom: none;
}

.documents__item {
  display: flex;
  margin-bottom: 56px;
  transition: opacity 0.6s, transform 0.6s;
  opacity: 0;
  transform: scale(0.9);
  @include respond-to(800) {
    margin-bottom: 0;
  }
  &.view-in {
    opacity: 1;
    transform: scale(1);
  }

  &:hover {
    .document__name {
      color: $cBlack;
      @include theme-monochrome-accent(color);
    }

    .document__date {
      color: #595959;
      @include theme-monochrome-accent(color);
    }
  }
}

.documents__preview {
  width: 120px;
  flex-grow: 0;
  flex-shrink: 0;

  + .documents__info {
    position: relative;
    padding-left: 24px;

    .document__date {
      position: absolute;
      bottom: 24px;
      @include respond-to(800) {
        position: static;
      }
    }
  }

  picture, img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.documents-list__preview {
  width: 50px;
}

.documents__preview__image {
  width: 53px !important;
  height: 66px !important;
}


.documents__info {
  max-width: 100%;
}

.document__name {
  color: $cBrand;
  transition: color 0.3s;
  @include theme-monochrome-accent(color);
}

.document__size {
  color: #6B7B84;
  letter-spacing: 0;
  margin-top: 8px;
  @include mix-font-size(11px, 24px);
  @include theme-monochrome-accent(color);
}

.document-list__size {
  line-height: 10px;
}

.document__date {
  color: #595959;
  letter-spacing: 0;
  margin-top: 8px;
  @include mix-font-size(14px, 18px);
  @include theme-monochrome-accent(color);
}

.document-list__date {
  bottom: 34px !important;
  width: max-content;
  position: static !important;
}

