//Грязный код из битрисового шаблона страницы продукта


.new-kasko .content__inner {
    /* padding: 43px 72px 43px 72px; */
	padding: 70px;
    flex: 1 1 auto;
    width: calc(100% - 416px);
    box-sizing: border-box;
}

.new-kasko {
	color: #58595B;
}

@media screen and (min-width: 800px) {
	.content.new-kasko.header-block {
		margin-top: -15px;
	}
}

.new-kasko.header-block .new_kasko_header .content__inner {
	padding: 40px 80px 0px 72px; 
	width: 45%; 
	min-height: 500px;
}

.new-kasko.header-block .content__inner h1 {
	font-size: 70px; 
	padding-bottom: 30px; 
	padding-top: 60px; 
	margin-bottom: 0px;
}

.new-kasko.header-block .content__inner .subtitle {
	font-size: 32px; 
	line-height: 1.2;
}

.new-kasko.detail-block .content__inner {
    /* padding: 37px 70px 52px; */
	padding: 55px 70px 70px;
}


.new-kasko.detail-block .new-kasko-detail-box {
	display: flex;
}

.new-kasko.detail-block .new-kasko-detail-box .new-kasko-detail-text{
	width: 70%;
	padding-right: 70px;
}


.new-kasko.header-block .content__inner {
    padding: 0px 72px 17px 72px;
    flex: 1 1 auto;
    width: calc(100% - 416px);
    box-sizing: border-box;
}

.new-kasko.benefits-block .content__inner {
    /* padding: 43px 70px 43px; */
	padding: 70px 70px 30px;
}

.new-kasko .content__inner {
	padding: 70px;
}

/* .new-kasko .vpki-grid {
	margin-left: 0;
	margin-right: 0;
} */

.new-kasko .new-kasko-detail-box .new-kasko-detail-banner {
	font-weight: bold; 
	text-align: center; 
	min-width: 315px; 
	min-height: 255px; 
	height: 255px;
	background-color: #ECECEC; 
	border: 1px solid rgba(217, 217, 217, 1); 
	margin: 0px 0px 0px 25px; 
	padding-top: 25px;
}

.new-kasko .new-kasko-detail-box .new-kasko-detail-banner {
	font-size: 22px; 
	color: #F26123;
}

.new-kasko .landing-button {
	color: #fff;
	background-color: #F26123!important;
	font-weight: 500;
	transition: .2s;
}

.new-kasko .landing-button:hover {
	background-color: #F68B28!important;
}

.new-kasko .new-kasko-detail-box .new-kasko-detail-banner .landing-button {

	padding: 10px 22px !important;
	
}

.new-kasko.quest-block .landing-button {

	text-transform: none;
	
}

.new-kasko.benefits-block {
	/* background-color: #8DC63F; */
	/* background: linear-gradient(90deg, #8DC63F 0%, #FFE040 100%); */
	background: #f7f7f7;
}

.new-kasko .new-product-content-header {
	text-align: center;
}

.new-kasko .new-product-content-header h3 {
	font-size: 26px;
    font-weight: bold;
}


.new-kasko.benefits-block .benefit-header {
	display: inline;
}

.new-kasko.benefits-block .benefit-header .vpki-icon {
	float: left;
	height: 44px;
}

.new-kasko.benefits-block .vpki-grid {
	justify-content: center;
}

.new-kasko.ins-registration .content__inner {
    /* background: linear-gradient(90deg, #8DC63F 0%, #FFE040 100%); */
	background: #f7f7f7;
}

.new-kasko.benefits-block .benefit-header-text {
	color: #F26123; 
	font-size: 18px !important;
	font-weight: bold;
	text-transform: none;
    padding-top: 9px;
	padding-bottom: 2px;
    padding-left: 60px;
}

.new-kasko.benefits-block .benefit-body {
	padding-left: 60px;
}

.new-kasko.benefits-block .benefit-body .benefit-preview {
	color: #58595B; 
	font-size: 15px !important;
	line-height: 1.4;
}

ul.sk-list,
.new-kasko.benefits-block .benefit-body .benefit-preview ul {
	padding-left: 15px;
}

ul.sk-list li,
.new-kasko.benefits-block .benefit-body .benefit-preview ul li {
    list-style: none;
    padding: 0.15em;
}

ul.sk-list li:before,
.new-kasko.benefits-block .benefit-body .benefit-preview ul li::before {
  content: "\2022";
  color: #F26123;
  font-weight: bold; 
  display: inline-block; 
  width: 1em; 
  margin-left: -1em; 
}
.new-kasko.restrictions-block .content__inner {
	padding: 70px 70px 14px;
}
.new-kasko.restrictions-block .restriction-header {
	display: inline;
}

.new-kasko.restrictions-block .restriction-header .vpki-icon {
	float: left;
    padding-right: 20px;
}

.new-kasko.restrictions-block .restriction-header-text {
	text-transform: none;
	display: flex;
}

.new-kasko.restrictions-block .vpki-grid {
    padding-top: 20px;
	justify-content: space-around;
	padding-left: 40px;
	padding-right: 40px;
}

.new-kasko.restrictions-block .vpki-grid .vpki-item {
    margin-left: 5px;
    margin-right: 5px;
	width: calc(33.33333% - 18px);
	margin-bottom: 20px;
}

.new-kasko.benefits-block .benefit-item {
	width: calc(49% - 92px);
	margin-bottom: 40px;
    margin-left: 40px;
    margin-right: 40px;
}

.new-kasko.compare-block .compare-table table {
    width: 100%;
    height: 500px;
}

.new-kasko.compare-block .compare-table table tr {
	/* border: 1px dashed #BEBEC4;  */
	border: 1px solid #FFFAFA; 
    height: 20px;
	text-align: center;
}

.new-kasko.compare-block .compare-table table .tr-head-first td:first-child{
	/* background-color: #C6E0B4; */
	font-weight: bold;
	padding: 10px 5px;
}

.new-kasko.compare-block .compare-table table .tr-head {
	/* background-color: #C6E0B4; */
}

.new-kasko.compare-block .compare-table table .tr-head td:first-child {
	font-weight: bold;
	/* border: 1px dashed #BEBEC4;  */
	border: 1px solid #FFFAFA; 
	/* background-color: #C6E0B4; */
}

.new-kasko.compare-block .compare-table table .tr-head td {
	border: 0px; 
	padding: 10px 5px;
}

.new-kasko.compare-block .compare-table table tr td {
	/* border: 1px dashed #BEBEC4;  */
	border: 1px solid #FFFAFA; 
	vertical-align: middle;
	padding: 0 5px;
	position: relative;
}

.new-kasko.compare-block .compare-table table tr th {
	/* border: 1px dashed #BEBEC4;  */
	border: 1px solid #FFFAFA; 
	color: #F26123;
	font-weight: bold;
	padding: 10px 5px;
}

.new-kasko.compare-block .compare-table table tr th:first-child {
	/* border: none;  */
	background-color: #fff;
}

.new-kasko.compare-block .compare-table table tr td:first-child {
	/* border: 1px dashed #BEBEC4; */
	border: 1px solid #FFFAFA; 
    min-width: 130px;
	text-align: left;
	/* background-color: #E2EFDA; */
	color: #58595B;
}

.new-kasko.compare-block .compare-table table tr:last-child {
	border: 0px; 
	background-color: transparent;
}

.new-kasko.compare-block .compare-table table tr:last-child td {
	border: 0px; 
	background-color: transparent;
}

.new-kasko.compare-block .compare-table table tr td .approved{
	color: rgb(234, 94, 32);
}

.new-kasko.compare-block .compare-table table tr td .approved-remark{
	color: #000;
	font-size: 11px;
	position: relative;
    top: -8px;
}

.new-kasko.compare-block .remark-box {
	color: #000;
	font-size: 11px;
	padding-top: 32px;
}

.new-kasko.compare-block .remark-box p{
	line-height: 15px;
	margin: 0px;
}

.new-kasko.compare-block .remark-box .approved-remark{
	color: #000;
	font-size: 8px;
	position: relative;
    top: -8px;
}

.new-kasko.compare-block .compare-table table tr:last-child td .landing-button {
	padding: 4px 8px !important;
}



.new-kasko.compare-block .compare-table table .compare-table-result-row td {
	padding: 10px 5px;
	background-color: #DDEBF7;
}

.new-kasko.docs-block {
	background: #f7f7f7;
}

.documents__info .document__name {
    color: #58595B!important;
	transition: .2s;
	line-height: 1.4;
}

.documents__info .document__size {
	margin-top: 8px;
    color: rgba(66, 68, 90, 0.59);
    font-size: 14px;
	line-height:1;
}

.new-kasko .documents__item {
	margin-bottom: 32px;
}
.new-kasko .documents__item .documents__preview {
	transition: .2s;
}
.new-kasko .documents__item:hover .documents__preview{
	opacity: .8;
}
.documents__item:hover  .documents__info .document__name {
    color: #f36c21!important;
}
#accordion-docs {
    background: #fff;
	margin-top: 22px;
}
#accordion-docs .documents-group__section {
	margin-top: 32px;
}

.new-kasko.products-info-block, .new-kasko.kasko-info-block {
	background: rgb(234,96,33);
	background: linear-gradient(90deg, rgba(234,96,33,1) 0%, rgba(244,155,45,1) 50%, rgba(253,211,57,1) 100%);
}
.new-kasko.products-info-block .slider-container {
	padding-top: 20px;
}
.new-kasko.products-info-block .slider-container .product__complex-slider {
    margin: 0px;
    padding: 0px 40px;
    background: transparent; 
    border: none;
	position: relative;
}

.new-kasko.products-info-block .slider-container .product__complex-slider .swiper-slide {
	/* width: 300px !important; */
	min-height: 220px;
	/* margin-right: 65px !important; */
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 1px 1px 3px 2px #53535338;
}

.new-kasko.products-info-block .slider-container .product__complex-slider .swiper-slide .slide-header{
	text-align: center;
    color: #F26123;
    font-size: 20px;
    padding-top: 20px;
    padding-bottom: 12px;
}

.new-kasko.products-info-block .slider-container .product__complex-slider .swiper-slide .slide-text{
	text-align: left;
	padding-bottom: 12px;
}

.new-kasko.products-info-block .slider-container .product__complex-slider .swiper-slide .slide-text p{
	margin-top: 10px;
	margin-left: 33px;
}

.new-kasko.products-info-block .slider-container .product__complex-slider .swiper-slide .slide-button{
	text-align: center;
    
}
.new-kasko.products-info-block .slider-container .product__complex-slider .swiper-slide .slide-button .landing-button {
	padding: 7px 23px !important;
}

.new-kasko.products-info-block .slider-container .swiper-button-next, 
.new-kasko.products-info-block .slider-container .swiper-button-prev {
	width: 48px;
	height: 48px;
	border-radius: 50%;
	background: transparent;
	border: none;
	display: flex;
	justify-content: center;
	align-items: center; 
	margin-top: 0;
	top: 50% !important;
	transform: translateY(-50%);
	transition: .2s;
}
.new-kasko.products-info-block .slider-container .swiper-container {
	padding: 0 15px;
}
.new-kasko.products-info-block .slider-container .swiper-button-next {
	background-image: url(/static/icons/arrow-in-circle-right.svg);
	background-repeat: no-repeat;
	background-size: 48px;
}
.new-kasko.products-info-block .slider-container .swiper-button-next:hover {
	background-image: url(/static/icons/arrow-in-circle-right-fill.svg);
}

.new-kasko.products-info-block .slider-container .swiper-button-prev {
	background-image: url(/static/icons/arrow-in-circle-green-left.svg);
	background-repeat: no-repeat;
	background-size: 48px;
}
.new-kasko.products-info-block .slider-container .swiper-button-prev:hover {
	background-image: url(/static/icons/arrow-in-circle-green-left-fill.svg);
}
.new-kasko.products-info-block .slider-container .swiper-button-prev {
	left: -5px;
}
.new-kasko.products-info-block .slider-container .swiper-button-next {
	right: -5px;
}

.new-kasko.products-info-block .slider__wrapper {
  overflow: hidden;
}

.new-kasko.products-info-block .slider__item {
  flex: 0 0 33.3333333333%; 
  max-width: 33.3333333333%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(255,255,255, 0.8);
  font-size: 7rem;
}

.new-kasko.products-info-block .slider__item:nth-child(1) {
   background-color: #f44336;
}

.new-kasko.info-block .info-addon .info-addon-image {
    background-image: url(/static/img/kasko-new/ins-kasko-reg.jpg);
    width: 33%;
    background-size: cover;
}

.new-kasko.info-block-2 .info-addon .info-addon-image {
    background-image: url(/static/img/kasko-new/ins-kasko-regul.jpg);
    width: 33%;
    background-size: cover;
}

.new-kasko.info-block .info-addon .info-addon-content, .new-kasko.info-block-2 .info-addon .info-addon-content {
	text-align: center;
    width: 67%;
	/* padding: 60px 70px 50px; */
	padding: 70px 70px 62px;
}

.new-kasko.info-block .info-addon .info-addon-content .info-addon-item, .new-kasko.info-block-2 .info-addon .info-addon-content .info-addon-item{
	text-align: left;
    height: 52px;
	display: flex;
	align-items: center;
	padding: 8px 0;
}

.new-kasko.info-block .info-addon .info-addon-content .info-addon-item p, .new-kasko.info-block-2 .info-addon .info-addon-content .info-addon-item p{
    /* padding-top: 10px; */
}

.new-kasko .info-addon {
	--list-item-icon: url('/static/icons/helios-sun.svg');
}
.new-kasko.info-block .info-addon .info-addon-content .info-addon-item::before, .new-kasko.info-block-2 .info-addon .info-addon-content .info-addon-item::before{
	flex-shrink: 0;
	width: 44px;
	height: 44px;
	background-image: var(--list-item-icon);
	background-repeat: no-repeat;
    background-size: 40px;
    background-position: center;
	content: "";
	margin-right: 12px;
	display: block;
    float: left;
}

.new-kasko.kasko-info-block .kasko-info-content {
	display: flex;
    justify-content: center;
	padding-top: 25px;
}

.new-kasko.kasko-info-block .kasko-info-content .kasko-info-item {
	width: 265px;
	height: 160px;
	background-color: rgb(236, 236, 236);
	margin: 10px 22px 10px 22px;	
	padding: 15px;
	border-radius: 10px;
	box-shadow: 1px 1px 3px 2px #53535338;
}

.new-kasko.kasko-info-block .kasko-info-content .kasko-info-item .item-header{
	margin: 12px 5px 5px 12px;
}

.new-kasko.kasko-info-block .kasko-info-content .kasko-info-item .item-header img{
	float: left;
	width: 32px;
	height: 32px;
	object-fit: contain;
	margin-left: 2px;
}

.new-kasko.kasko-info-block .kasko-info-content .kasko-info-item .item-header p{
	line-height: 30px;
	color: #F26123; 
	font-size: 18px;
	margin-left: 40px;
}

.new-kasko.kasko-info-block .kasko-info-content .kasko-info-item input[type=text]{
	margin: 14px 0px 23px 16px;
    width: 85%;
    line-height: 1;
    padding: 5px 8px;
    font-size: 16px;
	box-sizing: border-box;
	color: #58595B;
}

.new-kasko.kasko-info-block .kasko-info-content .kasko-info-item input::placeholder{
	opacity: 1;
	font-size: 16px;
	color: lightgray;
	font-weight: 500;
	
}

.new-kasko.kasko-info-block .kasko-info-content .kasko-info-item select {
	margin: 14px 0px 23px 16px;
    width: 85%;
    line-height: 1;
    padding: 5px 8px;
    font-size: 16px;
	opacity: 1;
	border-color:#96C22B; 
	color:#F26123;
	box-sizing: border-box;
}

.new-kasko.kasko-info-block .kasko-info-content .kasko-info-item .landing-button{
	padding: 5px 10px !important;
    margin: 0px;
    width: 85%;
}





.accordion__item {
  margin-bottom: 0.8rem;
  border-radius: 8px;
  box-shadow: 0 0.125rem 0.55rem rgba(0, 0, 0, 0.15)
}

.accordion__header {
  display: flex;
  align-items: center;
  padding: 0.95rem 1rem;
  color: #58595B;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease-out;
  border-radius: 8px;
  transition: .3s;
}

.docs-block .accordion__header {
  background-color: #fff;
}

.accordion__header::before {
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  background-image: url(/static/icons/arrow-in-circle-right.svg);
  background-repeat: no-repeat;
  background-size: 32px;
  content: "";
  margin-right: 12px;
  transition: .2s;
}

.accordion__header:hover::before {
  background-image: url(/static/icons/arrow-in-circle-right-fill.svg);
}

.accordion__item_show .accordion__header {
	color: #f26123;
}

.accordion__item_show .accordion__header::before {
  transform: rotate(90deg);
}

.accordion__header:hover {
  opcaity: 0.8;
}

.accordion__item_hidden .accordion__header {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion__body {
  /* padding: 0.75rem 1rem; */
  padding: 0.75rem 2rem 1.25rem;
  overflow: hidden;
  border-radius: 0 0 8px 8px;
  transition: .2s ease-in-out;
}

.accordion__item:not(.accordion__item_show) .accordion__body {
	max-height: 0;
	padding-top: 0;
	padding-bottom: 0;
}

.td-remark-tooltip {
  visibility: visible;
  opacity: 1;
  min-width: 150px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  font-size: 12px;
  line-height: 1.4;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 103%;
  left: 50%;
  transform: translate(-50%, 0);
}
.remark-box {
	display: none;
}

/* Tooltip arrow */
.td-remark-tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

@media screen and (max-width: 1200px) {
}

@media screen and (max-width: 1100px) {
}

@media screen and (max-width: 1000px) {
}
@media screen and (max-width: 900px) {
	.new-kasko.restrictions-block .content__inner,
    .new-kasko .content__inner {
		width: 100% !important;
		padding: 43px 30px;
	}
	.new-kasko.products-info-block{
		overflow: hidden;
	}

	.new-kasko.header-block .content__inner {
		width: 100% !important;
	}
	
	.new-kasko .new-kasko-detail-box .new-kasko-detail-banner {
		margin: 20px 0px 0px 0px;
	}

	.new-kasko.detail-block .content__inner {
		padding: 27px 30px 42px;
	}
	.vpki-grid {
		margin-left: 0!important;
		margin-right: 0!important;
	}

	.new-kasko.compare-block .compare-table {
		overflow-x: scroll;
		margin-right: -20px;
		padding-right: 40px;
		padding-left: 40px;
		margin-left: -36px;
	}

	.new-kasko.info-block .info-addon .info-addon-content, .new-kasko.info-block-2 .info-addon .info-addon-content {
		padding: 43px 30px;
	}
	
	.new-kasko.header-block .new_kasko_header .content__inner {
		background-color: transparent;
		padding: 30px; 
		width: 100%; 
		min-height: 0;
	}
	
	.new-kasko.benefits-block .benefit-item {
		width: calc(100% - 0px);
		margin-left: 0px; 
		margin-bottom: 10px; 
	}
	
	.new-kasko.kasko-info-block .kasko-info-content {
		display: block;
		text-align: center;
	}
	
	.new-kasko.kasko-info-block .kasko-info-content .kasko-info-item {
		display: inline-block;
	}
	
	.new-kasko.kasko-info-block .kasko-info-content .kasko-info-item .item-header {
		text-align: left;
	}
	
	.new-kasko.info-block .info-addon .info-addon-content, .new-kasko.info-block-2 .info-addon .info-addon-content {
		width: 100%;
	}
	
	.new-kasko.info-block .info-addon .info-addon-content .info-addon-item, .new-kasko.info-block-2 .info-addon .info-addon-content .info-addon-item {
		height: auto;
	}
	
	.new-kasko.restrictions-block .vpki-grid .vpki-item {
		width: 100%;
		margin-bottom: 20px;
	}
	.new-kasko.restrictions-block .restriction-header {
		display: flex;
		align-items: center;
	}
	
}
@media screen and (max-width: 800px) {
	.new-kasko.header-block .content__inner {
		padding: 0px 16px 17px;
	}
	.new-kasko.docs-block .documents-group {
		margin-bottom: 30px;
	}
}
@media screen and (max-width: 767px) {
	
	.new-kasko-detail-box {
		display: block !important;
	}

	.new-kasko.detail-block .new-kasko-detail-box .new-kasko-detail-text {
		width: 100%;
		padding-right: 0;
	}
	.new-kasko.products-info-block .slider-container .product__complex-slider {
		padding: 0 20px;
	}
}
@media screen and (max-width: 700px) {
	.new-kasko.products-info-block .slider-container .product__complex-slider .swiper-slide .slide-text p {
		margin: 10px 15px 0;
		text-align: center;
	}
}
@media screen and (max-width: 600px) {
	.new-kasko.restrictions-block .vpki-grid .vpki-item {
		margin-bottom: 0;
	}
	.new-kasko.restrictions-block .vpki-grid .vpki-item + .vpki-item {
		margin-top: 20px;
	}
}
@media screen and (max-width: 420px) {
	.new-kasko.restrictions-block .content__inner,
	.new-kasko .content__inner {
		padding: 32px 16px;
	}
	.new-kasko.header-block .content__inner h1 {
		font-size: 40px;
		padding-bottom: 20px;
		padding-top: 40px;
	}
	.new-kasko.header-block .content__inner .subtitle {
		font-size: 24px; 
	}
	.new-kasko.header-block .corp-hero__content p.header--larger {
		display: none;
	}
	.new-kasko.detail-block .content__inner {
	padding: 20px 16px 32px;
	}
	.new-kasko.header-block .new_kasko_header .content__inner {
		padding: 20px 16px;
	}
	.new-kasko .new-kasko-detail-box .new-kasko-detail-banner {
		min-width: initial;
	}
	.new-kasko.benefits-block .content__inner {
		padding: 32px 16px;
	}
	.new-kasko.benefits-block .benefit-header-text {
		height: auto;
	}
	.new-kasko.benefits-block .benefit-body,
	.new-kasko.benefits-block .benefit-header-text {
		padding-left: 52px;
	}
	.new-kasko .vpki-icon img {
		width: 36px;
	}
	.new-kasko .vpki-item + .vpki-item {
		margin-top: 18px;
	}
	.new-kasko .vpk-includes h3 {
		margin-bottom: 1em;
	}
	.new-kasko.compare-block .compare-table table .compare-table-result-row td,
	.new-kasko.compare-block .compare-table table .tr-head-first td:first-child,
	.new-kasko.compare-block .compare-table table tr th {
		padding: 5px 3px;
	}
	.new-kasko.compare-block .compare-table table tr td:first-child {
		min-width: 110px;
	}
	.new-kasko.kasko-info-block .kasko-info-content .kasko-info-item {
		margin-left: 0;
		margin-right: 0;
	}
	.new-kasko.info-block .info-addon .info-addon-content, .new-kasko.info-block-2 .info-addon .info-addon-content {
		padding: 32px 16px;
	}
	.new-kasko.products-info-block .slider-container .swiper-button-next, .new-kasko.products-info-block .slider-container .swiper-button-prev {
		width: 32px;
		height: 32px;
		background-size: cover;
	}
	.new-kasko.products-info-block .slider-container .product__complex-slider .swiper-slide {
		min-height: 180px;
	}
	.new-kasko.products-info-block .slider-container .swiper-container {
		padding: 0;
	}
	.new-kasko.compare-block .compare-table table {
		font-size: 14px;
		line-height: 1.4;
	}
	.new-kasko.restrictions-block .vpki-grid {
		padding: 10px 0 0;
	}
}