.index-page {
  height: 100vh;
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 450px;
  align-items: center;
  transition: background-image 1s;
  @include theme-monochrome-ground(background);
  @include respond-to(800) {
    height: 100%;
    min-height: 0;
    padding-top: 100px;
  }

  .theme-images & {
    background-image: none !important;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #F7F7F7;
    transition: opacity 0.6s, visibility 0.6s;
  }

  &.page-loaded {
    &:before {
      opacity: 0;
      visibility: hidden;
    }
  }
}

.index__content {
  width: 800px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  @include respond-to(800) {
    padding: 0 16px;
    box-sizing: border-box;
  }

  .big-font-size & {
    width: 100%;
  }
}

.index__search-block {
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.80);
  padding: 40px;
  transition: opacity 0.5s 0.3s, visibility 0.5s 0.3s, transform 0.5s 0.3s;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-40px);
  @include theme-monochrome-ground(background-color);
  @include respond-to(800) {
    padding: 24px 16px;
    max-width: 100%;
  }

  .page-loaded & {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}

@supports (backdrop-filter: blur(3px)) {
  .index__search-block {
    background-color: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(3px);
  }
}

.isb__text {
  color: $cBlack;
  margin-bottom: 20px;
  @include mix-font-size(16px, 24px);
  @include theme-monochrome-accent(color);
  @include respond-to(800) {
    margin: 0;
  }

  a {
    font-family: 'FFMetaWebProMedium', sans-serif;
  }
}

.isb__form {
  position: relative;
  @include respond-to(800) {
    margin-top: 15px;
  }

  &.is-empty {
    animation: gh-search-bounce 300ms ease-out both;
  }

}

input[type=text].isb__text-input {
  width: 100%;
  background: $cWhite;
  border-radius: 4px;
  letter-spacing: -0.3px;
  color: $cBlack;
  padding: 25px 64px 18px 24px;
  border: none;
  box-sizing: border-box;
  @include mix-font-size(24px, 28px);
  @include respond-to(800) {
    padding: 10px 48px 10px 16px;
    opacity: 1;
  }

  &::placeholder {
    color: #a0a0a0;
    opacity: 1;
    @include respond-to(800) {
      color: transparent;
    }
  }
}

@keyframes gh-search-bounce {
  0%, 100% {
    transform: translateX(0)
  }

  20%, 60% {
    transform: translateX(-5px)
  }

  40%, 80% {
    transform: translateX(5px)
  }
}

button[type=submit].isb__submit {
  position: absolute;
  border: none;
  background: transparent;
  box-shadow: none;
  -webkit-appearance: none;
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxkZWZzPjxwYXRoIGQ9Ik05OTcgMzU5Ljk3YzAtNC40MDIgMy41MzItNy45NyA3Ljg5LTcuOTdzNy44OSAzLjU2OCA3Ljg5IDcuOTdjMCA0LjQwMi0zLjUzMiA3Ljk3LTcuODkgNy45N3MtNy44OS0zLjU2OC03Ljg5LTcuOTd6IiBpZD0iYSIvPjxwYXRoIGQ9Ik0xMDE1LjMxMyAzNzEuMzA0bC00Ljg1My00LjkwNCIgaWQ9ImIiLz48L2RlZnM+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTk5NiAtMzUxKSI+PHVzZSB4bGluazpocmVmPSIjYSIgZmlsbC1vcGFjaXR5PSIwIiBmaWxsPSIjZmZmIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZT0iI2YzNmMyMSIgc3Ryb2tlLW1pdGVybGltaXQ9IjUwIiBzdHJva2Utd2lkdGg9IjIiLz48Zz48dXNlIHhsaW5rOmhyZWY9IiNiIiBmaWxsLW9wYWNpdHk9IjAiIGZpbGw9IiNmZmYiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlPSIjZjM2YzIxIiBzdHJva2UtbWl0ZXJsaW1pdD0iNTAiIHN0cm9rZS13aWR0aD0iMiIvPjwvZz48L2c+PC9zdmc+) 50% 50% no-repeat;
  width: 64px;
  height: 64px;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.2s;
  @include respond-to(800) {
    padding: 16px;
    width: 16px;
    height: 16px;
    box-sizing: content-box;
    background-size: 16px 16px;
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  &:hover {
    cursor: pointer;
    transform: translateY(-50%) scale(1.2);
  }

  &:focus {
  }

  &.is-loading {
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 32px;
      height: 32px;
      background: #fff url(/static/img/loader_brand2.png) left center no-repeat;
      -webkit-animation: play_loader 0.6s steps(18) infinite;
      animation: play_loader 0.6s steps(18) infinite;
    }
  }
}

.isb__footer {
  margin-top: 20px;
  color: $cBlack;
  display: flex;
  justify-content: space-between;
  @include mix-font-size(14px, 16px);
  @include theme-monochrome-accent(color);
  @include respond-to(800) {
    margin-top: 24px;
  }
}

.isb__links {
  @include respond-to(800) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -14px;
    margin-top: -10px;
    span {
      width: 100%;
      flex-grow: 1;
    }
    * {
      margin-top: 10px;
      margin-left: 14px;
    }
  }
}

.isb__outer-link {

}

.isb__link {
  color: $cBlack;
  margin-left: 12px;
  font-family: 'FFMetaWebProMedium', sans-serif;
  @include theme-monochrome-accent(color);
}

.index__actions {
  text-align: center;
  margin-top: 40px;

  a {
    display: inline-flex;
    vertical-align: middle;
    margin-top: 0;
    padding-right: 2.4rem;
    transition: opacity 0.5s 0.3s, visibility 0.5s 0.3s, transform 0.5s 0.3s;
    opacity: 0;
    visibility: hidden;
    transform: translateX(-40px);

    .page-loaded & {
      opacity: 1;
      visibility: visible;
      transform: translateX(0);
    }
  }

  a + a {
    margin-left: 2.4rem;
    transform: translateX(40px);
  }
}

@keyframes play_loader {
  100% {
    background-position: -576px;
  }
}