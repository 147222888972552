.reviews-list {
  display: flex;
  flex-wrap: wrap;
}
.review-item {
  width: calc(50% - 30px);
  margin: 15px;
  border: 2px solid rgb(235, 235, 235);
  border-radius: 5px;
  box-sizing: border-box;
  padding: 20px;
  justify-content: space-between;

  @include respond-to(800) {
    width: 100%;
    padding: 15px;
    margin: 10px 0;
  }

  .vue-star-rating-rating-text {
    display: none;
  }
}
.ri {
  display: flex;
  flex-direction: column;
  &-top {

  }
  &-row {
    display: flex;
    justify-content: space-between;  
  }
  &-text {
    margin-top: 12px;
  }
  &-bottom {
    margin-top: 14px;
    display: flex;
    justify-content: space-between;


    @include respond-to(500) {
      flex-direction: column-reverse;
      font-size: 14px;
    }
  }
  &-category {
    color: rgb(141, 198, 63);
    font-weight: bold;
  }

  &-imgs {
      display: flex;
      flex-wrap: wrap;
      margin: 10px 0 2px;

    img {
      max-width: 90px;
      max-height: 90px;
      padding: 5px;
      border-radius: 5px;
      border: 1px solid #eee;
      margin-bottom: 6px;
      cursor: pointer;

      + img {
        margin-left: 8px;
      }
    }
  }
}
.vel-toolbar {
  display: none!important;
}
.ri-select {
  width: 250px;
  margin: 12px 0 22px;
}