.products__list {

}

.products__item {
  display: flex;
  align-items: center;

  + .products__item {
    margin-top: 80px;
    @include respond-to(800) {
      margin-top: 40px;
    }
  }

  &:nth-child(even) {
    flex-direction: row-reverse;

    .products__description {
      margin-right: 0;
      margin-left: 64px;
      transform: translateX(32px);
    }

    .products__image {
      transform: translateX(-32px);
    }
  }

  &.view-in {
    .products__description {
      opacity: 1;
      transform: translateX(0);
    }

    .products__image {
      opacity: 1;
      transform: translateX(0);
    }
  }
  @include respond-to(800) {
    flex-direction: column-reverse;

    &:nth-child(even) {
      flex-direction: column-reverse;

      .products__description {
        margin-left: 0;
        transform: translateY(24px);
      }

      .products__image {
        transform: translateY(-24px);
      }
    }

    &.view-in {
      .products__description {
        transform: translateY(0);
      }

      .products__image {
        transform: translateY(0);
      }
    }
  }
}

.products__description {
  margin-right: 64px;
  padding-left: 64px;
  position: relative;
  transition: opacity 0.5s, transform 0.5s;
  opacity: 0;
  transform: translateX(-32px);
  @include respond-to(800) {
    margin-top: 32px;
    margin-right: 0;
    padding-left: 0;
    transform: translateY(24px);
  }
}

.products__image {
  width: 560px;
  flex-shrink: 0;
  flex-grow: 0;
  transition: opacity 0.5s, transform 0.5s;
  opacity: 0;
  transform: translateX(32px);
  @include respond-to(800) {
    width: 100%;
    transform: translateY(-24px);
  }
  picture, img {
    display: block;
    width: 100%;
  }
}

.products__icon {
  width: 48px;
  height: 48px;
  position: absolute;
  left: 0;
  top: -12px;

  img {
    width: 100%;
    display: block;
  }
}

.products__type {
  @include mix-font-size(32px, 32px);
  color: #262626;
  letter-spacing: -0.4px;
  margin-bottom: 16px;
  @include theme-monochrome-accent(color);
  @include respond-to(800) {
    padding-left: 64px;
  }
}

.products__text {
  color: #595959;
  @include mix-font-size(14px, 24px);
  @include theme-monochrome-accent(color);
}

.products__links {
  margin-top: 24px;
}

.products__link {
  color: #262626;
  letter-spacing: -0.5px;
  margin-right: 20px;
  @include mix-font-size(17px, 24px);
  @include theme-monochrome-accent(color);
  @include respond-to(800) {
    @include mix-font-size(17px, 24px);
    margin-bottom: 8px;
    width: auto;
  }
}

  .products__item {

  }
