html {
  font-size: $font-size-document;
  @include respond-to(1200) {
    font-size: $font-size-document;
  }
  @include respond-to(1100) {
    font-size: $font-size-document;
  }
  @include respond-to(1000) {
    font-size: $font-size-document;
  }
  @include respond-to(900) {
    font-size: $font-size-document;
  }
  @include respond-to(800) {
    font-size: $font-size-document;
  }
  @include respond-to(700) {
    font-size: $font-size-document;
  }
  @include respond-to(600) {
    font-size: $font-size-document;
  }
  @include respond-to(500) {
    font-size: $font-size-document;
  }
  @include respond-to(400) {
    font-size: $font-size-document;
  }
  @include respond-to(300) {
    font-size: $font-size-document;
  }
}

body {
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-family: 'FFMetaWebProNormal', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-variant-numeric: lining-nums; /* high-level property          */
  -moz-font-feature-settings: 'lnum'; /* low-level (old Firefox)      */
  -webkit-font-feature-settings: 'lnum'; /* low-level (old Webkit)       */
  font-feature-settings: 'lnum' on; /* low-level (all new browsers) */
  @include respond-to(800) {
    min-width: 0;
  }
}

.theme-images {
  &._grayscale {
    img, svg, picture {
      filter: grayscale(1);
    }
  }

  &._sepia {
    img, svg, picture {
      filter: sepia(1);
    }
  }

  &._invert {
    img, svg, picture {
      filter: invert(1);
    }
  }

  &._hidden {
    img, svg, picture {
      visibility: hidden;
    }
  }
}

#app {
  // display: flex;
  // flex-direction: column;
  // justify-content: flex-start;
  // align-items: stretch;
  min-height: 100vh;
  width: 100%;
  position: relative;

  &.big-font-size {
    word-wrap: break-word; //TODO: добавлять это свойство при модификации размера шрифта
  }
}

#page {
  min-height: 100%;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: stretch;

  color: #262626;
  @include theme-monochrome-accent(color);
  @include theme-monochrome-ground(background);
}

.site-header {
  flex-grow: 0;
}

.site-footer {
  flex-grow: 0;
}

.page-content {
  margin-top: 16px;
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-basis: auto;
  min-height: 500px;
  @include respond-to(800) {
    min-height: 0;
  }

  > .wrapper {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  }

  .index & {
    margin-top: 0;
    height: 100vh;
    @include respond-to(800) {
      height: 661px;
    }
  }
}


.content {
  background: #ffffff;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  @include theme-monochrome-ground(background);
  @include respond-to(800) {
    display: block;
  }
}

.content__inner {
  padding: 64px 80px 64px 72px;
  flex: 1 1 auto;
  width: calc(100% - 416px);
  box-sizing: border-box;
  @include respond-to(800) {
    box-sizing: border-box;
    padding: 16px;
    width: 100%;
  }
  &_lk {
    padding: 0;
    box-shadow: none;
    flex-shrink: 0;
  }
}

.content__inner--no-aside {
  padding-right: 72px;
  position: relative;
  /*
  h1 {
    max-width: calc(100% - 41.6rem);
  }
  */
}

.content__inner--nopt {
  padding-top: 0;
}


/*.content {
  background: #ffffff;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  position: relative;
  &:before {
    content: '';
    top: 0.8rem;
    width: 40rem;
    right: 0.8rem;
    bottom: 0.8rem;
    position: absolute;
    background: #F7F7F7;
  }
}*/

/*
.content__aside {
  box-sizing: border-box;
  width: 40rem;
  flex: 0 0 auto;
  position: absolute;
  right: 0.8rem;
  top: 0.8rem;
  //+.content__inner {
  //  order: 1;
  //  flex: 1 1 auto;
  //}
}
*/

/*
.content__inner {
  padding: 6.4rem 8rem 6.4rem 7.2rem;
  flex: 1 1 auto;
  margin-right: 41.6rem;
}
*/
@keyframes play_loader {
  100% {
    background-position: -576px;
  }
}

.data-preloader {
  flex-grow: 1;
  min-height: 200px;
  background: #fff;
  position: relative;
  @include theme-monochrome-ground(background);

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 32px;
    height: 32px;
    background: url('/static/img/loader_brand2.png') left center no-repeat;
    animation: play_loader 0.6s steps(18) infinite;
  }
}

.preloader {
  width: 32px;
  height: 32px;
  background: url('/static/img/loader_brand2.png') left center no-repeat;
  animation: play_loader 0.6s steps(18) infinite;
}

hr.titled {
  border: 0;
  border-bottom: 1px solid rgba(#A0A0A0, 0.24);
  margin-top: 24px;
  margin-bottom: 32px;
  position: relative;
  overflow: visible;
  @include theme-monochrome-ground(border-bottom-color);

  &:before {
    content: attr(title);
    padding-left: 8px;
    padding-right: 8px;
    background: #fff;
    position: absolute;
    left: 50%;
    top: -8px;
    transform: translateX(-50%);
    color: #A0A0A0;
    letter-spacing: 0;
    @include mix-font-size(14px, 16px);
    @include theme-monochrome-accent(color);
    @include theme-monochrome-ground(background);
  }
}

.full-vw {
  margin-left: calc(-1 * (100vw - 1072px) / 2);
  margin-right: calc(-1 * (100vw - 1072px) / 2);

  .wrapper {
    width: 1072px;
    margin-left: auto;
    margin-right: auto;
  }
}

.row {
  display: flex;

  .col--right-aligned {
    align-self: flex-end;
    text-align: right;
  }
}

.row--center {
  align-items: center;
}

.row--bottom {
  align-items: flex-end;
}

.row--top {
  align-items: flex-start;
}

.row--stretch {
  align-items: stretch;
}

.row--vert {
  flex-direction: column;
}

.row--spb {
  justify-content: space-between;
}

.row--wrap {
  flex-wrap: wrap;
}

.grid {
  display: flex;
  flex-wrap: wrap;
}

.col {
  transition: opacity 0.5s, transform 0.5s;

  &[data-inview] {
    opacity: 0;
    transform: translateY(20px);
  }

  &.view-in {
    opacity: 1;
    transform: translateY(0);
  }
}

.cols11 {
  margin-left: -16px;
  margin-right: -16px;
  @include respond-to(800) {
    margin-left: 0;
    margin-right: 0;
  }
  > .col {
    margin-left: 16px;
    margin-right: 16px;
    width: calc((100% - 64px) / 2);
    @include respond-to(800) {
      width: 100%;
      margin-right: 0;
      margin-left: 0;
    }
    + .col {
      @include respond-to(800) {
        margin-right: 0;
        margin-left: 0;
        margin-top: 32px;
      }
    }
    &.col--full-width {
      margin-left: 16px;
      margin-right: 16px;
      width: calc(100% - 32px);
    }
  }
}

.cols111 {
  margin-left: -14px;
  margin-right: -14px;
  @include respond-to(800) {
    margin-left: 0;
    margin-right: 0;
  }
  > .col {
    width: calc((100% - 84px) / 3);
    margin-left: 14px;
    margin-right: 14px;
    @include respond-to(800) {
      width: 100%;
      margin-right: 0;
      margin-left: 0;
    }
    + .col {
      @include respond-to(800) {
        margin-right: 0;
        margin-left: 0;
        margin-top: 32px;
      }
    }
    &.col--full-width {
      margin-left: 14px;
      margin-right: 14px;
      width: calc(100% - 28px);
      @include respond-to(800) {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
      }
    }
  }
}

.cols1111 {
  margin-left: -14px;
  margin-right: -14px;

  > .col {
    width: calc((100% - 1.4 * 2 * 30px) / 4);
    margin-left: 14px;
    margin-right: 14px;

    &.col--full-width {
      margin-left: 14px;
      margin-right: 14px;
      width: calc(100% - 28px);
    }
  }
}

.cols21 {
  margin-left: -14px;
  margin-right: -14px;
  @include respond-to(800) {
    margin-left: 0;
    margin-right: 0;
  }
  > .col {
    width: calc((100% - 84px) / 3);
    margin-left: 14px;
    margin-right: 14px;
    @include respond-to(800) {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
    + .col {
      @include respond-to(800) {
        margin-top: 32px;
      }
    }
    &:first-child {
      width: calc((100% - 56px) - ((100% - 84px) / 3));
      @include respond-to(800) {
        width: 100%;
      }
    }
  }
}

.cols12 {
  margin-left: -14px;
  margin-right: -14px;
  @include respond-to(800) {
    margin-left: 0;
    margin-right: 0;
  }
  > .col {
    width: calc((100% - 56px) - ((100% - 84px) / 3));
    margin-left: 14px;
    margin-right: 14px;
    @include respond-to(800) {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
    + .col {
      @include respond-to(800) {
        margin-top: 32px;
      }
    }
    &:first-child {
      width: calc((100% - 84px) / 3);
      @include respond-to(800) {
        width: 100%;
      }
    }
  }
}

.cols11111 {
  margin-left: -16px;
  margin-right: -16px;

  > .col {
    width: calc(20% - 32px);
    margin-left: 16px;
    margin-right: 16px;
  }
}

[data-fade-in-bottom] {
  transition: transform .5s .3s, opacity .5s .3s, visibility .5s .3s;
  transform: translateY(40px);
  opacity: 0;
  visibility: hidden;

  &.view-in {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}

.modal-enter-active {
  animation: modal-in .3s;
}

.modal-leave-active {
  animation: modal-in .3s reverse;
}

@keyframes modal-in {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-enter-active {
  animation: fade-in .5s;
}

.fade-leave-active {
  animation: fade-in .5s reverse;
}

.fade-fast-enter-active {
  animation: fade-in .2s linear;
}

.fade-fast-leave-active {
  animation: fade-in .2s linear reverse;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.scale-enter-active {
  animation: scale-out-in 0.3s;
}

.scale-leave-active {
  animation: scale-out-in 0.3s reverse;
}

@keyframes scale-out-in {
  0% {
    opacity: 0;
    transform: scale(0.4);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.step-switch-enter-active {
  animation: slide-in-up 0.2s;
}

.step-switch-leave-active {
  animation: slide-out-down 0.2s;
}


@keyframes slide-in-up {
  0% {
    opacity: 0;
    transform: translateY(10%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-out-down {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(10%);
  }
}

@keyframes slide-in-down {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

.slide-down-enter-active {
  animation: slide-in-down 0.3s;
}

.slide-down-leave-active {
  animation: slide-in-down 0.3s reverse;
}

.list-complete-enter, .list-complete-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.list-complete-leave-active {
  position: absolute;
}

.desktop-hidden {
  display: none !important;
}

.cert-list {
  @include respond-to(800) {
    flex-wrap: wrap;
  }
}

.mobile-hidden {
  @include respond-to(800) {
    display: none !important;
  }
}

@media screen and (max-width: 800px) {
  .mobile-visible {
    display: block !important;
  }
  .mobile-visible--as-flex {
    display: flex !important;
  }
  .mobile-visible--as-if {
    display: inline-flex !important;
  }
  .mobile-visible--as-ib {
    display: inline-block !important;
  }
  .mobile-visible--as-i {
    display: inline !important;
  }
  .page-content {
    margin-top: 0;
  }
  .table-cnt {
    width: 100vw;
    margin-left: -24px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    .table-cnt-inner {
      display: inline-block;
      width: auto;
      padding-left: 24px;
      padding-right: 24px;
    }
  }
  .row {
    flex-direction: column;
  }
  .form__row {
    .cols11, .cols12, .cols21, .cols111 {
      > .col {
        + .col {
          margin-top: 16px;
        }
      }
    }
  }
}

.changes {
  color: $cLightGrey;
  @include mix-font-size(14px, 14px);
  @include theme-monochrome-accent(color);
  margin-top: 15px;
}
