.product__hero {
  margin-left: -72px;
  margin-right: -72px;
  position: relative;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0;
  transition: opacity 0.5s;
  @include theme-monochrome-ground(background);
  @include respond-to(600) {
    background: none!important;
  }
  @include respond-to(800) {
    margin-left: -16px;
    margin-right: -16px;
  }
  .theme-images & {
    background-image: none !important;
  }

  * {
    transform: translateY(-20px);
    transition: opacity 0.6s, transform 0.6s;
    transition-delay: 0.5s;
    opacity: 0;
    position: relative;
  }

  &.view-in {
    opacity: 1;

    * {
      transform: translateY(0);
      opacity: 1;
    }
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: linear-gradient(-90deg, rgba(0, 0, 0, 0.00) 24%, rgba(0, 0, 0, 0.03) 28%, rgba(0, 0, 0, 0.06) 33%, rgba(0, 0, 0, 0.09) 39%, rgba(0, 0, 0, 0.13) 44%, rgba(0, 0, 0, 0.17) 50%, rgba(0, 0, 0, 0.21) 56%, rgba(0, 0, 0, 0.25) 62%, rgba(0, 0, 0, 0.29) 68%, rgba(0, 0, 0, 0.33) 74%, rgba(0, 0, 0, 0.37) 80%, rgba(0, 0, 0, 0.40) 85%, rgba(0, 0, 0, 0.44) 91%, rgba(0, 0, 0, 0.47) 96%, rgba(0, 0, 0, 0.50) 100%);
    @include respond-to(600) {
      background-image: none;
    }
  }
}

.product__hero--minh {
  min-height: 560px;
  @include respond-to(800) {
    min-height: inherit;
  }
}

.product__complex-slider {
  margin-left: -72px;
  margin-right: -72px;
  padding-top: 64px;
  padding-bottom: 80px;
  background: #F7F7F7;
  border: 16px solid $cWhite;

  .swiper-button-prev, .swiper-button-next {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #fff;
    border: 1px solid rgba(#a0a0a0, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    top: 98px;

    svg {
      display: block;
    }

    use {
      transition: stroke 0.3s;
      stroke: #595959;
    }

    &:hover {
      use {
        stroke: $cBrand;
      }
    }
  }

  .swiper-slide {
    transition: transform 0.6s, opacity 0.6s;
    transform: scale(0.9);
    opacity: 0;

    &.view-in {
      transform: scale(1);
      opacity: 1;
    }
  }
}

.product__image {
  position: relative;
  padding-bottom: 100%;
  margin-bottom: 40px;
  display: block;
  overflow: hidden;

  img {
    position: absolute;
    width: auto;
    height: 100%;
    //object-fit: cover;
    transition: opacity .3s;
    left: 50%;
    transform: translateX(-50%);
  }

  &:hover {
    img {
      opacity: .6;
    }
  }
}

.product__content {

}

.product__header {
  color: #262626;
  letter-spacing: -0.3px;
  margin-bottom: 24px;
  @include mix-font-size(24px, 24px);
  @include theme-monochrome-accent(color);
}

.product__type {
  color: #595959;
  letter-spacing: 0;
  margin-bottom: 8px;
  @include mix-font-size(16px, 24px);
}

.product__risks {
  list-style-type: none;
}

.product__risk {
  color: #A0A0A0;
  @include mix-font-size(14px, 24px);
  @include theme-monochrome-accent(color);
}

.complex-advantages-cnt {
  margin-top: 64px;

  h5 {
    max-width: 312px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
}

.complex-advantages {
  display: flex;
  margin-top: 56px;
}

.ca__list {
  display: flex;
}

.ca__item {
  padding: 16px 24px 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 208px;
  align-items: center;
  transition: opacity 0.6s, transform 0.6s;
  opacity: 0;
  transform: scale(0.9);

  &.view-in {
    opacity: 1;
    transform: scale(1);
  }

  + .ca__item {
    border-left: 1px solid rgba(#a0a0a0, 0.24);
  }
}

.ca-item__icon {
  margin-bottom: 16px;
}

.ca-item__text {
  color: #595959;
  letter-spacing: 0;
  text-align: center;
  width: 100%;
  @include mix-font-size(16px, 24px);
}


.activation-advantage {
  background: #7EBD1B;
  border-radius: 4px;
  padding: 32px;
  margin-left: 32px;
  text-align: center;
  transition: opacity 0.6s, transform 0.6s;
  opacity: 0;
  transform: scale(0.9);

  &.view-in {
    opacity: 1;
    transform: scale(1);
  }

  .text {
    @include metam;
    color: #FFFFFF;
    margin-bottom: 24px;
    @include theme-monochrome-ground(color);
  }
}

.product__incident {
  text-align: center;
  padding-top: 80px;
  padding-bottom: 80px;
}

.product-incident__list {
  list-style-type: none;
  display: flex;
  counter-reset: cnt;
  justify-content: center;
}

.product-incident__item {
  counter-increment: cnt;
  text-align: center;
  padding-bottom: 72px;
  position: relative;
  width: 280px;
  transition: opacity 0.6s, transform 0.6s;
  opacity: 0;
  transform: scale(0.9);

  &.view-in {
    opacity: 1;
    transform: scale(1);
  }

  + .product-incident__item {
    margin-left: 84px;
  }

  &:before {
    content: counter(cnt);
    display: block;
    width: 32px;
    margin-left: auto;
    margin-right: auto;
    height: 32px;
    text-align: center;
    @include metam;
    color: #A0A0A0;
    border-radius: 50%;
    background: $cWhite;
    margin-bottom: 16px;
    @include mix-font-size(14px, 32px);
  }
}

.pii__header {
  color: #262626;
  letter-spacing: -0.3px;
  text-align: center;
  margin-bottom: 24px;
  @include mix-font-size(24px, 24px);

}

.pii__text {
  color: #A0A0A0;
  @include mix-font-size(14px, 24px);
}

.pii__action {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}

.faq__section {
  padding-bottom: 80px;
  border-bottom: 1px solid rgba(#a0a0a0, 0.24);
}

.seotext-section {
  padding-top: 80px;
}

.seotext-content {
  column-count: 2;
  column-gap: 32px;
}

.corp-hero__content p.header {
  @include respond-to(600) {
    color: $cBlack;
    @include mix-font-size(24px, 32px);
  }
  @include respond-to(800) {
    @include mix-font-size(20px, 24px);
  }
}

@media screen and (max-width: 800px) {
  .product__complex-slider {
    margin-left: -24px;
    margin-right: -24px;
    border: 0;
    padding-top: 56px;
    padding-bottom: 64px;
  }
  .complex-advantages {
    flex-direction: column;
    margin-top: 24px;
  }
  .activation-advantage {
    margin-left: 0;
  }
  .ca__list {
    flex-wrap: wrap;
  }

  .ca__item {
    width: 50%;
    margin-bottom: 32px;

    &:nth-child(odd) {
      border-left: 0;
      padding-left: 0;
    }

    &:nth-child(even) {
      padding-right: 0;
    }
  }

  .product__incident {
    padding-top: 56px;
  }

  .product-incident__list {
    flex-direction: column;
    margin-left: 24px;
    margin-right: 24px;
  }

  .product-incident__item {
    width: auto;
    margin-left: 0;
    padding-bottom: 0;
    padding-left: 48px;
    text-align: left;

    &:before {
      position: absolute;
      left: 0;
      top: 0;
    }

    + .product-incident__item {
      margin-left: 0;
      margin-top: 40px;
    }
  }

  .pii__header {
    text-align: left;
    @include mix-font-size(20px);
  }

  .pii__action {
    position: static;
    text-align: center;
    margin-left: -48px;
    margin-top: 24px;
  }

  .faq__section {
    padding-bottom: 48px;
    padding-top: 48px;

    h3 {
      margin-bottom: 0;
    }
  }
  .seotext-section {
    padding-top: 56px;
  }
  .seotext-content {
    columns: 1;
  }
}
