.lk__dropdown {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;

}

.lk-dropdown__trigger {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  &:after {
    content: '';
    width: 1rem;
    height: 0.5rem;
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSI1IiB2aWV3Qm94PSIwIDAgMTAgNSI+ICA8cG9seWxpbmUgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjRjM2QzIxIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMiIgcG9pbnRzPSIyMCAxNSAxNiAxOCAxMiAxNSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTExIC0xNCkiLz48L3N2Zz4=) no-repeat 50% 50%;
    margin-left: 0.4rem;
  }
}

.lk-dropdown__icon {
  margin-right: 0.8rem;
  display: inline-block;
}

.lk-dropdown__selected {

}

.lk-dropdown__list {
  background: #fff;
  position: relative;
  padding: 2.4rem;
  box-sizing: border-box;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
  transition: opacity 0.3s, transform 0.3s, visibility 0.3s;
  transform: translateY(1rem);
  opacity: 0;
  visibility: hidden;
  &.is-visible {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    max-height: 20rem;
  }
}

.lk-dropdown__item {
  display: block;
  width: 100%;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  background: $cWhite;
  border: none;
  font: inherit;
  box-sizing: border-box;
  border-radius: 0;
  -webkit-appearance: none;
  transition: color 0.3s;
  text-align: left;
  svg {
    display: block;
  }
  &.is-active {
    color: $cBrand;
    &:hover {
      cursor: default;
    }
  }
  &:hover {
    cursor: pointer;
    color: $cBrand;
  }
  &:focus, &:active {
  }
}
