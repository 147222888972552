.filial-detail__content {
  display: flex;
}

.filial-detail__info {
  flex-grow: 1;
}

.filial-detail__aside {
  width: 44.8rem;
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 7.2rem;
  .map {
    height: 36rem;
  }
}


.filial-detail__block {
  +.filial-detail__block {
    margin-top: 3.2rem;
  }
}

.filial-detail__block--important {
  position: relative;
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
  z-index: 0;
  &:before {
    content: '';
    position: absolute;
    left: -2.4rem;
    right: -2.4rem;
    top: 0;
    bottom: 0;
    background: #F7F7F7;
    border-radius: 4px;
    z-index: -1;
  }

}

@media screen and (max-width: $breakpoint) {
  .filial-detail__content {
    flex-direction: column;
  }
  .filial-detail__info {

  }
  .filial-detail__aside {
    width: auto;
    margin-top: 3.2rem;
    margin-left: -2.4rem;
    margin-right: -2.4rem;
    .map {
      height: 28rem;
    }
  }
}
