.leaders {
  position: relative;
  transition: min-height .4s;
}

.leaders__item {
  width: 344px;

  + .leaders__item {
    border-top: 1px solid rgba(#a0a0a0, .24);
  }
}

.leaders__trigger {
  display: flex;
  padding-top: 24px;
  padding-bottom: 24px;
  align-items: center;
  transition: opacity .3s;
  opacity: .54;

  &._active {
    opacity: 1;

    .leaders__name {
      @include metam;
      @include theme-monochrome-accent(color);
    }
  }
}

.leaders__pic {
  width: 12px;
  height: 12px;
  margin-right: 24px;
  flex-shrink: 0;
  overflow: hidden;
  border-radius: 50%;
  &--extended {
    width: 120px;
    height: 120px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.leaders__intro {
  display: flex;
  flex-direction: column;
}

.leaders__name {
  color: #262626;
  @include theme-monochrome-accent(color);
  letter-spacing: -0.22px;
  margin-bottom: 8px;
  @include mix-font-size(18px, 24px);
}

.leaders__job {
  color: #595959;
  letter-spacing: -0.2px;
  @include mix-font-size(16px, 20px);
  @include theme-monochrome-accent(color);
}

.leaders__content {
  position: absolute;
  transition: opacity .3s, visibility .3s;
  top: 0;
  left: 416px;
  right: 0;
  opacity: 0;
  visibility: hidden;
  padding-top: 24px;

  &._active {
    opacity: 1;
    visibility: visible;
  }

  h4 {
    margin-bottom: 16px
  }
}

.leaders__bio {
  margin-top: 32px;
}

.leaders__history {
  margin-top: 8px;

  .col {
    margin-top: 32px;
  }
}

.leaders__year {
  @include mix-font-size(17px, 24px);
}

@media screen and (max-width: $breakpoint) {
  .leaders__item {
    width: 100%;

    + .leaders__item {
      border-top: 1px solid rgba(#a0a0a0, .24);
    }
  }

  .leaders__trigger {
    &._active {
      .leaders__pic {
      }
    }
  }

  .leaders__pic {
    img {
    }
  }

  .leaders__intro {
    display: flex;
    flex-direction: column;
  }

  .leaders__name {
    @include metam;
    color: #262626;
    letter-spacing: -0.22px;
    margin-bottom: 8px;
    @include mix-font-size(18px, 24px);
  }

  .leaders__job {
    color: #595959;
    letter-spacing: -0.2px;
    @include mix-font-size(16px, 20px);
  }

  .leaders__content {
    position: static;
    transition: height .4s;
    overflow: hidden;
    padding-top: 0;
    box-sizing: border-box;

    h4 {
      margin-bottom: 16px
    }
  }

  .leaders__bio {
    margin-top: 32px;
  }

  .leaders__history {
    margin-top: 8px;

    .col {
      margin-top: 32px;
    }
  }

  .leaders__year {
    @include mix-font-size(17px, 24px);
  }

}
