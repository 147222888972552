.attachment-zone {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-left: -1.6rem;
  margin-right: -1.6rem;
}

.attachment-item {
  @include mix-font-size(12px, 16px);
  width: calc((100% - 12.8rem) / 4);
  margin-left: 1.6rem;
  margin-right: 1.6rem;
  margin-top: 3.2rem;

  .attachment-item__icon--error {
    display: none;
  }

  &.dz-error {
    .attachment-item__icon--error {
      display: block;
    }

    .attachment-item__icon--done {
      display: none;
    }
  }
}

.attachment-item--empty {
  width: calc(100% - 3.2rem);
  margin-top: 0;
}

.attachment-item__label {
  display: inline-block;
  overflow: hidden;
  cursor: pointer;

  [type=file] {
    position: absolute;
    width: 1px;
    height: 1px;
    outline: none;
    left: -10px;
    opacity: 0;
  }
}

.attachment-item__label--empty {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
}

.attachment-item__add {
  margin-left: 1.2rem;
  @include mix-font-size(16px);
}

.attachment-item_docs {
  border: none !important;
  background: none !important
}

.attachment-item__icon {
  display: flex;

  svg {
    display: block;
  }

  img {
    display: block;
  }
}

.attachment-item__name {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.attachment-item__action {
  margin-top: 0.8rem;

}

.attachment-item__filename {
  color: #7B7B7B;
  @include theme-monochrome-accent(color);

  + .attachment-item__remove {
    margin-left: 1.2rem;
  }

}

.attachment-item__remove {
  color: $cLightGrey;
  transition: color 0.3s;
  @include theme-monochrome-accent(color);

  &:hover {
    color: $cBlack;
    @include theme-monochrome-accent(color);
  }
}

@media screen and (max-width: $breakpoint) {
  .attachment-zone {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
  }
  .attachment-item {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .attachment-item__label {
    margin-bottom: 1.6rem;
  }
}
