.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 3.2rem;
  border-top: 1px solid rgba(#A0A0A0, 0.24);

  li {
    &.left-arrow, &.right-arrow {

    }
  }
}

.pagination__list {
  display: flex;
  align-items: center;

  .disabled {
    opacity: 0.5;

    &:hover {
      cursor: default;
    }
  }
}

.pagination__item {
  display: flex;
  align-items: stretch;
  height: 3.2rem;

  .pagination__link {
    @include meta;
    color: #A0A0A0;
    @include mix-font-size(17px);
    @include theme-monochrome-accent(color);
    @include theme-monochrome-accent(border-color);

    &:hover {
      color: $cBrand;
      @include theme-monochrome-accent(color);
      @include theme-monochrome-accent(border-color);
      cursor: pointer;
    }
  }

  &._active {
    .pagination__link {
      color: inherit;
      @include metam;
      @include theme-monochrome-accent(color);
      @include theme-monochrome-accent(border-color);

      &:hover {
        color: inherit;
        cursor: default;
        @include theme-monochrome-accent(color);
        @include theme-monochrome-accent(border-color);
      }
    }
  }
}

.pagination__link {
  min-width: 3.2rem;
  height: 3.2rem;
  box-sizing: border-box;
  line-height: 3.2rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  text-align: center;
  display: block;
  transition: color 0.3s;
  @include metam;

  &[href] {
    @include meta;
    @include mix-font-size(17px);
    color: #A0A0A0;
    @include theme-monochrome-accent(color);
    @include theme-monochrome-accent(border-color);

    &:hover {
      color: $cBrand;
      @include theme-monochrome-accent(color);
      @include theme-monochrome-accent(border-color);
    }
  }
}

.pagination__action {
  .pagination__link {
    width: 3.2rem;
    height: 3.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(#A0A0A0, 0.15);
    border-radius: 50%;
    transition: border-color 0.3s;
    @include theme-monochrome-accent(color);
    @include theme-monochrome-accent(border-color);

    &:hover {
      border-color: $cBrand;
      @include theme-monochrome-accent(color);
      @include theme-monochrome-accent(border-color);

      use {
        stroke: $cBrand;
      }
    }
  }

  svg {
    display: block;
  }

  use {
    transition: stroke 0.3s;
    stroke: #595959;
  }

  &.disabled {
    .pagination__link {
      &:hover {
        cursor: default;
        border-color: rgba(#A0A0A0, 0.15);
        @include theme-monochrome-accent(color);
        @include theme-monochrome-accent(border-color);

        use {
          stroke: #595959;
        }
      }
    }
  }

  &:first-child {
    margin-right: 2.4rem;

    svg {
      margin-left: -2px;
    }
  }

  &:last-child {
    margin-left: 2.4rem;

    svg {
      margin-right: -2px;
    }
  }
}
