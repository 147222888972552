@import "variables";

@mixin mix-font-size($font, $line:inherit, $important:false) {
  @if ($important) {
    font-size: $font / $font-size-document * 1rem !important;
    @if ($line != inherit) {
      line-height: ($line / $font) * 1em !important;
    }
  }
  font-size: $font / $font-size-document * 1rem;
  @if ($line != inherit) {
    line-height: ($line / $font) * 1em;
  }
}

@function PxToRem($px) {
  @return $px / $font-size-document * 1rem
}

@mixin theme-monochrome-accent($style-color) {
  .theme-monochrome._black-accent & {
    @content;
    #{$style-color}: #000 !important;
  }
  .theme-monochrome._white-accent & {
    @content;
    #{$style-color}: #fff !important;
  }
  .theme-monochrome._sepia-accent & {
    @content;
    #{$style-color}: #472312 !important;
  }
}

@mixin theme-monochrome-ground($style-color) {
  .theme-monochrome._black-ground & {
    @content;
    #{$style-color}: #000 !important;
  }
  .theme-monochrome._white-ground & {
    @content;
    #{$style-color}: #fff !important;
  }
  .theme-monochrome._sepia-ground & {
    @content;
    #{$style-color}: #F5F5DC !important;
  }
}


// $max-width-px рекомендовано указывать кратные 100
@mixin respond-to($max-width-px) {
  @media screen and (max-width: $max-width-px * 1px) {
    @content
  }
}