.info-addon .info-addon-image {
  background-image: url("/static/img/info_addon_image.jpg"); 
  width: 75%; 
  background-size: cover;
}

.how-to-content .how-to-image {
  width: 35%;
}

.how-to-content .how-to-items {
  width: 65%;
}

@media only screen and (max-width: 600px) {
  .info-addon .info-addon-image {
    display: none;
  }

  .how-to-content .how-to-image {
    display: none;
  }

  .how-to-content .how-to-items {
    width: auto;
  }
}

.landing-button {
  letter-spacing: 0.3px !important;
  padding: 15px 36px !important;
  background: #EA5921 linear-gradient(-180deg,hsla(0,0%,94.5%,0),hsla(0,0%,90.2%,0)) !important;
}

.landing-button:hover {
  background: #EA5921 linear-gradient(-180deg,hsla(0,0%,94.5%,.1),hsla(0,0%,90.2%,.1)) !important;
}

.extra-product-content-header {
  text-align: center;
  margin-bottom: 26px;
}

.extra-product-content-header h3{
  color:#EA5921;
  margin-bottom: 10px !important;
  letter-spacing: -.4px;
  font-size: 3.8rem;
  line-height: 1em;
}

.extra-product-content-header p{
  font-size: 1.8rem;
}

.info-addon {
  display: flex;
}

.info-addon-image {
  background-image: url(/static/img/info_addon_image.jpg); width: 75%; 
  background-size: cover;
  background-position-x: center;
}

.info-addon-content {
  padding: 60px 30px 30px 30px;
}

.benefit-header {
  font-size: 1.8rem !important;
}

.benefit-preview {
  font-size: 1.9rem;
}

.benefit-section {
  display: flex;
  flex-flow: row wrap; 
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between; 
  padding-top: 24px;
}

.benefit-section .benefit {
  padding: 0px 0px 40px 0px;
}

.benefit-section .benefit-centered {
  text-align: center;
}

.benefit-section .benefit p {
  padding: 0px; 
  margin: 0px; 
  font-size: 1.7rem;
}

.terms-grid {
  display: flex; 
  flex-wrap: wrap; 
  justify-content: space-around !important;
}

.terms-grid .term-item{
  width: 29%;
  margin-left: 20px;
  margin-right: 20px;
}

.terms-grid .vpki-item .term-header{
  color: #EA5921; 
  text-align: center; 
  text-transform: none;
  font-size: 1.8rem;
}

.terms-grid .vpki-item .vpki-body .term-preview{
  color: #5a6275; 
  font-size: 1.7rem;
}

.terms-grid .vpki-item .vpki-body .term-preview i{
  font-style: italic;
}

.how-to-content {
  display: flex;
}

.how-to-content .how-to-items .how-to-items-row{
  display: flex;
  justify-content: space-around;
}

.how-to-content .how-to-items .how-to-items-row .how-to-item{
  padding: 0px 0px 40px 40px; 
  width: 50%; 
  color: rgb(90, 98, 117); 
  font-size: 1.7rem;
}

.how-to-content .how-to-items .how-to-items-row .how-to-item .how-to-item-icon{
  width: 52px; 
  height: 37px; 
  border-radius: 27px; 
  background-color: #EA5921;
  font-size: 3rem; 
  text-align: center; 
  font-weight: bold; 
  padding-top: 14px; 
  color: white; 
  margin-bottom: 10px;
}

.how-to-content .how-to-items .how-to-items-row .how-to-item .how-to-item-header{
  margin-bottom: 10px;
  font-weight: bold; 
}

.how-to-content .how-to-items .how-to-items-row .how-to-item .how-to-item-content ul{
  padding-left: 10px;
}

.how-to-content .how-to-items .how-to-items-row .how-to-item .how-to-item-content ul li{
  list-style: disc;
}

.how-to-footer-item{
  padding: 0px 0px 40px;
  font-size: 1.7rem;
}

.how-to-footer-item .how-to-footer-item-header{
  color: #EA5921; 
  text-align: center;
  text-transform: none; 
  font-weight: bold;
  padding-bottom: 12px;
}

.how-to-footer-item .how-to-footer-item-content{
  color: rgb(90, 98, 117); 
  display: flex; 
  justify-content: space-around;
}

.colored-background {
  background-color: #fecc2d;
}

.tooltip-html .tooltiptext {
  visibility: hidden;
  display: none;
  width: max-content;
  padding: 10px 10px 10px 10px;
  background-color: #d7e3e7;
  color: black;
  border-radius: 6px;
  position: absolute;
  z-index: 10000;
  font-size: 1rem;
  line-height: normal;
  font-weight: normal;
}

.tooltip-html:hover .tooltiptext {
  visibility: visible;
  display: block;
}

.tooltip-html {
  position: relative;
  display: inline-block;
  color:#EA5921;
  font-weight: bold;
}

.tooltip-html:hover {
  color:#EA5921;
  font-weight: bold;
  cursor: pointer;
}

.landing-primary-color{
  color:#EA5921 !important;
}
