.dissolution-form {
  margin-top: 3.2rem;
}

@media screen and (max-width: $breakpoint) {
  .dissolution-form {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    .lk__action {
      color: $cBrand;
      padding: 0;
      background: inherit;
      font-size: inherit;
      line-height: inherit;
    }
  }
}
