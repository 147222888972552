.gallery {
  margin-top: 4.8rem;
  margin-bottom: 4.8rem;

  .swiper-button-prev, .swiper-button-next {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #fff;
    border: 1px solid rgba(#a0a0a0, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    top: 168px;

    svg {
      display: block;
    }

    use {
      transition: stroke 0.3s;
      stroke: #595959;
    }

    &:hover {
      use {
        stroke: $cBrand;
      }
    }
  }

  .swiper-button-prev {

  }

  .swiper-button-next {

  }
}

.gallery__slide {

}

.gallery__image {
  height: 368px;

  picture, img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.gallery__description {
  text-align: center;
  padding: 1.6rem;
  box-sizing: border-box;
  @include mix-font-size(14px, 24px);
  color: #A0A0A0;
  background: #ffffff;
  border-bottom: 1px solid rgba(#a0a0a0, 0.24);
}

@media screen and (max-width: $breakpoint) {
  .gallery .swiper-button-prev, .gallery .swiper-button-next {
    top: 64px;
  }
}
