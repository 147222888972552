.fs-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #F7F7F7;
  z-index: 1000;
  padding-top: 96px;
  text-transform: none;
  @include theme-monochrome-ground(background);
  @include respond-to(800) {
    padding-top: 72px;
  }

  .wrapper {
    height: 100%;
  }
}

.fs-modal-scroller {
  background: #FFFFFF;
  border-radius: 4px;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  @include theme-monochrome-ground(background);
}

.fs-modal-content {
  background: #FFFFFF;
  padding-top: 56px;
  margin-left: 72px;
  margin-right: 72px;
  position: relative;
  @include theme-monochrome-ground(background);

  .big-font-size & {
    margin-left: 10px;
    margin-right: 10px;
  }

  @include respond-to(800) {
    margin: 0;
    padding: 24px 16px 0;
  }
}


.fs-modal-header {
  @include meta;
  color: #262626;
  letter-spacing: -0.75px;
  text-transform: none;
  text-align: center;
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid rgba(#A0A0A0, 0.24);
  @include mix-font-size(60px, 56px);
  @include theme-monochrome-accent(color);
  @include respond-to(800) {
    @include mix-font-size(34px, 48px);
  }
}

.fs-modal-header--small {
  @include meta;
  letter-spacing: -1px;
  color: $cBlack;
  text-align: left;
  @include mix-font-size(48px, 48px);
  @include theme-monochrome-accent(color);
}

.fs-modal-header--center {
  text-align: center;
  font-size: 3.8rem;
}

.fs-modal-close {
  position: absolute;
  width: 14px;
  height: 14px;
  left: 50%;
  top: 32px;
  padding: 14px;
  transform: translateX(-50%);
  border: 1px solid rgba(#A0A0A0, 0.25);
  border-radius: 50%;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDE0IDE0Ij48cGF0aCBkPSJNMTMgMUwxIDEzTTEgMWwxMiAxMiIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2U9IiMyNjI2MjYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIi8+PC9zdmc+);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  transition: transform 0.4s;
  @include theme-monochrome-ground(border-color) {
    filter: invert(1);
  }
  @include respond-to(800) {
    top: 16px;
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  &:hover {
    transform: translateX(-50%) rotate(180deg);
  }
}

.fs-modal-footer {
  padding-bottom: 48px;
}

.sweet-modal .sweet-box-actions .sweet-action-close {
  transition: background-color .3s, color .3s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.sweet-modal-overlay {
  z-index: 9999999999 !important;
}

.sweet-modal .sweet-box-actions .sweet-action-close:hover {
  background: $cBrand !important;
  @include theme-monochrome-ground(background);
}

.multiselect__option--highlight {
  background: $cBrand !important;
  @include theme-monochrome-accent(color);
}

.multiselect__option--highlight:after {
  background: $cBrand !important;
  @include theme-monochrome-accent(color);
}

.multiselect__tags {
  border-radius: 0;
  border: none;
  border-bottom: 2px solid $cBlack;
  background: none;
  margin-top: -3px;
  padding: 8px 40px 0px 8px !important;
  @include theme-monochrome-accent(border-bottom-color);
}

.multiselect__tags:focus {
  border-bottom-color: $cBrand;
  @include theme-monochrome-accent(border-bottom-color);
}

.multiselect__single {
  background: none;
  font-family: 'FFMetaWebProMedium', sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #262626;
  margin-bottom: 2px;
  @include mix-font-size(16px, 24px);
  @include theme-monochrome-accent(color);
}

.multiselect .filled {
  border-bottom: 2px solid #D4D4D4;
  @include theme-monochrome-ground(border-bottom-color);

  + .form__label {
    top: 0.6rem;
    @include mix-font-size(14px, 20px);
  }

  &:focus {
    border-bottom-color: $cBrand;
    @include theme-monochrome-accent(color);
  }
}

.multiselect__input {
  padding: 0 !important;
}

.multiselect__input:focus {
  border-bottom-color: transparent !important;
}

.multiselect__content-wrapper {
  min-width: 100%;
  max-width: 400px;
  overflow-x: scroll;
  scrollbar-width: thin;
  @include mix-font-size(16px);
}
