.content_lk {
  background: none;
  font-size: 1.6rem;
  align-items: flex-start;
  box-shadow: none;
  padding: 8px 16px 80px;
  @include respond-to(800) {
    padding: 8px 0 32px;
  }
}

.lk__section {
  padding-top: 3.2rem;
  padding-bottom: 3.2rem;
  border-bottom: 1px solid #DEDEDE;
}

.lk__message {
  background-color: #FFFFFF;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  color: #828382;
  @include mix-font-size(24px, 38px);
  letter-spacing: -0.3px;
  margin-top: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 98px;
  padding-bottom: 98px;

  p {
    text-align: center;
  }
}

.lk__icon {
  margin-right: 0.6rem;
}

.lk__right {
  width: 23.2rem;
  flex: 0 0 auto;

  &.is-bottom {
    align-self: flex-end;
  }
}

.lk__left {
  /*flex: 1;*/
  width: calc(100% - 25.2rem);
  padding-right: 2rem;
}

.lk__actions {
  margin-top: 0.8rem*2 - 1.2rem;
  margin-left: -1.2rem;

  > .btn.lk__actions-item {
    margin-left: 1.2rem;
    margin-top: 1.2rem;
  }
}

.lk__action {
  display: inline-flex;
  align-items: center;
  transition: color 0.3s;
  @include respond-to(800) {
      font-size: 1.4rem;
      width: 30%;
  }
  svg {
    stroke: $cBlack;
    margin-left: 0.6rem;
    transition: stroke 0.3s, transform 0.2s;
    position: relative;
    bottom: 1px;
    flex-shrink: 0;
  }

  &:hover {
    color: $cBrand;

    svg {
      stroke: $cBrand;
      transform: translateX(0.6rem);
    }
  }

  .product__hero & {
    svg {
      stroke: $cWhite;
    }

    &:hover {
      svg {
        stroke: $cBrand;
      }
    }
  }
}

.lk__action--backlink {
  svg {
    margin-left: 0;
    margin-right: 0.6rem;
  }

  &:hover {
    svg {
      transform: translateX(-0.6rem);
    }
  }
}

.lk__action--active {
  color: $cBrand;

  svg {
    stroke: $cBrand;
  }

  &:hover {
    color: $cBlack;

    svg {
      stroke: $cBlack;
    }
  }
}

.lk__action--active-search {
  color: $cBrand;
  @include respond-to(800) {
    font-size: 1.4rem;
    width: 100%;
  }
  svg {
    stroke: $cBrand;
  }

  &:hover {
    color: $cBlack;

    svg {
      stroke: $cBlack;
    }
  }
}

.lk__action--has-badge {
  position: relative;
  padding-right: 3.4rem;

  .badge {
    position: absolute;
    right: 0;
    top: 0.4rem;
  }
}

.lk__header {
  @include meta;
  font-size: 3.2rem;
  line-height: 3.2rem;
  letter-spacing: -0.4px;

  + .lk__subheader {
    margin-top: 0.8rem;
    color: $cGrey;

    .lk-entity__status:not(.lk__subheader--no-offset) {
      margin-left: 1.6rem;
    }
  }
}

.lk__header--gray {
  color: $cLightGrey;
}

.lk-entity__status--red {
  color: $cError;
}

.lk-entity__status--gray {
  color: $cLightGrey;
}

.lk__entity {
  margin-top: 2.4rem;
}

.lk-entity__name {
  @include metam;
  margin-bottom: 0.8rem;
}

.lk-entity__info {
  color: $cGrey;

  + .lk-entity__action {
    margin-top: 1.6rem;
  }

  + .lk-entity__info {
    margin-top: 0.8rem;
  }

  .lk-entity__status {
    margin-left: 1.6rem;

    + .lk-entity__status {
      margin-left: 0.8rem;
    }
  }

}


.lk-entity__action {
  margin-top: 0.8rem;

  &:first-child {
    margin-top: 0;
  }
}

.lk-entity__action--main {
  + .lk-entity__action {
    margin-top: 1.6rem;
  }
}

.lk-entity__row {
  margin-top: 1.6rem;

  .lk-entity__name + .lk-entity__info {
    margin-left: 0.8rem;
  }
}

.text--meta {
  color: $cLightGrey;
}

.text--error {
  color: $cError;
}


.lk-incident__status {
}

.lk-incident__header {
  svg {
    display: block;
  }

  .lk__icon {
    display: inline-block;
    width: 2.4rem;
    height: 2.4rem;
    line-height: 2.4rem;
    margin-right: 1.2rem;
    vertical-align: bottom;

    svg {
      display: block;
    }

  }
}

.lk-incident__text {
  font-size: 1.4rem;
  line-height: 1.6rem;

  p, p + p {
    margin-top: 0.8rem;
  }
}

.lk-incident__actions {

}

.lk__products {
  position: relative;
}

.lk__filter-select {
  display: none;
}

@media screen and (max-width: $breakpoint) {
  .lk__dropdown {
    display: none;
  }
  .lk__filter-select {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    line-height: 0;
    overflow: hidden;
    width: 1.8rem;
    height: 1.6rem;

    select {
      position: absolute;
      padding: 0;
      border: 0;
      margin: 0;
    }
  }
  .lk__left,
  .lk__right {
    width: 100%;
    order: 2;

    &.is-mobile-first {
      order: 1;
    }
  }
  .lk-incident__text,
  .lk-entity__status {
    display: none;
  }
  .lk-incident__status {
    margin-bottom: 16px;
  }
  .lk__header {
    font-size: 2.4rem;
    line-height: 2.4rem;
    letter-spacing: -0.03rem;
  }
  .lk-entity__action,
  .extra-info__action {
    .btn {
      width: fit-content;
    }
  }
  /*
  .lk__action{
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.4rem;
    letter-spacing: -0.047rem;
    padding: 12px 24px;
    color: #262626;
    border-radius: 0.4rem;
    background-color: #f2f2f2;
    svg{
      display: none;
    }
  }
  */
  /*
  .lk__action--active{
    color: #fff;
    background-color: #f36b20;
    background-image: linear-gradient(180deg, rgba(241, 241, 241, 0.1) 0%, rgba(230, 230, 230, 0.1) 100%);
  }
  */
  /*
  .lk__action--has-badge{
    padding-right: 50px;
    .badge{
      top: 1.4rem;
      right: auto;
      left: calc(100% - 4.2rem);
    }
  }
  */
  .lk-entity__action + .lk-entity__action {
    margin-top: 1.6em;
  }
  .lk__right__with-mobile-offset .lk__right {
    margin-top: 1.6em;
  }
}

.form-input--new {
  .input-field__input {
    border: 1px solid #f1f1f1 !important;
    border-radius: 15px !important;
    padding: 1rem !important;
    text-align: center;
    box-sizing: border-box;
  }
  .input-field__label {
    text-align: center;
    left: 50% !important;
    transform: translateX(-50%);
    width: 100%;
  }
  .input-field__eye {
    top: 4rem;
  }
  .input-field__error {
    text-align: center;
  }
  .input-field__input:not(:focus):not(._filled) +.input-field__label {
    top: 3.4rem!important;
  }
  .input-field__eye {
    top: 3rem!important;
  }
}

.fg-input--new {
  padding-bottom: 8px!important;
  padding-top: 2.4rem!important;
  .input-field__error {
    display: none!important;
  }
  
  .input-field__input:focus+.input-field__label,
  ._filled+.input-field__label, 
  ._focus+.input-field__label, 
  ._radio .input-field__label {
    top: 0!important;
  }
  
}

.auth__input--email .input-field__label:before {
  content: "* ";
  color: red;
}

.fg-input--new.input-field.is-loading:before {
  top: 75px!important;
}