.corp__hero {
  margin-left: -72px;
  margin-right: -72px;
  position: relative;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 64px;
  opacity: 0;
  transition: opacity 0.5s;
  @include respond-to(600) {
    background: none!important;
  }
  @include respond-to(800){
    margin-left: -24px;
    margin-right: -24px;
    margin-bottom: 40px;
  }
  * {
    transform: translateY(-20px);
    transition: opacity 0.6s, transform 0.6s;
    transition-delay: 0.5s;
    opacity: 0;
  }

  &.view-in {
    opacity: 1;

    * {
      transform: translateY(0);
      opacity: 1;
    }
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    @include respond-to(600) {
      display: none;
    }
  }
  &--margin {
    @include respond-to(800){
      margin-left: -16px;
      margin-right: -16px;
      margin-bottom: 0;
    }
  }
}

.corp-hero__header {
  color: #FFFFFF;
  letter-spacing: -0.4px;
  margin-bottom: 48px;
  @include mix-font-size(24px, 32px);
  @include theme-monochrome-accent(color);
  @include respond-to(600) {
    color: $cBlack;
    @include mix-font-size(24px, 32px);
  }
  @include respond-to(800) {
    @include mix-font-size(20px, 24px);
  }
}

.corp-hero__content {
  color: $cWhite;
  position: relative;
  @include theme-monochrome-accent(color);
  @include respond-to(600) {
    color: $cBlack;
  }
  @include respond-to(800) {
    padding: 16px;
  }
  p {
    max-width: 65.6rem;

    &.header {
      color: #FFFFFF;
      letter-spacing: -0.4px;
      margin-bottom: 48px;
      @include mix-font-size(24px, 32px);
      @include theme-monochrome-accent(color);
    }

    &.header--larger {
      @include mix-font-size(32px);
    }
    &.header--border {
      @include respond-to(600) {
        border-top: 1px solid #f1f1f1;
        padding-top: 24px;
        margin-bottom: 24px;
      }
    }
  }

  h1 {
    color: $cWhite;
    @include theme-monochrome-accent(color);
  }
}

.corp__features {

}

.corp-feature {
  padding-left: 72px;
  position: relative;

  + .corp-feature {
    margin-top: 48px;
  }

  transition: opacity 0.6s, transform 0.6s;
  opacity: 0;
  transform: scale(0.9);

  &.view-in {
    opacity: 1;
    transform: scale(1);
  }
}

.cf-icon {
  width: 56px;
  height: 56px;
  position: absolute;
  left: 0;
  top: -8px;

  img {
    display: block;
  }
}

.cf-content {
  h5 {
    margin-bottom: 16px;
  }

  ul {
    margin-top: 16px;
  }
}

.corp__tabs {
  margin-top: 80px;
  margin-bottom: 80px;
}

//------------------------------------------------
#floating-button {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  z-index: 99999999;
  background: $cBrand;
  position: fixed;
  bottom: 30px;
  right: 30px;
  cursor: pointer;
  box-shadow: 0 2px 5px #666;
}

.plus {
  color: white;
  position: absolute;
  top: 3px;
  display: block;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 0;
  margin: 0;
  font-weight: 300;
  animation: plus-out 0.3s;
  transition: all 0.3s;
  @include mix-font-size(32px, 55px);
}

#container-floating {
  position: fixed;
  width: 70px;
  height: 70px;
  z-index: 99999999;
  bottom: 30px;
  right: 30px;
}

#container-floating:hover {
  height: 400px;
  width: 90px;
  padding: 30px;
}

#container-floating:hover .plus {
  animation: plus-in 0.15s linear;
  animation-fill-mode: forwards;
}

.edit {
  position: absolute;
  top: 0;
  display: block;
  bottom: 0;
  left: 0;
  display: block;
  right: 0;
  padding: 0;
  opacity: 0;
  margin: auto;
  line-height: 65px;
  transform: rotateZ(-70deg);
  transition: all 0.3s;
  animation: edit-out 0.3s;
}

#container-floating:hover .edit {
  animation: edit-in 0.2s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;
}

@keyframes edit-in {
  from {
    opacity: 0;
    transform: rotateZ(-70deg);
  }
  to {
    opacity: 1;
    transform: rotateZ(0deg);
  }
}

@keyframes edit-out {
  from {
    opacity: 1;
    transform: rotateZ(0deg);
  }
  to {
    opacity: 0;
    transform: rotateZ(-70deg);
  }
}

@keyframes plus-in {
  from {
    opacity: 1;
    transform: rotateZ(0deg);
  }
  to {
    opacity: 0;
    transform: rotateZ(180deg);
  }
}

@keyframes plus-out {
  from {
    opacity: 0;
    transform: rotateZ(180deg);
  }
  to {
    opacity: 1;
    transform: rotateZ(0deg);
  }
}

.nds {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: fixed;
  z-index: 99999999;
  transform: scale(0);
  cursor: pointer;
}

.nd1 {
  background: $cBrand;
  right: 40px;
  bottom: 120px;
  animation-delay: 0.2s;
  animation: bounce-out-nds 0.3s linear;
  animation-fill-mode: forwards;
}

.nd3 {
  background: #f7f7f7;
  right: 40px;
  bottom: 180px;
  animation-delay: 0.15s;
  animation: bounce-out-nds 0.15s linear;
  animation-fill-mode: forwards;
}

.nd4 {
  background: $cBrand;
  right: 40px;
  bottom: 240px;
  animation-delay: 0.1s;
  animation: bounce-out-nds 0.1s linear;
  animation-fill-mode: forwards;
}

.nd5 {
  background-image: url('https://lh3.googleusercontent.com/-X-aQXHatDQY/Uy86XLOyEdI/AAAAAAAAAF0/TBEZvkCnLVE/w140-h140-p/fb3a11ae-1fb4-4c31-b2b9-bf0cfa835c27');
  background-size: 100%;
  right: 40px;
  bottom: 300px;
  animation-delay: 0.08s;
  animation: bounce-out-nds 0.1s linear;
  animation-fill-mode: forwards;
}

@keyframes bounce-nds {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes bounce-out-nds {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0);
  }
}

#container-floating:hover .nds {

  animation: bounce-nds 0.1s linear;
  animation-fill-mode: forwards;
}

#container-floating:hover .nd3 {
  animation-delay: 0.08s;
}

#container-floating:hover .nd4 {
  animation-delay: 0.15s;
}

#container-floating:hover .nd5 {
  animation-delay: 0.2s;
}

.letter {
  font-family: 'Roboto', sans-serif;
  color: white;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  @include mix-font-size(23px, 40px);
}

.reminder {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  line-height: 40px;
}

.profile {
  border-radius: 50%;
  width: 40px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 20px;
}

.nds .tooltiptext {
  visibility: hidden;
  background-color: #f7f7f7;
  color: #898989;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 99999999;
  top: -5px;
  right: 105%;
  width: max-content;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px;
  margin-right: 10px
}

.nds:hover .tooltiptext {
  visibility: visible;
}

.main_tooltip .tooltiptext {
  visibility: hidden;
  background-color: #f7f7f7;
  color: #898989;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 99999999;
  right: 105%;
  width: max-content;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px;
  margin-right: 10px
}

.main_tooltip:hover .tooltiptext {
  visibility: visible;
}

.header {
  &--white {
    color: $cWhite;
    letter-spacing: -1px;
    margin-bottom: 32px;
    @include mix-font-size(48px, 48px);
    @include theme-monochrome-accent(color);
    @include respond-to(600) {
      @include mix-font-size(30px, 30px);
    }
  }
}

//------------------------------------------------

@media screen and (max-width: $breakpoint) {
  .corp-feature {
    padding-left: 0;
  }

  .cf-icon {
    position: static;
    margin-bottom: 24px;
  }
  .corp__tabs {
    margin-top: 56px;
    margin-bottom: 56px;
  }
}
