.content__aside,
.aside {
  box-sizing: border-box;
  width: 416px;
  border: 8px solid $cWhite;
  flex: 0 0 auto;
  background-color: #F7F7F7;
  padding: 104px 64px 80px;
  @include theme-monochrome-ground(background-color);
  @include theme-monochrome-accent(border-color);
  @include respond-to(800) {
    padding: 16px;
    width: 100%;
    border: 16px solid $cWhite;
  }
  &[data-inview] {
    transition: opacity 0.5s, transform 0.5s;
    opacity: 0;

    &.view-in {
      opacity: 1;
    }
  }

  .dummy {
    padding-top: 141px;
  }
  &--empty {
    @include respond-to(800) {
      display: none;
    }
  }

  &_lk {
    width: 384px;
    border: none;
    padding: 0;
    margin: 116px 32px 0;
    @include respond-to(800) {
      margin: 0;
      margin-top: 24px;
      border: none;
    }
  }

  &_policy-detail {
    width: 384px;
    border: none;
    border-radius: 8px;
    padding: 105px 0 0 32px;
    @include respond-to(800) {
      border: none;
      display: flex;
      flex-direction: column;
    }
  }
  &__extra {
    margin-top: 17px;
    @include mix-font-size(17px, 24px);
  }
  &__extra-links {
    display: flex;
    flex-direction: column;
    @include mix-font-size(17px, 24px);
  }
  &__title {
    padding: 24px 40px;
    border-bottom: 3px solid #F7F7F7;
    @include theme-monochrome-accent(border-color);
    text-transform: uppercase;
    @include respond-to(800) {
      padding: 20px 16px;
    }
  }
  &__wrapper {
    padding: 24px 40px;
    @include respond-to(800) {
      padding: 20px 16px;
    }
  }
  &__point {
    &:not(:first-child) {
      padding-top: 24px;
    }
    @include metam;
  }
  &__list {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
  }
  &__btns {
    width: 100%;
    display: flex;
    flex-direction: column;
    .btn-new {
      &:not(:first-child) {
        margin-top: 8px;
      }
    }
  }
}

.aside__section {
  @include theme-monochrome-ground(background-color);
  + .aside__section {
    margin-top: 40px;
  }
  &_policy-detail {
    background-color: white;
    border-radius: 8px;
    @include respond-to(800) {
      margin-top: 24px;
      order: 2;
    }
  }
}

.aside__profile {
  background-color: white;
  border-radius: 8px;
}


.aside-section__header {
  padding-bottom: 16px;
  text-transform: uppercase;
  border-bottom: 1px solid rgba($cLightGrey, 0.24);
  @include theme-monochrome-accent(border-color);
}

.aside-section-list__item {
  border-bottom: 1px solid rgba($cLightGrey, 0.24);
  padding-top: 16px;
  padding-bottom: 16px;
}

.aside-section__manager {
  padding-top: 24px;
  padding-bottom: 24px;
  background-color: #ffffff;
  &_policy-detail {
    border-radius: 8px;
    margin-top: 24px;
    padding: 36px 40px;
    @include theme-monochrome-ground(background-color);
    @include respond-to(500) {
      padding: 32px 16px;
      order: 1;
      margin-top: 0;
    }
  }
}

.aside-section__action {
  display: flex;
  align-items: center;
  height: 48px;
  margin-top: 16px;
  background: $cWhite;
  border-radius: 4px;
  padding-left: 22px;

  svg {
    margin-right: 12px;
    display: block;
  }

}

.aside-manager__info {
  overflow: hidden;

  picture {
    float: right;
    margin-left: 16px;
    width: 56px;
    height: 56px;
    position: relative;
    transform: translate3d(0, 0, 0);
    border-radius: 50%;
    overflow: hidden;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  p {
    + p {
      margin-top: 8px;
    }
  }

  p.manager-name {
    @include metam;
    letter-spacing: -0.23px;
    color: $cBlack;
    @include mix-font-size(18px, 20px);
    @include theme-monochrome-accent(color);
  }

  p.manager-title {
    color: $cGrey;
    letter-spacing: -0.2px;
    line-height: 20px;
    @include theme-monochrome-accent(color);
  }
}

.aside-manager__contacts {
  margin-top: 16px;

  p {
    margin-top: 0.8rem;
  }
}

.questionnaire-starter {
  border: 1px solid #E8E8E8;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.qstarter__icon {

}

.qstarter__header {
  margin-top: 16px;
  margin-bottom: 16px;
  color: $cBlack;
  letter-spacing: -0.22px;
  @include mix-font-size(18px, 20px);
}

.qstarter__text {
  line-height: 20px;
  color: $cGrey;
  width: 100%;
}

.qstarter__action {
  margin-top: 16px;
}

.files__list {

}

.files__item {
  padding-top: 24px;
}

.files__link {
  display: block;
  line-height: 16px;

  &:hover {
    .file__name {
      color: $cBrand;
    }
  }
}

.file__name {
  @include metam;
  color: #3D474D;
  transition: color 0.3s;
  @include mix-font-size(14px);
}

.file__info {
  margin-top: 8px;
  opacity: 0.5;
  font-family: Helvetica, sans-serif;
  color: #6B7B84;
  @include mix-font-size(11px);
}

.aside {
  &__header{
    color: #3C3C3B;
    text-transform: uppercase;
    @include mix-font-size(16px, 24px);
    padding: 24px 40px 16px;
    border-bottom: 3px solid #F7F7F7;
    @include theme-monochrome-ground(border-color);
    @include theme-monochrome-accent(color);
    @include respond-to(500) {
      padding: 24px 16px;
    }
  }
  &__links {
    display: flex;
    flex-direction: column;
    padding: 24px 40px;
    @include respond-to(500) {
      padding: 24px 16px;
    }
  }

  &__link {
    color: #f36c21;
    fill: #f36c21;
    @include mix-font-size(17px, 24px);
    @include theme-monochrome-accent(color);
    &:not(:first-child) {
      margin-top: 17px;
    }

    img {
      margin-right: .4em;
    }
  }

  &__hr {
    display: block;
    margin: 20px 0 0;
    border: 0;
    height: 1px;
    background: #e8e8e8;
    @include theme-monochrome-ground(background-color);
  }
}

@media screen and (max-width: $breakpoint) {
  .content__aside {
    .dummy {
      padding-top: 0;
    }
  }
}
