.damage-content {
  .subheader {
    margin-bottom: 0;

    + p {
      margin-top: 0;
    }
  }
}

.damage-wizard {

}

.dw-steps-header {
  display: flex;
  justify-content: space-between;
  counter-reset: cnt;
  margin-top: 2.4rem;
  margin-bottom: 2.4rem;
}

.dw-steps__item {
  counter-increment: cnt;
  position: relative;
  padding-left: 40px;
  @include mix-font-size(14px, 16px);
  color: #A0A0A0;

  &:before {
    content: counter(cnt);
    width: 32px;
    height: 32px;
    box-sizing: border-box;
    border: 1px solid #A0A0A0;
    color: #A0A0A0;
    line-height: 30px;
    border-radius: 50%;
    background-color: $cWhite;
    @include metam;
    text-align: center;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.dw-steps__item--filled {
  color: #A0A0A0;

  &:before {
    border-color: $cBrand;
    background-color: $cBrand;
    color: $cWhite;
  }
}

.dw-steps__item--active {
  color: $cBlack;

  &:before {
    border-color: $cBrand;
    background-color: $cBrand;
    color: $cWhite;
  }
}

.dw-content {
  background: #F7F7F7;
  border-radius: 4px;
  padding: 4rem 3.2rem;
  margin-bottom: 40px;
  margin-top: 40px;
}

.dw-status-message {
  padding-bottom: 2.4rem;
  border-bottom: 1px solid rgba(#A0A0A0, 0.24);
  padding-right: 7.2rem;
  position: relative;

  &:after {
    content: '';
    width: 50px;
    height: 50px;
    position: absolute;
    right: 0;
    top: 0;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MCIgaGVpZ2h0PSI1MCI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2U9IiNGMzZDMjEiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxIDEpIj48Y2lyY2xlIGN4PSIyNCIgY3k9IjI0IiByPSIyNCIvPjxwYXRoIGQ9Ik0yNCAxMy40VjI4bTAgNi40djEiLz48L2c+PC9zdmc+);
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }
}

.dwsm__header {
  color: #262626;
  letter-spacing: -0.3px;
  margin-bottom: 1.6rem;
  @include mix-font-size(24px, 24px);
}

.dwsm__text {
  color: #595959;
}

.dw-file-zone {

}


.damage-filled-info {

}

.dfi-section {
  margin-top: 2.4rem;
}

.dfi-section-header {
  @include metam;
  color: #262626;
  margin-bottom: 20px;
  @include mix-font-size(17px, 24px);
}

.dfi-list {
  .row {
    display: flex;

    + .row {
      margin-top: 16px;
    }
  }

  dt {
    width: 136px;
    flex-grow: 0;
    color: #7B7B7B;
    @include mix-font-size(14px, 16px);
  }

}

.dfi-attachments {
  display: flex;
  align-items: flex-start;
}

.dfi-attachment-item {
  width: 117px;
  margin-right: 19px;
}

.dfi-ai-image {
  height: 64px;
  margin-bottom: 16px;

  img {
    height: 100%;
    width: auto;
  }
}

.dfi-ai-name {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #7B7B7B;
  @include mix-font-size(12px, 16px);
}

@media screen and (max-width: $breakpoint) {
  .dw-steps__item {
    padding-left: 0;
    position: relative;

    &:before {
      position: static;
      display: block;
      transform: none;
    }

    span {
      position: absolute;
      display: none;
    }
  }
  .dw-steps__item--active {
    span {
      display: block;
      margin-top: 5px;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
    }
  }

  .dw-content {
    margin-left: -1.6rem;
    margin-right: -1.6rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;

    .lk__action {
      color: $cBrand;
      padding: 0;
      background: inherit;
      font-size: inherit;
      line-height: inherit;
    }
  }

  .dfi-attachments {
    flex-wrap: wrap;
  }
  .dfi-attachment-item {
    margin-bottom: 2.4rem;
  }
}
