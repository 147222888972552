.courses-list {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  &__item {
    display: inline-block;
    width: 280px;
    margin-bottom: 35px;
  }

  &__img {
    display: block;
    object-fit: cover;
  }

  &__name {
    color: #262626;
    font-size: 24px;
    letter-spacing: -0.3px;
    line-height: 29/24*1em;

    &:not(:first-child) {
      margin-top: 16px;
    }
  }

  &__description {
    color: #a0a0a0;
    font-size: 14px;
    line-height: 20/14*1em;

    &:not(:first-child) {
      margin-top: 11px;
    }
  }

  &__actions {
    display: block;
    font-size: 17px;

    &:not(:first-child) {
      margin-top: 8px;
    }
  }
}