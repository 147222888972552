.content__inner--consultation {
  display: flex;
  flex-direction: column;
  @include respond-to(800) {
    padding-left: 16px;
    padding-right: 16px;
  }

  iframe {
    @include respond-to(800) {
      height: 90vh;
    }
  }
  .consultation-content {
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
  }
}

.consultation-content-chat {
  width: 100%;
  overflow: auto;
  display: flex;
}

.cons-list {

}

.cons-item {
  padding-top: 3.2rem;
  padding-bottom: 3.2rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #DDDDDD;
}

.cons-photo {
  width: 9.6rem;
  margin-right: 4rem;
}

.cons-image {
  width: 9.6rem;
  height: 9.6rem;
  border-radius: 50%;
  overflow: hidden;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.cons-info {
  flex-grow: 1;
}

.cons-name {
  @include meta;
  letter-spacing: -0.4px;
  margin-bottom: 8px;
  @include mix-font-size(32px, 32px);

  + .cons-job {
    margin-top: 0;
  }
}

.cons-job {
  @include metam;
  @include mix-font-size(16px, 24px);
}

.cons-actions {
  width: 262px;
  flex-grow: 0;
}

.cons-button {
  + .cons-progress {
    margin-top: 16px;
  }
}

.cons-progress {
  position: relative;

  p + p {
    margin-top: 0;
  }

  &:before {
    content: '';
    width: 22px;
    height: 22px;
    position: absolute;
    left: -32px;
    top: 0;
    background-position: 0 50%;
    background-repeat: no-repeat;
  }
}

.cons-progress--processing {
  &:before {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAUCAYAAACEYr13AAABcElEQVQ4T5WUzVECQRCFX/cWXOWocJAM1AiUAs8Swm4ErgEAwxqAEAGYAd7dEjPADLBKwCNeoXbaGthdfhQY5jg173tvunuG6mF+AOCENXvq9quPIxfVw7wkGgK1iDNNVRpObTkbgFg0ZMGDqox7NpAUwExXWksXwIURCtBzOOsdSpMCgvKYjLAWnvkEUqYuAKYQ8oPK6HlXmj8Ac1C9nRa1drqAXC/SkPQdEk+VvofboH8ByaFaWHAJ0lqlERVUJu11yF7AMk0xp6NZF4S7hVAwYIc8VRqZ9uMgIHFTr/mqJpg05/GeCsrjpjUgTaPnCpD7JM3RANHzhkD8OMWHNSC+whOAYixuBuWxOggwRYz0rENAdSmkd2b4VkWM22hccwB+BKIeyxNTyHTtHKRIqENCNyvXyLUapHiUG4krC9x9D2vzMUXSAeEybtELO1nX+jGtXeuTNbu2n8vWf0Bt5ow65LpVxEIfkBxr9m1d1wG/zyDSXT90UyQAAAAASUVORK5CYII=);
  }
}

.cons-progress--planned {
  &:before {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAACMklEQVRIS6WV0W0aQRCGv5HvnCdiU0E2FRhXYKgguILYb1FAMh3EqcBEAilvSQfBFcSpIFBBrgPA5CVmyUazt0eOy92ZyCutkOaWf//9Z/4ZoWLNB+Y4trx2v2mL0HJg9KhA4hxTESbrmNvmMFmUQUgxqIDRA1cIA+C46uIQX+AY2kM+FC/YAfaga74CLf9H4Rtwp9tGTDUUWf+t7bfjLFwwtTGdPPgWOID+CCyXTrh4PkomdYzv+6Yrjs/AEbCwMS8zcA9cYDqzG7rNj0mSB131zR0O1xgnnXx8/saY6AAlcAJsmXvgVd9c43gHLO2GVhHUn+kZp7+NcfJvXlJwleoI4X1jlFxLXgInnFc9vw5YLwyyfMkkkZ89M3Bwo4lqjBJNSul6DDi8XOU6c45LWfWM6vMqe8ITgTNJb+W+Z1S0FwidxijR0noKYy1BLdepMvZJsTHNKhflk1d3LuRr7i3wH8Ca9Z2SKnXtmhR4XykCG5WqEnzVN5kUs72TlzPSFrwxTk4LJsol7625EOGT9oOiq8qymGOuZkl7SljBnWm5hYNq36M6g9T1DG+QvwSXNsYULb2wG07LLF0HHPrFd9/AMkuXaWc3nO8LHkDVyirLzMa0tWyLbdNLon53wuWjbTN9/k3WalWCnbaZPbNQUhpW7/ttnzHTQPSLE4S232nD17VlmmGVjybLAOdHk7KvW0uEoY0Y1o6mPIKyP3igK0KXdJCqMTw70oE62RwyqWoDfwCnakntzgEL0AAAAABJRU5ErkJggg==);
  }
}

.consultation-content {
  iframe {
    width: 100%;
  }
}

.cons-result {
  margin-bottom: 40px;
}

@media screen and (max-width: $breakpoint) {
  .cons-item {
    flex-direction: column;
  }
  .cons-photo {
    margin-right: 0;
    margin-bottom: 2.4rem;
  }
  .cons-info {
    margin-bottom: 2.4rem;
  }
  .cons-progress {
    margin-left: 32px;
  }
}
