.form__radio {
  overflow: hidden;
  position: relative;

  + .form__radio {
    margin-top: 1.6rem;
  }
}

input[type=radio] {
  width: 1px;
  height: 1px;
  opacity: 0.1;
  position: absolute;
  left: -1rem;
  z-index: -1;
}

input[type=radio] + label {
  display: inline-block;
  min-height: 24px;
  padding-left: 34px;
  position: relative;
  @include mix-font-size(14px, 24px);

  &:not(:first-of-type) {
    margin-left: 1.5em;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    width: 24px;
    height: 24px;
    background: $cWhite;
    border: 1px solid #D9D9D9;
    box-shadow: inset 0 -2px 0 0 #F2F2F2;
    border-radius: 50%;
    box-sizing: border-box;
    transition: background-color 0.3s, border-color 0.3s, box-shadow 0.3s;
    @include theme-monochrome-ground(background);
    @include theme-monochrome-ground(border-color);
  }

  &:after {
    content: '';
    width: 8px;
    height: 8px;
    position: absolute;
    left: 8px;
    top: 8px;
    border-radius: 50%;
    transform-origin: 50% 50%;
    transform: scale(0.4);
    opacity: 0;
    transition: transform 0.2s, opacity 0.2s;
    background: $cWhite;
    @include theme-monochrome-ground(background);
  }

  &:hover {
    cursor: pointer;
  }

  a {
    border-bottom: 1px solid $cGrey;
    @include theme-monochrome-accent(border-bottom-color);
  }
}

input[type=radio]:checked + label {
  &:before {
    background: $cBrand;
    border-color: $cBrand;
    box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.04);
    @include theme-monochrome-accent(background);
    @include theme-monochrome-accent(border-color);
  }

  &:after {
    opacity: 1;
    transform: scale(1);
  }
}


input[type=radio]:disabled + label {
  &:hover {
    cursor: default;
  }
}

input[type=radio]:disabled:checked + label {
  &:before {
    background: #D9D9D9;
    border: 1px solid $cWhite;
    box-shadow: none;
    @include theme-monochrome-ground(background);
    @include theme-monochrome-ground(border-color);
  }

  &:after {

  }

}