.popup {
  .sweet-modal,
  .sweet-modal.is-alert {
    max-width: 100%;
    width: initial;
    @include theme-monochrome-ground(background-color);

    .sweet-box-actions {
      .sweet-action-close {
        border-radius: 50%;
        border: 1px solid #a0a0a0;
        width: 40px;
        height: 40px;
        @include theme-monochrome-accent(border-color);

        svg * {
          @include theme-monochrome-accent(fill);
        }
      }
    }

    .sweet-content {
      padding: 40px 0;
      text-align: initial;
    }
  }
}

#app {
  .sweet-modal {
    display: block;
    transform: none;
    position: relative;
    top: 0;
    left: 0;
    max-width: 80%;
    width: 100%;
    max-height: 100%;
    height: auto;
    @include respond-to(600) {
      width: auto;
      max-width: 100%;
    }

    &-overlay {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .message-modal--nt {
    .sweet-modal {
      max-width: 300px;
      border-radius: 8px;
      border: 1px solid #828284;

      .sweet-box-actions {
        display: none;
      }

      .btn--nt {
        margin-top: 16px;
      }

      .sweet-content {
        padding-top: 32px;
        padding-bottom: 32px;
      }
    }
  }
}

.sweet-modal {
  @include theme-monochrome-ground(background-color);
}
