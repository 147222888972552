@media print {

  @page {
    size: A4;
    margin: 20mm 15mm 20mm 15mm;
  }

  *,
  *:before,
  *:after {
    background: transparent !important;
    color: #000 !important; /* Black prints faster */
    box-shadow: none !important;
    text-shadow: none !important;
  }


  /*
   * Don't show links that are fragment identifiers,
   * or use the `javascript:` pseudo protocol
   */

  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: "";
  }

  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  /*
   * Printing Tables:
   * http://css-discuss.incutio.com/wiki/Printing_Tables
   */

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  body {
    min-width: 100%;
    filter: grayscale(100%);
    -webkit-print-color-adjust: exact;
  }

  .breadcrumbs__item[href]:after {
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2IiBoZWlnaHQ9IjgiIHZpZXdCb3g9IjAgMCA2IDgiPjxwYXRoIGZpbGw9Im5vbmUiIHN0cm9rZT0iI0EwQTBBMCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBkPSJNMSA4bDQtNC00LTQiLz48L3N2Zz4=) 50% 50% no-repeat !important;
  }

  .print-hidden {
    display: none;
  }
  .page-content {
    margin-top: 3.2rem;
  }

  .page-content > .wrapper {
    max-width: inherit;
  }

  .content__aside {
    width: 30%;
    padding-right: 0;
    padding-top: 0;
    padding-left: 1rem;

    .aside__section {
      margin-top: 0;
    }
  }

  .aside-manager__info picture {
    display: none;
  }
  .content__inner {
    width: 70%;
    padding: 0;
  }
  .aside__section {
    margin-top: 0;

    .action-button {
      display: none;
    }
  }
  .site-header__menu {
    display: none;
  }

  .site-header__logo {
    margin-left: 0;
  }
  .site-header {
    border-bottom: 1px solid #efefef;
    padding: 0;
  }
  .site-header__item {
    @include mix-font-size(14px);
  }
  .site-header__item:not([href^="tel:"]) {
    display: none;
  }
  .site-header__icon {
    stroke: #a0a0a0;
  }

  .policy-name__edit {
    display: none;
  }

  .policy-header__actions {
    display: none;
  }

  .policy__info {
    border: 1px solid #ccc;
    border-radius: 4px;
    page-break-inside: avoid;
  }
  .policy-services__item {
    background: #fff !important;
    width: 100%;
  }


  .psi__footer {
    display: none;
  }

  .policy-info__footer {
    display: none;
  }

  .policy__section {
    page-break-inside: avoid;
  }
  .policy__total .fill {
    border-bottom: 1px solid #ccc;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
  }
  .policy-info__table table {

  }

  .policy-info__table table tbody tr:nth-child(odd) td {
    background: #eee !important;
  }

  .site-footer {
    display: none;
  }

  .policy__options .form__checkbox {
    input[type=checkbox] + label {
      display: none;
    }

    input[type=checkbox]:checked + label {
      display: inline-block;
      padding-left: 0;
    }
  }
}
