
//Animations on scroll
.v-view--fade-in {
	transition-delay: 1s;
    transition: .5s ease-in;
}
.v-view--in-step {
    transition: .4s;
}
.view-out.v-view--fade-in {
    opacity: 0;
    transform: translateY(20px);
}
.view-in.v-view--fade-in {
    opacity: 1;
    transform: translateY(0);
}
.view-out.v-view--in-step {
    opacity: 0;
    /* visibility: hidden; */
    transform: translateY(-40px);
}
.view-in.v-view--in-step {
	opacity: 1;
	/* visibility: visible; */
	transform: translateY(0);
}

.v-view--in-step-left {
	transition: .5s;	
}
.view-out.v-view--in-step-left {
    /* opacity: 0; */
    transform: translateX(150px);
}
.view-in.v-view--in-step-left {
	opacity: 1;
	transform: translateY(0);
}

.v-view-quare {
	opacity: 0;
	transform: translateY(-40px);
	transition: .3s;	
}
.v-view-quare:nth-child(1) {
	transition-delay: 0;
}
.v-view-quare:nth-child(2) {
	transition-delay: .5s;
}
.v-view-quare:nth-child(3) {
	transition-delay: 1s;
}
.view-in.v-view-quare {
	opacity: 1;
	transform: translateY(0);
}


.v-view--in-step-down {
	transition: .5s;	
	opacity: 0;
}
.view-out.v-view--in-step-down {
    transform: translateY(40px);
}
.view-in.v-view--in-step-down {
	opacity: 1;
	transform: translateY(0);
}

.v-view--to-center,
.v-view--to-center--inverse {
	opacity: 0;
    transition: .4s;
}
.v-view--to-center:nth-child(even) {
	transform: translateX(-80px);
}
.v-view--to-center:nth-child(odd) {
	transform: translateX(80px);
}
.v-view--to-center--inverse:nth-child(even) {
	transform: translateX(80px);
}
.v-view--to-center--inverse:nth-child(odd) {
	transform: translateX(-80px);
}
.view-in.v-view--to-center,
.view-in.v-view--to-center--inverse {
	opacity: 1;
	transform: translateX(0);
}

.v-view--in-step-right {
	transition: .7s ease-in-out;	
	opacity: 0;
    transform: translateX(-50px);
}
.view-in.v-view--in-step-right {
	opacity: 1;
	transform: translateX(0);
}

.bg-figure-item {
	transition: 6s ease-in-out;
}
.view-out.bg-figure-item {
	transform: scale(.8);
}
.view-in.bg-figure-item {
    transform: scale(1.1);
}
.figure--floating {
	animation: float 8s ease-in-out infinite;
}
.figure--floating-scale {
	animation: float-scale 12s ease-in-out infinite;
}

.sp-content--bg-figure--top-left {
    --bg-side-length: 480px;
}
.sp-content--bg-figure--top-left .bg-figure-item {
    left: -200px;
    top: 100px;
}

.sp-content--bg-figure--bottom-right .bg-figure-item {
    bottom: -170px;
    right: -200px;
}

@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-10px);
	}
	100% {
		transform: translatey(0px);
	}
}

@keyframes float-scale {
	0% {
		transform: translate(0px) scale(1);
	}
	25% {
		transform: scale(1.15);
	}
	50% {
		transform: translate(-10px, 0px) scale(1.1);
	}
	75% {
		transform: scale(1.02);
	}
	100% {
		transform: translate(0px) scale(1);
	}
}

@keyframes float-xy {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-10px);
	}
	100% {
		transform: translatey(0px);
	}
}