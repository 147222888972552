.vacancies__intro {
  transition: opacity 0.6s, transform 0.6s;
  transition-delay: 0.3s;
  opacity: 0;
  transform: translateY(10px);

  &.view-in {
    opacity: 1;
    transform: translateY(0);
  }
}

.vacancies__features {
  margin-bottom: 48px;
  margin-top: 32px;
}

.vacancies__extra {
  border: 4px solid #f7f7f7 !important;
  padding-left: 10px !important;
  width: calc((94% - 6.4rem) / 2) !important;
  @include theme-monochrome-ground(border-color);

  .vac-item__actions {
    padding-left: 10px !important;
  }
}

.vacancies__feature {
  transition: opacity 0.6s, transform 0.6s;
  opacity: 0;
  transform: scale(0.9);

  &.view-in {
    opacity: 1;
    transform: scale(1);
  }
}

.vac-feature__icon {
  margin-bottom: 16px;
}

.vac-feature__text {
  color: #262626;
  text-transform: uppercase;
  @include mix-font-size(16px, 24px);
  @include theme-monochrome-accent(color);
}

.vac-search {
  position: relative;
  margin-bottom: 0;

}

.vac-search__form {

}

.vac-search__submit {
  position: absolute;
  border: none;
  background: none;
  box-shadow: none;
  padding: 10px;
  right: 0;
  top: 28px;
  cursor: pointer;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.2);
  }

  &:focus {
  }
}

.vac-search__clear {
  width: 20px;
  height: 20px;
  position: absolute;
  border: 1px solid rgba(#A0A0A0, 0.25);
  border-radius: 50%;
  right: 40px;
  top: 33px;
  transition: transform .3s, opacity .3s, visibility .3s;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDE0IDE0Ij48cGF0aCBkPSJNMTMgMUwxIDEzTTEgMWwxMiAxMiIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2U9IiMyNjI2MjYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIi8+PC9zdmc+);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 40%;
  opacity: 0;
  visibility: hidden;
  transform: translateX(100%);
  background-color: transparent;
  display: block;
  @include theme-monochrome-accent(border-color);

  &.active {
    opacity: 1;
    visibility: visible;
    transform: translateX(0) scale(1);
  }

  &:hover {
    cursor: pointer;
    transform: translateX(0) scale(1.2);
  }
}

.vac-regions-select {
  position: absolute;
  right: 32px;
  top: 32px;

  select {
    opacity: 0;
    padding: 0;
    position: absolute;
    margin-top: 0;
    @include mix-font-size(17px, 24px);
  }

  label {
    position: relative;
    padding-right: 16px;
    @include mix-font-size(17px, 24px);

    &:hover {
      cursor: pointer;
    }

    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 4px;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSI1IiB2aWV3Qm94PSIwIDAgMTAgNSI+ICA8cG9seWxpbmUgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjRjM2QzIxIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMiIgcG9pbnRzPSIyMCAxNSAxNiAxOCAxMiAxNSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTExIC0xNCkiLz48L3N2Zz4=);
      background-position: 50% 50%;
      background-repeat: no-repeat;
      width: 10px;
      height: 6px;
    }
  }
}

.vacancies__list {
  &.is-loading {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      background: rgba(255, 255, 255, 0.75);
      z-index: 1;
    }

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 32px;
      height: 32px;
      background: url('/static/img/loader_brand2.png') left center no-repeat;
      -webkit-animation: play_loader 0.6s steps(18) infinite;
      animation: play_loader 0.6s steps(18) infinite;
      z-index: 2;
    }
  }

  .no-results {
    min-height: 240px;
  }
}

.no-results {
  margin-top: 60px;
}

.vacancies__item {
  //padding-bottom: 112px;
  border-top: 1px solid rgba(#a0a0a0, 0.24);
  padding-top: 48px;
  position: relative;
  transition: opacity 0.6s, transform 0.6s;
  opacity: 0;
  transform: scale(0.9);
  @include theme-monochrome-accent(border-top-color);

  &.view-in {
    opacity: 1;
    transform: scale(1);
  }

  &:nth-child(1), &:nth-child(2) {
    border-top: none;
  }
}

.vac-item {
  display: flex;
  flex-direction: column;
}

.vac-item__region {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  color: #595959;
  letter-spacing: -0.2px;
  @include mix-font-size(16px, 20px);
  @include theme-monochrome-accent(color);

  p + p {
    margin-top: 0.8rem;
  }

  svg {
    display: block;
    stroke: $cBrand;
    margin-right: 12px;
    @include theme-monochrome-accent(stroke);
  }
}

.vac-item__name {
  color: #262626;
  letter-spacing: -0.3px;
  margin-bottom: 16px;
  @include mix-font-size(24px, 24px);
  @include theme-monochrome-accent(color);
}

.vac-item__type {
  color: #262626;
  letter-spacing: -0.2px;
  margin-bottom: 24px;
  @include mix-font-size(16px, 20px);
  @include theme-monochrome-accent(color);
}

.vac-item__income {
  color: #595959;
  letter-spacing: 0;
  @include mix-font-size(17px, 24px);
  @include theme-monochrome-accent(color);
}

.vac-item__actions {
  //position: absolute;
  //bottom: 32px;
  //left: 0;
  //right: 0;
  padding-bottom: 32px;
  padding-top: 32px;
  margin-top: auto;
}

.vac-for-students {
  border: 1px solid #E8E8E8;
  padding: 24px;
  @include theme-monochrome-accent(border-color);
}

.vfs__icon {

}

.vfs__header {
  margin-top: 16px;
  margin-bottom: 16px;
  color: #262626;
  letter-spacing: -0.22px;
  @include mix-font-size(18px, 20px);
  @include theme-monochrome-accent(color);
}

.vfs__text {
  color: #595959;
  letter-spacing: -0.2px;
  @include mix-font-size(16px, 20px);
  @include theme-monochrome-accent(color);
}


.vacancy__geo {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  .vac-item__region {
    margin-right: 32px;
    margin-bottom: 0;
  }

  transition: opacity 0.6s, transform 0.6s;
  transition-delay: 0.3s;
  opacity: 0;

  &.view-in {
    opacity: 1;
  }
}


.vacancy-geo__action {
  color: #262626;
  letter-spacing: -0.5px;
  display: block;
  @include mix-font-size(17px, 17px);
  @include theme-monochrome-accent(color);
}


.vacancy__hero {
  margin-top: 24px;
  transition: opacity 0.6s, transform 0.6s;
  transition-delay: 0.3s;
  opacity: 0;
  transform: translateY(10px);

  &.view-in {
    opacity: 1;
    transform: translateY(0);
  }

}

.vac-hero__header {
  margin-bottom: 24px;
}

.vac-hero__footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.vacancy__type {
  color: #262626;
  letter-spacing: -0.2px;
  @include mix-font-size(16px, 20px);
  @include theme-monochrome-accent(color);
}

.vacancy__income {
  color: #262626;
  letter-spacing: -0.3px;
  margin-bottom: 8px;
  @include mix-font-size(24px, 24px);
  @include theme-monochrome-accent(color);
}

.vacancy__time {
  color: #A0A0A0;
  @include mix-font-size(14px, 24px);
  @include theme-monochrome-accent(color);
}

.vac-hero__action {
  margin-left: 32px;
  flex-grow: 0;
  flex-shrink: 0;
}

.vacancy__section {
  margin-bottom: 40px;
  transition: opacity 0.6s, transform 0.6s;
  opacity: 0;
  transform: translateY(10px);

  &.view-in {
    opacity: 1;
    transform: translateY(0);
  }

}

.vacancy__footer {
  border-top: 1px solid rgba(#a0a0a0, 0.24);
  padding-top: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 32px;
}


@media screen and (max-width: $breakpoint) {
  .vacancies__feature {
    display: flex;
    align-items: center;
  }

  .vac-feature__icon {
    margin-bottom: 0;
    margin-right: 12px;
  }

  .vac-search__form {
    display: flex;
    flex-direction: column-reverse;
    align-items: stretch;

  }

  .vac-regions-select {
    position: static;
  }

  .vacancies__item {
    //padding-bottom: 40px;
    padding-top: 40px;
  }
  .vac-item__actions {
    position: static;
    display: flex;
    align-items: center;
    margin-top: 32px;

    a.btn + .lk__action {
      margin-top: 0;
      margin-left: 24px;
    }
  }

  .vacancies__item:nth-child(2) {
    border-top: 1px solid rgba(160, 160, 160, 0.24);
  }
  .vacancies__list {
    > .col + .col {
      margin-top: 0;
    }
  }
  .vacancy__geo {
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  }
  .vac-item__region {
    margin-right: 0;
  }
  .vacancy-geo__action {
    margin-top: 24px;
    padding-left: 24px;
  }

  .vacancies__extra {
    border: 4px solid #f7f7f7 !important;
    padding-left: 10px !important;
    margin-bottom: 10px !important;
    width: 100% !important;
    @include theme-monochrome-ground(border-color);
  }

  .vacancy__hero {
    margin-left: -24px;
    margin-right: -24px;
  }

  .vac-hero__footer {
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  }

  .vac-hero__action {
    margin-left: 0;
    margin-top: 24px;
  }

  .vacancy__footer {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .btn {
      margin-top: 24px;
    }
  }
}
