.notifies {
  overflow: hidden;
  transition: height 0.3s;
  &.is-collapsed {
    height: 0;
  }
}

.notify__item {
  position: relative;
}

.notify__row {
  color: $cGrey;
  line-height: 2rem;
  +.notify__row {
    margin-top: 1.6rem;
  }
  p + p {
    margin-top: 0.8rem;
  }
}

.notify__remove {
  position: absolute;
  padding: 0.5rem;
  top: 3px;
  right: 4px;
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.2);
  }
  svg {
    display: block;
  }
}

.notifies__hider {
  &.collapsible-opened {
    .hider-hide {
      display: inline;
    }
    .hider-show {
      display: none;
    }
    .notifies-hidden__action{
      &:after {
        transform: rotate(90deg);
      }
    }
  }
  .hider-hide {
    display: none;
  }
}

.notifies-hidden__container {

}

.notifies-hidden__action {
  display: block;
  color: $cGrey;
  padding-top: 1.4rem;
  padding-bottom: 1.6rem;
  position: relative;
  &:after, &:before {
    content:'';
    position: absolute;
    background: $cLightGrey;
    width: 1px;
    height: 6px;
    transition: transform 0.2s;
  }
  &:before {
    transform: rotate(90deg);
    left: -7px;
    top: 24px;
  }
  &:after {
    left: -7px;
    top: 24px;
  }
}
