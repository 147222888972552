%button {
  font-family: inherit;
  display: inline-block;
  box-sizing: border-box;
  background: $cBtn linear-gradient(-180deg, rgba(#F1F1F1, 0.1) 0%, rgba(#E6E6E6, 0.1) 100%);
  border-radius: 4px;
  text-align: center;
  @include mix-font-size(16px, 24px);
  color: $cWhite;
  letter-spacing: -0.5px;
  padding: 12px 24px;
  transition: background-color 0.3s, background-image 0.3s, color 0.3s;
  -webkit-appearance: none;
  border: none;
  white-space: nowrap;
  position: relative;
  @include theme-monochrome-accent(background);
  @include theme-monochrome-ground(color);

  .big-font-size & {
    white-space: normal;
  }

  &:hover {
    cursor: pointer;
    background: $cBtn linear-gradient(-180deg, rgba(#F1F1F1, 0) 0%, rgba(#E6E6E6, 0) 100%);
    @include theme-monochrome-accent(background);
  }

  &:focus {
  }

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 32px;
    height: 32px;
    background: transparent url('/static/img/loader_white2.png') left center no-repeat;
    animation: play_loader 0.6s steps(18) infinite;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s, visibility 0.2s;
  }
}

a.btn,
span.btn,
label.btn,
button.btn {
  @extend %button;

  &.btn--secondary {
    background: #F2F2F2 linear-gradient(-180deg, rgba(#F2F2F2, 0.1) 0%, rgba(#F2F2F2, 0.1) 100%);
    color: $cBlack;
    @include theme-monochrome-accent(color);
    @include theme-monochrome-ground(background);

    &:hover {
      background: #E6E6E6 linear-gradient(-180deg, rgba(#F2F2F2, 0) 0%, rgba(#F2F2F2, 0) 100%);
      @include theme-monochrome-ground(background);
    }

    &.is-loading {
      &:hover {
        background: #F2F2F2 linear-gradient(-180deg, rgba(#F2F2F2, 0.1) 0%, rgba(#F2F2F2, 0.1) 100%);
        @include theme-monochrome-ground(background);
      }
    }

    &:after {
      background: transparent url('/static/img/loader_white2.png') left center no-repeat;
      animation: play_loader 0.6s steps(18) infinite;
    }
  }

  &.btn--iconic {
    height: 48px;
    width: 48px;
    text-align: center;
    line-height: PxToRem(48px);
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;

    + .btn--iconic {
      margin-left: 8px;
    }
  }

  svg {
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;

    + span {
      display: inline-block;
      vertical-align: middle;
      margin-left: 5px;
    }
  }

  &.is-loading {
    color: transparent;

    &:hover {
      color: transparent;
      background: $cBtn linear-gradient(-180deg, rgba(#F1F1F1, 0.1) 0%, rgba(#E6E6E6, 0.1) 100%);
      cursor: default;
      @include theme-monochrome-accent(background);
    }

    &:after {
      opacity: 1;
      visibility: visible;
    }
  }

  &:disabled,
  &.disabled {
    background: $cBtnDis;
    color: rgba($cWhite, 0.25);
    letter-spacing: -0.5px;
    text-shadow: 0 -1px 0 rgba(#000, 0.5);
    pointer-events: none;
    @include theme-monochrome-ground(background);
    @include theme-monochrome-ground(color);

    &:hover {
      background: $cBtnDis;
      @include theme-monochrome-ground(background);
    }
  }

  &.btn--white {
    background: $cWhite;
    color: $cBlack;

    @include theme-monochrome-accent(color);
    @include theme-monochrome-ground(background);

    &:hover {
      background: $cBtn linear-gradient(-180deg, rgba(#F1F1F1, 0) 0%, rgba(#E6E6E6, 0) 100%);
      color: $cWhite;

      @include theme-monochrome-accent(color);
      @include theme-monochrome-ground(background);
    }
  }

  &.hidden {
    opacity: 0;
    visibility: hidden;
  }

  + br + .lk__action {
    margin-top: 16px;
  }

  &.btn--small {
    font-size: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-transform: initial;
    @include mix-font-size(16px);

    + .btn--small {
      margin-left: 8px;
    }
  }

  &._block {
    display: block;
  }
}

.btn--form {
  @include respond-to(600) {
    width: 100%;
  }
}

.btns {
  $btns-margin: 16px;
  margin: -$btns-margin;
  margin-right: 0;
  margin-bottom: 0;

  > a.btn,
  > span.btn,
  > label.btn,
  > button.btn {
    margin: $btns-margin !important;
    margin-right: 0 !important;
    margin-bottom: 0 !important;
  }

  &--lk {
    padding: 32px 40px;
    margin: 0;
    background-color: $cWhite;
    @include theme-monochrome-ground(background-color);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    > a.btn-new_secondary {
      margin: 8px 0 0 !important;
      &:first-child {
        margin: 0 !important;
      }
    }
    @include respond-to(500) {
      padding: 24px 16px;
    }
  }
}

.btn--add {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;

  svg {
    display: block;
  }
}

.action-button {
  display: flex;
  align-items: center;
  //height: 48px;
  margin-top: 16px;
  background: #ffffff;
  border-radius: 4px;
  padding: 12px 6px 12px 22px;
  transition: background-color 0.3s, color 0.3s;
  @include theme-monochrome-ground(background);

  &:hover {
    background: #fefefe;
    @include theme-monochrome-ground(background);
  }

  svg {
    margin-right: 12px;
    display: block;
    transition: stroke 0.3s, fill 0.3s;
  }

  &.disabled,
  &:disabled {
    opacity: .5;
    pointer-events: none;
  }
}

.action-button--no-icon {
  padding-left: 0;
  text-align: center;
  justify-content: center;
}

.action-button--fit-content {
  display: inline-flex;
  width: fit-content;
  width: auto;
  padding-left: 36px;
  padding-right: 36px;
}

.action-button--primary {
  background: $cBtn linear-gradient(-180deg, rgba(#F1F1F1, 0.1) 0%, rgba(#E6E6E6, 0.1) 100%) !important;
  color: $cWhite;
  @include theme-monochrome-accent(background);
  @include theme-monochrome-ground(color);

  &:hover {
    background: $cBtn linear-gradient(-180deg, rgba(#F1F1F1, 0) 0%, rgba(#E6E6E6, 0) 100%) !important;
    color: $cWhite;
    @include theme-monochrome-accent(background);
    @include theme-monochrome-ground(color);
  }
}

.action-button--secondary {
  background: transparent;
  color: $cBlack;

  @include theme-monochrome-accent(color);
  @include theme-monochrome-ground(background);


  &:hover {
    background: transparent;
    color: $cBrand;
    @include theme-monochrome-accent(color);
    @include theme-monochrome-ground(background);
  }
}

.input-button {
  -webkit-appearance: none;
  border: none;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  padding: 0;
  margin: 0;
  background: transparent;
  right: 0;
  top: 24px;

  svg {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

@keyframes play_loader {
  100% {
    background-position: -576px;
  }
}

.btn--width {
  @include respond-to(600) {
    width: 100%;
  }
}

.btn-new {
  width: 100%;
  display: inline-block;
  padding: 12px 14px;
  box-sizing: border-box;
  transition: background-color .3s,background-image .3s,color .3s;
  color: #ffffff;
  background: #E58324 linear-gradient(-180deg,hsla(0,0%,94.5%,.1),hsla(0,0%,90.2%,.1));
  @include mix-font-size(16px, 24px);
  position: relative;
  cursor: pointer;
  border-radius: 4px;
  border: none;
  @include theme-monochrome-accent(background);
  @include theme-monochrome-ground(color);
  text-align: center;
  &:hover {
    background: #E58324 linear-gradient(-180deg, rgba(#F1F1F1, 0) 0%, rgba(#E6E6E6, 0) 100%);
    color: #ffffff;
    @include theme-monochrome-accent(background);
    @include theme-monochrome-ground(color);
  }
  &:focus {
    background: #E58324 linear-gradient(-180deg, rgba(#F1F1F1, 0) 0%, rgba(#E6E6E6, 0) 100%);
    outline: none;
    @include theme-monochrome-accent(background);
  }
  &::-moz-focus-inner {
    border: 0;
  }
  &:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 32px;
    height: 32px;
    background: transparent url('/static/img/loader_white2.png') left center no-repeat;
    animation: play_loader 0.6s steps(18) infinite;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s, visibility 0.2s;
  }
  &_secondary {
    background: #F7F7F7;
    color: #262626;
    @include theme-monochrome-accent(background);
    @include theme-monochrome-ground(color);
    &:hover {
      background: linear-gradient(180deg, rgba(241, 241, 241, 0.1) 0%, rgba(0, 0, 0, 0.02) 100%), #F7F7F7;
      @include theme-monochrome-ground(background);
      color: #ED7634;
      @include theme-monochrome-ground(color);
    }
    &:focus {
      background: linear-gradient(180deg, rgba(241, 241, 241, 0.1) 0%, rgba(0, 0, 0, 0.04) 100%), #F7F7F7;
      color: #ED7634;
      outline: none;
      border: 0;
    }
    &::-moz-focus-inner {
      border: 0;
    }
  }
  &:disabled,
  &.disabled {
    background: $cBtnDis;
    color: rgba($cWhite, 0.25);
    letter-spacing: -0.5px;
    text-shadow: 0 -1px 0 rgba(#000, 0.5);
    pointer-events: none;
    @include theme-monochrome-ground(background);
    @include theme-monochrome-ground(color);

    &:hover {
      background: $cBtnDis;
      @include theme-monochrome-ground(background);
    }
  }
}

.btn-mini {
    width: auto;
    padding: 8px 10px;
    font-size: 1.2rem;
    background-color: #f36c21;
}

//new template
.btn--nt {
  border-radius: 15px!important;
}