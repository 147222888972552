.policy {

}

.policy__header {

}

.policy__name {
  letter-spacing: -1px;
  margin-bottom: 1.6rem;
  display: flex;
  align-items: center;
  @include mix-font-size(48px, 48px);
}

.policy-name__edit {
  border: 0;
  background: transparent;
  margin-left: 2rem;

  &:hover {
    cursor: pointer;

    use {
      fill: $cBlack;
    }
  }

  &:focus {
  }

  use {
    transition: fill 0.3s;
    fill: $cBrand;
  }

}

.policy-name__text {
  position: relative;
  z-index: 0;

  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    left: -1rem;
    right: -1rem;
    top: -1rem;
    bottom: -1rem;
    border: 2px dashed rgba($cBrand, 0.6);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;
    border-radius: 4px;
  }

  &[contenteditable=true] {
    &:after {
      opacity: 1;
      visibility: visible;
    }

    + button {
      @extend %button;
      margin-bottom: 0;

      &:disabled,
      &.disabled {
        background: $cBtnDis;
        color: rgba($cWhite, 0.25);
        letter-spacing: -0.5px;
        text-shadow: 0 -1px 0 rgba(#000, 0.5);

        &:hover {
          background: $cBtnDis;
        }
      }
    }
  }
}

.policy-name__input {

}

.policy__type {
  letter-spacing: -0.3px;
  margin-bottom: 0.8rem;
  @include mix-font-size(24px, 24px);
}

.policy-header__actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.4rem;

  a {
    transition: color 0.3s;
    @include mix-font-size(14px);

    &:hover {
      color: $cBrand;
    }
  }

  div {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    & > a {
      @include mix-font-size(17px);
      margin-left: 2.4rem;
    }
  }
}

.policy__info {
  margin-top: 3.2rem;
  padding: 4rem 3.2rem;
  background: #F7F7F7;
}

.policy-info__header {
  p {

  }

  h4 {
    margin-top: 0.8rem;
  }
}

.policy-info__body {
  padding-top: 2.4rem;
  margin-top: 3.2rem;
  border-top: 1px solid rgba(#A0A0A0, 0.24);

  > table {
    width: 100%;

    td {
      padding-right: 4rem;
      vertical-align: top;
      color: #595959;

      span {

      }

      &:last-child {
        text-align: right;
        padding-right: 0;
      }

      p.text--error {
        margin-top: 0.8rem;
      }
    }
  }
}

.policy-info__footer {
  position: relative;

  > table {
    margin-top: 3.6rem;

    td {
      vertical-align: top;
      width: 45%;
    }
  }

  .action-button {
    margin-top: 0;
    padding-left: 0;
  }
}

.policy__extra {
  position: absolute;
  right: 0;
  top: 1.4rem;

  a.trigger {
    width: 2.4rem;
    height: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      display: block;
    }

    use {
      fill: $cBrand;
      transition: fill 0.3s;
    }

    &:hover {
      use {
        fill: $cBlack;
      }
    }
  }

  &:hover {
    .actions {
      transform: translateX(-50%) scale(1);
      opacity: 1;
    }
  }

  .actions {
    position: absolute;
    white-space: nowrap;
    padding: 2.4rem;
    background: #fff;
    border-radius: 0.4rem;
    box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s, transform 0.3s;
    left: 50%;
    transform: translateX(-50%) scale(0.4);
    opacity: 0;
  }

  a.action {
    transition: color 0.3s;

    &:hover {
      color: $cBrand;
    }

    + a.action {
      margin-top: 2.4rem;
    }
  }
}

.policy__services {
  display: flex;
  justify-content: space-between;
  margin-top: 4rem;

  .swiper-container {
    width: 100%;
  }
}

.policy-services__item {
  width: 28rem;
  box-sizing: border-box;
  padding: 2.4rem;
  background: #fff;
  border-radius: 4px;
}

.psi__header {
  margin-bottom: 1.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.psi__icon {
  svg {
    display: block;
  }
}

.psi__status {

}

.psi__body {

}

.psi__footer {
  margin-top: 2.4rem;
}

.psi__actions {
  margin-top: 2.4rem;
  display: flex;
  flex-direction: column;

  > a.psi__action,
  > button.psi__action {
    width: 100%;
    margin: 0;

    &:not(:first-child) {
      margin-top: 10px;
    }

  }

}


.policy__section {
  margin-top: 8rem;

  h3 + h4 {
    margin-bottom: 0;
  }
}

.policy__content {
  position: relative;
  margin-top: 2.4rem;

  table {
    width: 100%;
    margin-left: 2.4rem;

    td {
      padding-top: 0.8rem;
      padding-bottom: 0.8rem;
      vertical-align: middle;
    }

    tbody {
      border-bottom: 1px solid rgba(#A0A0A0, 0.24);

      &:first-child {
        border-bottom: 0;

        p.policy__subject {
          margin-left: -2.4rem;
        }
      }
    }

    tr:last-child {
      td {
        padding-bottom: 2.4rem;
      }
    }

    tr:first-child {
      td {
        padding-top: 1.6rem;
      }
    }

    td[colspan] {
      padding-bottom: 1.6rem;
      vertical-align: top;
    }
  }
}

.policy-subject__row + .policy-subject__row {
  margin-top: 1.6rem;
}

.policy__subject {
  padding-left: 2.4rem;
  position: relative;

  .icon {
    position: absolute;
    left: 0;
    top: 0;
  }

  p + p {
    margin-top: 0.8rem;
  }

  p > span {
    margin-left: 1.6rem;
  }
}

.policy-subject__image {
  position: absolute;
  width: 20rem;
  height: 20rem;
  display: flex;
  align-items: center;
  right: 7.2rem;
  top: -4rem;

  img {
    display: block;
    width: 100%;
  }
}

.policy__options {
  padding-top: 4rem;
  padding-bottom: 0.8rem;
  margin-left: 2.4rem;

  .form__checkbox {
    margin-top: 0;
    margin-right: 4.8rem;
  }
}

.policy-options__items {
  display: flex;
  align-items: center;
}

.policy__total {
  margin-top: 3.2rem;

  .sum {
    float: right;
  }

  .text--darken {
    float: left;
  }

  .fill {
    display: block;
    overflow: hidden;
    border-left: 8px solid $cWhite;
    border-right: 8px solid $cWhite;
    height: 1px;
    margin-top: 1.4rem;
    background: linear-gradient(to right, transparent 75%, #A0A0A0 75%);
    background-size: 4px 1px;
  }

  .sum-row {
    overflow: hidden;
  }
}

.policy-info__table {
  &.table-cnt {
    margin-bottom: 5.6rem;
  }

  table {
    width: 100%;

    thead {
      th {
        font-weight: normal;
        @include metam;
        white-space: nowrap;
        padding: 1.6rem;

        &:first-child {
          text-align: left;
        }

        &:last-child {
          text-align: right;
        }
      }
    }

    tbody {
      tr:nth-child(odd) {
        td {
          background: #F2F2F2;
        }
      }

      td {
        padding: 1.6rem;

        &:last-child {
          text-align: right;
        }
      }
    }
  }
}

.policy__customer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 2.4rem;

  dd {
    width: 20%;
  }

  dt {
    width: 80%;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
  }
}

.mobile-icon {
  display: none;
}

.slider-btn {
  position: relative;
  display: block;
  border: 0.1rem solid rgba(160, 160, 160, .15);
  background-color: transparent;
  width: 3.2rem;
  height: 3.2rem;
  box-sizing: border-box;
  border-radius: 100%;
  transition: opacity .2s;

  &::after {
    content: '';
    position: absolute;
    width: .6rem;
    height: 1rem;
    top: 1rem;
    left: 1.3rem;
    background: no-repeat center;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSI2cHgiIGhlaWdodD0iMTBweCIgdmlld0JveD0iMCAwIDYgMTAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+ICAgICAgICA8dGl0bGU+U2hhcGU8L3RpdGxlPiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4gICAgPGRlZnM+PC9kZWZzPiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPiAgICAgICAgPGcgaWQ9ImFsbC1jb250cm9sIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMjU0LjAwMDAwMCwgLTY3NS4wMDAwMDApIiBzdHJva2U9IiM1OTU5NTkiIHN0cm9rZS13aWR0aD0iMiI+ICAgICAgICAgICAgPGcgaWQ9Im1lZGljaW5lIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyNC4wMDAwMDAsIDYzMi4wMDAwMDApIj4gICAgICAgICAgICAgICAgPGcgaWQ9ImNvbnRlbnQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDI0LjAwMDAwMCwgMjQuMDAwMDAwKSI+ICAgICAgICAgICAgICAgICAgICA8ZyBpZD0iYXJyb3ctciIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjA4LjAwMDAwMCwgMjQuMDAwMDAwKSByb3RhdGUoLTkwLjAwMDAwMCkgdHJhbnNsYXRlKC0yMDguMDAwMDAwLCAtMjQuMDAwMDAwKSB0cmFuc2xhdGUoMTkyLjAwMDAwMCwgOC4wMDAwMDApIj4gICAgICAgICAgICAgICAgICAgICAgICA8ZyBpZD0iY2hldnJvbi1kb3duLWNvcHkiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEyLjgwMDAwMCwgMTUuMjAwMDAwKSI+ICAgICAgICAgICAgICAgICAgICAgICAgICAgIDxwb2x5bGluZSBpZD0iU2hhcGUiIHBvaW50cz0iMCAwIDMuMiAzLjIgNi40IDguODgxNzg0MmUtMTciPjwvcG9seWxpbmU+ICAgICAgICAgICAgICAgICAgICAgICAgPC9nPiAgICAgICAgICAgICAgICAgICAgPC9nPiAgICAgICAgICAgICAgICA8L2c+ICAgICAgICAgICAgPC9nPiAgICAgICAgPC9nPiAgICA8L2c+PC9zdmc+);
  }

  &.slider-btn--prev {
    transform: scale(-1, 1);
  }

  &.swiper-button-disabled {
    opacity: .5;
  }
}

.policy-slider-btn {
  display: none;
  position: absolute;
  top: 3.2rem;
  z-index: 2;

  &.policy-slider-btn--prev {
    left: calc(50% - 11.2rem);
  }

  &.policy-slider-btn--next {
    right: calc(50% - 11.2rem);
  }
}


@media screen and (max-width: $breakpoint) {
  .policy__name {
    font-weight: 400;
    letter-spacing: -0.04rem;
    @include mix-font-size(32px, 32px);
  }
  .policy__name {
    flex-wrap: wrap;
    width: 100%;
  }
  .policy-name__text {
    width: 100%;

    &[contenteditable=true] {
      text-overflow: clip;
      white-space: normal;
      overflow: visible;
      width: 100%;
      margin-bottom: 2rem;
    }
  }
  .policy-name__edit {
    display: block;
    /*margin-left: 0;*/
    /*margin-top: 2rem;*/
    padding: 0;
    margin-left: 1.2rem;
  }
  .mobile-icon {
    display: inline-block;
    margin-right: .8rem;
  }
  .policy-header__actions {
    display: block;
    margin-bottom: 0;

    .text--larger {
      border-top: 1px solid #f2f2f2;
      margin-top: 2.4rem;
      width: calc(100% + 4.8rem);
      margin-left: -2.4rem;
      justify-content: flex-start;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        height: 56px;
        margin-left: 0;
        text-align: center;
        letter-spacing: -0.05rem;
        box-sizing: border-box;
        @include mix-font-size(16px, 24px);

        &:nth-child(2n) {
          border-left: 1px solid #f2f2f2;
        }
      }
    }
  }
  .policy__info {
    margin-top: 0;
    width: calc(100% + 4.8rem);
    margin-left: -2.4rem;
    box-sizing: border-box;
    padding: 3.2rem 2.4rem;

    & + & {
      margin-top: 3.2rem;
    }
  }
  .policy-info__header h4 {
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .policy-info__body {
    margin-top: 1.6rem;
    padding-top: 0;
    border-top: none;

    > table {
      tr {
        display: block;
      }

      td {
        display: block;
        padding-right: 0;
        margin-bottom: 1.6rem;
      }
    }
  }
  .policy-info__footer {
    > table {
      width: 100%;

      tr {
        display: block;
      }

      td {
        display: block;
        text-align: left;
        width: 100%;
      }
    }

    .action-button {
      display: inline-flex;
      width: auto;
    }
  }
  .policy__extra {
    position: relative;
    width: 32px;
    margin-left: auto;
    margin-right: auto;
  }
  .policy__services {
    width: calc(100vw - 4.8rem);
  }
  .policy-services__item {
    margin-left: auto;
    margin-right: auto;
  }
  .policy__section {
    margin-top: 5.6rem;
  }
  .policy__subject p > span {
    display: block;
    margin-left: 0;
  }
  .sum-row span {
    display: block;

    &.fill {
      display: none;
    }

    &.sum {
      margin-top: .8rem;
    }
  }
  .policy__total {
    .text--darken,
    .sum {
      float: none;
    }
  }
  .policy-subject__image {
    display: none;
  }
  .policy__content {
    > table {
      width: calc(100% - 2.4rem);

      tr {
        display: block;
      }

      td {
        display: block;
      }
    }
  }
  .policy-options__items {
    display: block;

    .form__checkbox {
      margin-top: 2rem;
      margin-right: 0;
    }
  }
  .policy__options {
    padding-top: 0;
  }
  .policy__customer {
    display: block;

    dd,
    dt {
      width: 100%;
    }

    dt {
      margin-bottom: 16px;
    }
  }

  .psi__header {
    display: block;
  }
  .psi__icon,
  .psi__status,
  .psi__body,
  .psi__footer {
    text-align: center;
  }
  .psi__icon {
    margin-bottom: 1.6rem;
  }
  .policy-slider-btn {
    display: block;
    top: 50%;
    margin-top: -16px;
    background: #fff;
    border-radius: 50%;

    &.swiper-button-disabled {
      display: none;
    }

    &.policy-slider-btn--prev {
      left: 4px;
    }

    &.policy-slider-btn--next {
      right: 4px;
    }
  }

  .policy__services .swiper-container {
    width: calc(100% + 4.8rem);
    margin-left: -2.4rem;
    margin-right: -2.4rem;
  }
}
