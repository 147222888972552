.record-content {
  margin-top: -4rem;

  .lk__section {
    border-bottom: 0;
  }

  .lk__header, .patient-picker-cnt {
    width: 100%;
  }

  .lk__left {
    width: calc(100% - 30.4rem);
  }

  .lk__right {
    margin-left: 5.2rem;
  }

  .row + .row {
    margin-top: 3.2rem;
  }

  .patient-picker-cnt {
    margin-top: 3.2rem;

    .col:first-child {
      margin-right: 2.4rem;
    }
  }
}

.record-extra {

}

.record-extra__item {
  padding: 3.2rem 4rem;
  background: #f7f7f7;
  border-radius: 8px;

  + .record-extra__item {
    margin-top: 3.2rem;
  }
}

.record-ectra-status {
  opacity: 0.5;
  margin-bottom: 0.8rem;
}

.records-extra-header {
  opacity: 0.5;
  letter-spacing: -0.4px;
  margin-bottom: 16px;
  @include mix-font-size(32px, 32px);
}

.record-extra-body {
  display: flex;
  align-items: flex-start;
}

.record-extra-description {
  margin-right: 7.2rem;
  opacity: 0.5;
}

.record-extra-action {
}


@media screen and (max-width: $breakpoint) {
  .record-content {
    margin-top: -4rem;

    .lk__section {
      border-bottom: 0;
    }

    .lk__left {
      width: 100%;
    }

    .lk__right {
      margin-left: 0;
    }
  }
  .record-extra {
    margin-left: -0.8rem;
    margin-right: -0.8rem;
  }
  .record-extra-body {
    flex-direction: column;
  }

  .record-extra-description {
    margin-right: 0;
  }

  .record-extra__item {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }

  .record-extra-action {
    margin-top: 1.6rem;
  }
}
