.about {
  width: 100%;
  display: flex;
  @include respond-to(800) {
    flex-direction: column;
  }
}

.about__content {

}

.about__sidebar {
  width: 344px;
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 72px;
  box-sizing: border-box;
  border: 8px solid #F7F7F7;
  padding: 40px 32px;
  @include respond-to(800) {
    width: 100%;
    margin-left: 0;
    margin-top: 32px;
    padding: 24px 16px;
  }
}

.as-text--type1 {
  @include metam;
  color: #262626;
  @include mix-font-size(17px, 24px);
  @include theme-monochrome-accent(color);

  + .as-text--type4 {
    margin-top: 8px;
  }
}

.as-text--type2 {
  color: #ED7D31;
  letter-spacing: -0.4px;
  margin-bottom: 8px;
  @include mix-font-size(24px, 32px);
  @include theme-monochrome-accent(color);
}

.as-text--type3 {
  color: #595959;
  @include mix-font-size(16px, 20px);
  @include theme-monochrome-accent(color);
  max-width: 100%;
}

.as-text--type4 {
  color: #A0A0A0;
  @include mix-font-size(14px, 20px);
  @include theme-monochrome-accent(color);
}

.as__section {
  text-align: center;
  padding-top: 16px;
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(#A0A0A0, .24);
  @include theme-monochrome-accent(border-color);
}

.as__section-cols {
  display: flex;
}

.as__section-col {
  width: 50%;
  padding-left: 12px;
  padding-right: 12px;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  + .as__section-col {
    border-left: 1px solid rgba(#A0A0A0, .24);
  }
}

.as__donut {
  position: relative;
}

.as-legend {
  margin-top: 24px;
}

.as-legend-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.as-legend-label {
  color: #A0A0A0;
  @include mix-font-size(14px, 24px);
  @include theme-monochrome-accent(color);
}


.as-legend-value {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.as-legend-count {
  @include mix-font-size(14px, 24px);
}

.as-legend-color {
  width: 8px;
  height: 8px;
  margin-left: 8px;
}


.about__map-container {
  position: relative;
  margin-top: 80px;
  @include respond-to(800) {
    margin-top: 48px;
  }
}

.about__map-counts {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
}

.about__map-count {
  + .about__map-count {
    margin-left: 40px;
  }
}

.amc-digit {
  color: #595959;
  letter-spacing: -0.67px;
  @include mix-font-size(40px, 40px);
  @include theme-monochrome-accent(color);
  @include respond-to(800) {
    @include mix-font-size(28px, 32px);
  }
}

.amc-text {
  color: #262626;
  letter-spacing: 0;
  text-transform: uppercase;
  @include mix-font-size(17px, 24px);
  @include theme-monochrome-accent(color);
  @include respond-to(800) {
    @include mix-font-size(12px, 16px);
  }
}

.about__map {
  padding-top: 40px;
  padding-left: 18px;
  @include respond-to(800) {
    padding-left: 0;
  }
  img {
    width: 100%;
  }
}

.about__banner,
.banner {
  background: $cBrand;
  display: flex;
  color: $cWhite;
  padding: 64px 72px;
  margin: 80px -72px 120px;
  @include theme-monochrome-accent(background);
  @include theme-monochrome-ground(color);
  &--feature {
    flex-direction: column;
  }
  &__col {
    width: 33.333%;
    &--list {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 35px;
      @include respond-to(800) {
        flex-direction: column;
        margin-top: 0;
      }
    }
  }

  &__lead {
    font-size: 32px;
    letter-spacing: -0.4px;
    line-height: 32px;
  }

  &__group {
    @include mix-font-size(24px, 24px);
    &:not(:first-child) {
      margin-top: 32px;
    }
    &--list {
      list-style: disc;
      width: 40%;
      padding-left: 10px;
      @include respond-to(800) {
        flex-direction: column;
        margin-top: 15px;
      }
      &:not(:first-child) {
        margin-top: 0;
        margin-left: 30px;
        @include respond-to(800) {
          margin-left: 0;
          margin-top: 15px;
        }
      }
      &:nth-child(2) {
        width: 45%;
        @include respond-to(800) {
          width: 100%;
        }
      }
      @include respond-to(800) {
        list-style: none;
        width: 100%;
        padding-left: 0;
        @include mix-font-size(18px, 24px);
        letter-spacing: -0.225px;
      }
    }
  }
}

.about__banner {
  @include respond-to(800) {
    margin-left: -1.6rem;
    margin-right: -1.6rem;
    flex-direction: column;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    padding-top: 4rem;
    padding-bottom: 4rem;
    margin-bottom: 4rem;
  }
}

.ab-col {
  margin-left: 64px;
  width: 280px;
  flex-shrink: 0;
  flex-grow: 0;
  @include respond-to(800) {
    margin-left: 0;
    margin-top: 3.2rem;
    width: 100%;
  }
}

.ab-col--wide {
  margin-left: 0;
  margin-right: -32px;
  width: auto;
  flex: 1;
  @include respond-to(800) {
    margin-right: 0;
    margin-top: 0;
  }
}

.ab-lead {
  letter-spacing: -0.4px;
  @include mix-font-size(32px, 32px);
}

.ab-group {
  strong {
    color: $cWhite;
  }

  p + p {
    margin-top: .8rem;
  }

  + .ab-group {
    margin-top: 3.2rem;
  }
}

.about__reliability {
  display: flex;
  justify-content: space-between;
  @include respond-to(800) {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }
}

.ar__item {
  width: 160px;
  @include respond-to(800) {
    flex-shrink: 0;
  }
}

.ar__item-image {
  width: 120px;
  margin-left: auto;
  margin-right: auto;
  height: 120px;

  img {
    width: 100%;
  }
}

.ar__item-text {
  color: #262626;
  text-align: center;
  @include mix-font-size(14px, 20px);
  @include theme-monochrome-accent(color);
}

.about__nav {
  display: flex;
  flex-wrap: wrap;
  padding: 28px 54px;
  background: #F7F7F7;
  border-radius: 4px;
  margin-top: 80px;
  @include theme-monochrome-ground(background);
  @include respond-to(800) {
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 40px;
  }
}

.about__nav-item {
  display: flex;
  //height: 24px;
  align-items: center;

  + .about__nav-item {
    margin-left: 40px;
    @include respond-to(800) {
      margin-left: 0;
      margin-top: 24px;
    }
  }
}

.about__nav-icon {
  width: 24px;
  margin-right: 12px;

  svg {

    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}

.about__nav-link {
  color: #262626;
  letter-spacing: -0.5px;
  white-space: nowrap;
  @include mix-font-size(17px, 24px);
  @include theme-monochrome-accent(color);
}

