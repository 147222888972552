.specialist-wrapper {
  width: 65.6rem;
  h1 {
    width: 100%;
    max-width: inherit;
  }
}

.specialist-detail {
  .specialist__exp {

  }
  .specialist__info {
    margin-top: 3.2rem;
    margin-bottom: 0.8rem;
  }
}

.specialist-header {
  margin-bottom: 1.6rem;
}

.specialist-resume {
  margin-top: 4.8rem;
  padding-bottom: 4rem;
  border-bottom:  1px solid rgba(#a0a0a0, 0.24);
}

.spr__row {
  +.spr__row {
    margin-top: 2.4rem;
  }
}

.spr__header {
  margin-bottom: 2.4rem;
}

.spr__list {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.spr__item {
  width: calc(50% - 40px);
  margin-left: 40px;
  margin-right: 20px;
  margin-bottom: 16px;
  +.spr__item {
    margin-top: 0;
  }
}

.specialist-photo {
  position: absolute;
  right: 7.2rem;
  top: 12rem;
  width: 27.2rem;
  text-align: center;
}

.specialist-photo-container {
  margin-bottom: 4rem;
  height: 27.2rem;
  padding-top: 2rem;
  box-sizing: border-box;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.specialist__action {
  .btn {
    width: 100%;
  }
}

.specialist-certificates {
  margin-top: 5.6rem;
}

.cert-list {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: -1rem;
  margin-right: -1rem;
}

.cert-item {
  margin-left: 1rem;
  margin-right: 1rem;
  a {

  }
  img {
    max-width: 160px;
  }
}



@media screen and (max-width: $breakpoint){
  .specialist-wrapper{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    h1 {
      order: 1;
      text-align: center;
    }
  }
  .specialist-detail{
    order: 4;
    width: 100%;
    &.is-top{
      order: 2;
    }
  }
  .specialist-photo{
    position: static;
    order: 3;
    margin-left: auto;
    margin-right: auto;
  }
  .spr__list{
    display: block;
  }
  .specialist-header,
  .specialist__exp{
    text-align: center;
  }
  .specialist-photo-container{
    border-bottom: 1px solid #e8e8e8;
  }
  .spr__item{
    width: calc(100% - 4rem);
  }
  .cert-item{
    display: inline-block;
    width: calc(50% - 2rem);
    min-width: 12rem;
    max-width: 14.8rem;
    margin-bottom: 2.4rem;
    a, img{
      width: 100%;
      height: auto;
    }
  }
}
