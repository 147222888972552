select {
  opacity: 0;
  //position: relative;
  //z-index: 1;
  //-webkit-appearance: none;
  //width: 100%;
  //@include mix-font-size(16px, 24px);
  //color: $cBlack;
  //border: none;
  //margin-top: 32px;
  //padding-bottom: 8px;
  //padding-right: 30px;
  //@include theme-monochrome-accent(color);

  &:focus {
    ~ .select-label {
      border-bottom-color: $cBrand;
      @include theme-monochrome-accent(border-bottom-color);
    }
  }

  ~ .form__label {
    right: 0;
    padding-bottom: 0;
  }

  &.filled {
    ~ .form__label {
      @include mix-font-size(14px, 20px);
      top: 0.6rem;
    }

    ~ .select-label {
      color: $cBlack;
      border-bottom-color: $cBlack;
      @include theme-monochrome-accent(color);
      @include theme-monochrome-accent(border-bottom-color);
    }
  }
}

.select-label {
  position: absolute;
  z-index: 0;
  //@include mix-font-size(16px, 24px);
  //color: transparent;
  //min-height: 24px;
  //border-bottom: 2px solid #D4D4D4;
  //padding-bottom: 6px;
  //top: 32px;
  top: 0;
  display: block;
  width: 100%;
  transition: color 0.2s;
  pointer-events: none;
  //@include theme-monochrome-ground(border-bottom-color);

  &.dis {
    color: rgba(100, 102, 105, 0.2);
    @include theme-monochrome-accent(color);
  }

  &:after {
    content: '';
    width: 32px;
    height: 1.5em;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSI1IiB2aWV3Qm94PSIwIDAgMTAgNSI+ICA8cG9seWxpbmUgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjRjM2QzIxIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMiIgcG9pbnRzPSIyMCAxNSAxNiAxOCAxMiAxNSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTExIC0xNCkiLz48L3N2Zz4=);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    position: absolute;
    right: 0;
    top: calc(3.2rem);
  }
}

@media screen and (max-width: $breakpoint) {
  select {
    overflow: hidden;
  }
}