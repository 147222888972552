.g-chat {

}

.g-chat-header {
  position: relative;
  padding-top: 1.6rem;
  text-align: center;

  &:before {
    content: '';
    width: 100%;
    position: absolute;
    height: 1px;
    background: rgba(#A0A0A0, 0.24);
    bottom: 12px;
    left: 0;
  }
}

.gch-text {
  display: inline-block;
  position: relative;
  padding-left: 1rem;
  padding-right: 1rem;
  background: $cWhite;
  color: #595959;
  text-align: center;
  line-height: 24px;

  .badge {

  }
}

.gch-date {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
}

.g-chat-content {
  margin-top: 40px;
  margin-bottom: 72px;
}

.g-chat-body {
  max-height: 500px;
  overflow: auto;
  margin-top: 40px;
  margin-bottom: 40px;
}

.g-chat-row {
  display: flex;
  align-items: flex-start;

  + .g-chat-row {
    margin-top: 40px;
  }
}

.g-chat-icon {
  width: 40px;
  height: 40px;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 16px;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.g-chat-icon--missed {
  background: $cError;

}

.g-chat-icon--answered {
  background: $cGreen;
}

.g-chat-message {
  padding: 16px 24px;
  border: 1px solid rgba(#A0A0A0, 0.4);
  border-radius: 4px;
  max-width: 70%;
}

.g-chat-message--result {
  position: relative;
  border: 1px solid #F7F7F7;
  background: #F7F7F7;

  .g-chat-message-date {
    position: absolute;
    right: 24px;
    top: 16px;
  }

}

.g-chat-message-date {
  @include mix-font-size(12px, 16px);
  color: #A0A0A0;
  margin-bottom: 8px;
}

.g-chat-message-header {
  @include metam;
  color: #262626;
  letter-spacing: 0;
  margin-bottom: 8px;
  @include mix-font-size(17px, 24px);
}


.g-chat-message-text {
}

.g-chat-row--user {
  flex-direction: row-reverse;

  .g-chat-message {
    background: #2F69B3;
    color: $cWhite;
  }

  .g-chat-message-date {
    color: $cWhite;
  }

  .g-chat-icon {
    margin-right: 0;
    margin-left: 16px;
  }

  .g-chat-row--user {
    margin-top: 8px;

    .g-chat-icon {
      opacity: 0;
      visibility: hidden;
    }
  }
}

.g-chat-row--doctor {
  + .g-chat-row--doctor {
    margin-top: 8px;

    .g-chat-icon {
      opacity: 0;
      visibility: hidden;
    }
  }

  + .g-chat-row--system {
    .g-chat-icon {
      opacity: 1;
      visibility: visible;
    }
  }
}

.g-chat-row--system {
  .g-chat-message {
    background: transparent;
  }

  .g-chat-message-date {
    color: #A0A0A0;
    margin-bottom: 0;
  }

  .g-chat-message-text {
    color: #A0A0A0;
    @include mix-font-size(14px, 24px);
  }

  .g-chat-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: none;
    }
  }

  + .g-chat-row--doctor {
    .g-chat-icon {
      opacity: 1;
      visibility: visible;
    }
  }
}

.g-chat-footer {
  margin-top: 2.4rem;
  display: flex;
  align-items: flex-end;
}

.g-chat-attach {
  position: relative;
  overflow: hidden;
  flex-grow: 0;
  margin-right: 1.6rem;

  label {
    display: flex;
    padding: 1rem;

    svg {
      display: block;
    }

    &:hover {
      cursor: pointer;
    }
  }

  input {
    position: absolute;
    width: 1px;
    height: 1px;
    left: -10px;
    opacity: 0;
  }
}

.g-chat-text-input {
  flex-grow: 1;
}

.g-chat-send {
  flex-grow: 0;
  margin-left: 16px;

  button {
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background: $cBrand;

    svg {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 80%;
    }
  }
}

.g-chat-footer {

}


@media screen and (max-width: $breakpoint) {
  .g-chat-message {
    display: flex;
    flex-direction: column;
  }
  .g-chat-message--result {
    .g-chat-message-date {
      position: static;
      order: 1;
    }

    .g-chat-message-header {
      order: 2;
    }

    .g-chat-message-text {
      order: 3;
    }
  }
  .g-chat-body {
    max-height: inherit;
  }
}
