.validate-form {
  margin-top: 32px;
  .form__radios {
    margin-top: 24px;
    display: flex;
    align-items: center;
    .form__radio + .form__radio {
      margin-top: 0;
      margin-left: 40px;
    }
    + .form__row {
      margin-top: 40px;
    }
  }
  .input-field select {
    padding-bottom: 24px!important;
  }
}
 
