.site-header {
  background: $cWhite;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 40px;
  padding-right: 40px;
  align-items: center;
  min-height: 80px;
  //position: relative;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.05);
  z-index: 100;
  transition: box-shadow 0.3s, background-color 0.3s;
  flex-wrap: wrap;
  position: relative; // для ie
  position: sticky;
  top: 0;

  @include theme-monochrome-ground(background-color);
  @include respond-to(600) {
    min-height: 64px;
  }
  @include respond-to(500) {
    padding: 0 16px;
  }
  @include respond-to(800) {
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .index & {
    background: transparent;
    box-shadow: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    @include respond-to(800) {
      background: #fff;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    &.menu-visible, &.menu-phone-visible {
      background: $cWhite;
      box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.05);
      @include theme-monochrome-ground(background-color);

      .site-header__item {
        //color: $cBlack;
        @include theme-monochrome-accent(color);
      }
    }
  }
}

.site-header__left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 80px;
  position: relative;
  @include respond-to(600) {
    height: 64px;
  }
}

.site-header__right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 80px;
  @include respond-to(800) {
    width: 50px;
  }
  @include respond-to(600) {
    height: 64px;
  }
  @include respond-to(400) {
    //justify-content: space-between;
    //flex-grow: 1;
    //padding-left: 40px;
  }
}

.site-header__item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @include mix-font-size(12px, 24px);
  text-transform: uppercase;
  font-variant-numeric: lining-nums;
  margin-left: 40px;
  transition: color 0.3s;
  position: relative;
  cursor: pointer;

  .site-header__item {
    margin-left: 0;
  }

  @include respond-to(800) {
    font-size: 0;
    width: 14px;
  }
  @include respond-to(600) {
    margin-left: 36px;
  }
  @include respond-to(400) {
    //margin: 0;
  }
}

.site-header__item--with-menu {
  position: relative;
}

.site-header__icon {
  margin-right: 16px;
  stroke: $cBrand;

  @include theme-monochrome-accent(stroke);
  path {
    @include theme-monochrome-accent(stroke);
  }
  @include respond-to(800) {
    margin: 0;
  }

  svg {
    display: block;
  }
}

@media screen and (min-width: 800px) {
  .site-header__auth-btn {
    padding: 3px 10px;
    border-radius: 15px;
    border: 1px solid #d0d0d0;
    text-transform: none;
    font-size: 1.3rem;
  }  
}

.site-header__logo {
  margin-left: 40px;
  display: inline-flex;
  align-items: center;

  img {
    max-width: 100%;
  }

  &--mobile {
    display: none;

    &:hover {
      opacity: 0.7;
    }
  }


  .use-letter {
    fill: #595959;
    transition: fill 0.3s;
    @include theme-monochrome-accent(fill);
    /*
    .index & {
      fill: $cWhite;
    }
    */
    .menu-visible & {
      fill: #595959;
      @include theme-monochrome-accent(fill);
    }
  }
}

%menubar {
  content: '';
  width: 100%;
  height: 0.2rem;
  border-radius: 0.1rem;
  background: $cBrand;
  position: absolute;
  left: 0;
  transition: opacity 0.3s, visibility 0.3s, transform 0.3s, top 0.3s, bottom 0.3s, background-color 0.3s;

  @include theme-monochrome-accent(background-color);
  /*
  .index & {
    background: $cWhite;
  }
  */
  .menu-visible & {
    background: $cBrand;
    @include theme-monochrome-accent(background-color);
  }
}

.site-header__menu {
  //height: 4rem;
  min-width: PxToRem(70px);
  box-sizing: border-box;
  padding: 7px 0 9px 0;
  @include meta;
  @include mix-font-size(12px, 24px);
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  @include respond-to(500) {
    font-size: 0;
    min-width: 16px;
  }

  .site-header__burger {
    margin-right: .5em;
  }

  .menu-visible & {
    color: $cBlack;
    @include theme-monochrome-accent(color);
  }
}

.site-header__burger {
  display: inline-block;
  width: 1.6rem;
  height: 1.2rem;
  position: relative;
  margin-top: PxToRem(-2px);
  @include respond-to(500) {
    margin: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  &:after, &:before, i {
    @extend %menubar;
  }

  &:after {
    bottom: 0;
  }

  &:before {
    top: 0;
  }

  i {
    top: 50%;
    transform: translateY(-50%);
  }

  .menu-visible & {
    i {
      opacity: 0;
      visibility: hidden;
    }

    &:after {
      transform: rotate(45deg);
      bottom: PxToRem(5px);

    }

    &:before {
      transform: rotate(-45deg);
      top: PxToRem(5px);
    }
  }
}

.header__nav-wrap {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  pointer-events: none;
  @include respond-to(900) {
    display: none;
  }

  .menu-visible & {
    pointer-events: auto;
  }
}

.header__nav {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  transition: opacity 0.5s, transform 0.5s, visibility 0.5s;
  opacity: 0;
  visibility: hidden;
  transform: translateY(3rem);
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
  @include theme-monochrome-ground(background);
  max-height: calc(100vh - 200%);
  overflow-y: auto;

  .menu-visible & {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  .action-button {
    background: #F7F7F7;
    @include theme-monochrome-ground(background-color);
    @include theme-monochrome-accent(color);

    &:hover {
      background: #f0f0f0;
      @include theme-monochrome-ground(background-color);
      @include theme-monochrome-accent(color);
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.05);
  }
}

.header-nav__inner {
  margin-left: 30px;
  margin-right: 30px;
  display: flex;
  text-transform: none;
  padding-top: 32px;
  padding-bottom: 64px;
}

.header-nav__subheader {
  @include meta;
  font-size: 2.4rem;
  line-height: 2.4rem;
  color: #262626;
  letter-spacing: -0.3px;
  @include theme-monochrome-accent(color);
}

.header-nav__item {
  @include meta;
  color: #595959;
  @include theme-monochrome-accent(color);

  + .header-nav__item {
    margin-top: 16px;
  }
}

.header-nav__link {
  transition: color 0.3s;

  &:hover {
    color: $cBrand;
    @include theme-monochrome-accent(color);
  }
}

.header-nav__column {
  box-sizing: border-box;
  padding-left: 60px;
  padding-right: 60px;
  flex-grow: 1;

  &:first-child {
    width: 31%;
    // padding-left: 0;
    border-left: 1px solid rgba(#A0A0A0, 0.24);
    @include theme-monochrome-accent(border-left-color);
    
    .header-nav__subnav {
      margin-top: 16px;
    }

    @media screen and (min-width: 901px) {
      order: 2;
      .header-nav__subheader {
        display: none;
      }
    }
  }

  &:last-child {
    padding-right: 0;
    padding-left: 52px;
    width: 31%;
    border-left: 1px solid rgba(#A0A0A0, 0.24);
    @include theme-monochrome-accent(border-left-color);

    @media screen and (min-width: 901px) {
      order: 3;
    }
  }
}

.header-nav__column--middle {
  width: 44%;
  display: flex;
  padding-left: 0;
  flex-direction: column;
  justify-content: space-between;
  
  @media screen and (min-width: 901px) {
    order: 1;
    // .header-nav__subheader {
    //   display: none;
    // }
  }
}

.header-nav__subnav {
  margin-top: 32px;
}

.header-nav__row {
  display: flex;
  align-items: flex-start;
}

.header-nav__subcol {
  width: 50%;
}

.header-nav__section + .header-nav__section {
  margin-top: 1rem;
}

.header-nav__actions {
  display: flex;
  flex-direction: column;
}

.header__mobile-menu.mobile-menu {
  display: none;
  @include respond-to(900) {
    display: block;
  }
}

.personal-popover {
  position: absolute;
  right: 0;
  top: 100%;
  box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.1);
  padding: 2.4rem;
  background: $cWhite;
  @include theme-monochrome-ground(background-color);
  min-width: 150px;
  text-transform: none;
  transition: opacity .3s, visibility .3s, transform .3s;
  opacity: 0;
  visibility: hidden;
  transform: scale(0);
  z-index: 1;
  &--block {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }
}

.ppm__item {
  + .ppm__item {
    margin-top: 8px;
  }
}

.ppm__link {
  display: block;
  padding-top: 8px;
  padding-bottom: 8px;
  color: $cBlack;
  transition: color .3s;
  @include mix-font-size(16px);
  @include theme-monochrome-accent(color);

  &:hover {
    color: $cBrand;
    @include theme-monochrome-accent(color);
  }
}

.site-header__phone {
  display: none;
}

/*
@media screen and (max-width: $breakpoint) {
  .site-header {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    box-sizing: border-box;
    overflow: visible;
    width: 100%;
  }
  .site-header__right,
  .header__nav {
    display: none;
  }
  .site-header__left {
    width: 100%;
    flex-direction: row-reverse;
    justify-content: flex-end;
    position: relative;
  }
  .site-header__logo {
    margin-left: 0;
    margin-right: auto;

    img {
      width: 120px;
      height: auto;
    }
  }
  .site-header__menu {
    position: static;
    margin-left: 3.2rem;
    margin-right: 0;
    padding-right: 0;
    transform: none;

    span {
      display: none;
    }

    .site-header__burger {
      display: block;
      width: 2.4rem;
      height: 2rem;
    }
  }
  .header__mobile-menu,
  .header__mobile-menu-phone {
    display: block;
    position: absolute;
    top: 80px;
    right: 0;
    opacity: 0;
    visibility: hidden;
    width: 100%;
    transform: translateX(-100%);
    transition: opacity 0.3s, visibility 0.3s, transform 0.3s;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    background-color: #fff;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
    @include theme-monochrome-ground(background-color);
  }
  .mobile-menu__top {
    display: flex;
    align-items: stretch;
    justify-content: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }
  .mobile-menu__top-item {
    width: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5.6rem;
    color: #7b7b7b;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 2.4rem;
    @include theme-monochrome-accent(color);

    & + & {
      border-left: 1px solid rgba(0, 0, 0, 0.05);
    }
  }
  .mobile-menu__top-icon {
    stroke: $cBrand;
    @include theme-monochrome-accent(stroke);

    svg {
      display: block;
    }
  }
  .mobile-menu__top-item--city {
    width: calc(100% - 16rem);

    .mobile-menu__top-icon {
      margin-right: .8rem;
    }
  }
  %menubar {
    height: 0.3rem;
  }
  .menu-visible {
    .site-header__burger {
      &:before {
        top: 9px;
      }

      &:after {
        bottom: 8px;
      }
    }

    .header__mobile-menu {
      right: 0;
      opacity: 1;
      visibility: visible;
      transform: translateX(0);
    }
  }
  .menu-phone-visible .header__mobile-menu-phone {
    right: 0;
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }
  .mobile-menu__manager {
    padding: 2.4rem 2.4rem 2.8rem;
    background-color: #f7f7f7;
    @include theme-monochrome-ground(background-color);

    .manager-name,
    .manager-title {
      text-transform: none;
    }

    .manager-title {
      position: relative;
    }
  }
  .header__extra {
    position: absolute;
    right: 1.5rem;
    top: 0;

    a.trigger {
      width: 2.4rem;
      height: 2.4rem;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        display: block;
      }

      use {
        fill: $cBrand;
        transition: fill 0.3s;
        @include theme-monochrome-accent(fill);
      }

      &:hover {
        use {
          fill: $cBlack;
          @include theme-monochrome-accent(fill);
        }
      }
    }

    &:hover {
      .actions {
        transform: translateX(1.5rem) scale(1);
        opacity: 1;
      }
    }

    .actions {
      position: absolute;
      white-space: nowrap;
      padding: 2.4rem;
      background: #fff;
      border-radius: 0.4rem;
      box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.1);
      transition: opacity 0.3s, transform 0.3s;
      right: 0;
      transform: translateX(1.5rem) scale(0.4);
      opacity: 0;
    }

    a.action {
      display: block;
      transition: color 0.3s;

      &:hover {
        color: $cBrand;
        @include theme-monochrome-accent(color);
      }

      + a.action {
        margin-top: 2.4rem;
      }
    }
  }
  .mobile-menu__actions {
    width: 100%;
    box-sizing: border-box;
    padding-top: 3.6rem;
    padding-bottom: 2.4rem;
  }
  .header-nav__section {
    padding-top: 2.4rem;
    padding-bottom: 3.6rem;
    border-bottom: 1px solid #f2f2f2;
    @include theme-monochrome-ground(border-bottom);

    & + & {
      margin-top: 0;
    }
  }
  .mobile-menu__user-menu {
    transition: max-height .6s;
    overflow: hidden;
    max-height: 0;
  }

  .mobile-menu__user-menu--opened {
    max-height: 50vh;
  }

  .mobile-menu__user-menu-inner {
    padding: 1.6rem 2.4rem;
    border-bottom: 1px solid rgba(#a0a0a0, .1);
  }

  .mobile-menu__user-menu-list {
    list-style-type: none;
  }

  .mobile-menu__user-menu-item {

  }

  .mobile-menu__user-menu-link {
    display: block;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-transform: none;
  }

  .mobile-menu__inner {
    padding-left: 2.4rem;
    padding-right: 2.4rem;

    .header-nav__subnav {
      margin-top: 0;
    }

    .action-button {
      width: 100%;
      max-width: 360px;
      justify-content: center;
      padding: 0;
      @include mix-font-size(16px, 24px);
      font-weight: 400;
      letter-spacing: -0.05rem;
      text-transform: none;
      background-color: #f2f2f2;
      margin-left: auto;
      margin-right: auto;
      @include theme-monochrome-ground(background-color);
    }
  }
  .mobile-menu__corp {
    display: block;
    width: fit-content;
    margin: 3.2rem auto 4rem;
    color: #7b7b7b;
    font-size: 1.7rem;
    font-weight: 400;
    line-height: 2.4rem;
    text-align: center;
    @include theme-monochrome-accent(color);
  }
  .header-nav__subheader {
    text-transform: none;
  }
  .site-header__phone {
    display: flex;
    transition: filter .3s;

    .site-header__icon {
      margin-right: 0;
    }

    &.is-gray {
      filter: grayscale(100%);
    }
  }
  .mobile-menu-phone__top {
    display: flex;
    border-bottom: 1px solid #f2f2f2;
    @include theme-monochrome-ground(border-bottom-color);
  }
  .mobile-menu-phone__link {
    display: block;
    box-sizing: border-box;
    width: 50%;
    padding: 1.6rem 2.4rem;
    text-align: left;
    color: #7b7b7b;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.6rem;
    text-transform: none;
    @include theme-monochrome-accent(color);

    span {
      display: block;
      color: #262626;
      font-size: 1.6rem;
      line-height: 2.4rem;
      letter-spacing: -0.05rem;
      margin-top: .8rem;
      @include theme-monochrome-accent(color);
    }

    & + & {
      border-left: 1px solid #f2f2f2;
      @include theme-monochrome-ground(border-left-color);
    }
  }
  .mobile-menu-phone__bottom {
    background-color: #f7f7f7;
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
    @include theme-monochrome-ground(background-color);

    .btn {
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
      text-transform: none;
    }
  }
}
*/

.city_qtip {
  z-index: 999;
  //right: 275px;
  position: absolute;
  left: 33px;
  top: calc(100% + 10px);
  //width: 265px;
  font-size: 14px;
  @include mix-font-size(14px);
  transform: translateX(-50%);

  &-content {
    position: relative;
    padding: 16px 24px 24px;
    text-align: center;
    background-color: #fff;
    border: 1px solid #f3f3f3;
    border-radius: 3px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
    @include theme-monochrome-ground(background-color);
    @include theme-monochrome-ground(border-color);

    &:after {
      content: '';
      position: absolute;
      display: block;
      top: -20px;
      left: 50%;
      margin-left: -7px;
      border: 11px solid transparent;
      border-bottom: 10px solid white;
      @include theme-monochrome-ground(border-bottom-color);
    }
  }

  &-content_text {
    color: #595959;
    line-height: 2;
    font-family: open-sans, sans-serif;
    text-transform: initial;
    @include theme-monochrome-accent(color);
  }

  &-actions {
    margin-top: 15px;
    white-space: nowrap;
  }
}


.chenge_city, .city_ok {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 15px;
  padding-right: 15px;
  margin-right: 5px;
}

.button {
  display: inline-block;
  line-height: 1;
  border: 1px solid #f36c21;
  background: none;
  color: #252525;
  transition: color .2s, background-color .2s;
  cursor: pointer;
  @include theme-monochrome-accent(border-color);
  @include theme-monochrome-accent(color);
}

.button-filled {
  display: inline-block;
  line-height: 1;
  border: 1px solid #f36c21;
  background-color: #f36c21;
  transition: color .2s, background-color .2s;
  background-image: linear-gradient(90deg, #f36c21 0, #f36c21);
  color: #fff;
  cursor: pointer;
  @include theme-monochrome-accent(border-color);
  @include theme-monochrome-accent(background);
  @include theme-monochrome-ground(color);

  &:hover {
    background: white;
    @include theme-monochrome-accent(background);
  }
}
