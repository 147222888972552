.specialists {

}

.specialists__form {
  margin-bottom: 4rem;
}

.specialists__list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: -2.8rem;
  margin-right: -2.8rem;
  overflow: hidden;
}

.specialists__item {
  width: 32rem;
  margin-left: 2.8rem;
  margin-right: 2.8rem;
  padding-bottom: 4rem;
  border-bottom: 1px solid rgba(#A0A0A0, 0.24);
  margin-bottom: 5.6rem;
  transition: all .4s;
  display: flex;
  flex-direction: column;
}

.specialist__image {
  width: 100%;
  height: 24rem;
  margin-bottom: 4rem;
  display: block;
  box-sizing: border-box;
  text-align: center;

  div {
    width: 100%;
    height: 100%;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    object-fit: contain;
    display: block;
  }

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
}

.specialist__info {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.specialist__name {
  @include metam;
  color: #262626;
  letter-spacing: -0.23px;
  @include mix-font-size(18px, 20px);
}

.specialist__job {
  margin-top: 0.8rem;
  color: $cGrey;
}

.specialist__exp {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
}

.specialist__about {
  color: $cGrey;
  @include mix-font-size(17px);
}

.specialist__footer {
  margin-top: auto;
}

.specialist__actions {
  margin-top: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.specialist__meta {
  color: #7B7B7B;
  margin-top: 1.6rem;
  @include mix-font-size(14px);
}

@media screen and (max-width: $breakpoint) {
  .specialists__list {
    margin-left: 0;
    margin-right: 0;
  }
  .specialists__item {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .specialist__info .specialist__exp {
    text-align: left;
  }
  .specialist__actions {
    display: block;
  }
}
