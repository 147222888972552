.regional-actions {
  position: relative;
}

.region-selector {

}

.region-select {
  display: inline-flex;
  align-items: center;
  color: #262626;
  letter-spacing: -0.4px;
  transition: color 0.3s;
  @include mix-font-size(32px, 32px);

  svg {
    stroke: $cBrand;
    margin-left: 10px;
    transition: transform 0.3s;
  }

  &:hover {
    color: $cBrand;

    svg {
      transform: translateX(1rem);
    }
  }
}

.regional-map {
  padding-top: 32px;
}

#map {
  height: 600px;
}

.regional-map, .regional-map-cnt {
  position: relative;
}

.filial-info {
  width: 416px;
  background: $cWhite;
  border-radius: 4px;
  position: absolute;
  top: 56px;
  bottom: 56px;
  right: 64px;
  transition: transform 0.3s, opacity 0.3s, visibility 0.3s;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.4);
  overflow: auto;

  &.is-visible {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }

  &:after {
    content: '';
    position: absolute;
    left: 10px;
    right: 10px;
    top: 70px;
    bottom: 70px;
    background: rgba(255, 255, 255, 0.75);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;
  }

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 32px;
    height: 32px;
    background: url('/static/img/loader_brand2.png') left center no-repeat;
    -webkit-animation: play_loader 0.6s steps(18) infinite;
    animation: play_loader 0.6s steps(18) infinite;
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;
  }

  &.is-loading {
    &:after,
    &:before {
      opacity: 1;
      visibility: visible;
    }
  }
}

.filial-info-content {
  padding: 72px 40px 40px;
}

.filial-info-close {
  position: absolute;
  width: 14px;
  height: 14px;
  right: 24px;
  top: 24px;
  padding: 14px;
  border: 1px solid rgba(#A0A0A0, 0.25);
  border-radius: 50%;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDE0IDE0Ij48cGF0aCBkPSJNMTMgMUwxIDEzTTEgMWwxMiAxMiIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2U9IiMyNjI2MjYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIi8+PC9zdmc+);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  transition: transform 0.4s;

  &:hover {
    transform: rotate(180deg);
  }
}

.fic-name {
  color: #262626;
  letter-spacing: -0.3px;
  margin-bottom: 16px;
  @include mix-font-size(24px, 24px);
  @include theme-monochrome-accent(color);
}

.fic-address {
  margin-bottom: 32px;
}


.filials__list {

}

.filials__item {
  padding-top: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid rgba(#a0a0a0, .24);
  position: relative;
}

.fi__header {
}

.fi__body {
  display: flex;
  @include respond-to(700){
    flex-direction: column;
  }
  @include respond-to(900) {
    justify-content: space-between;
    width: 65%;
  }
}

.fi__col {
  @include respond-to(700) {
    width: auto;
    margin-right: 0;
  }
  &:not(:first-child) {
    margin-left: 50px;
    @include respond-to(700) {
      margin-top: 10px;
    }
    @include respond-to(800) {
      margin-left: 0;
    }
  }
}

.fi__action {
  position: absolute;
  top: 48px;
  right: 16px;
}

.fi__name {

}

.fi__address {

}

@media screen and (max-width: $breakpoint) {
  .region-selector {
    margin-bottom: 2.4rem;
  }
  .region-select {
    width: calc(100% - 16px);
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
    padding-right: 16px;
    @include mix-font-size(24px, 24px);

    svg {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      margin-left: 0;
    }
  }
  .regional-map {
    padding-top: 0;
    margin-top: -24px;
    margin-left: -24px;
    margin-right: -24px;
  }
  #map {
    height: 400px;
  }
  .filial-info {
    top: auto;
    bottom: auto;
    right: auto;
    width: auto;
    margin-top: -40vh;
    margin-left: 24px;
    margin-right: 24px;

    &.is-visible {
      position: relative;
    }
  }
  .filial-info-content {
    padding-left: 24px;
    padding-right: 24px;
  }
  .filial-info-close {
    right: auto;
    top: 16px;
    left: 50%;
    transform: translateX(-50%);

    &:hover {
      transform: translateX(-50%);
    }

  }
  .fi__action {
    position: static;
    margin-top: 24px;
  }
}

