input[type=text], input[type=email], input[type=password], input[type=number], input[type=date], input[type=tel], textarea, select, .select-label {
  border: none;
  min-height: 2.4rem;
  background: $cWhite;
  border: 0;
  border-bottom: 2px solid #D4D4D4;
  box-shadow: none;
  border-radius: 0;
  padding: 3.2rem 0 0.6rem 0;
  display: block;
  width: 100%;
  @include metam;
  @include mix-font-size(16px, 24px);
  color: $cBlack;
  transition: border-color 0.3s;

  &:not([type=number]) {
    //-webkit-appearance: textfield;
  }

  @include theme-monochrome-ground(background);
  @include theme-monochrome-accent(border-color) {
    border: 1px solid;
  }
  @include theme-monochrome-accent(color);

  &::-webkit-input-placeholder {
    opacity: 0;
    line-height: 3.2rem;
  }

  &:-moz-placeholder {
    opacity: 0;
  }

  &:-ms-input-placeholder {
    opacity: 0;
  }

  &:focus {
    border-bottom-color: $cBrand;
    @include theme-monochrome-accent(border-bottom-color);

    + .form__label {
      @include mix-font-size(14px, 20px);
      top: 0.6rem;
    }
  }

  &:disabled {
    color: rgba($cBlack, 0.5);
    @include theme-monochrome-accent(color);

    + label {
      color: rgba($cBlack, 0.5);
      @include theme-monochrome-accent(color);
    }
  }

  &.filled {
    border-bottom-color: $cBlack;
    filter: none;
    @include theme-monochrome-accent(border-bottom-color);

    + .form__label {
      @include mix-font-size(14px, 20px);
      top: 0.6rem;
    }

    &:focus {
      border-bottom-color: $cBrand;
      @include theme-monochrome-accent(border-bottom-color);
    }
  }

  &:-webkit-autofill {
    border-bottom-color: $cBlack;
    background-color: white;
    -webkit-box-shadow: inset 0 0 0 50px #fff;
    filter: none;
    @include theme-monochrome-accent(border-bottom-color);
    @include theme-monochrome-ground(background-color);

    + .form__label {
      @include mix-font-size(14px, 20px);
      top: 0.6rem;
    }

    &:focus {
      border-bottom-color: $cBrand;
      @include theme-monochrome-accent(border-bottom-color);
    }
  }

  .hero & {
    background: transparent;
  }
}

input:-webkit-autofill {
  + label {
    @extend .active;
  }
}

/*textarea {
  border: 2px solid #D4D4D4;
  height: 20rem;
  box-sizing: border-box;
  font-size: 1.6rem;
  line-height: 2.4rem;
  padding: 3.2rem 0 0.8rem 0;
  transition: border-color 0.3s;
  resize: none;
  &::-webkit-input-placeholder {
    opacity: 0;
    line-height: 2.4rem;
  }
  &:-moz-placeholder {
    opacity: 0;
    line-height: 2.4rem;
  }
  &:-ms-input-placeholder {
    opacity: 0;
    line-height: 2.4rem;
  }
  &:focus {
    border-color: $cBrand;
  }
  &.textarea--smaller {
    height: 12rem;
  }
}*/

input[type=date] {
  color: #fff;
  @include theme-monochrome-ground(color);

  &.filled {
    color: $cBlack;
    @include theme-monochrome-ground(color);
  }
}

.input__icon {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 28px;

  svg {
    display: block;
  }
}

.input__icon--interact {
  &:hover {
    cursor: pointer;
  }
}
