[data-page="news"] {
  h1 {
    margin-bottom: 0;
  }
}

.years-filter {
  position: relative;
}

.years-filter__list {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -3px;
  text-align: right;
}

.years-filter__item {
  margin-right: 24px;
  box-shadow: inset 0 -4px 0 0 transparent;
  transition: box-shadow 0.3s;
  display: inline-block;

  &.active {
    box-shadow: inset 0 -4px 0 0 #F36C21;

    &:hover {
      box-shadow: inset 0 -4px 0 0 #F36C21;
    }
  }

  &:hover {
    box-shadow: inset 0 -4px 0 0 rgba(#F36C21, 0.2);
  }

  &:last-child {
    margin-right: 0;
  }
}

.years-filter__link {
  text-transform: uppercase;
  padding-bottom: 16px;
  display: block;
  @include mix-font-size(16px)
}


.news__list {
  &.is-loading {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background: rgba(255, 255, 255, 0.75);
      z-index: 1;
    }

    &:after {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      bottom: 0;
      right: 0;
      transform: translate(-50%, -50%);
      width: 32px;
      height: 32px;
      background: rgba(255, 255, 255, 0.75) url('/static/img/loader_brand2.png') left center no-repeat;
      animation: play_loader 0.6s steps(18) infinite;
      z-index: 2;
    }
  }

  .no-results {
    min-height: 240px;
  }
}

.news__item {
  display: flex;
  align-items: flex-start;
  position: relative;
  padding-top: 32px;
  padding-bottom: 32px;
  border-bottom: 1px solid rgba(#a0a0a0, 0.24);
  transition: opacity 0.6s, transform 0.6s;
  opacity: 0;
  transform: translateX(-32px);

  &.view-in {
    opacity: 1;
    transform: translateX(0);
  }

  &:after {
    content: '';
    width: 40px;
    height: 40px;
    position: absolute;
    border-radius: 50%;
    right: 16px;
    top: 72px;
    box-sizing: border-box;
    background-position: calc(50% + 1px) 50%;
    background-repeat: no-repeat;
    transform-origin: 50% 50%;
    transition: border-width 0.2s, border-color 0.2s, background-color 0.2s, background-image 0.2s;
    border: 1px solid rgba(#a0a0a0, 0.15);
    background-color: transparent;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSIxMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMSA5bDQtNC00LTQiIHN0cm9rZT0iIzU5NTk1OSIgc3Ryb2tlLXdpZHRoPSIyIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPjwvc3ZnPg==);
  }

  &:hover {
    &:after {
      border-color: $cBrand;
      background-color: $cBrand;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSIxMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMSA5bDQtNC00LTQiIHN0cm9rZT0iI0ZGRiIgc3Ryb2tlLXdpZHRoPSIyIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPjwvc3ZnPg==);
    }
  }
}

.news__image {
  width: 284px;
  flex-shrink: 0;
  flex-grow: 0;
  margin-right: 40px;

  picture, img {
    display: block;
    width: 100%;
  }

  + .news__preview {
    width: 508px;
    padding-right: 0;
  }
}

.news__preview {
  width: auto;
  padding-right: 64px;
}

.news-preview__date {
  color: #A0A0A0;
  letter-spacing: 0;
  margin-bottom: 16px;
  @include mix-font-size(14px, 16px);
  @include theme-monochrome-accent(color);
}

.news-preview__header {
  margin-bottom: 16px;
  color: #262626;
  letter-spacing: -0.3px;
  @include mix-font-size(24px, 24px);
  @include theme-monochrome-accent(color);
}

.news-preview__text {
  @include mix-font-size(16px, 24px);
  color: #595959;
  letter-spacing: 0;
  @include theme-monochrome-accent(color);
}

.news__date {
  color: #262626;
}

.news-inner__content {
  margin-top: 4rem;
  color: #595959;
  transition: opacity 0.6s, transform 0.6s;
  opacity: 0;

  &.view-in {
    opacity: 1;
  }
}

.news-inner__action {
  margin-top: 40px;
  padding-top: 32px;
  border-top: 1px solid rgba(#a0a0a0, 0.24);
}

@media screen and (max-width: $breakpoint) {
  .years-filter {
    margin-top: 32px;
  }

  .years-filter__list {
    position: static;
    text-align: left;
    white-space: nowrap;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    direction: rtl;
  }

  .years-filter__item {
    margin-left: 24px;
    margin-right: 0;
  }

  .news__item {
    flex-direction: column;

    &:after {
      position: static;
      margin-top: 16px;
      content: 'Подробнее';
      background-image: none;
      width: auto;
      height: auto;
      padding: 12px 24px;
      background-color: $cBrand;
      color: $cWhite;
      border-radius: 4px;
    }

    &:hover {
      &:after {
        background-image: none;
      }
    }
  }

  .news__image {
    width: 100%;
    margin-right: 0;
    margin-bottom: 32px;

    + .news__preview {
      width: 100%;
    }
  }
  .news__preview {
    width: 100%;
    padding-right: 0;
  }
}

