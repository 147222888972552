.get-offers {
  margin-top: 64px;
  margin-bottom: 24px;
}


.bank__introduction {
  padding-bottom: 48px;
  border-bottom: 1px solid rgba(160, 160, 160, 0.24);
  margin-bottom: 53px;
  @include respond-to(800) {
    padding-bottom: 0;
  }
  &._no-border {
    border: none;
  }
}

.bank__introduction-btn {
  margin-top: 32px;
}


.bank__accordion {
  margin-top: 64px;

  .accordion {
    margin-right: -48px;
  }

  .accordion-trigger {
    width: calc(100% - 48px);
    box-sizing: border-box;
  }

  .accordion-content {
    width: 100%;
    box-sizing: border-box;
    padding-right: 48px;
  }

  .accordion-content-inner {
    padding-bottom: 48px !important;
  }
}

.bank__examples-title {
  margin-top: 52px;
  margin-bottom: 32px;
  color: $cBlack;
  @include metam;
  @include mix-font-size(18px, 20px);
  letter-spacing: -0.022rem;
  @include theme-monochrome-accent(color);
}

.bank__slider {
  margin-right: -48px;

  .swiper-wrapper {
    align-items: stretch;
  }

  .swiper-slide {
    height: auto;
    max-width: 206px;
    margin-right: 40px;
  }

  .swiper-button-prev, .swiper-button-next {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #fff;
    border: 1px solid rgba(#a0a0a0, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    top: 90px;

    svg {
      display: block;
    }

    use {
      transition: stroke 0.3s;
      stroke: #595959;
    }

    &:hover {
      use {
        stroke: $cBrand;
      }
    }
  }
}

.bank__slide {
  position: relative;
  height: 100%;
  box-sizing: border-box;
  padding-bottom: 48px;

  .product__image {
    border-radius: 4px;
    overflow: hidden;
  }

  .product__header {
    margin-bottom: 22px;
  }
}

.bank__slide-more {
  position: absolute;
  bottom: 0;
  left: 0;
}

.bank__slide-text {
  color: #a0a0a0;
  @include mix-font-size(14px, 24px);
  @include theme-monochrome-accent(color);
}

.bank__all-products {
  margin-top: 48px;
  padding-bottom: 16px;
}

.bank__small-text {
  max-width: 574px;

  &.mar-40 {
    margin-bottom: 40px;
  }
}

.bank__vac-for-students {
  max-width: 409px;
  margin-top: 40px;
  opacity: 0;
  transform: translateY(32px);
  transition: opacity 0.5s, transform 0.5s;
  @include respond-to(1000) {
    max-width: 100%;
  }
  .vfs__header {
    margin-top: 0;
    @include metam;
  }

  .vfs__icon {
    line-height: 0;
    margin-bottom: 16px;
    overflow: hidden;
  }

  &.view-in {
    opacity: 1;
    transform: translateY(0);
  }
}

.bank__form {
  margin-bottom: 56px;
  opacity: 0;
  transform: translateY(16px);
  transition: opacity 0.5s, transform 0.5s;

  &.view-in {
    opacity: 1;
    transform: translateY(0);
  }
}

.bank__directions {
  margin-top: 32px;
}

.attachment-zone--no-top {
  margin-top: 0;
  padding-top: 0;
  border-top: none;
}


@media screen and (max-width: $breakpoint) {
  .bank__accordion {
    margin-top: 40px;

    .accordion {
      margin-right: 0;
    }

    .accordion-trigger {
      width: 100%;

      h4 {
        @include mix-font-size(16px, 20px);
      }
    }

    .accordion-content {
      padding-right: 0;
    }
  }
  .bank__slide .product__image {
    max-width: 280px;
    padding-bottom: 280px;
  }
  .bank__introduction-btn,
  .bank__all-products {
    max-width: 360px;

    a.btn {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .bank__slider .swiper-slide {
    max-width: none;
  }
  .bank__slider {
    margin-right: 0;

    .swiper-button-prev,
    .swiper-button-next {
      top: 125px;
    }
  }
}