@charset "UTF-8";
/* 09.2022 Новые цвета, остальные цвета сохраняются для поддержки*/
/* 09.2022 Новые цвета, остальные цвета сохраняются для поддержки*/
@font-face {
  font-family: 'PFCentroSlabPro-Thin';
  src: url("/static/fonts/355078_0_0.woff2") format("woff2"), url("/static/fonts/355078_0_0.woff") format("woff"); }

@font-face {
  font-family: 'FFMetaWebProNormal';
  src: url("/static/fonts/355078_1_0.woff2") format("woff2"), url("/static/fonts/355078_1_0.woff") format("woff"), url("/static/fonts/355078_1_0.ttf") format("truetype"); }

@font-face {
  font-family: 'FFMetaWebProMedium';
  src: url("/static/fonts/355078_2_0.woff2") format("woff2"), url("/static/fonts/355078_2_0.woff") format("woff"), url("/static/fonts/355078_2_0.ttf") format("truetype"); }

html {
  font-size: 10px; }
  @media screen and (max-width: 1200px) {
    html {
      font-size: 10px; } }
  @media screen and (max-width: 1100px) {
    html {
      font-size: 10px; } }
  @media screen and (max-width: 1000px) {
    html {
      font-size: 10px; } }
  @media screen and (max-width: 900px) {
    html {
      font-size: 10px; } }
  @media screen and (max-width: 800px) {
    html {
      font-size: 10px; } }
  @media screen and (max-width: 700px) {
    html {
      font-size: 10px; } }
  @media screen and (max-width: 600px) {
    html {
      font-size: 10px; } }
  @media screen and (max-width: 500px) {
    html {
      font-size: 10px; } }
  @media screen and (max-width: 400px) {
    html {
      font-size: 10px; } }
  @media screen and (max-width: 300px) {
    html {
      font-size: 10px; } }

body {
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-family: 'FFMetaWebProNormal', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-variant-numeric: lining-nums;
  /* high-level property          */
  -moz-font-feature-settings: 'lnum';
  /* low-level (old Firefox)      */
  -webkit-font-feature-settings: 'lnum';
  /* low-level (old Webkit)       */
  font-feature-settings: 'lnum' on;
  /* low-level (all new browsers) */ }
  @media screen and (max-width: 800px) {
    body {
      min-width: 0; } }

.theme-images._grayscale img, .theme-images._grayscale svg, .theme-images._grayscale picture {
  filter: grayscale(1); }

.theme-images._sepia img, .theme-images._sepia svg, .theme-images._sepia picture {
  filter: sepia(1); }

.theme-images._invert img, .theme-images._invert svg, .theme-images._invert picture {
  filter: invert(1); }

.theme-images._hidden img, .theme-images._hidden svg, .theme-images._hidden picture {
  visibility: hidden; }

#app {
  min-height: 100vh;
  width: 100%;
  position: relative; }
  #app.big-font-size {
    word-wrap: break-word; }

#page {
  min-height: 100%;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: stretch;
  color: #262626; }
  .theme-monochrome._black-accent #page {
    color: #000 !important; }
  .theme-monochrome._white-accent #page {
    color: #fff !important; }
  .theme-monochrome._sepia-accent #page {
    color: #472312 !important; }
  .theme-monochrome._black-ground #page {
    background: #000 !important; }
  .theme-monochrome._white-ground #page {
    background: #fff !important; }
  .theme-monochrome._sepia-ground #page {
    background: #F5F5DC !important; }

.site-header {
  flex-grow: 0; }

.site-footer {
  flex-grow: 0; }

.page-content {
  margin-top: 16px;
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-basis: auto;
  min-height: 500px; }
  @media screen and (max-width: 800px) {
    .page-content {
      min-height: 0; } }
  .page-content > .wrapper {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column; }
  .index .page-content {
    margin-top: 0;
    height: 100vh; }
    @media screen and (max-width: 800px) {
      .index .page-content {
        height: 661px; } }

.content {
  background: #ffffff;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-start;
  align-items: stretch; }
  .theme-monochrome._black-ground .content {
    background: #000 !important; }
  .theme-monochrome._white-ground .content {
    background: #fff !important; }
  .theme-monochrome._sepia-ground .content {
    background: #F5F5DC !important; }
  @media screen and (max-width: 800px) {
    .content {
      display: block; } }

.content__inner {
  padding: 64px 80px 64px 72px;
  flex: 1 1 auto;
  width: calc(100% - 416px);
  box-sizing: border-box; }
  @media screen and (max-width: 800px) {
    .content__inner {
      box-sizing: border-box;
      padding: 16px;
      width: 100%; } }
  .content__inner_lk {
    padding: 0;
    box-shadow: none;
    flex-shrink: 0; }

.content__inner--no-aside {
  padding-right: 72px;
  position: relative;
  /*
  h1 {
    max-width: calc(100% - 41.6rem);
  }
  */ }

.content__inner--nopt {
  padding-top: 0; }

/*.content {
  background: #ffffff;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  position: relative;
  &:before {
    content: '';
    top: 0.8rem;
    width: 40rem;
    right: 0.8rem;
    bottom: 0.8rem;
    position: absolute;
    background: #F7F7F7;
  }
}*/
/*
.content__aside {
  box-sizing: border-box;
  width: 40rem;
  flex: 0 0 auto;
  position: absolute;
  right: 0.8rem;
  top: 0.8rem;
  //+.content__inner {
  //  order: 1;
  //  flex: 1 1 auto;
  //}
}
*/
/*
.content__inner {
  padding: 6.4rem 8rem 6.4rem 7.2rem;
  flex: 1 1 auto;
  margin-right: 41.6rem;
}
*/
@keyframes play_loader {
  100% {
    background-position: -576px; } }

.data-preloader {
  flex-grow: 1;
  min-height: 200px;
  background: #fff;
  position: relative; }
  .theme-monochrome._black-ground .data-preloader {
    background: #000 !important; }
  .theme-monochrome._white-ground .data-preloader {
    background: #fff !important; }
  .theme-monochrome._sepia-ground .data-preloader {
    background: #F5F5DC !important; }
  .data-preloader::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 32px;
    height: 32px;
    background: url("/static/img/loader_brand2.png") left center no-repeat;
    animation: play_loader 0.6s steps(18) infinite; }

.preloader {
  width: 32px;
  height: 32px;
  background: url("/static/img/loader_brand2.png") left center no-repeat;
  animation: play_loader 0.6s steps(18) infinite; }

hr.titled {
  border: 0;
  border-bottom: 1px solid rgba(160, 160, 160, 0.24);
  margin-top: 24px;
  margin-bottom: 32px;
  position: relative;
  overflow: visible; }
  .theme-monochrome._black-ground hr.titled {
    border-bottom-color: #000 !important; }
  .theme-monochrome._white-ground hr.titled {
    border-bottom-color: #fff !important; }
  .theme-monochrome._sepia-ground hr.titled {
    border-bottom-color: #F5F5DC !important; }
  hr.titled:before {
    content: attr(title);
    padding-left: 8px;
    padding-right: 8px;
    background: #fff;
    position: absolute;
    left: 50%;
    top: -8px;
    transform: translateX(-50%);
    color: #A0A0A0;
    letter-spacing: 0;
    font-size: 1.4rem;
    line-height: 1.14286em; }
    .theme-monochrome._black-accent hr.titled:before {
      color: #000 !important; }
    .theme-monochrome._white-accent hr.titled:before {
      color: #fff !important; }
    .theme-monochrome._sepia-accent hr.titled:before {
      color: #472312 !important; }
    .theme-monochrome._black-ground hr.titled:before {
      background: #000 !important; }
    .theme-monochrome._white-ground hr.titled:before {
      background: #fff !important; }
    .theme-monochrome._sepia-ground hr.titled:before {
      background: #F5F5DC !important; }

.full-vw {
  margin-left: calc(-1 * (100vw - 1072px) / 2);
  margin-right: calc(-1 * (100vw - 1072px) / 2); }
  .full-vw .wrapper {
    width: 1072px;
    margin-left: auto;
    margin-right: auto; }

.row {
  display: flex; }
  .row .col--right-aligned {
    align-self: flex-end;
    text-align: right; }

.row--center {
  align-items: center; }

.row--bottom {
  align-items: flex-end; }

.row--top {
  align-items: flex-start; }

.row--stretch {
  align-items: stretch; }

.row--vert {
  flex-direction: column; }

.row--spb {
  justify-content: space-between; }

.row--wrap {
  flex-wrap: wrap; }

.grid {
  display: flex;
  flex-wrap: wrap; }

.col {
  transition: opacity 0.5s, transform 0.5s; }
  .col[data-inview] {
    opacity: 0;
    transform: translateY(20px); }
  .col.view-in {
    opacity: 1;
    transform: translateY(0); }

.cols11 {
  margin-left: -16px;
  margin-right: -16px; }
  @media screen and (max-width: 800px) {
    .cols11 {
      margin-left: 0;
      margin-right: 0; } }
  .cols11 > .col {
    margin-left: 16px;
    margin-right: 16px;
    width: calc((100% - 64px) / 2); }
    @media screen and (max-width: 800px) {
      .cols11 > .col {
        width: 100%;
        margin-right: 0;
        margin-left: 0; } }
    @media screen and (max-width: 800px) {
      .cols11 > .col + .col {
        margin-right: 0;
        margin-left: 0;
        margin-top: 32px; } }
    .cols11 > .col.col--full-width {
      margin-left: 16px;
      margin-right: 16px;
      width: calc(100% - 32px); }

.cols111 {
  margin-left: -14px;
  margin-right: -14px; }
  @media screen and (max-width: 800px) {
    .cols111 {
      margin-left: 0;
      margin-right: 0; } }
  .cols111 > .col {
    width: calc((100% - 84px) / 3);
    margin-left: 14px;
    margin-right: 14px; }
    @media screen and (max-width: 800px) {
      .cols111 > .col {
        width: 100%;
        margin-right: 0;
        margin-left: 0; } }
    @media screen and (max-width: 800px) {
      .cols111 > .col + .col {
        margin-right: 0;
        margin-left: 0;
        margin-top: 32px; } }
    .cols111 > .col.col--full-width {
      margin-left: 14px;
      margin-right: 14px;
      width: calc(100% - 28px); }
      @media screen and (max-width: 800px) {
        .cols111 > .col.col--full-width {
          margin-left: 0;
          margin-right: 0;
          width: 100%; } }

.cols1111 {
  margin-left: -14px;
  margin-right: -14px; }
  .cols1111 > .col {
    width: calc((100% - 1.4 * 2 * 30px) / 4);
    margin-left: 14px;
    margin-right: 14px; }
    .cols1111 > .col.col--full-width {
      margin-left: 14px;
      margin-right: 14px;
      width: calc(100% - 28px); }

.cols21 {
  margin-left: -14px;
  margin-right: -14px; }
  @media screen and (max-width: 800px) {
    .cols21 {
      margin-left: 0;
      margin-right: 0; } }
  .cols21 > .col {
    width: calc((100% - 84px) / 3);
    margin-left: 14px;
    margin-right: 14px; }
    @media screen and (max-width: 800px) {
      .cols21 > .col {
        width: 100%;
        margin-left: 0;
        margin-right: 0; } }
    @media screen and (max-width: 800px) {
      .cols21 > .col + .col {
        margin-top: 32px; } }
    .cols21 > .col:first-child {
      width: calc((100% - 56px) - ((100% - 84px) / 3)); }
      @media screen and (max-width: 800px) {
        .cols21 > .col:first-child {
          width: 100%; } }

.cols12 {
  margin-left: -14px;
  margin-right: -14px; }
  @media screen and (max-width: 800px) {
    .cols12 {
      margin-left: 0;
      margin-right: 0; } }
  .cols12 > .col {
    width: calc((100% - 56px) - ((100% - 84px) / 3));
    margin-left: 14px;
    margin-right: 14px; }
    @media screen and (max-width: 800px) {
      .cols12 > .col {
        width: 100%;
        margin-left: 0;
        margin-right: 0; } }
    @media screen and (max-width: 800px) {
      .cols12 > .col + .col {
        margin-top: 32px; } }
    .cols12 > .col:first-child {
      width: calc((100% - 84px) / 3); }
      @media screen and (max-width: 800px) {
        .cols12 > .col:first-child {
          width: 100%; } }

.cols11111 {
  margin-left: -16px;
  margin-right: -16px; }
  .cols11111 > .col {
    width: calc(20% - 32px);
    margin-left: 16px;
    margin-right: 16px; }

[data-fade-in-bottom] {
  transition: transform .5s .3s, opacity .5s .3s, visibility .5s .3s;
  transform: translateY(40px);
  opacity: 0;
  visibility: hidden; }
  [data-fade-in-bottom].view-in {
    opacity: 1;
    visibility: visible;
    transform: translateY(0); }

.modal-enter-active {
  animation: modal-in .3s; }

.modal-leave-active {
  animation: modal-in .3s reverse; }

@keyframes modal-in {
  0% {
    opacity: 0;
    transform: translateY(100px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

.fade-enter-active {
  animation: fade-in .5s; }

.fade-leave-active {
  animation: fade-in .5s reverse; }

.fade-fast-enter-active {
  animation: fade-in .2s linear; }

.fade-fast-leave-active {
  animation: fade-in .2s linear reverse; }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.scale-enter-active {
  animation: scale-out-in 0.3s; }

.scale-leave-active {
  animation: scale-out-in 0.3s reverse; }

@keyframes scale-out-in {
  0% {
    opacity: 0;
    transform: scale(0.4); }
  100% {
    opacity: 1;
    transform: scale(1); } }

.step-switch-enter-active {
  animation: slide-in-up 0.2s; }

.step-switch-leave-active {
  animation: slide-out-down 0.2s; }

@keyframes slide-in-up {
  0% {
    opacity: 0;
    transform: translateY(10%); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes slide-out-down {
  0% {
    opacity: 1;
    transform: translateY(0); }
  100% {
    opacity: 0;
    transform: translateY(10%); } }

@keyframes slide-in-down {
  0% {
    transform: translateY(-100%); }
  100% {
    transform: translateY(0); } }

.slide-down-enter-active {
  animation: slide-in-down 0.3s; }

.slide-down-leave-active {
  animation: slide-in-down 0.3s reverse; }

.list-complete-enter, .list-complete-leave-to {
  opacity: 0;
  transform: translateY(30px); }

.list-complete-leave-active {
  position: absolute; }

.desktop-hidden {
  display: none !important; }

@media screen and (max-width: 800px) {
  .cert-list {
    flex-wrap: wrap; } }

@media screen and (max-width: 800px) {
  .mobile-hidden {
    display: none !important; } }

@media screen and (max-width: 800px) {
  .mobile-visible {
    display: block !important; }
  .mobile-visible--as-flex {
    display: flex !important; }
  .mobile-visible--as-if {
    display: inline-flex !important; }
  .mobile-visible--as-ib {
    display: inline-block !important; }
  .mobile-visible--as-i {
    display: inline !important; }
  .page-content {
    margin-top: 0; }
  .table-cnt {
    width: 100vw;
    margin-left: -24px;
    overflow: auto;
    -webkit-overflow-scrolling: touch; }
    .table-cnt .table-cnt-inner {
      display: inline-block;
      width: auto;
      padding-left: 24px;
      padding-right: 24px; }
  .row {
    flex-direction: column; }
  .form__row .cols11 > .col + .col, .form__row .cols12 > .col + .col, .form__row .cols21 > .col + .col, .form__row .cols111 > .col + .col {
    margin-top: 16px; } }

.changes {
  color: #A0A0A0;
  font-size: 1.4rem;
  line-height: 1em;
  margin-top: 15px; }
  .theme-monochrome._black-accent .changes {
    color: #000 !important; }
  .theme-monochrome._white-accent .changes {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .changes {
    color: #472312 !important; }

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5 {
  font-weight: normal;
  transition: opacity 0.6s, transform 0.6s; }
  h1[data-inview], h2[data-inview], h3[data-inview], h4[data-inview], h5[data-inview], h6[data-inview], .h1[data-inview], .h2[data-inview], .h3[data-inview], .h4[data-inview], .h5[data-inview] {
    opacity: 0;
    transform: translateY(10px); }
    h1[data-inview].view-in, h2[data-inview].view-in, h3[data-inview].view-in, h4[data-inview].view-in, h5[data-inview].view-in, h6[data-inview].view-in, .h1[data-inview].view-in, .h2[data-inview].view-in, .h3[data-inview].view-in, .h4[data-inview].view-in, .h5[data-inview].view-in {
      opacity: 1;
      transform: translateY(0); }

h1:not(.custom), .h1 {
  font-size: 6rem;
  color: #58595b;
  letter-spacing: -0.75px;
  padding-bottom: 40px;
  border-bottom: 1px solid #F2F2F2;
  margin-bottom: 40px;
  font-size: 6rem;
  line-height: 0.93333em; }
  .theme-monochrome._black-accent h1:not(.custom), .theme-monochrome._black-accent .h1 {
    color: #000 !important; }
  .theme-monochrome._white-accent h1:not(.custom), .theme-monochrome._white-accent .h1 {
    color: #fff !important; }
  .theme-monochrome._sepia-accent h1:not(.custom), .theme-monochrome._sepia-accent .h1 {
    color: #472312 !important; }
  .theme-monochrome._black-ground h1:not(.custom), .theme-monochrome._black-ground .h1 {
    border-bottom-color: #000 !important; }
  .theme-monochrome._white-ground h1:not(.custom), .theme-monochrome._white-ground .h1 {
    border-bottom-color: #fff !important; }
  .theme-monochrome._sepia-ground h1:not(.custom), .theme-monochrome._sepia-ground .h1 {
    border-bottom-color: #F5F5DC !important; }
  @media screen and (max-width: 600px) {
    h1:not(.custom), .h1 {
      font-weight: 400;
      margin-bottom: 24px;
      padding-bottom: 0;
      border-bottom: none;
      font-size: 3.2rem;
      line-height: 1em; } }
  h1:not(.custom) + .subheader, .h1 + .subheader {
    font-family: 'FFMetaWebProMedium', sans-serif;
    font-weight: normal;
    font-style: normal;
    margin-top: -32px;
    margin-bottom: 40px;
    font-size: 1.7rem; }
  h1:not(.custom).noborder, .h1.noborder {
    padding-bottom: 0;
    border-bottom: 0; }
    @media screen and (max-width: 600px) {
      h1:not(.custom).noborder, .h1.noborder {
        color: #58595b;
        letter-spacing: -0.425px;
        font-size: 3.4rem;
        line-height: 1.38235em;
        margin-bottom: 24px; } }
  @media screen and (max-width: 600px) {
    h1:not(.custom).noborder--mobile, .h1.noborder--mobile {
      margin-bottom: 0; } }

h2:not(.custom), .h2 {
  color: #58595b;
  letter-spacing: -1px;
  margin-bottom: 32px;
  font-size: 4.8rem;
  line-height: 1em; }
  .theme-monochrome._black-accent h2:not(.custom), .theme-monochrome._black-accent .h2 {
    color: #000 !important; }
  .theme-monochrome._white-accent h2:not(.custom), .theme-monochrome._white-accent .h2 {
    color: #fff !important; }
  .theme-monochrome._sepia-accent h2:not(.custom), .theme-monochrome._sepia-accent .h2 {
    color: #472312 !important; }
  @media screen and (max-width: 600px) {
    h2:not(.custom), .h2 {
      font-size: 3rem;
      line-height: 1em; } }
  h2:not(.custom) + .subheader, .h2 + .subheader {
    font-family: 'FFMetaWebProMedium', sans-serif;
    font-weight: normal;
    font-style: normal;
    margin-top: -24px;
    margin-bottom: 32px;
    font-size: 1.7rem; }
  h2:not(.custom) ~ h4, .h2 ~ h4 {
    margin-top: -8px; }

h3:not(.custom), .h3 {
  letter-spacing: -0.4px;
  color: #58595b;
  margin-bottom: 24px;
  font-size: 3.2rem;
  line-height: 1em; }
  .theme-monochrome._black-accent h3:not(.custom), .theme-monochrome._black-accent .h3 {
    color: #000 !important; }
  .theme-monochrome._white-accent h3:not(.custom), .theme-monochrome._white-accent .h3 {
    color: #fff !important; }
  .theme-monochrome._sepia-accent h3:not(.custom), .theme-monochrome._sepia-accent .h3 {
    color: #472312 !important; }
  @media screen and (max-width: 600px) {
    h3:not(.custom), .h3 {
      font-size: 3.2rem;
      line-height: 1.25em; } }

h4:not(.custom), .h4 {
  letter-spacing: -0.3px;
  color: #58595b;
  margin-bottom: 24px;
  font-size: 2.4rem;
  line-height: 1em; }
  .theme-monochrome._black-accent h4:not(.custom), .theme-monochrome._black-accent .h4 {
    color: #000 !important; }
  .theme-monochrome._white-accent h4:not(.custom), .theme-monochrome._white-accent .h4 {
    color: #fff !important; }
  .theme-monochrome._sepia-accent h4:not(.custom), .theme-monochrome._sepia-accent .h4 {
    color: #472312 !important; }
  @media screen and (max-width: 600px) {
    h4:not(.custom), .h4 {
      margin-bottom: 16px;
      font-size: 2rem;
      line-height: 1.2em; } }
  h4:not(.custom) + p.text--smaller, .h4 + p.text--smaller {
    margin-top: -16px; }

h5:not(.custom), .h5 {
  text-transform: uppercase;
  color: #58595b;
  font-size: 1.6rem;
  line-height: 1.5em; }
  .theme-monochrome._black-accent h5:not(.custom), .theme-monochrome._black-accent .h5 {
    color: #000 !important; }
  .theme-monochrome._white-accent h5:not(.custom), .theme-monochrome._white-accent .h5 {
    color: #fff !important; }
  .theme-monochrome._sepia-accent h5:not(.custom), .theme-monochrome._sepia-accent .h5 {
    color: #472312 !important; }

.styled {
  color: #595959; }
  .theme-monochrome._black-accent .styled {
    color: #000 !important; }
  .theme-monochrome._white-accent .styled {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .styled {
    color: #472312 !important; }
  .styled table {
    width: calc(100% + 32px);
    margin-left: -16px; }
    @media screen and (max-width: 800px) {
      .styled table {
        display: block;
        overflow-x: scroll; } }
    .styled table th {
      text-align: left;
      font-weight: normal;
      padding: 16px;
      box-shadow: inset 0 -2px 0 0 #E8E8E8; }
    .styled table td {
      font-size: 16px;
      color: #5B5B5B;
      padding: 16px; }
      .theme-monochrome._black-accent .styled table td {
        color: #000 !important; }
      .theme-monochrome._white-accent .styled table td {
        color: #fff !important; }
      .theme-monochrome._sepia-accent .styled table td {
        color: #472312 !important; }
    .styled table tbody tr:nth-child(odd) td {
      background: rgba(242, 242, 242, 0.5); }
      .theme-monochrome._black-ground .styled table tbody tr:nth-child(odd) td {
        background: #000 !important; }
      .theme-monochrome._white-ground .styled table tbody tr:nth-child(odd) td {
        background: #fff !important; }
      .theme-monochrome._sepia-ground .styled table tbody tr:nth-child(odd) td {
        background: #F5F5DC !important; }
  .styled ul li {
    list-style: disc;
    margin-left: 20px;
    font-size: 1.6rem; }
    .styled ul li + li {
      margin-top: 8px; }
  .styled .floated-left {
    float: left;
    margin-right: 64px;
    margin-bottom: 32px; }
    @media screen and (max-width: 800px) {
      .styled .floated-left {
        display: block;
        float: none;
        margin-right: 0;
        margin-left: 0;
        margin-bottom: 24px;
        margin-top: 24px;
        width: 100%; } }
  .styled .floated-right {
    float: right;
    margin-left: 64px;
    margin-bottom: 32px; }
    @media screen and (max-width: 800px) {
      .styled .floated-right {
        display: block;
        float: none;
        margin-right: 0;
        margin-left: 0;
        margin-bottom: 24px;
        margin-top: 24px;
        width: 100%; } }
  .styled h5 + ul {
    margin-top: 24px; }

p + p {
  margin-top: 16px; }

p + ul {
  margin-top: 16px; }

strong {
  font-family: 'FFMetaWebProMedium', sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #58595b; }
  .theme-monochrome._black-accent strong {
    color: #000 !important; }
  .theme-monochrome._white-accent strong {
    color: #fff !important; }
  .theme-monochrome._sepia-accent strong {
    color: #472312 !important; }

blockquote {
  font-family: 'PFCentroSlabPro-Thin', serif;
  font-weight: normal;
  font-style: normal;
  color: #5B5B5B;
  margin-top: 30px;
  margin-bottom: 56px;
  font-size: 4rem;
  line-height: 1em; }
  .theme-monochrome._black-accent blockquote {
    color: #000 !important; }
  .theme-monochrome._white-accent blockquote {
    color: #fff !important; }
  .theme-monochrome._sepia-accent blockquote {
    color: #472312 !important; }
  blockquote:first-child {
    margin-top: 0; }

cite {
  font-family: 'FFMetaWebProNormal', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-style: normal;
  text-transform: uppercase;
  display: block;
  margin-top: 24px;
  font-size: 1.6rem;
  line-height: 1.5em; }

.bq-extra {
  text-transform: none;
  color: #A0A0A0;
  letter-spacing: -0.2px; }
  .theme-monochrome._black-accent .bq-extra {
    color: #000 !important; }
  .theme-monochrome._white-accent .bq-extra {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .bq-extra {
    color: #472312 !important; }

.text--larger {
  font-size: 1.7rem; }

.text--smaller {
  font-size: 1.4rem;
  line-height: 1.14286em; }

.text--smallest {
  font-size: 1.2rem;
  line-height: 1.33333em; }

.text--darken {
  color: #58595b; }
  .theme-monochrome._black-accent .text--darken {
    color: #000 !important; }
  .theme-monochrome._white-accent .text--darken {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .text--darken {
    color: #472312 !important; }

.text--grey {
  color: #5B5B5B; }
  .theme-monochrome._black-accent .text--grey {
    color: #000 !important; }
  .theme-monochrome._white-accent .text--grey {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .text--grey {
    color: #472312 !important; }

.text--lighten {
  color: #7b7b7b; }
  .theme-monochrome._black-accent .text--lighten {
    color: #000 !important; }
  .theme-monochrome._white-accent .text--lighten {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .text--lighten {
    color: #472312 !important; }

.text--lightest {
  color: #A0A0A0; }
  .theme-monochrome._black-accent .text--lightest {
    color: #000 !important; }
  .theme-monochrome._white-accent .text--lightest {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .text--lightest {
    color: #472312 !important; }

.text--success {
  color: #8dc63f; }
  .theme-monochrome._black-accent .text--success {
    color: #000 !important; }
  .theme-monochrome._white-accent .text--success {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .text--success {
    color: #472312 !important; }

.text--error {
  color: #F32121; }
  .theme-monochrome._black-accent .text--error {
    color: #000 !important; }
  .theme-monochrome._white-accent .text--error {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .text--error {
    color: #472312 !important; }

.text--no-mb {
  margin-bottom: 0; }

a {
  transition: color 0.3s; }
  .theme-monochrome._black-accent a {
    text-decoration: underline;
    color: #000 !important; }
  .theme-monochrome._white-accent a {
    text-decoration: underline;
    color: #fff !important; }
  .theme-monochrome._sepia-accent a {
    text-decoration: underline;
    color: #472312 !important; }
  a:hover {
    color: #f26123; }
    .theme-monochrome._black-accent a:hover {
      text-decoration-style: wavy;
      color: #000 !important; }
    .theme-monochrome._white-accent a:hover {
      text-decoration-style: wavy;
      color: #fff !important; }
    .theme-monochrome._sepia-accent a:hover {
      text-decoration-style: wavy;
      color: #472312 !important; }
  a[href^="tel:"] {
    white-space: nowrap; }

.link {
  color: #f26123;
  transition: color 0.3s;
  cursor: pointer;
  padding: 0;
  background: transparent;
  border: 0; }
  .link_new {
    color: #E58324; }
    .link_new:focus {
      color: #3C3C3B;
      outline: none; }
    .link_new::-moz-focus-inner {
      border: 0; }
  .link svg {
    stroke: #f26123;
    margin-left: 0.6rem;
    transition: stroke 0.3s, transform 0.2s; }
  .theme-monochrome._black-accent .link {
    color: #000 !important; }
  .theme-monochrome._white-accent .link {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .link {
    color: #472312 !important; }
  .link:hover {
    color: #58595b; }
    .theme-monochrome._black-accent .link:hover {
      color: #000 !important; }
    .theme-monochrome._white-accent .link:hover {
      color: #fff !important; }
    .theme-monochrome._sepia-accent .link:hover {
      color: #472312 !important; }
    .link:hover svg {
      stroke: #58595b;
      transform: translateX(0.6rem); }

.annotation {
  font-family: 'FFMetaWebProNormal', sans-serif;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.4px;
  margin-bottom: 24px;
  font-size: 2.4rem;
  line-height: 1.33333em; }
  @media screen and (max-width: 800px) {
    .annotation {
      font-size: 2rem;
      line-height: 1.1em; } }
  .annotation._width-small {
    max-width: 656px; }
    .annotation._width-small--bank {
      max-width: 100%; }
      @media screen and (max-width: 800px) {
        .annotation._width-small--bank {
          border-top: 1px solid #f1f1f1;
          padding-top: 24px;
          letter-spacing: -0.3px;
          font-size: 2.4rem;
          line-height: 1.33333em; } }

.site-header {
  background: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 40px;
  padding-right: 40px;
  align-items: center;
  min-height: 80px;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.05);
  z-index: 100;
  transition: box-shadow 0.3s, background-color 0.3s;
  flex-wrap: wrap;
  position: relative;
  position: sticky;
  top: 0; }
  .theme-monochrome._black-ground .site-header {
    background-color: #000 !important; }
  .theme-monochrome._white-ground .site-header {
    background-color: #fff !important; }
  .theme-monochrome._sepia-ground .site-header {
    background-color: #F5F5DC !important; }
  @media screen and (max-width: 600px) {
    .site-header {
      min-height: 64px; } }
  @media screen and (max-width: 500px) {
    .site-header {
      padding: 0 16px; } }
  @media screen and (max-width: 800px) {
    .site-header {
      background: #fff;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); } }
  .index .site-header {
    background: transparent;
    box-shadow: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0; }
    @media screen and (max-width: 800px) {
      .index .site-header {
        background: #fff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); } }
    .index .site-header.menu-visible, .index .site-header.menu-phone-visible {
      background: #ffffff;
      box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.05); }
      .theme-monochrome._black-ground .index .site-header.menu-visible, .theme-monochrome._black-ground .index .site-header.menu-phone-visible {
        background-color: #000 !important; }
      .theme-monochrome._white-ground .index .site-header.menu-visible, .theme-monochrome._white-ground .index .site-header.menu-phone-visible {
        background-color: #fff !important; }
      .theme-monochrome._sepia-ground .index .site-header.menu-visible, .theme-monochrome._sepia-ground .index .site-header.menu-phone-visible {
        background-color: #F5F5DC !important; }
      .theme-monochrome._black-accent .index .site-header.menu-visible .site-header__item, .theme-monochrome._black-accent .index .site-header.menu-phone-visible .site-header__item {
        color: #000 !important; }
      .theme-monochrome._white-accent .index .site-header.menu-visible .site-header__item, .theme-monochrome._white-accent .index .site-header.menu-phone-visible .site-header__item {
        color: #fff !important; }
      .theme-monochrome._sepia-accent .index .site-header.menu-visible .site-header__item, .theme-monochrome._sepia-accent .index .site-header.menu-phone-visible .site-header__item {
        color: #472312 !important; }

.site-header__left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 80px;
  position: relative; }
  @media screen and (max-width: 600px) {
    .site-header__left {
      height: 64px; } }

.site-header__right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 80px; }
  @media screen and (max-width: 800px) {
    .site-header__right {
      width: 50px; } }
  @media screen and (max-width: 600px) {
    .site-header__right {
      height: 64px; } }

.site-header__item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.2rem;
  line-height: 2em;
  text-transform: uppercase;
  font-variant-numeric: lining-nums;
  margin-left: 40px;
  transition: color 0.3s;
  position: relative;
  cursor: pointer; }
  .site-header__item .site-header__item {
    margin-left: 0; }
  @media screen and (max-width: 800px) {
    .site-header__item {
      font-size: 0;
      width: 14px; } }
  @media screen and (max-width: 600px) {
    .site-header__item {
      margin-left: 36px; } }

.site-header__item--with-menu {
  position: relative; }

.site-header__icon {
  margin-right: 16px;
  stroke: #f26123; }
  .theme-monochrome._black-accent .site-header__icon {
    stroke: #000 !important; }
  .theme-monochrome._white-accent .site-header__icon {
    stroke: #fff !important; }
  .theme-monochrome._sepia-accent .site-header__icon {
    stroke: #472312 !important; }
  .theme-monochrome._black-accent .site-header__icon path {
    stroke: #000 !important; }
  .theme-monochrome._white-accent .site-header__icon path {
    stroke: #fff !important; }
  .theme-monochrome._sepia-accent .site-header__icon path {
    stroke: #472312 !important; }
  @media screen and (max-width: 800px) {
    .site-header__icon {
      margin: 0; } }
  .site-header__icon svg {
    display: block; }

@media screen and (min-width: 800px) {
  .site-header__auth-btn {
    padding: 3px 10px;
    border-radius: 15px;
    border: 1px solid #d0d0d0;
    text-transform: none;
    font-size: 1.3rem; } }

.site-header__logo {
  margin-left: 40px;
  display: inline-flex;
  align-items: center; }
  .site-header__logo img {
    max-width: 100%; }
  .site-header__logo--mobile {
    display: none; }
    .site-header__logo--mobile:hover {
      opacity: 0.7; }
  .site-header__logo .use-letter {
    fill: #595959;
    transition: fill 0.3s;
    /*
    .index & {
      fill: $cWhite;
    }
    */ }
    .theme-monochrome._black-accent .site-header__logo .use-letter {
      fill: #000 !important; }
    .theme-monochrome._white-accent .site-header__logo .use-letter {
      fill: #fff !important; }
    .theme-monochrome._sepia-accent .site-header__logo .use-letter {
      fill: #472312 !important; }
    .menu-visible .site-header__logo .use-letter {
      fill: #595959; }
      .theme-monochrome._black-accent .menu-visible .site-header__logo .use-letter {
        fill: #000 !important; }
      .theme-monochrome._white-accent .menu-visible .site-header__logo .use-letter {
        fill: #fff !important; }
      .theme-monochrome._sepia-accent .menu-visible .site-header__logo .use-letter {
        fill: #472312 !important; }

.site-header__burger:after, .site-header__burger:before, .site-header__burger i {
  content: '';
  width: 100%;
  height: 0.2rem;
  border-radius: 0.1rem;
  background: #f26123;
  position: absolute;
  left: 0;
  transition: opacity 0.3s, visibility 0.3s, transform 0.3s, top 0.3s, bottom 0.3s, background-color 0.3s;
  /*
  .index & {
    background: $cWhite;
  }
  */ }
  .theme-monochrome._black-accent .site-header__burger:after, .theme-monochrome._black-accent .site-header__burger:before, .theme-monochrome._black-accent .site-header__burger i, .site-header__burger .theme-monochrome._black-accent i {
    background-color: #000 !important; }
  .theme-monochrome._white-accent .site-header__burger:after, .theme-monochrome._white-accent .site-header__burger:before, .theme-monochrome._white-accent .site-header__burger i, .site-header__burger .theme-monochrome._white-accent i {
    background-color: #fff !important; }
  .theme-monochrome._sepia-accent .site-header__burger:after, .theme-monochrome._sepia-accent .site-header__burger:before, .theme-monochrome._sepia-accent .site-header__burger i, .site-header__burger .theme-monochrome._sepia-accent i {
    background-color: #472312 !important; }
  .menu-visible .site-header__burger:after, .menu-visible .site-header__burger:before, .menu-visible .site-header__burger i, .site-header__burger .menu-visible i {
    background: #f26123; }
    .theme-monochrome._black-accent .menu-visible .site-header__burger:after, .theme-monochrome._black-accent .menu-visible .site-header__burger:before, .theme-monochrome._black-accent .menu-visible .site-header__burger i, .site-header__burger .theme-monochrome._black-accent .menu-visible i {
      background-color: #000 !important; }
    .theme-monochrome._white-accent .menu-visible .site-header__burger:after, .theme-monochrome._white-accent .menu-visible .site-header__burger:before, .theme-monochrome._white-accent .menu-visible .site-header__burger i, .site-header__burger .theme-monochrome._white-accent .menu-visible i {
      background-color: #fff !important; }
    .theme-monochrome._sepia-accent .menu-visible .site-header__burger:after, .theme-monochrome._sepia-accent .menu-visible .site-header__burger:before, .theme-monochrome._sepia-accent .menu-visible .site-header__burger i, .site-header__burger .theme-monochrome._sepia-accent .menu-visible i {
      background-color: #472312 !important; }

.site-header__menu {
  min-width: 7rem;
  box-sizing: border-box;
  padding: 7px 0 9px 0;
  font-family: 'FFMetaWebProNormal', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 1.2rem;
  line-height: 2em;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative; }
  @media screen and (max-width: 500px) {
    .site-header__menu {
      font-size: 0;
      min-width: 16px; } }
  .site-header__menu .site-header__burger {
    margin-right: .5em; }
  .menu-visible .site-header__menu {
    color: #58595b; }
    .theme-monochrome._black-accent .menu-visible .site-header__menu {
      color: #000 !important; }
    .theme-monochrome._white-accent .menu-visible .site-header__menu {
      color: #fff !important; }
    .theme-monochrome._sepia-accent .menu-visible .site-header__menu {
      color: #472312 !important; }

.site-header__burger {
  display: inline-block;
  width: 1.6rem;
  height: 1.2rem;
  position: relative;
  margin-top: -0.2rem; }
  @media screen and (max-width: 500px) {
    .site-header__burger {
      margin: 0;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0); } }
  .site-header__burger:after {
    bottom: 0; }
  .site-header__burger:before {
    top: 0; }
  .site-header__burger i {
    top: 50%;
    transform: translateY(-50%); }
  .menu-visible .site-header__burger i {
    opacity: 0;
    visibility: hidden; }
  .menu-visible .site-header__burger:after {
    transform: rotate(45deg);
    bottom: 0.5rem; }
  .menu-visible .site-header__burger:before {
    transform: rotate(-45deg);
    top: 0.5rem; }

.header__nav-wrap {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  pointer-events: none; }
  @media screen and (max-width: 900px) {
    .header__nav-wrap {
      display: none; } }
  .menu-visible .header__nav-wrap {
    pointer-events: auto; }

.header__nav {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  transition: opacity 0.5s, transform 0.5s, visibility 0.5s;
  opacity: 0;
  visibility: hidden;
  transform: translateY(3rem);
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
  max-height: calc(100vh - 200%);
  overflow-y: auto; }
  .theme-monochrome._black-ground .header__nav {
    background: #000 !important; }
  .theme-monochrome._white-ground .header__nav {
    background: #fff !important; }
  .theme-monochrome._sepia-ground .header__nav {
    background: #F5F5DC !important; }
  .menu-visible .header__nav {
    opacity: 1;
    visibility: visible;
    transform: translateY(0); }
  .header__nav .action-button {
    background: #F7F7F7; }
    .theme-monochrome._black-ground .header__nav .action-button {
      background-color: #000 !important; }
    .theme-monochrome._white-ground .header__nav .action-button {
      background-color: #fff !important; }
    .theme-monochrome._sepia-ground .header__nav .action-button {
      background-color: #F5F5DC !important; }
    .theme-monochrome._black-accent .header__nav .action-button {
      color: #000 !important; }
    .theme-monochrome._white-accent .header__nav .action-button {
      color: #fff !important; }
    .theme-monochrome._sepia-accent .header__nav .action-button {
      color: #472312 !important; }
    .header__nav .action-button:hover {
      background: #f0f0f0; }
      .theme-monochrome._black-ground .header__nav .action-button:hover {
        background-color: #000 !important; }
      .theme-monochrome._white-ground .header__nav .action-button:hover {
        background-color: #fff !important; }
      .theme-monochrome._sepia-ground .header__nav .action-button:hover {
        background-color: #F5F5DC !important; }
      .theme-monochrome._black-accent .header__nav .action-button:hover {
        color: #000 !important; }
      .theme-monochrome._white-accent .header__nav .action-button:hover {
        color: #fff !important; }
      .theme-monochrome._sepia-accent .header__nav .action-button:hover {
        color: #472312 !important; }
  .header__nav:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.05); }

.header-nav__inner {
  margin-left: 30px;
  margin-right: 30px;
  display: flex;
  text-transform: none;
  padding-top: 32px;
  padding-bottom: 64px; }

.header-nav__subheader {
  font-family: 'FFMetaWebProNormal', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 2.4rem;
  line-height: 2.4rem;
  color: #262626;
  letter-spacing: -0.3px; }
  .theme-monochrome._black-accent .header-nav__subheader {
    color: #000 !important; }
  .theme-monochrome._white-accent .header-nav__subheader {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .header-nav__subheader {
    color: #472312 !important; }

.header-nav__item {
  font-family: 'FFMetaWebProNormal', sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #595959; }
  .theme-monochrome._black-accent .header-nav__item {
    color: #000 !important; }
  .theme-monochrome._white-accent .header-nav__item {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .header-nav__item {
    color: #472312 !important; }
  .header-nav__item + .header-nav__item {
    margin-top: 16px; }

.header-nav__link {
  transition: color 0.3s; }
  .header-nav__link:hover {
    color: #f26123; }
    .theme-monochrome._black-accent .header-nav__link:hover {
      color: #000 !important; }
    .theme-monochrome._white-accent .header-nav__link:hover {
      color: #fff !important; }
    .theme-monochrome._sepia-accent .header-nav__link:hover {
      color: #472312 !important; }

.header-nav__column {
  box-sizing: border-box;
  padding-left: 60px;
  padding-right: 60px;
  flex-grow: 1; }
  .header-nav__column:first-child {
    width: 31%;
    border-left: 1px solid rgba(160, 160, 160, 0.24); }
    .theme-monochrome._black-accent .header-nav__column:first-child {
      border-left-color: #000 !important; }
    .theme-monochrome._white-accent .header-nav__column:first-child {
      border-left-color: #fff !important; }
    .theme-monochrome._sepia-accent .header-nav__column:first-child {
      border-left-color: #472312 !important; }
    .header-nav__column:first-child .header-nav__subnav {
      margin-top: 16px; }
    @media screen and (min-width: 901px) {
      .header-nav__column:first-child {
        order: 2; }
        .header-nav__column:first-child .header-nav__subheader {
          display: none; } }
  .header-nav__column:last-child {
    padding-right: 0;
    padding-left: 52px;
    width: 31%;
    border-left: 1px solid rgba(160, 160, 160, 0.24); }
    .theme-monochrome._black-accent .header-nav__column:last-child {
      border-left-color: #000 !important; }
    .theme-monochrome._white-accent .header-nav__column:last-child {
      border-left-color: #fff !important; }
    .theme-monochrome._sepia-accent .header-nav__column:last-child {
      border-left-color: #472312 !important; }
    @media screen and (min-width: 901px) {
      .header-nav__column:last-child {
        order: 3; } }

.header-nav__column--middle {
  width: 44%;
  display: flex;
  padding-left: 0;
  flex-direction: column;
  justify-content: space-between; }
  @media screen and (min-width: 901px) {
    .header-nav__column--middle {
      order: 1; } }

.header-nav__subnav {
  margin-top: 32px; }

.header-nav__row {
  display: flex;
  align-items: flex-start; }

.header-nav__subcol {
  width: 50%; }

.header-nav__section + .header-nav__section {
  margin-top: 1rem; }

.header-nav__actions {
  display: flex;
  flex-direction: column; }

.header__mobile-menu.mobile-menu {
  display: none; }
  @media screen and (max-width: 900px) {
    .header__mobile-menu.mobile-menu {
      display: block; } }

.personal-popover {
  position: absolute;
  right: 0;
  top: 100%;
  box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.1);
  padding: 2.4rem;
  background: #ffffff;
  min-width: 150px;
  text-transform: none;
  transition: opacity .3s, visibility .3s, transform .3s;
  opacity: 0;
  visibility: hidden;
  transform: scale(0);
  z-index: 1; }
  .theme-monochrome._black-ground .personal-popover {
    background-color: #000 !important; }
  .theme-monochrome._white-ground .personal-popover {
    background-color: #fff !important; }
  .theme-monochrome._sepia-ground .personal-popover {
    background-color: #F5F5DC !important; }
  .personal-popover--block {
    opacity: 1;
    visibility: visible;
    transform: scale(1); }

.ppm__item + .ppm__item {
  margin-top: 8px; }

.ppm__link {
  display: block;
  padding-top: 8px;
  padding-bottom: 8px;
  color: #58595b;
  transition: color .3s;
  font-size: 1.6rem; }
  .theme-monochrome._black-accent .ppm__link {
    color: #000 !important; }
  .theme-monochrome._white-accent .ppm__link {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .ppm__link {
    color: #472312 !important; }
  .ppm__link:hover {
    color: #f26123; }
    .theme-monochrome._black-accent .ppm__link:hover {
      color: #000 !important; }
    .theme-monochrome._white-accent .ppm__link:hover {
      color: #fff !important; }
    .theme-monochrome._sepia-accent .ppm__link:hover {
      color: #472312 !important; }

.site-header__phone {
  display: none; }

/*
@media screen and (max-width: $breakpoint) {
  .site-header {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    box-sizing: border-box;
    overflow: visible;
    width: 100%;
  }
  .site-header__right,
  .header__nav {
    display: none;
  }
  .site-header__left {
    width: 100%;
    flex-direction: row-reverse;
    justify-content: flex-end;
    position: relative;
  }
  .site-header__logo {
    margin-left: 0;
    margin-right: auto;

    img {
      width: 120px;
      height: auto;
    }
  }
  .site-header__menu {
    position: static;
    margin-left: 3.2rem;
    margin-right: 0;
    padding-right: 0;
    transform: none;

    span {
      display: none;
    }

    .site-header__burger {
      display: block;
      width: 2.4rem;
      height: 2rem;
    }
  }
  .header__mobile-menu,
  .header__mobile-menu-phone {
    display: block;
    position: absolute;
    top: 80px;
    right: 0;
    opacity: 0;
    visibility: hidden;
    width: 100%;
    transform: translateX(-100%);
    transition: opacity 0.3s, visibility 0.3s, transform 0.3s;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    background-color: #fff;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
    @include theme-monochrome-ground(background-color);
  }
  .mobile-menu__top {
    display: flex;
    align-items: stretch;
    justify-content: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }
  .mobile-menu__top-item {
    width: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5.6rem;
    color: #7b7b7b;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 2.4rem;
    @include theme-monochrome-accent(color);

    & + & {
      border-left: 1px solid rgba(0, 0, 0, 0.05);
    }
  }
  .mobile-menu__top-icon {
    stroke: $cBrand;
    @include theme-monochrome-accent(stroke);

    svg {
      display: block;
    }
  }
  .mobile-menu__top-item--city {
    width: calc(100% - 16rem);

    .mobile-menu__top-icon {
      margin-right: .8rem;
    }
  }
  %menubar {
    height: 0.3rem;
  }
  .menu-visible {
    .site-header__burger {
      &:before {
        top: 9px;
      }

      &:after {
        bottom: 8px;
      }
    }

    .header__mobile-menu {
      right: 0;
      opacity: 1;
      visibility: visible;
      transform: translateX(0);
    }
  }
  .menu-phone-visible .header__mobile-menu-phone {
    right: 0;
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }
  .mobile-menu__manager {
    padding: 2.4rem 2.4rem 2.8rem;
    background-color: #f7f7f7;
    @include theme-monochrome-ground(background-color);

    .manager-name,
    .manager-title {
      text-transform: none;
    }

    .manager-title {
      position: relative;
    }
  }
  .header__extra {
    position: absolute;
    right: 1.5rem;
    top: 0;

    a.trigger {
      width: 2.4rem;
      height: 2.4rem;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        display: block;
      }

      use {
        fill: $cBrand;
        transition: fill 0.3s;
        @include theme-monochrome-accent(fill);
      }

      &:hover {
        use {
          fill: $cBlack;
          @include theme-monochrome-accent(fill);
        }
      }
    }

    &:hover {
      .actions {
        transform: translateX(1.5rem) scale(1);
        opacity: 1;
      }
    }

    .actions {
      position: absolute;
      white-space: nowrap;
      padding: 2.4rem;
      background: #fff;
      border-radius: 0.4rem;
      box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.1);
      transition: opacity 0.3s, transform 0.3s;
      right: 0;
      transform: translateX(1.5rem) scale(0.4);
      opacity: 0;
    }

    a.action {
      display: block;
      transition: color 0.3s;

      &:hover {
        color: $cBrand;
        @include theme-monochrome-accent(color);
      }

      + a.action {
        margin-top: 2.4rem;
      }
    }
  }
  .mobile-menu__actions {
    width: 100%;
    box-sizing: border-box;
    padding-top: 3.6rem;
    padding-bottom: 2.4rem;
  }
  .header-nav__section {
    padding-top: 2.4rem;
    padding-bottom: 3.6rem;
    border-bottom: 1px solid #f2f2f2;
    @include theme-monochrome-ground(border-bottom);

    & + & {
      margin-top: 0;
    }
  }
  .mobile-menu__user-menu {
    transition: max-height .6s;
    overflow: hidden;
    max-height: 0;
  }

  .mobile-menu__user-menu--opened {
    max-height: 50vh;
  }

  .mobile-menu__user-menu-inner {
    padding: 1.6rem 2.4rem;
    border-bottom: 1px solid rgba(#a0a0a0, .1);
  }

  .mobile-menu__user-menu-list {
    list-style-type: none;
  }

  .mobile-menu__user-menu-item {

  }

  .mobile-menu__user-menu-link {
    display: block;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-transform: none;
  }

  .mobile-menu__inner {
    padding-left: 2.4rem;
    padding-right: 2.4rem;

    .header-nav__subnav {
      margin-top: 0;
    }

    .action-button {
      width: 100%;
      max-width: 360px;
      justify-content: center;
      padding: 0;
      @include mix-font-size(16px, 24px);
      font-weight: 400;
      letter-spacing: -0.05rem;
      text-transform: none;
      background-color: #f2f2f2;
      margin-left: auto;
      margin-right: auto;
      @include theme-monochrome-ground(background-color);
    }
  }
  .mobile-menu__corp {
    display: block;
    width: fit-content;
    margin: 3.2rem auto 4rem;
    color: #7b7b7b;
    font-size: 1.7rem;
    font-weight: 400;
    line-height: 2.4rem;
    text-align: center;
    @include theme-monochrome-accent(color);
  }
  .header-nav__subheader {
    text-transform: none;
  }
  .site-header__phone {
    display: flex;
    transition: filter .3s;

    .site-header__icon {
      margin-right: 0;
    }

    &.is-gray {
      filter: grayscale(100%);
    }
  }
  .mobile-menu-phone__top {
    display: flex;
    border-bottom: 1px solid #f2f2f2;
    @include theme-monochrome-ground(border-bottom-color);
  }
  .mobile-menu-phone__link {
    display: block;
    box-sizing: border-box;
    width: 50%;
    padding: 1.6rem 2.4rem;
    text-align: left;
    color: #7b7b7b;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.6rem;
    text-transform: none;
    @include theme-monochrome-accent(color);

    span {
      display: block;
      color: #262626;
      font-size: 1.6rem;
      line-height: 2.4rem;
      letter-spacing: -0.05rem;
      margin-top: .8rem;
      @include theme-monochrome-accent(color);
    }

    & + & {
      border-left: 1px solid #f2f2f2;
      @include theme-monochrome-ground(border-left-color);
    }
  }
  .mobile-menu-phone__bottom {
    background-color: #f7f7f7;
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
    @include theme-monochrome-ground(background-color);

    .btn {
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
      text-transform: none;
    }
  }
}
*/
.city_qtip {
  z-index: 999;
  position: absolute;
  left: 33px;
  top: calc(100% + 10px);
  font-size: 14px;
  font-size: 1.4rem;
  transform: translateX(-50%); }
  .city_qtip-content {
    position: relative;
    padding: 16px 24px 24px;
    text-align: center;
    background-color: #fff;
    border: 1px solid #f3f3f3;
    border-radius: 3px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1); }
    .theme-monochrome._black-ground .city_qtip-content {
      background-color: #000 !important; }
    .theme-monochrome._white-ground .city_qtip-content {
      background-color: #fff !important; }
    .theme-monochrome._sepia-ground .city_qtip-content {
      background-color: #F5F5DC !important; }
    .theme-monochrome._black-ground .city_qtip-content {
      border-color: #000 !important; }
    .theme-monochrome._white-ground .city_qtip-content {
      border-color: #fff !important; }
    .theme-monochrome._sepia-ground .city_qtip-content {
      border-color: #F5F5DC !important; }
    .city_qtip-content:after {
      content: '';
      position: absolute;
      display: block;
      top: -20px;
      left: 50%;
      margin-left: -7px;
      border: 11px solid transparent;
      border-bottom: 10px solid white; }
      .theme-monochrome._black-ground .city_qtip-content:after {
        border-bottom-color: #000 !important; }
      .theme-monochrome._white-ground .city_qtip-content:after {
        border-bottom-color: #fff !important; }
      .theme-monochrome._sepia-ground .city_qtip-content:after {
        border-bottom-color: #F5F5DC !important; }
  .city_qtip-content_text {
    color: #595959;
    line-height: 2;
    font-family: open-sans, sans-serif;
    text-transform: initial; }
    .theme-monochrome._black-accent .city_qtip-content_text {
      color: #000 !important; }
    .theme-monochrome._white-accent .city_qtip-content_text {
      color: #fff !important; }
    .theme-monochrome._sepia-accent .city_qtip-content_text {
      color: #472312 !important; }
  .city_qtip-actions {
    margin-top: 15px;
    white-space: nowrap; }

.chenge_city, .city_ok {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 15px;
  padding-right: 15px;
  margin-right: 5px; }

.button {
  display: inline-block;
  line-height: 1;
  border: 1px solid #f36c21;
  background: none;
  color: #252525;
  transition: color .2s, background-color .2s;
  cursor: pointer; }
  .theme-monochrome._black-accent .button {
    border-color: #000 !important; }
  .theme-monochrome._white-accent .button {
    border-color: #fff !important; }
  .theme-monochrome._sepia-accent .button {
    border-color: #472312 !important; }
  .theme-monochrome._black-accent .button {
    color: #000 !important; }
  .theme-monochrome._white-accent .button {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .button {
    color: #472312 !important; }

.button-filled {
  display: inline-block;
  line-height: 1;
  border: 1px solid #f36c21;
  background-color: #f36c21;
  transition: color .2s, background-color .2s;
  background-image: linear-gradient(90deg, #f36c21 0, #f36c21);
  color: #fff;
  cursor: pointer; }
  .theme-monochrome._black-accent .button-filled {
    border-color: #000 !important; }
  .theme-monochrome._white-accent .button-filled {
    border-color: #fff !important; }
  .theme-monochrome._sepia-accent .button-filled {
    border-color: #472312 !important; }
  .theme-monochrome._black-accent .button-filled {
    background: #000 !important; }
  .theme-monochrome._white-accent .button-filled {
    background: #fff !important; }
  .theme-monochrome._sepia-accent .button-filled {
    background: #472312 !important; }
  .theme-monochrome._black-ground .button-filled {
    color: #000 !important; }
  .theme-monochrome._white-ground .button-filled {
    color: #fff !important; }
  .theme-monochrome._sepia-ground .button-filled {
    color: #F5F5DC !important; }
  .button-filled:hover {
    background: white; }
    .theme-monochrome._black-accent .button-filled:hover {
      background: #000 !important; }
    .theme-monochrome._white-accent .button-filled:hover {
      background: #fff !important; }
    .theme-monochrome._sepia-accent .button-filled:hover {
      background: #472312 !important; }

.content__aside,
.aside {
  box-sizing: border-box;
  width: 416px;
  border: 8px solid #ffffff;
  flex: 0 0 auto;
  background-color: #F7F7F7;
  padding: 104px 64px 80px; }
  .theme-monochrome._black-ground .content__aside, .theme-monochrome._black-ground
  .aside {
    background-color: #000 !important; }
  .theme-monochrome._white-ground .content__aside, .theme-monochrome._white-ground
  .aside {
    background-color: #fff !important; }
  .theme-monochrome._sepia-ground .content__aside, .theme-monochrome._sepia-ground
  .aside {
    background-color: #F5F5DC !important; }
  .theme-monochrome._black-accent .content__aside, .theme-monochrome._black-accent
  .aside {
    border-color: #000 !important; }
  .theme-monochrome._white-accent .content__aside, .theme-monochrome._white-accent
  .aside {
    border-color: #fff !important; }
  .theme-monochrome._sepia-accent .content__aside, .theme-monochrome._sepia-accent
  .aside {
    border-color: #472312 !important; }
  @media screen and (max-width: 800px) {
    .content__aside,
    .aside {
      padding: 16px;
      width: 100%;
      border: 16px solid #ffffff; } }
  .content__aside[data-inview],
  .aside[data-inview] {
    transition: opacity 0.5s, transform 0.5s;
    opacity: 0; }
    .content__aside[data-inview].view-in,
    .aside[data-inview].view-in {
      opacity: 1; }
  .content__aside .dummy,
  .aside .dummy {
    padding-top: 141px; }
  @media screen and (max-width: 800px) {
    .content__aside--empty,
    .aside--empty {
      display: none; } }
  .content__aside_lk,
  .aside_lk {
    width: 384px;
    border: none;
    padding: 0;
    margin: 116px 32px 0; }
    @media screen and (max-width: 800px) {
      .content__aside_lk,
      .aside_lk {
        margin: 0;
        margin-top: 24px;
        border: none; } }
  .content__aside_policy-detail,
  .aside_policy-detail {
    width: 384px;
    border: none;
    border-radius: 8px;
    padding: 105px 0 0 32px; }
    @media screen and (max-width: 800px) {
      .content__aside_policy-detail,
      .aside_policy-detail {
        border: none;
        display: flex;
        flex-direction: column; } }
  .content__aside__extra,
  .aside__extra {
    margin-top: 17px;
    font-size: 1.7rem;
    line-height: 1.41176em; }
  .content__aside__extra-links,
  .aside__extra-links {
    display: flex;
    flex-direction: column;
    font-size: 1.7rem;
    line-height: 1.41176em; }
  .content__aside__title,
  .aside__title {
    padding: 24px 40px;
    border-bottom: 3px solid #F7F7F7;
    text-transform: uppercase; }
    .theme-monochrome._black-accent .content__aside__title, .theme-monochrome._black-accent
    .aside__title {
      border-color: #000 !important; }
    .theme-monochrome._white-accent .content__aside__title, .theme-monochrome._white-accent
    .aside__title {
      border-color: #fff !important; }
    .theme-monochrome._sepia-accent .content__aside__title, .theme-monochrome._sepia-accent
    .aside__title {
      border-color: #472312 !important; }
    @media screen and (max-width: 800px) {
      .content__aside__title,
      .aside__title {
        padding: 20px 16px; } }
  .content__aside__wrapper,
  .aside__wrapper {
    padding: 24px 40px; }
    @media screen and (max-width: 800px) {
      .content__aside__wrapper,
      .aside__wrapper {
        padding: 20px 16px; } }
  .content__aside__point,
  .aside__point {
    font-family: 'FFMetaWebProMedium', sans-serif;
    font-weight: normal;
    font-style: normal; }
    .content__aside__point:not(:first-child),
    .aside__point:not(:first-child) {
      padding-top: 24px; }
  .content__aside__list,
  .aside__list {
    display: flex;
    flex-direction: column;
    margin-top: 16px; }
  .content__aside__btns,
  .aside__btns {
    width: 100%;
    display: flex;
    flex-direction: column; }
    .content__aside__btns .btn-new:not(:first-child),
    .aside__btns .btn-new:not(:first-child) {
      margin-top: 8px; }

.theme-monochrome._black-ground .aside__section {
  background-color: #000 !important; }

.theme-monochrome._white-ground .aside__section {
  background-color: #fff !important; }

.theme-monochrome._sepia-ground .aside__section {
  background-color: #F5F5DC !important; }

.aside__section + .aside__section {
  margin-top: 40px; }

.aside__section_policy-detail {
  background-color: white;
  border-radius: 8px; }
  @media screen and (max-width: 800px) {
    .aside__section_policy-detail {
      margin-top: 24px;
      order: 2; } }

.aside__profile {
  background-color: white;
  border-radius: 8px; }

.aside-section__header {
  padding-bottom: 16px;
  text-transform: uppercase;
  border-bottom: 1px solid rgba(160, 160, 160, 0.24); }
  .theme-monochrome._black-accent .aside-section__header {
    border-color: #000 !important; }
  .theme-monochrome._white-accent .aside-section__header {
    border-color: #fff !important; }
  .theme-monochrome._sepia-accent .aside-section__header {
    border-color: #472312 !important; }

.aside-section-list__item {
  border-bottom: 1px solid rgba(160, 160, 160, 0.24);
  padding-top: 16px;
  padding-bottom: 16px; }

.aside-section__manager {
  padding-top: 24px;
  padding-bottom: 24px;
  background-color: #ffffff; }
  .aside-section__manager_policy-detail {
    border-radius: 8px;
    margin-top: 24px;
    padding: 36px 40px; }
    .theme-monochrome._black-ground .aside-section__manager_policy-detail {
      background-color: #000 !important; }
    .theme-monochrome._white-ground .aside-section__manager_policy-detail {
      background-color: #fff !important; }
    .theme-monochrome._sepia-ground .aside-section__manager_policy-detail {
      background-color: #F5F5DC !important; }
    @media screen and (max-width: 500px) {
      .aside-section__manager_policy-detail {
        padding: 32px 16px;
        order: 1;
        margin-top: 0; } }

.aside-section__action {
  display: flex;
  align-items: center;
  height: 48px;
  margin-top: 16px;
  background: #ffffff;
  border-radius: 4px;
  padding-left: 22px; }
  .aside-section__action svg {
    margin-right: 12px;
    display: block; }

.aside-manager__info {
  overflow: hidden; }
  .aside-manager__info picture {
    float: right;
    margin-left: 16px;
    width: 56px;
    height: 56px;
    position: relative;
    transform: translate3d(0, 0, 0);
    border-radius: 50%;
    overflow: hidden; }
    .aside-manager__info picture img {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); }
  .aside-manager__info p + p {
    margin-top: 8px; }
  .aside-manager__info p.manager-name {
    font-family: 'FFMetaWebProMedium', sans-serif;
    font-weight: normal;
    font-style: normal;
    letter-spacing: -0.23px;
    color: #58595b;
    font-size: 1.8rem;
    line-height: 1.11111em; }
    .theme-monochrome._black-accent .aside-manager__info p.manager-name {
      color: #000 !important; }
    .theme-monochrome._white-accent .aside-manager__info p.manager-name {
      color: #fff !important; }
    .theme-monochrome._sepia-accent .aside-manager__info p.manager-name {
      color: #472312 !important; }
  .aside-manager__info p.manager-title {
    color: #5B5B5B;
    letter-spacing: -0.2px;
    line-height: 20px; }
    .theme-monochrome._black-accent .aside-manager__info p.manager-title {
      color: #000 !important; }
    .theme-monochrome._white-accent .aside-manager__info p.manager-title {
      color: #fff !important; }
    .theme-monochrome._sepia-accent .aside-manager__info p.manager-title {
      color: #472312 !important; }

.aside-manager__contacts {
  margin-top: 16px; }
  .aside-manager__contacts p {
    margin-top: 0.8rem; }

.questionnaire-starter {
  border: 1px solid #E8E8E8;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center; }

.qstarter__header {
  margin-top: 16px;
  margin-bottom: 16px;
  color: #58595b;
  letter-spacing: -0.22px;
  font-size: 1.8rem;
  line-height: 1.11111em; }

.qstarter__text {
  line-height: 20px;
  color: #5B5B5B;
  width: 100%; }

.qstarter__action {
  margin-top: 16px; }

.files__item {
  padding-top: 24px; }

.files__link {
  display: block;
  line-height: 16px; }
  .files__link:hover .file__name {
    color: #f26123; }

.file__name {
  font-family: 'FFMetaWebProMedium', sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #3D474D;
  transition: color 0.3s;
  font-size: 1.4rem; }

.file__info {
  margin-top: 8px;
  opacity: 0.5;
  font-family: Helvetica, sans-serif;
  color: #6B7B84;
  font-size: 1.1rem; }

.aside__header {
  color: #3C3C3B;
  text-transform: uppercase;
  font-size: 1.6rem;
  line-height: 1.5em;
  padding: 24px 40px 16px;
  border-bottom: 3px solid #F7F7F7; }
  .theme-monochrome._black-ground .aside__header {
    border-color: #000 !important; }
  .theme-monochrome._white-ground .aside__header {
    border-color: #fff !important; }
  .theme-monochrome._sepia-ground .aside__header {
    border-color: #F5F5DC !important; }
  .theme-monochrome._black-accent .aside__header {
    color: #000 !important; }
  .theme-monochrome._white-accent .aside__header {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .aside__header {
    color: #472312 !important; }
  @media screen and (max-width: 500px) {
    .aside__header {
      padding: 24px 16px; } }

.aside__links {
  display: flex;
  flex-direction: column;
  padding: 24px 40px; }
  @media screen and (max-width: 500px) {
    .aside__links {
      padding: 24px 16px; } }

.aside__link {
  color: #f36c21;
  fill: #f36c21;
  font-size: 1.7rem;
  line-height: 1.41176em; }
  .theme-monochrome._black-accent .aside__link {
    color: #000 !important; }
  .theme-monochrome._white-accent .aside__link {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .aside__link {
    color: #472312 !important; }
  .aside__link:not(:first-child) {
    margin-top: 17px; }
  .aside__link img {
    margin-right: .4em; }

.aside__hr {
  display: block;
  margin: 20px 0 0;
  border: 0;
  height: 1px;
  background: #e8e8e8; }
  .theme-monochrome._black-ground .aside__hr {
    background-color: #000 !important; }
  .theme-monochrome._white-ground .aside__hr {
    background-color: #fff !important; }
  .theme-monochrome._sepia-ground .aside__hr {
    background-color: #F5F5DC !important; }

@media screen and (max-width: 740px) {
  .content__aside .dummy {
    padding-top: 0; } }

.breadcrumbs {
  font-size: 0rem;
  margin-bottom: 24px; }
  .breadcrumbs + h1, .breadcrumbs + h2, .breadcrumbs + h3, .breadcrumbs + h4, .breadcrumbs + h5, .breadcrumbs + h6 {
    margin-top: 0; }

.breadcrumbs__item:last-child .breadcrumbs__link {
  cursor: default; }
  .breadcrumbs__item:last-child .breadcrumbs__link:hover {
    color: #A0A0A0; }
  .breadcrumbs__item:last-child .breadcrumbs__link:after {
    display: none; }

.breadcrumbs__link {
  font-size: 1.4rem;
  color: #A0A0A0;
  transition: color 0.3s;
  display: inline-block;
  margin-right: 14px; }
  .theme-monochrome._black-accent .breadcrumbs__link {
    color: #000 !important; }
  .theme-monochrome._white-accent .breadcrumbs__link {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .breadcrumbs__link {
    color: #472312 !important; }
  .breadcrumbs__link:after {
    content: '';
    width: 4px;
    height: 24px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2IiBoZWlnaHQ9IjgiIHZpZXdCb3g9IjAgMCA2IDgiPjxwYXRoIGZpbGw9Im5vbmUiIHN0cm9rZT0iI0EwQTBBMCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBkPSJNMSA4bDQtNC00LTQiLz48L3N2Zz4=);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px; }
  .breadcrumbs__link:hover {
    color: #f26123; }
  .breadcrumbs__link:focus {
    color: #f26123;
    outline: none; }
  .breadcrumbs__link::-moz-focus-inner {
    border: 0; }

.tooltip__trigger {
  display: inline-block;
  vertical-align: middle;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  transition: background-image 0.3s; }

.tooltip__trigger[href]:hover:before, .tooltip__trigger:empty:hover {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIyMiI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMSAxKSI+PGNpcmNsZSBjeD0iMTAiIGN5PSIxMCIgcj0iMTAiIGZpbGw9IiNGMzZDMjEiIHN0cm9rZT0iI0YzNkMyMSIvPjxwYXRoIHN0cm9rZT0iI0ZGRiIgZD0iTTEwIDE0di00bTAtNFY1Ii8+PC9nPjwvc3ZnPg==); }

.tooltip__trigger {
  position: relative;
  padding-left: 3rem; }
  .tooltip__trigger[href]:hover {
    cursor: pointer; }
  .tooltip__trigger:empty {
    width: 2.2rem;
    height: 2.2rem;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIyMiI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2U9IiNGMzZDMjEiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxIDEpIj48Y2lyY2xlIGN4PSIxMCIgY3k9IjEwIiByPSIxMCIvPjxwYXRoIGQ9Ik0xMCAxNHYtNG0wLTRWNSIvPjwvZz48L3N2Zz4=);
    margin-top: -3px;
    transition: background-image 0.3s; }
    .tooltip__trigger:empty:before {
      display: none; }
  .tooltip__trigger:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 2.2rem;
    height: 2.2rem;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIyMiI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2U9IiNGMzZDMjEiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxIDEpIj48Y2lyY2xlIGN4PSIxMCIgY3k9IjEwIiByPSIxMCIvPjxwYXRoIGQ9Ik0xMCAxNHYtNG0wLTRWNSIvPjwvZz48L3N2Zz4=);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    transition: background-image 0.3s;
    margin-top: -3px; }

.tooltip__text {
  font-size: 1.4rem;
  line-height: 1.14286em; }

.tooltip--right {
  margin-left: 0.6rem; }

.tooltip--left {
  margin-right: 0.6rem; }

.tooltip {
  display: block !important;
  z-index: 10000; }
  .tooltip-inner {
    background: #ffffff;
    color: #595959;
    border-radius: 4px;
    padding: 24px 32px;
    max-width: 400px;
    box-shadow: 0 6px 25px rgba(0, 0, 0, 0.1); }
    .theme-monochrome._black-accent .tooltip-inner {
      background: #000 !important; }
    .theme-monochrome._white-accent .tooltip-inner {
      background: #fff !important; }
    .theme-monochrome._sepia-accent .tooltip-inner {
      background: #472312 !important; }
    .theme-monochrome._black-ground .tooltip-inner {
      color: #000 !important; }
    .theme-monochrome._white-ground .tooltip-inner {
      color: #fff !important; }
    .theme-monochrome._sepia-ground .tooltip-inner {
      color: #F5F5DC !important; }
    .theme-monochrome._black-accent .tooltip-inner {
      border: 1px solid white;
      border-color: #000 !important; }
    .theme-monochrome._white-accent .tooltip-inner {
      border: 1px solid white;
      border-color: #fff !important; }
    .theme-monochrome._sepia-accent .tooltip-inner {
      border: 1px solid white;
      border-color: #472312 !important; }
  .tooltip .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: #ffffff; }
  .tooltip[x-placement^="top"] {
    margin-bottom: 5px; }
    .tooltip[x-placement^="top"] .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0; }
  .tooltip[x-placement^="bottom"] {
    margin-top: 5px; }
    .tooltip[x-placement^="bottom"] .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0; }
  .tooltip[x-placement^="right"] {
    margin-left: 5px; }
    .tooltip[x-placement^="right"] .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0; }
  .tooltip[x-placement^="left"] {
    margin-right: 5px; }
    .tooltip[x-placement^="left"] .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0; }
  .tooltip[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity .15s, visibility .15s; }
  .tooltip[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity .15s; }

.collapsible-closed [data-collapsible-body] {
  height: 0 !important; }

[data-collapsible-body] {
  overflow: hidden;
  transition: height 0.3s; }

[data-collapsible] {
  border-bottom: 1px solid #F2F2F2; }
  [data-collapsible] + [data-collapsible] {
    border-top: 1px solid #F2F2F2;
    margin-top: -1px; }

.accordion + h4 {
  margin-top: 40px;
  margin-bottom: 0; }

.accordion__trigger {
  /* Программа «Забота о: */
  display: block;
  position: relative;
  overflow: hidden;
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
  padding-right: 8.8rem; }
  .accordion__trigger:hover {
    cursor: pointer; }
  .accordion__trigger:after {
    content: '';
    width: 4rem;
    height: 4rem;
    position: absolute;
    border-radius: 50%;
    right: 0;
    top: 50%;
    box-sizing: border-box;
    border: 1px solid rgba(160, 160, 160, 0.15);
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSI2IiB2aWV3Qm94PSIwIDAgMTAgNiI+ICA8cG9seWxpbmUgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjNTk1OTU5IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMiIgcG9pbnRzPSIwIDAgNCA0IDggMCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMSAxKSIvPjwvc3ZnPg==);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    transform-origin: 50% 50%;
    transform: translateY(-50%);
    transition: border-width 0.2s, border-color 0.2s, transform 0.3s; }
  .collapsible-opened .accordion__trigger:after {
    border: 2px solid #8dc63f;
    transform: rotate(180deg) translateY(50%);
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSI2IiB2aWV3Qm94PSIwIDAgMTAgNiI+ICA8cG9seWxpbmUgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjN0VCRDFCIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMiIgcG9pbnRzPSI4IDQgNCAwIDAgNCIgdHJhbnNmb3JtPSJyb3RhdGUoLTE4MCA0LjUgMi41KSIvPjwvc3ZnPg==); }

.accordion__inner {
  padding-bottom: 2.4rem;
  font-size: 1.6rem; }

.extra-info {
  display: flex;
  flex-direction: row;
  padding-top: 4rem;
  padding-bottom: 4rem;
  padding-right: 3.2rem;
  padding-left: 4.2rem;
  align-items: center;
  margin-top: 4rem;
  border: 1px solid #DEDEDE;
  box-sizing: border-box;
  max-width: 64.8rem;
  transition: opacity 0.6s 0.4s, transform 0.6s 0.4s;
  opacity: 0;
  transform: translateY(2rem); }
  .extra-info.view-in {
    opacity: 1;
    transform: translateY(0); }

.extra-info--centered {
  margin-left: auto;
  margin-right: auto; }

.extra-info__left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 4.3rem; }
  .extra-info__left svg {
    display: block; }

.extra-info__right {
  border-left: 1px solid #E8E8E8;
  padding-left: 3.2rem; }

.extra-info__header {
  letter-spacing: -0.4px;
  font-size: 3.2rem;
  line-height: 1em; }

.extra-info__text {
  margin-top: 1.6rem;
  font-size: 1.5rem; }

.extra-info__action {
  margin-top: 1.6rem; }

@media screen and (max-width: 740px) {
  .extra-info {
    border: none;
    padding: 0;
    margin-top: 5.6rem;
    margin-bottom: 5.6rem;
    display: block; }
  .extra-info__left {
    padding-right: 0; }
  .extra-info__right {
    border-left: 0;
    padding-left: 0;
    margin-top: 2.2rem; }
  .extra-info__header {
    font-size: 2.4rem;
    line-height: 1em;
    letter-spacing: -0.03rem; } }

.badge {
  display: inline-block;
  min-width: 1.8rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  box-sizing: border-box;
  height: 1.8rem;
  background: #8dc63f;
  border-radius: 1rem;
  text-align: center;
  font-family: Helvetica, sans-serif;
  font-size: 1.2rem !important;
  line-height: 1.5em !important;
  font-size: 1.2rem;
  line-height: 1.5em;
  color: #FFFFFF;
  text-transform: none !important; }

.badge--secondary {
  min-width: 1.9rem;
  height: 1.9rem;
  line-height: 1.7rem;
  background: transparent;
  border: 1px solid #979797;
  font-family: Helvetica, sans-serif;
  color: #A0A0A0;
  border-radius: 2rem;
  margin-top: -5px;
  vertical-align: middle;
  margin-left: 0.4rem; }

.notifies {
  overflow: hidden;
  transition: height 0.3s; }
  .notifies.is-collapsed {
    height: 0; }

.notify__item {
  position: relative; }

.notify__row {
  color: #5B5B5B;
  line-height: 2rem; }
  .notify__row + .notify__row {
    margin-top: 1.6rem; }
  .notify__row p + p {
    margin-top: 0.8rem; }

.notify__remove {
  position: absolute;
  padding: 0.5rem;
  top: 3px;
  right: 4px;
  transition: transform 0.2s; }
  .notify__remove:hover {
    transform: scale(1.2); }
  .notify__remove svg {
    display: block; }

.notifies__hider.collapsible-opened .hider-hide {
  display: inline; }

.notifies__hider.collapsible-opened .hider-show {
  display: none; }

.notifies__hider.collapsible-opened .notifies-hidden__action:after {
  transform: rotate(90deg); }

.notifies__hider .hider-hide {
  display: none; }

.notifies-hidden__action {
  display: block;
  color: #5B5B5B;
  padding-top: 1.4rem;
  padding-bottom: 1.6rem;
  position: relative; }
  .notifies-hidden__action:after, .notifies-hidden__action:before {
    content: '';
    position: absolute;
    background: #A0A0A0;
    width: 1px;
    height: 6px;
    transition: transform 0.2s; }
  .notifies-hidden__action:before {
    transform: rotate(90deg);
    left: -7px;
    top: 24px; }
  .notifies-hidden__action:after {
    left: -7px;
    top: 24px; }

.lk__dropdown {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1; }

.lk-dropdown__trigger {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 0.8rem;
  padding-right: 0.8rem; }
  .lk-dropdown__trigger:after {
    content: '';
    width: 1rem;
    height: 0.5rem;
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSI1IiB2aWV3Qm94PSIwIDAgMTAgNSI+ICA8cG9seWxpbmUgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjRjM2QzIxIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMiIgcG9pbnRzPSIyMCAxNSAxNiAxOCAxMiAxNSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTExIC0xNCkiLz48L3N2Zz4=) no-repeat 50% 50%;
    margin-left: 0.4rem; }

.lk-dropdown__icon {
  margin-right: 0.8rem;
  display: inline-block; }

.lk-dropdown__list {
  background: #fff;
  position: relative;
  padding: 2.4rem;
  box-sizing: border-box;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s, transform 0.3s, visibility 0.3s;
  transform: translateY(1rem);
  opacity: 0;
  visibility: hidden; }
  .lk-dropdown__list.is-visible {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    max-height: 20rem; }

.lk-dropdown__item {
  display: block;
  width: 100%;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  background: #ffffff;
  border: none;
  font: inherit;
  box-sizing: border-box;
  border-radius: 0;
  -webkit-appearance: none;
  transition: color 0.3s;
  text-align: left; }
  .lk-dropdown__item svg {
    display: block; }
  .lk-dropdown__item.is-active {
    color: #f26123; }
    .lk-dropdown__item.is-active:hover {
      cursor: default; }
  .lk-dropdown__item:hover {
    cursor: pointer;
    color: #f26123; }

.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 3.2rem;
  border-top: 1px solid rgba(160, 160, 160, 0.24); }

.pagination__list {
  display: flex;
  align-items: center; }
  .pagination__list .disabled {
    opacity: 0.5; }
    .pagination__list .disabled:hover {
      cursor: default; }

.pagination__item {
  display: flex;
  align-items: stretch;
  height: 3.2rem; }
  .pagination__item .pagination__link {
    font-family: 'FFMetaWebProNormal', sans-serif;
    font-weight: normal;
    font-style: normal;
    color: #A0A0A0;
    font-size: 1.7rem; }
    .theme-monochrome._black-accent .pagination__item .pagination__link {
      color: #000 !important; }
    .theme-monochrome._white-accent .pagination__item .pagination__link {
      color: #fff !important; }
    .theme-monochrome._sepia-accent .pagination__item .pagination__link {
      color: #472312 !important; }
    .theme-monochrome._black-accent .pagination__item .pagination__link {
      border-color: #000 !important; }
    .theme-monochrome._white-accent .pagination__item .pagination__link {
      border-color: #fff !important; }
    .theme-monochrome._sepia-accent .pagination__item .pagination__link {
      border-color: #472312 !important; }
    .pagination__item .pagination__link:hover {
      color: #f26123;
      cursor: pointer; }
      .theme-monochrome._black-accent .pagination__item .pagination__link:hover {
        color: #000 !important; }
      .theme-monochrome._white-accent .pagination__item .pagination__link:hover {
        color: #fff !important; }
      .theme-monochrome._sepia-accent .pagination__item .pagination__link:hover {
        color: #472312 !important; }
      .theme-monochrome._black-accent .pagination__item .pagination__link:hover {
        border-color: #000 !important; }
      .theme-monochrome._white-accent .pagination__item .pagination__link:hover {
        border-color: #fff !important; }
      .theme-monochrome._sepia-accent .pagination__item .pagination__link:hover {
        border-color: #472312 !important; }
  .pagination__item._active .pagination__link {
    color: inherit;
    font-family: 'FFMetaWebProMedium', sans-serif;
    font-weight: normal;
    font-style: normal; }
    .theme-monochrome._black-accent .pagination__item._active .pagination__link {
      color: #000 !important; }
    .theme-monochrome._white-accent .pagination__item._active .pagination__link {
      color: #fff !important; }
    .theme-monochrome._sepia-accent .pagination__item._active .pagination__link {
      color: #472312 !important; }
    .theme-monochrome._black-accent .pagination__item._active .pagination__link {
      border-color: #000 !important; }
    .theme-monochrome._white-accent .pagination__item._active .pagination__link {
      border-color: #fff !important; }
    .theme-monochrome._sepia-accent .pagination__item._active .pagination__link {
      border-color: #472312 !important; }
    .pagination__item._active .pagination__link:hover {
      color: inherit;
      cursor: default; }
      .theme-monochrome._black-accent .pagination__item._active .pagination__link:hover {
        color: #000 !important; }
      .theme-monochrome._white-accent .pagination__item._active .pagination__link:hover {
        color: #fff !important; }
      .theme-monochrome._sepia-accent .pagination__item._active .pagination__link:hover {
        color: #472312 !important; }
      .theme-monochrome._black-accent .pagination__item._active .pagination__link:hover {
        border-color: #000 !important; }
      .theme-monochrome._white-accent .pagination__item._active .pagination__link:hover {
        border-color: #fff !important; }
      .theme-monochrome._sepia-accent .pagination__item._active .pagination__link:hover {
        border-color: #472312 !important; }

.pagination__link {
  min-width: 3.2rem;
  height: 3.2rem;
  box-sizing: border-box;
  line-height: 3.2rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  text-align: center;
  display: block;
  transition: color 0.3s;
  font-family: 'FFMetaWebProMedium', sans-serif;
  font-weight: normal;
  font-style: normal; }
  .pagination__link[href] {
    font-family: 'FFMetaWebProNormal', sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 1.7rem;
    color: #A0A0A0; }
    .theme-monochrome._black-accent .pagination__link[href] {
      color: #000 !important; }
    .theme-monochrome._white-accent .pagination__link[href] {
      color: #fff !important; }
    .theme-monochrome._sepia-accent .pagination__link[href] {
      color: #472312 !important; }
    .theme-monochrome._black-accent .pagination__link[href] {
      border-color: #000 !important; }
    .theme-monochrome._white-accent .pagination__link[href] {
      border-color: #fff !important; }
    .theme-monochrome._sepia-accent .pagination__link[href] {
      border-color: #472312 !important; }
    .pagination__link[href]:hover {
      color: #f26123; }
      .theme-monochrome._black-accent .pagination__link[href]:hover {
        color: #000 !important; }
      .theme-monochrome._white-accent .pagination__link[href]:hover {
        color: #fff !important; }
      .theme-monochrome._sepia-accent .pagination__link[href]:hover {
        color: #472312 !important; }
      .theme-monochrome._black-accent .pagination__link[href]:hover {
        border-color: #000 !important; }
      .theme-monochrome._white-accent .pagination__link[href]:hover {
        border-color: #fff !important; }
      .theme-monochrome._sepia-accent .pagination__link[href]:hover {
        border-color: #472312 !important; }

.pagination__action .pagination__link {
  width: 3.2rem;
  height: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(160, 160, 160, 0.15);
  border-radius: 50%;
  transition: border-color 0.3s; }
  .theme-monochrome._black-accent .pagination__action .pagination__link {
    color: #000 !important; }
  .theme-monochrome._white-accent .pagination__action .pagination__link {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .pagination__action .pagination__link {
    color: #472312 !important; }
  .theme-monochrome._black-accent .pagination__action .pagination__link {
    border-color: #000 !important; }
  .theme-monochrome._white-accent .pagination__action .pagination__link {
    border-color: #fff !important; }
  .theme-monochrome._sepia-accent .pagination__action .pagination__link {
    border-color: #472312 !important; }
  .pagination__action .pagination__link:hover {
    border-color: #f26123; }
    .theme-monochrome._black-accent .pagination__action .pagination__link:hover {
      color: #000 !important; }
    .theme-monochrome._white-accent .pagination__action .pagination__link:hover {
      color: #fff !important; }
    .theme-monochrome._sepia-accent .pagination__action .pagination__link:hover {
      color: #472312 !important; }
    .theme-monochrome._black-accent .pagination__action .pagination__link:hover {
      border-color: #000 !important; }
    .theme-monochrome._white-accent .pagination__action .pagination__link:hover {
      border-color: #fff !important; }
    .theme-monochrome._sepia-accent .pagination__action .pagination__link:hover {
      border-color: #472312 !important; }
    .pagination__action .pagination__link:hover use {
      stroke: #f26123; }

.pagination__action svg {
  display: block; }

.pagination__action use {
  transition: stroke 0.3s;
  stroke: #595959; }

.pagination__action.disabled .pagination__link:hover {
  cursor: default;
  border-color: rgba(160, 160, 160, 0.15); }
  .theme-monochrome._black-accent .pagination__action.disabled .pagination__link:hover {
    color: #000 !important; }
  .theme-monochrome._white-accent .pagination__action.disabled .pagination__link:hover {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .pagination__action.disabled .pagination__link:hover {
    color: #472312 !important; }
  .theme-monochrome._black-accent .pagination__action.disabled .pagination__link:hover {
    border-color: #000 !important; }
  .theme-monochrome._white-accent .pagination__action.disabled .pagination__link:hover {
    border-color: #fff !important; }
  .theme-monochrome._sepia-accent .pagination__action.disabled .pagination__link:hover {
    border-color: #472312 !important; }
  .pagination__action.disabled .pagination__link:hover use {
    stroke: #595959; }

.pagination__action:first-child {
  margin-right: 2.4rem; }
  .pagination__action:first-child svg {
    margin-left: -2px; }

.pagination__action:last-child {
  margin-left: 2.4rem; }
  .pagination__action:last-child svg {
    margin-right: -2px; }

.g-chat-header {
  position: relative;
  padding-top: 1.6rem;
  text-align: center; }
  .g-chat-header:before {
    content: '';
    width: 100%;
    position: absolute;
    height: 1px;
    background: rgba(160, 160, 160, 0.24);
    bottom: 12px;
    left: 0; }

.gch-text {
  display: inline-block;
  position: relative;
  padding-left: 1rem;
  padding-right: 1rem;
  background: #ffffff;
  color: #595959;
  text-align: center;
  line-height: 24px; }

.gch-date {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center; }

.g-chat-content {
  margin-top: 40px;
  margin-bottom: 72px; }

.g-chat-body {
  max-height: 500px;
  overflow: auto;
  margin-top: 40px;
  margin-bottom: 40px; }

.g-chat-row {
  display: flex;
  align-items: flex-start; }
  .g-chat-row + .g-chat-row {
    margin-top: 40px; }

.g-chat-icon {
  width: 40px;
  height: 40px;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 16px;
  border-radius: 50%;
  overflow: hidden; }
  .g-chat-icon img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.g-chat-icon--missed {
  background: #F32121; }

.g-chat-icon--answered {
  background: #8dc63f; }

.g-chat-message {
  padding: 16px 24px;
  border: 1px solid rgba(160, 160, 160, 0.4);
  border-radius: 4px;
  max-width: 70%; }

.g-chat-message--result {
  position: relative;
  border: 1px solid #F7F7F7;
  background: #F7F7F7; }
  .g-chat-message--result .g-chat-message-date {
    position: absolute;
    right: 24px;
    top: 16px; }

.g-chat-message-date {
  font-size: 1.2rem;
  line-height: 1.33333em;
  color: #A0A0A0;
  margin-bottom: 8px; }

.g-chat-message-header {
  font-family: 'FFMetaWebProMedium', sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #262626;
  letter-spacing: 0;
  margin-bottom: 8px;
  font-size: 1.7rem;
  line-height: 1.41176em; }

.g-chat-row--user {
  flex-direction: row-reverse; }
  .g-chat-row--user .g-chat-message {
    background: #2F69B3;
    color: #ffffff; }
  .g-chat-row--user .g-chat-message-date {
    color: #ffffff; }
  .g-chat-row--user .g-chat-icon {
    margin-right: 0;
    margin-left: 16px; }
  .g-chat-row--user .g-chat-row--user {
    margin-top: 8px; }
    .g-chat-row--user .g-chat-row--user .g-chat-icon {
      opacity: 0;
      visibility: hidden; }

.g-chat-row--doctor + .g-chat-row--doctor {
  margin-top: 8px; }
  .g-chat-row--doctor + .g-chat-row--doctor .g-chat-icon {
    opacity: 0;
    visibility: hidden; }

.g-chat-row--doctor + .g-chat-row--system .g-chat-icon {
  opacity: 1;
  visibility: visible; }

.g-chat-row--system .g-chat-message {
  background: transparent; }

.g-chat-row--system .g-chat-message-date {
  color: #A0A0A0;
  margin-bottom: 0; }

.g-chat-row--system .g-chat-message-text {
  color: #A0A0A0;
  font-size: 1.4rem;
  line-height: 1.71429em; }

.g-chat-row--system .g-chat-icon {
  display: flex;
  align-items: center;
  justify-content: center; }
  .g-chat-row--system .g-chat-icon img {
    width: 100%;
    height: 100%;
    object-fit: none; }

.g-chat-row--system + .g-chat-row--doctor .g-chat-icon {
  opacity: 1;
  visibility: visible; }

.g-chat-footer {
  margin-top: 2.4rem;
  display: flex;
  align-items: flex-end; }

.g-chat-attach {
  position: relative;
  overflow: hidden;
  flex-grow: 0;
  margin-right: 1.6rem; }
  .g-chat-attach label {
    display: flex;
    padding: 1rem; }
    .g-chat-attach label svg {
      display: block; }
    .g-chat-attach label:hover {
      cursor: pointer; }
  .g-chat-attach input {
    position: absolute;
    width: 1px;
    height: 1px;
    left: -10px;
    opacity: 0; }

.g-chat-text-input {
  flex-grow: 1; }

.g-chat-send {
  flex-grow: 0;
  margin-left: 16px; }
  .g-chat-send button {
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background: #f26123; }
    .g-chat-send button svg {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 80%; }

@media screen and (max-width: 740px) {
  .g-chat-message {
    display: flex;
    flex-direction: column; }
  .g-chat-message--result .g-chat-message-date {
    position: static;
    order: 1; }
  .g-chat-message--result .g-chat-message-header {
    order: 2; }
  .g-chat-message--result .g-chat-message-text {
    order: 3; }
  .g-chat-body {
    max-height: inherit; } }

.hero {
  padding: 32px;
  background: #F7F7F7;
  border-radius: 4px;
  margin-bottom: 32px;
  min-height: 50px; }
  .theme-monochrome._black-ground .hero {
    background: #000 !important; }
  .theme-monochrome._white-ground .hero {
    background: #fff !important; }
  .theme-monochrome._sepia-ground .hero {
    background: #F5F5DC !important; }
  .hero .attachment-zone {
    margin-top: 24px;
    padding-top: 16px;
    border-top: 1px solid rgba(160, 160, 160, 0.24); }
    .theme-monochrome._black-ground .hero .attachment-zone {
      border-top-color: #000 !important; }
    .theme-monochrome._white-ground .hero .attachment-zone {
      border-top-color: #fff !important; }
    .theme-monochrome._sepia-ground .hero .attachment-zone {
      border-top-color: #F5F5DC !important; }

.hero_thin {
  padding: 32px;
  background: #F7F7F7;
  border-radius: 4px;
  margin-bottom: 32px; }
  .theme-monochrome._black-ground .hero_thin {
    background: #000 !important; }
  .theme-monochrome._white-ground .hero_thin {
    background: #fff !important; }
  .theme-monochrome._sepia-ground .hero_thin {
    background: #F5F5DC !important; }
  .hero_thin .attachment-zone {
    margin-top: 24px;
    padding-top: 16px;
    border-top: 1px solid rgba(160, 160, 160, 0.24); }
    .theme-monochrome._black-ground .hero_thin .attachment-zone {
      border-top-color: #000 !important; }
    .theme-monochrome._white-ground .hero_thin .attachment-zone {
      border-top-color: #fff !important; }
    .theme-monochrome._sepia-ground .hero_thin .attachment-zone {
      border-top-color: #F5F5DC !important; }

.hero--inverse {
  background: #ffffff;
  margin-top: 32px; }

.hero__content + .hero__content {
  margin-top: 24px; }

.hero__content--full-width {
  margin-right: -82px; }

.hero-with-icon {
  padding-right: 114px;
  position: relative; }
  .hero-with-icon:before {
    content: '';
    position: absolute;
    width: 50px;
    height: 50px;
    top: 32px;
    right: 32px;
    background-position: 50% 50%;
    background-repeat: no-repeat; }

.hero--inform:before {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MCIgaGVpZ2h0PSI1MCI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2U9IiNGMzZDMjEiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxIDEpIj48Y2lyY2xlIGN4PSIyNCIgY3k9IjI0IiByPSIyNCIvPjxwYXRoIGQ9Ik0yNCAxMy40VjI4bTAgNi40djEiLz48L2c+PC9zdmc+); }

.hero--clock:before {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MCIgaGVpZ2h0PSI1MCI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2U9IiNBMEEwQTAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIiBvcGFjaXR5PSIuNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMSAxKSI+PGNpcmNsZSBjeD0iMjQiIGN5PSIyNCIgcj0iMjQiLz48cGF0aCBkPSJNMjQgOS42VjI0bDcuMiA3LjIiLz48L2c+PC9zdmc+); }

.hero--bubble:before {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MCIgaGVpZ2h0PSI1MCI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMiI+PHBhdGggc3Ryb2tlPSIjQTBBMEEwIiBkPSJNNDkgMzNhNS4zMzMgNS4zMzMgMCAwIDEtNS4zMzMgNS4zMzNoLTMyTDEgNDlWNi4zMzNBNS4zMzMgNS4zMzMgMCAwIDEgNi4zMzMgMWgzNy4zMzRBNS4zMzMgNS4zMzMgMCAwIDEgNDkgNi4zMzNWMzN6Ii8+PHBhdGggc3Ryb2tlPSIjRjM2QzIxIiBkPSJNMTMgMTRoMjQiLz48cGF0aCBzdHJva2U9IiM5Nzk3OTciIGQ9Ik0xMyAyMGgxNm0tMTYgNmgxNiIvPjwvZz48L3N2Zz4=); }

@media screen and (max-width: 740px) {
  .hero {
    padding: 24px; }
  .hero-with-icon {
    padding-right: 24px; }
    .hero-with-icon:before {
      display: none; }
  .hero__content--full-width {
    margin-right: 0; } }

.info-addon .info-addon-image {
  background-image: url("/static/img/info_addon_image.jpg");
  width: 75%;
  background-size: cover; }

.how-to-content .how-to-image {
  width: 35%; }

.how-to-content .how-to-items {
  width: 65%; }

@media only screen and (max-width: 600px) {
  .info-addon .info-addon-image {
    display: none; }
  .how-to-content .how-to-image {
    display: none; }
  .how-to-content .how-to-items {
    width: auto; } }

.landing-button {
  letter-spacing: 0.3px !important;
  padding: 15px 36px !important;
  background: #EA5921 linear-gradient(-180deg, rgba(241, 241, 241, 0), rgba(230, 230, 230, 0)) !important; }

.landing-button:hover {
  background: #EA5921 linear-gradient(-180deg, rgba(241, 241, 241, 0.1), rgba(230, 230, 230, 0.1)) !important; }

.extra-product-content-header {
  text-align: center;
  margin-bottom: 26px; }

.extra-product-content-header h3 {
  color: #EA5921;
  margin-bottom: 10px !important;
  letter-spacing: -.4px;
  font-size: 3.8rem;
  line-height: 1em; }

.extra-product-content-header p {
  font-size: 1.8rem; }

.info-addon {
  display: flex; }

.info-addon-image {
  background-image: url(/static/img/info_addon_image.jpg);
  width: 75%;
  background-size: cover;
  background-position-x: center; }

.info-addon-content {
  padding: 60px 30px 30px 30px; }

.benefit-header {
  font-size: 1.8rem !important; }

.benefit-preview {
  font-size: 1.9rem; }

.benefit-section {
  display: flex;
  flex-flow: row wrap;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 24px; }

.benefit-section .benefit {
  padding: 0px 0px 40px 0px; }

.benefit-section .benefit-centered {
  text-align: center; }

.benefit-section .benefit p {
  padding: 0px;
  margin: 0px;
  font-size: 1.7rem; }

.terms-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around !important; }

.terms-grid .term-item {
  width: 29%;
  margin-left: 20px;
  margin-right: 20px; }

.terms-grid .vpki-item .term-header {
  color: #EA5921;
  text-align: center;
  text-transform: none;
  font-size: 1.8rem; }

.terms-grid .vpki-item .vpki-body .term-preview {
  color: #5a6275;
  font-size: 1.7rem; }

.terms-grid .vpki-item .vpki-body .term-preview i {
  font-style: italic; }

.how-to-content {
  display: flex; }

.how-to-content .how-to-items .how-to-items-row {
  display: flex;
  justify-content: space-around; }

.how-to-content .how-to-items .how-to-items-row .how-to-item {
  padding: 0px 0px 40px 40px;
  width: 50%;
  color: #5a6275;
  font-size: 1.7rem; }

.how-to-content .how-to-items .how-to-items-row .how-to-item .how-to-item-icon {
  width: 52px;
  height: 37px;
  border-radius: 27px;
  background-color: #EA5921;
  font-size: 3rem;
  text-align: center;
  font-weight: bold;
  padding-top: 14px;
  color: white;
  margin-bottom: 10px; }

.how-to-content .how-to-items .how-to-items-row .how-to-item .how-to-item-header {
  margin-bottom: 10px;
  font-weight: bold; }

.how-to-content .how-to-items .how-to-items-row .how-to-item .how-to-item-content ul {
  padding-left: 10px; }

.how-to-content .how-to-items .how-to-items-row .how-to-item .how-to-item-content ul li {
  list-style: disc; }

.how-to-footer-item {
  padding: 0px 0px 40px;
  font-size: 1.7rem; }

.how-to-footer-item .how-to-footer-item-header {
  color: #EA5921;
  text-align: center;
  text-transform: none;
  font-weight: bold;
  padding-bottom: 12px; }

.how-to-footer-item .how-to-footer-item-content {
  color: #5a6275;
  display: flex;
  justify-content: space-around; }

.colored-background {
  background-color: #fecc2d; }

.tooltip-html .tooltiptext {
  visibility: hidden;
  display: none;
  width: max-content;
  padding: 10px 10px 10px 10px;
  background-color: #d7e3e7;
  color: black;
  border-radius: 6px;
  position: absolute;
  z-index: 10000;
  font-size: 1rem;
  line-height: normal;
  font-weight: normal; }

.tooltip-html:hover .tooltiptext {
  visibility: visible;
  display: block; }

.tooltip-html {
  position: relative;
  display: inline-block;
  color: #EA5921;
  font-weight: bold; }

.tooltip-html:hover {
  color: #EA5921;
  font-weight: bold;
  cursor: pointer; }

.landing-primary-color {
  color: #EA5921 !important; }

.gallery {
  margin-top: 4.8rem;
  margin-bottom: 4.8rem; }
  .gallery .swiper-button-prev, .gallery .swiper-button-next {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #fff;
    border: 1px solid rgba(160, 160, 160, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    top: 168px; }
    .gallery .swiper-button-prev svg, .gallery .swiper-button-next svg {
      display: block; }
    .gallery .swiper-button-prev use, .gallery .swiper-button-next use {
      transition: stroke 0.3s;
      stroke: #595959; }
    .gallery .swiper-button-prev:hover use, .gallery .swiper-button-next:hover use {
      stroke: #f26123; }

.gallery__image {
  height: 368px; }
  .gallery__image picture, .gallery__image img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover; }

.gallery__description {
  text-align: center;
  padding: 1.6rem;
  box-sizing: border-box;
  font-size: 1.4rem;
  line-height: 1.71429em;
  color: #A0A0A0;
  background: #ffffff;
  border-bottom: 1px solid rgba(160, 160, 160, 0.24); }

@media screen and (max-width: 740px) {
  .gallery .swiper-button-prev, .gallery .swiper-button-next {
    top: 64px; } }

.no-results {
  text-align: center; }

.no-results__icon {
  margin-bottom: 1.6rem; }

.no-results__text {
  margin-bottom: 1.6rem; }

.v-autocomplete .v-autocomplete-list {
  z-index: 1;
  width: 100%;
  text-align: left;
  border: none;
  border-top: none;
  max-height: 400px;
  overflow-y: auto;
  border-top: 2px solid #f26123;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  padding: 3.2rem;
  background-color: #fff; }
  .vac-search__form .v-autocomplete .v-autocomplete-list {
    box-sizing: border-box;
    padding: 2.4rem; }
  .v-autocomplete .v-autocomplete-list .v-autocomplete-list-item {
    cursor: pointer;
    background-color: #fff;
    padding: 0.8rem;
    font-size: 2.4rem;
    line-height: 1em;
    transition: color 0.3s; }
    .vac-search__form .v-autocomplete .v-autocomplete-list .v-autocomplete-list-item {
      color: #A0A0A0; }
      .vac-search__form .v-autocomplete .v-autocomplete-list .v-autocomplete-list-item span {
        color: #58595b; }

fieldset:not([class]) legend {
  margin-bottom: 1.6rem; }

fieldset:not([class]) + fieldset:not([class]) {
  margin-top: 3.2rem; }

.form__row,
.input-field {
  position: relative;
  margin-top: 0; }
  .form__row + .form__row,
  .input-field + .form__row {
    margin-top: 1.6rem; }
  .form__row:disabled:after,
  .input-field:disabled:after {
    content: '';
    width: 40px;
    height: 40px;
    position: absolute;
    background-color: #fff;
    right: 0;
    top: 24px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxOCIgdmlld0JveD0iMCAwIDE0IDE4Ij4gIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMSAxKSI+ICAgIDxwYXRoIHN0cm9rZT0iI0Q5RDlEOCIgc3Ryb2tlLXdpZHRoPSIyIiBkPSJNOS4xNzY0NzA1OSAxNkwyLjgyMzUyOTQxIDE2QzEuMjY0MTM3MTggMTYgMy4xMzQ3NDczNmUtMTYgMTQuNzU0MTgzNyAwIDEzLjIxNzM5MTNMMCA2LjI2MDg2OTU3IDEyIDYuMjYwODY5NTcgMTIgMTMuMjE3MzkxM0MxMiAxNC43NTQxODM3IDEwLjczNTg2MjggMTYgOS4xNzY0NzA1OSAxNnpNMi40NzA1ODgyNCA2LjI2MDg2OTU3TDIuNDcwNTg4MjQgMy40NzgyNjA4N0MyLjQ3MDU4ODI0IDEuNTU3MjcwNDQgNC4wNTA3NTk3MSAzLjA4OTMxNjI0ZS0xNiA2IDBMNiAwQzcuOTQ5MjQwMjkgNy43MjMyOTA2MWUtMTcgOS41Mjk0MTE3NiAxLjU1NzI3MDQ0IDkuNTI5NDExNzYgMy40NzgyNjA4N0w5LjUyOTQxMTc2IDYuMjYwODY5NTciLz4gICAgPHBhdGggZmlsbD0iI0Q5RDlEOCIgZmlsbC1ydWxlPSJub256ZXJvIiBkPSJNNiwxMi44Njk1NjUyIEw2LDEyLjg2OTU2NTIgQzUuNzgzMjAxOTYsMTIuODcxNzcxIDUuNTkyNjQ2ODksMTIuNzI4NDE3MiA1LjUzNzY0NzA2LDEyLjUyMTczOTEgTDQuOTY5NDExNzYsMTAuNjUwNDM0OCBDNC45MDM2MzA4OCwxMC4zNDM1NDUgNC45ODE0MTkxMSwxMC4wMjM3NzI4IDUuMTgxMjQzNjQsOS43Nzk2MzU0MyBDNS4zODEwNjgxNyw5LjUzNTQ5ODAxIDUuNjgxNzI5MzEsOS4zOTI4OTYyOSA2LDkuMzkxMzA0MzUgTDYsOS4zOTEzMDQzNSBDNi4zMTU5OTUyMSw5LjM5MzUyNTQxIDYuNjE0NDY4MzgsOS41MzQ3MjQ4NCA2LjgxMzg2NjIxLDkuNzc2MzIyMDEgQzcuMDEzMjY0MDQsMTAuMDE3OTE5MiA3LjA5Mjc0MzU4LDEwLjMzNDY2MDEgNy4wMzA1ODgyNCwxMC42NCBMNi40NjIzNTI5NCwxMi41MjE3MzkxIEM2LjQwNzM1MzExLDEyLjcyODQxNzIgNi4yMTY3OTgwNCwxMi44NzE3NzEgNiwxMi44Njk1NjUyIFoiLz4gIDwvZz48L3N2Zz4=");
    background-position: 50% 50%;
    background-repeat: no-repeat; }
    .theme-monochrome._black-ground .form__row:disabled:after, .theme-monochrome._black-ground
    .input-field:disabled:after {
      background-color: #000 !important; }
    .theme-monochrome._white-ground .form__row:disabled:after, .theme-monochrome._white-ground
    .input-field:disabled:after {
      background-color: #fff !important; }
    .theme-monochrome._sepia-ground .form__row:disabled:after, .theme-monochrome._sepia-ground
    .input-field:disabled:after {
      background-color: #F5F5DC !important; }

.form__row--date .input-button {
  position: absolute; }

.form__row--inline {
  display: flex;
  align-items: center; }
  .form__row--inline .form__row {
    width: 40%;
    margin-right: 4rem; }
  .form__row--inline .form__radio + .form__radio {
    margin-top: 0;
    margin-left: 5.6rem; }

.form__label {
  font-family: 'FFMetaWebProNormal', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 1.6rem;
  line-height: 1.5em;
  color: #58595b;
  display: block;
  position: absolute;
  padding-bottom: 1rem;
  left: 0;
  top: 3.2rem;
  transition: top 0.3s, font-size 0.3s, line-height 0.3s;
  pointer-events: none; }
  .theme-monochrome._black-accent .form__label {
    color: #000 !important; }
  .theme-monochrome._white-accent .form__label {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .form__label {
    color: #472312 !important; }

.form__row--hidden {
  display: none; }

.form__action {
  margin-top: 2rem; }
  .form__action .secondary-action {
    text-align: center;
    margin-top: 1.6rem; }

.form__sign {
  display: flex;
  justify-content: space-between; }

.input__helper, .input__error {
  color: #A0A0A0;
  margin-top: 0.8rem;
  font-size: 1.4rem;
  line-height: 1.14286em; }
  .theme-monochrome._black-accent .input__helper, .theme-monochrome._black-accent .input__error {
    color: #000 !important; }
  .theme-monochrome._white-accent .input__helper, .theme-monochrome._white-accent .input__error {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .input__helper, .theme-monochrome._sepia-accent .input__error {
    color: #472312 !important; }
  .input__helper a, .input__error a {
    color: #f26123; }
    .theme-monochrome._black-accent .input__helper a, .theme-monochrome._black-accent .input__error a {
      color: #000 !important; }
    .theme-monochrome._white-accent .input__helper a, .theme-monochrome._white-accent .input__error a {
      color: #fff !important; }
    .theme-monochrome._sepia-accent .input__helper a, .theme-monochrome._sepia-accent .input__error a {
      color: #472312 !important; }
    .input__helper a.disabled, .input__error a.disabled {
      opacity: 0.5; }
  .input__helper .helper__tooltip, .input__error .helper__tooltip {
    border: none;
    position: relative;
    display: inline-block;
    padding-left: 2.7rem;
    line-height: 1.8rem;
    transition: color 0.3s; }
    .input__helper .helper__tooltip:before, .input__error .helper__tooltip:before {
      content: '';
      width: 2rem;
      height: 2rem;
      position: absolute;
      left: 0;
      top: -0.2rem;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIj4gIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjQTBBMEEwIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMSAxKSI+ICAgIDxjaXJjbGUgY3g9IjkiIGN5PSI5IiByPSI5Ii8+ICAgIDxwYXRoIGQ9Ik05IDEzTDkgOU05IDVMOSA1Ii8+ICA8L2c+PC9zdmc+);
      background-position: 50% 50%;
      background-repeat: no-repeat; }
    .input__helper .helper__tooltip:hover, .input__error .helper__tooltip:hover {
      cursor: pointer;
      color: #58595b; }
      .theme-monochrome._black-accent .input__helper .helper__tooltip:hover, .theme-monochrome._black-accent .input__error .helper__tooltip:hover {
        color: #000 !important; }
      .theme-monochrome._white-accent .input__helper .helper__tooltip:hover, .theme-monochrome._white-accent .input__error .helper__tooltip:hover {
        color: #fff !important; }
      .theme-monochrome._sepia-accent .input__helper .helper__tooltip:hover, .theme-monochrome._sepia-accent .input__error .helper__tooltip:hover {
        color: #472312 !important; }
  .input__helper a.lk__action, .input__error a.lk__action {
    border-bottom: none; }
    .input__helper a.lk__action.lk__action--active, .input__error a.lk__action.lk__action--active {
      color: #f26123; }
      .theme-monochrome._black-accent .input__helper a.lk__action.lk__action--active, .theme-monochrome._black-accent .input__error a.lk__action.lk__action--active {
        color: #000 !important; }
      .theme-monochrome._white-accent .input__helper a.lk__action.lk__action--active, .theme-monochrome._white-accent .input__error a.lk__action.lk__action--active {
        color: #fff !important; }
      .theme-monochrome._sepia-accent .input__helper a.lk__action.lk__action--active, .theme-monochrome._sepia-accent .input__error a.lk__action.lk__action--active {
        color: #472312 !important; }

.input__error {
  color: #F32121;
  display: none; }
  .theme-monochrome._black-accent .input__error {
    color: #000 !important; }
  .theme-monochrome._white-accent .input__error {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .input__error {
    color: #472312 !important; }
  .show-error .input__error {
    display: block; }

.show-error .form__label {
  color: #F32121; }
  .theme-monochrome._black-accent .show-error .form__label {
    color: #000 !important; }
  .theme-monochrome._white-accent .show-error .form__label {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .show-error .form__label {
    color: #472312 !important; }

.show-error input[data-validate] {
  color: #FB969D; }
  .theme-monochrome._black-accent .show-error input[data-validate] {
    color: #000 !important; }
  .theme-monochrome._white-accent .show-error input[data-validate] {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .show-error input[data-validate] {
    color: #472312 !important; }

.form__group {
  padding-top: 3.2rem;
  padding-bottom: 3.2rem;
  border-bottom: 1px solid rgba(160, 160, 160, 0.24); }
  .theme-monochrome._black-accent .form__group {
    border-bottom-color: #000 !important; }
  .theme-monochrome._white-accent .form__group {
    border-bottom-color: #fff !important; }
  .theme-monochrome._sepia-accent .form__group {
    border-bottom-color: #472312 !important; }
  .form__group legend {
    display: block;
    float: left;
    width: 100%;
    position: static;
    color: #262626;
    letter-spacing: -0.3px;
    padding: 0;
    z-index: 1;
    font-size: 2.4rem;
    line-height: 1em; }
    .theme-monochrome._black-accent .form__group legend {
      color: #000 !important; }
    .theme-monochrome._white-accent .form__group legend {
      color: #fff !important; }
    .theme-monochrome._sepia-accent .form__group legend {
      color: #472312 !important; }
    .form__group legend + .form__row {
      clear: both;
      padding-top: 2.4rem; }
    .form__group legend + .attachment-zone {
      border-top: 0; }
  .form__group:first-child {
    padding-top: 0; }

/*.form__group {
  padding-top: 5.6rem;
  padding-bottom: 3.2rem;
  border-bottom: 1px solid rgba(#A0A0A0, 0.24);
  position: relative;
  legend {
    display: block;
    position: absolute;
    top: 3.2rem;
    width: 100%;
    margin: 0;
    font-size: 24px;
    color: #262626;
    letter-spacing: -0.3px;
    line-height: 24px;
    padding: 0;
    z-index: 1;
  }
}*/
@media screen and (max-width: 740px) {
  .form__row--inline {
    display: block; }
    .form__row--inline .form__row {
      width: 100%;
      margin-right: 0;
      margin-bottom: 2rem; }
  .form__label {
    font-size: 1.4rem; }
  .form__group {
    padding-top: 3.6rem; }
    .form__group legend + .attachment-zone {
      clear: both; }
  .form__group legend {
    position: relative;
    font-size: 1.8rem;
    line-height: 1em;
    margin-top: 1.6rem; }
  .form__row--inline .form__radio + .form__radio {
    margin-left: 0;
    margin-top: 2.4rem; } }

[data-v-1ad2436f].fade-enter, [data-v-1ad2436f].slide-fade-enter {
  opacity: 1 !important; }

input[type=text], input[type=email], input[type=password], input[type=number], input[type=date], input[type=tel], textarea, select, .select-label {
  border: none;
  min-height: 2.4rem;
  background: #ffffff;
  border: 0;
  border-bottom: 2px solid #D4D4D4;
  box-shadow: none;
  border-radius: 0;
  padding: 3.2rem 0 0.6rem 0;
  display: block;
  width: 100%;
  font-family: 'FFMetaWebProMedium', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 1.6rem;
  line-height: 1.5em;
  color: #58595b;
  transition: border-color 0.3s; }
  .theme-monochrome._black-ground input[type=text], .theme-monochrome._black-ground input[type=email], .theme-monochrome._black-ground input[type=password], .theme-monochrome._black-ground input[type=number], .theme-monochrome._black-ground input[type=date], .theme-monochrome._black-ground input[type=tel], .theme-monochrome._black-ground textarea, .theme-monochrome._black-ground select, .theme-monochrome._black-ground .select-label {
    background: #000 !important; }
  .theme-monochrome._white-ground input[type=text], .theme-monochrome._white-ground input[type=email], .theme-monochrome._white-ground input[type=password], .theme-monochrome._white-ground input[type=number], .theme-monochrome._white-ground input[type=date], .theme-monochrome._white-ground input[type=tel], .theme-monochrome._white-ground textarea, .theme-monochrome._white-ground select, .theme-monochrome._white-ground .select-label {
    background: #fff !important; }
  .theme-monochrome._sepia-ground input[type=text], .theme-monochrome._sepia-ground input[type=email], .theme-monochrome._sepia-ground input[type=password], .theme-monochrome._sepia-ground input[type=number], .theme-monochrome._sepia-ground input[type=date], .theme-monochrome._sepia-ground input[type=tel], .theme-monochrome._sepia-ground textarea, .theme-monochrome._sepia-ground select, .theme-monochrome._sepia-ground .select-label {
    background: #F5F5DC !important; }
  .theme-monochrome._black-accent input[type=text], .theme-monochrome._black-accent input[type=email], .theme-monochrome._black-accent input[type=password], .theme-monochrome._black-accent input[type=number], .theme-monochrome._black-accent input[type=date], .theme-monochrome._black-accent input[type=tel], .theme-monochrome._black-accent textarea, .theme-monochrome._black-accent select, .theme-monochrome._black-accent .select-label {
    border: 1px solid;
    border-color: #000 !important; }
  .theme-monochrome._white-accent input[type=text], .theme-monochrome._white-accent input[type=email], .theme-monochrome._white-accent input[type=password], .theme-monochrome._white-accent input[type=number], .theme-monochrome._white-accent input[type=date], .theme-monochrome._white-accent input[type=tel], .theme-monochrome._white-accent textarea, .theme-monochrome._white-accent select, .theme-monochrome._white-accent .select-label {
    border: 1px solid;
    border-color: #fff !important; }
  .theme-monochrome._sepia-accent input[type=text], .theme-monochrome._sepia-accent input[type=email], .theme-monochrome._sepia-accent input[type=password], .theme-monochrome._sepia-accent input[type=number], .theme-monochrome._sepia-accent input[type=date], .theme-monochrome._sepia-accent input[type=tel], .theme-monochrome._sepia-accent textarea, .theme-monochrome._sepia-accent select, .theme-monochrome._sepia-accent .select-label {
    border: 1px solid;
    border-color: #472312 !important; }
  .theme-monochrome._black-accent input[type=text], .theme-monochrome._black-accent input[type=email], .theme-monochrome._black-accent input[type=password], .theme-monochrome._black-accent input[type=number], .theme-monochrome._black-accent input[type=date], .theme-monochrome._black-accent input[type=tel], .theme-monochrome._black-accent textarea, .theme-monochrome._black-accent select, .theme-monochrome._black-accent .select-label {
    color: #000 !important; }
  .theme-monochrome._white-accent input[type=text], .theme-monochrome._white-accent input[type=email], .theme-monochrome._white-accent input[type=password], .theme-monochrome._white-accent input[type=number], .theme-monochrome._white-accent input[type=date], .theme-monochrome._white-accent input[type=tel], .theme-monochrome._white-accent textarea, .theme-monochrome._white-accent select, .theme-monochrome._white-accent .select-label {
    color: #fff !important; }
  .theme-monochrome._sepia-accent input[type=text], .theme-monochrome._sepia-accent input[type=email], .theme-monochrome._sepia-accent input[type=password], .theme-monochrome._sepia-accent input[type=number], .theme-monochrome._sepia-accent input[type=date], .theme-monochrome._sepia-accent input[type=tel], .theme-monochrome._sepia-accent textarea, .theme-monochrome._sepia-accent select, .theme-monochrome._sepia-accent .select-label {
    color: #472312 !important; }
  input[type=text]::-webkit-input-placeholder, input[type=email]::-webkit-input-placeholder, input[type=password]::-webkit-input-placeholder, input[type=number]::-webkit-input-placeholder, input[type=date]::-webkit-input-placeholder, input[type=tel]::-webkit-input-placeholder, textarea::-webkit-input-placeholder, select::-webkit-input-placeholder, .select-label::-webkit-input-placeholder {
    opacity: 0;
    line-height: 3.2rem; }
  input[type=text]:-moz-placeholder, input[type=email]:-moz-placeholder, input[type=password]:-moz-placeholder, input[type=number]:-moz-placeholder, input[type=date]:-moz-placeholder, input[type=tel]:-moz-placeholder, textarea:-moz-placeholder, select:-moz-placeholder, .select-label:-moz-placeholder {
    opacity: 0; }
  input[type=text]:-ms-input-placeholder, input[type=email]:-ms-input-placeholder, input[type=password]:-ms-input-placeholder, input[type=number]:-ms-input-placeholder, input[type=date]:-ms-input-placeholder, input[type=tel]:-ms-input-placeholder, textarea:-ms-input-placeholder, select:-ms-input-placeholder, .select-label:-ms-input-placeholder {
    opacity: 0; }
  input[type=text]:focus, input[type=email]:focus, input[type=password]:focus, input[type=number]:focus, input[type=date]:focus, input[type=tel]:focus, textarea:focus, select:focus, .select-label:focus {
    border-bottom-color: #f26123; }
    .theme-monochrome._black-accent input[type=text]:focus, .theme-monochrome._black-accent input[type=email]:focus, .theme-monochrome._black-accent input[type=password]:focus, .theme-monochrome._black-accent input[type=number]:focus, .theme-monochrome._black-accent input[type=date]:focus, .theme-monochrome._black-accent input[type=tel]:focus, .theme-monochrome._black-accent textarea:focus, .theme-monochrome._black-accent select:focus, .theme-monochrome._black-accent .select-label:focus {
      border-bottom-color: #000 !important; }
    .theme-monochrome._white-accent input[type=text]:focus, .theme-monochrome._white-accent input[type=email]:focus, .theme-monochrome._white-accent input[type=password]:focus, .theme-monochrome._white-accent input[type=number]:focus, .theme-monochrome._white-accent input[type=date]:focus, .theme-monochrome._white-accent input[type=tel]:focus, .theme-monochrome._white-accent textarea:focus, .theme-monochrome._white-accent select:focus, .theme-monochrome._white-accent .select-label:focus {
      border-bottom-color: #fff !important; }
    .theme-monochrome._sepia-accent input[type=text]:focus, .theme-monochrome._sepia-accent input[type=email]:focus, .theme-monochrome._sepia-accent input[type=password]:focus, .theme-monochrome._sepia-accent input[type=number]:focus, .theme-monochrome._sepia-accent input[type=date]:focus, .theme-monochrome._sepia-accent input[type=tel]:focus, .theme-monochrome._sepia-accent textarea:focus, .theme-monochrome._sepia-accent select:focus, .theme-monochrome._sepia-accent .select-label:focus {
      border-bottom-color: #472312 !important; }
    input[type=text]:focus + .form__label, input[type=email]:focus + .form__label, input[type=password]:focus + .form__label, input[type=number]:focus + .form__label, input[type=date]:focus + .form__label, input[type=tel]:focus + .form__label, textarea:focus + .form__label, select:focus + .form__label, .select-label:focus + .form__label {
      font-size: 1.4rem;
      line-height: 1.42857em;
      top: 0.6rem; }
  input[type=text]:disabled, input[type=email]:disabled, input[type=password]:disabled, input[type=number]:disabled, input[type=date]:disabled, input[type=tel]:disabled, textarea:disabled, select:disabled, .select-label:disabled {
    color: rgba(88, 89, 91, 0.5); }
    .theme-monochrome._black-accent input[type=text]:disabled, .theme-monochrome._black-accent input[type=email]:disabled, .theme-monochrome._black-accent input[type=password]:disabled, .theme-monochrome._black-accent input[type=number]:disabled, .theme-monochrome._black-accent input[type=date]:disabled, .theme-monochrome._black-accent input[type=tel]:disabled, .theme-monochrome._black-accent textarea:disabled, .theme-monochrome._black-accent select:disabled, .theme-monochrome._black-accent .select-label:disabled {
      color: #000 !important; }
    .theme-monochrome._white-accent input[type=text]:disabled, .theme-monochrome._white-accent input[type=email]:disabled, .theme-monochrome._white-accent input[type=password]:disabled, .theme-monochrome._white-accent input[type=number]:disabled, .theme-monochrome._white-accent input[type=date]:disabled, .theme-monochrome._white-accent input[type=tel]:disabled, .theme-monochrome._white-accent textarea:disabled, .theme-monochrome._white-accent select:disabled, .theme-monochrome._white-accent .select-label:disabled {
      color: #fff !important; }
    .theme-monochrome._sepia-accent input[type=text]:disabled, .theme-monochrome._sepia-accent input[type=email]:disabled, .theme-monochrome._sepia-accent input[type=password]:disabled, .theme-monochrome._sepia-accent input[type=number]:disabled, .theme-monochrome._sepia-accent input[type=date]:disabled, .theme-monochrome._sepia-accent input[type=tel]:disabled, .theme-monochrome._sepia-accent textarea:disabled, .theme-monochrome._sepia-accent select:disabled, .theme-monochrome._sepia-accent .select-label:disabled {
      color: #472312 !important; }
    input[type=text]:disabled + label, input[type=email]:disabled + label, input[type=password]:disabled + label, input[type=number]:disabled + label, input[type=date]:disabled + label, input[type=tel]:disabled + label, textarea:disabled + label, select:disabled + label, .select-label:disabled + label {
      color: rgba(88, 89, 91, 0.5); }
      .theme-monochrome._black-accent input[type=text]:disabled + label, .theme-monochrome._black-accent input[type=email]:disabled + label, .theme-monochrome._black-accent input[type=password]:disabled + label, .theme-monochrome._black-accent input[type=number]:disabled + label, .theme-monochrome._black-accent input[type=date]:disabled + label, .theme-monochrome._black-accent input[type=tel]:disabled + label, .theme-monochrome._black-accent textarea:disabled + label, .theme-monochrome._black-accent select:disabled + label, .theme-monochrome._black-accent .select-label:disabled + label {
        color: #000 !important; }
      .theme-monochrome._white-accent input[type=text]:disabled + label, .theme-monochrome._white-accent input[type=email]:disabled + label, .theme-monochrome._white-accent input[type=password]:disabled + label, .theme-monochrome._white-accent input[type=number]:disabled + label, .theme-monochrome._white-accent input[type=date]:disabled + label, .theme-monochrome._white-accent input[type=tel]:disabled + label, .theme-monochrome._white-accent textarea:disabled + label, .theme-monochrome._white-accent select:disabled + label, .theme-monochrome._white-accent .select-label:disabled + label {
        color: #fff !important; }
      .theme-monochrome._sepia-accent input[type=text]:disabled + label, .theme-monochrome._sepia-accent input[type=email]:disabled + label, .theme-monochrome._sepia-accent input[type=password]:disabled + label, .theme-monochrome._sepia-accent input[type=number]:disabled + label, .theme-monochrome._sepia-accent input[type=date]:disabled + label, .theme-monochrome._sepia-accent input[type=tel]:disabled + label, .theme-monochrome._sepia-accent textarea:disabled + label, .theme-monochrome._sepia-accent select:disabled + label, .theme-monochrome._sepia-accent .select-label:disabled + label {
        color: #472312 !important; }
  input[type=text].filled, input[type=email].filled, input[type=password].filled, input[type=number].filled, input[type=date].filled, input[type=tel].filled, textarea.filled, select.filled, .select-label.filled {
    border-bottom-color: #58595b;
    filter: none; }
    .theme-monochrome._black-accent input[type=text].filled, .theme-monochrome._black-accent input[type=email].filled, .theme-monochrome._black-accent input[type=password].filled, .theme-monochrome._black-accent input[type=number].filled, .theme-monochrome._black-accent input[type=date].filled, .theme-monochrome._black-accent input[type=tel].filled, .theme-monochrome._black-accent textarea.filled, .theme-monochrome._black-accent select.filled, .theme-monochrome._black-accent .select-label.filled {
      border-bottom-color: #000 !important; }
    .theme-monochrome._white-accent input[type=text].filled, .theme-monochrome._white-accent input[type=email].filled, .theme-monochrome._white-accent input[type=password].filled, .theme-monochrome._white-accent input[type=number].filled, .theme-monochrome._white-accent input[type=date].filled, .theme-monochrome._white-accent input[type=tel].filled, .theme-monochrome._white-accent textarea.filled, .theme-monochrome._white-accent select.filled, .theme-monochrome._white-accent .select-label.filled {
      border-bottom-color: #fff !important; }
    .theme-monochrome._sepia-accent input[type=text].filled, .theme-monochrome._sepia-accent input[type=email].filled, .theme-monochrome._sepia-accent input[type=password].filled, .theme-monochrome._sepia-accent input[type=number].filled, .theme-monochrome._sepia-accent input[type=date].filled, .theme-monochrome._sepia-accent input[type=tel].filled, .theme-monochrome._sepia-accent textarea.filled, .theme-monochrome._sepia-accent select.filled, .theme-monochrome._sepia-accent .select-label.filled {
      border-bottom-color: #472312 !important; }
    input[type=text].filled + .form__label, input[type=email].filled + .form__label, input[type=password].filled + .form__label, input[type=number].filled + .form__label, input[type=date].filled + .form__label, input[type=tel].filled + .form__label, textarea.filled + .form__label, select.filled + .form__label, .select-label.filled + .form__label {
      font-size: 1.4rem;
      line-height: 1.42857em;
      top: 0.6rem; }
    input[type=text].filled:focus, input[type=email].filled:focus, input[type=password].filled:focus, input[type=number].filled:focus, input[type=date].filled:focus, input[type=tel].filled:focus, textarea.filled:focus, select.filled:focus, .select-label.filled:focus {
      border-bottom-color: #f26123; }
      .theme-monochrome._black-accent input[type=text].filled:focus, .theme-monochrome._black-accent input[type=email].filled:focus, .theme-monochrome._black-accent input[type=password].filled:focus, .theme-monochrome._black-accent input[type=number].filled:focus, .theme-monochrome._black-accent input[type=date].filled:focus, .theme-monochrome._black-accent input[type=tel].filled:focus, .theme-monochrome._black-accent textarea.filled:focus, .theme-monochrome._black-accent select.filled:focus, .theme-monochrome._black-accent .select-label.filled:focus {
        border-bottom-color: #000 !important; }
      .theme-monochrome._white-accent input[type=text].filled:focus, .theme-monochrome._white-accent input[type=email].filled:focus, .theme-monochrome._white-accent input[type=password].filled:focus, .theme-monochrome._white-accent input[type=number].filled:focus, .theme-monochrome._white-accent input[type=date].filled:focus, .theme-monochrome._white-accent input[type=tel].filled:focus, .theme-monochrome._white-accent textarea.filled:focus, .theme-monochrome._white-accent select.filled:focus, .theme-monochrome._white-accent .select-label.filled:focus {
        border-bottom-color: #fff !important; }
      .theme-monochrome._sepia-accent input[type=text].filled:focus, .theme-monochrome._sepia-accent input[type=email].filled:focus, .theme-monochrome._sepia-accent input[type=password].filled:focus, .theme-monochrome._sepia-accent input[type=number].filled:focus, .theme-monochrome._sepia-accent input[type=date].filled:focus, .theme-monochrome._sepia-accent input[type=tel].filled:focus, .theme-monochrome._sepia-accent textarea.filled:focus, .theme-monochrome._sepia-accent select.filled:focus, .theme-monochrome._sepia-accent .select-label.filled:focus {
        border-bottom-color: #472312 !important; }
  input[type=text]:-webkit-autofill, input[type=email]:-webkit-autofill, input[type=password]:-webkit-autofill, input[type=number]:-webkit-autofill, input[type=date]:-webkit-autofill, input[type=tel]:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill, .select-label:-webkit-autofill {
    border-bottom-color: #58595b;
    background-color: white;
    -webkit-box-shadow: inset 0 0 0 50px #fff;
    filter: none; }
    .theme-monochrome._black-accent input[type=text]:-webkit-autofill, .theme-monochrome._black-accent input[type=email]:-webkit-autofill, .theme-monochrome._black-accent input[type=password]:-webkit-autofill, .theme-monochrome._black-accent input[type=number]:-webkit-autofill, .theme-monochrome._black-accent input[type=date]:-webkit-autofill, .theme-monochrome._black-accent input[type=tel]:-webkit-autofill, .theme-monochrome._black-accent textarea:-webkit-autofill, .theme-monochrome._black-accent select:-webkit-autofill, .theme-monochrome._black-accent .select-label:-webkit-autofill {
      border-bottom-color: #000 !important; }
    .theme-monochrome._white-accent input[type=text]:-webkit-autofill, .theme-monochrome._white-accent input[type=email]:-webkit-autofill, .theme-monochrome._white-accent input[type=password]:-webkit-autofill, .theme-monochrome._white-accent input[type=number]:-webkit-autofill, .theme-monochrome._white-accent input[type=date]:-webkit-autofill, .theme-monochrome._white-accent input[type=tel]:-webkit-autofill, .theme-monochrome._white-accent textarea:-webkit-autofill, .theme-monochrome._white-accent select:-webkit-autofill, .theme-monochrome._white-accent .select-label:-webkit-autofill {
      border-bottom-color: #fff !important; }
    .theme-monochrome._sepia-accent input[type=text]:-webkit-autofill, .theme-monochrome._sepia-accent input[type=email]:-webkit-autofill, .theme-monochrome._sepia-accent input[type=password]:-webkit-autofill, .theme-monochrome._sepia-accent input[type=number]:-webkit-autofill, .theme-monochrome._sepia-accent input[type=date]:-webkit-autofill, .theme-monochrome._sepia-accent input[type=tel]:-webkit-autofill, .theme-monochrome._sepia-accent textarea:-webkit-autofill, .theme-monochrome._sepia-accent select:-webkit-autofill, .theme-monochrome._sepia-accent .select-label:-webkit-autofill {
      border-bottom-color: #472312 !important; }
    .theme-monochrome._black-ground input[type=text]:-webkit-autofill, .theme-monochrome._black-ground input[type=email]:-webkit-autofill, .theme-monochrome._black-ground input[type=password]:-webkit-autofill, .theme-monochrome._black-ground input[type=number]:-webkit-autofill, .theme-monochrome._black-ground input[type=date]:-webkit-autofill, .theme-monochrome._black-ground input[type=tel]:-webkit-autofill, .theme-monochrome._black-ground textarea:-webkit-autofill, .theme-monochrome._black-ground select:-webkit-autofill, .theme-monochrome._black-ground .select-label:-webkit-autofill {
      background-color: #000 !important; }
    .theme-monochrome._white-ground input[type=text]:-webkit-autofill, .theme-monochrome._white-ground input[type=email]:-webkit-autofill, .theme-monochrome._white-ground input[type=password]:-webkit-autofill, .theme-monochrome._white-ground input[type=number]:-webkit-autofill, .theme-monochrome._white-ground input[type=date]:-webkit-autofill, .theme-monochrome._white-ground input[type=tel]:-webkit-autofill, .theme-monochrome._white-ground textarea:-webkit-autofill, .theme-monochrome._white-ground select:-webkit-autofill, .theme-monochrome._white-ground .select-label:-webkit-autofill {
      background-color: #fff !important; }
    .theme-monochrome._sepia-ground input[type=text]:-webkit-autofill, .theme-monochrome._sepia-ground input[type=email]:-webkit-autofill, .theme-monochrome._sepia-ground input[type=password]:-webkit-autofill, .theme-monochrome._sepia-ground input[type=number]:-webkit-autofill, .theme-monochrome._sepia-ground input[type=date]:-webkit-autofill, .theme-monochrome._sepia-ground input[type=tel]:-webkit-autofill, .theme-monochrome._sepia-ground textarea:-webkit-autofill, .theme-monochrome._sepia-ground select:-webkit-autofill, .theme-monochrome._sepia-ground .select-label:-webkit-autofill {
      background-color: #F5F5DC !important; }
    input[type=text]:-webkit-autofill + .form__label, input[type=email]:-webkit-autofill + .form__label, input[type=password]:-webkit-autofill + .form__label, input[type=number]:-webkit-autofill + .form__label, input[type=date]:-webkit-autofill + .form__label, input[type=tel]:-webkit-autofill + .form__label, textarea:-webkit-autofill + .form__label, select:-webkit-autofill + .form__label, .select-label:-webkit-autofill + .form__label {
      font-size: 1.4rem;
      line-height: 1.42857em;
      top: 0.6rem; }
    input[type=text]:-webkit-autofill:focus, input[type=email]:-webkit-autofill:focus, input[type=password]:-webkit-autofill:focus, input[type=number]:-webkit-autofill:focus, input[type=date]:-webkit-autofill:focus, input[type=tel]:-webkit-autofill:focus, textarea:-webkit-autofill:focus, select:-webkit-autofill:focus, .select-label:-webkit-autofill:focus {
      border-bottom-color: #f26123; }
      .theme-monochrome._black-accent input[type=text]:-webkit-autofill:focus, .theme-monochrome._black-accent input[type=email]:-webkit-autofill:focus, .theme-monochrome._black-accent input[type=password]:-webkit-autofill:focus, .theme-monochrome._black-accent input[type=number]:-webkit-autofill:focus, .theme-monochrome._black-accent input[type=date]:-webkit-autofill:focus, .theme-monochrome._black-accent input[type=tel]:-webkit-autofill:focus, .theme-monochrome._black-accent textarea:-webkit-autofill:focus, .theme-monochrome._black-accent select:-webkit-autofill:focus, .theme-monochrome._black-accent .select-label:-webkit-autofill:focus {
        border-bottom-color: #000 !important; }
      .theme-monochrome._white-accent input[type=text]:-webkit-autofill:focus, .theme-monochrome._white-accent input[type=email]:-webkit-autofill:focus, .theme-monochrome._white-accent input[type=password]:-webkit-autofill:focus, .theme-monochrome._white-accent input[type=number]:-webkit-autofill:focus, .theme-monochrome._white-accent input[type=date]:-webkit-autofill:focus, .theme-monochrome._white-accent input[type=tel]:-webkit-autofill:focus, .theme-monochrome._white-accent textarea:-webkit-autofill:focus, .theme-monochrome._white-accent select:-webkit-autofill:focus, .theme-monochrome._white-accent .select-label:-webkit-autofill:focus {
        border-bottom-color: #fff !important; }
      .theme-monochrome._sepia-accent input[type=text]:-webkit-autofill:focus, .theme-monochrome._sepia-accent input[type=email]:-webkit-autofill:focus, .theme-monochrome._sepia-accent input[type=password]:-webkit-autofill:focus, .theme-monochrome._sepia-accent input[type=number]:-webkit-autofill:focus, .theme-monochrome._sepia-accent input[type=date]:-webkit-autofill:focus, .theme-monochrome._sepia-accent input[type=tel]:-webkit-autofill:focus, .theme-monochrome._sepia-accent textarea:-webkit-autofill:focus, .theme-monochrome._sepia-accent select:-webkit-autofill:focus, .theme-monochrome._sepia-accent .select-label:-webkit-autofill:focus {
        border-bottom-color: #472312 !important; }
  .hero input[type=text], .hero input[type=email], .hero input[type=password], .hero input[type=number], .hero input[type=date], .hero input[type=tel], .hero textarea, .hero select, .hero .select-label {
    background: transparent; }

/*textarea {
  border: 2px solid #D4D4D4;
  height: 20rem;
  box-sizing: border-box;
  font-size: 1.6rem;
  line-height: 2.4rem;
  padding: 3.2rem 0 0.8rem 0;
  transition: border-color 0.3s;
  resize: none;
  &::-webkit-input-placeholder {
    opacity: 0;
    line-height: 2.4rem;
  }
  &:-moz-placeholder {
    opacity: 0;
    line-height: 2.4rem;
  }
  &:-ms-input-placeholder {
    opacity: 0;
    line-height: 2.4rem;
  }
  &:focus {
    border-color: $cBrand;
  }
  &.textarea--smaller {
    height: 12rem;
  }
}*/
input[type=date] {
  color: #fff; }
  .theme-monochrome._black-ground input[type=date] {
    color: #000 !important; }
  .theme-monochrome._white-ground input[type=date] {
    color: #fff !important; }
  .theme-monochrome._sepia-ground input[type=date] {
    color: #F5F5DC !important; }
  input[type=date].filled {
    color: #58595b; }
    .theme-monochrome._black-ground input[type=date].filled {
      color: #000 !important; }
    .theme-monochrome._white-ground input[type=date].filled {
      color: #fff !important; }
    .theme-monochrome._sepia-ground input[type=date].filled {
      color: #F5F5DC !important; }

.input__icon {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 28px; }
  .input__icon svg {
    display: block; }

.input__icon--interact:hover {
  cursor: pointer; }

select {
  opacity: 0; }
  select:focus ~ .select-label {
    border-bottom-color: #f26123; }
    .theme-monochrome._black-accent select:focus ~ .select-label {
      border-bottom-color: #000 !important; }
    .theme-monochrome._white-accent select:focus ~ .select-label {
      border-bottom-color: #fff !important; }
    .theme-monochrome._sepia-accent select:focus ~ .select-label {
      border-bottom-color: #472312 !important; }
  select ~ .form__label {
    right: 0;
    padding-bottom: 0; }
  select.filled ~ .form__label {
    font-size: 1.4rem;
    line-height: 1.42857em;
    top: 0.6rem; }
  select.filled ~ .select-label {
    color: #58595b;
    border-bottom-color: #58595b; }
    .theme-monochrome._black-accent select.filled ~ .select-label {
      color: #000 !important; }
    .theme-monochrome._white-accent select.filled ~ .select-label {
      color: #fff !important; }
    .theme-monochrome._sepia-accent select.filled ~ .select-label {
      color: #472312 !important; }
    .theme-monochrome._black-accent select.filled ~ .select-label {
      border-bottom-color: #000 !important; }
    .theme-monochrome._white-accent select.filled ~ .select-label {
      border-bottom-color: #fff !important; }
    .theme-monochrome._sepia-accent select.filled ~ .select-label {
      border-bottom-color: #472312 !important; }

.select-label {
  position: absolute;
  z-index: 0;
  top: 0;
  display: block;
  width: 100%;
  transition: color 0.2s;
  pointer-events: none; }
  .select-label.dis {
    color: rgba(100, 102, 105, 0.2); }
    .theme-monochrome._black-accent .select-label.dis {
      color: #000 !important; }
    .theme-monochrome._white-accent .select-label.dis {
      color: #fff !important; }
    .theme-monochrome._sepia-accent .select-label.dis {
      color: #472312 !important; }
  .select-label:after {
    content: '';
    width: 32px;
    height: 1.5em;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSI1IiB2aWV3Qm94PSIwIDAgMTAgNSI+ICA8cG9seWxpbmUgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjRjM2QzIxIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMiIgcG9pbnRzPSIyMCAxNSAxNiAxOCAxMiAxNSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTExIC0xNCkiLz48L3N2Zz4=);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    position: absolute;
    right: 0;
    top: calc(3.2rem); }

@media screen and (max-width: 740px) {
  select {
    overflow: hidden; } }

a.btn,
span.btn,
label.btn,
button.btn, .policy-name__text[contenteditable=true] + button {
  font-family: inherit;
  display: inline-block;
  box-sizing: border-box;
  background: #f26123 linear-gradient(-180deg, rgba(241, 241, 241, 0.1) 0%, rgba(230, 230, 230, 0.1) 100%);
  border-radius: 4px;
  text-align: center;
  font-size: 1.6rem;
  line-height: 1.5em;
  color: #ffffff;
  letter-spacing: -0.5px;
  padding: 12px 24px;
  transition: background-color 0.3s, background-image 0.3s, color 0.3s;
  -webkit-appearance: none;
  border: none;
  white-space: nowrap;
  position: relative; }
  .theme-monochrome._black-accent a.btn,
  .theme-monochrome._black-accent span.btn,
  .theme-monochrome._black-accent label.btn,
  .theme-monochrome._black-accent button.btn, .theme-monochrome._black-accent .policy-name__text[contenteditable=true] + button {
    background: #000 !important; }
  .theme-monochrome._white-accent a.btn,
  .theme-monochrome._white-accent span.btn,
  .theme-monochrome._white-accent label.btn,
  .theme-monochrome._white-accent button.btn, .theme-monochrome._white-accent .policy-name__text[contenteditable=true] + button {
    background: #fff !important; }
  .theme-monochrome._sepia-accent a.btn,
  .theme-monochrome._sepia-accent span.btn,
  .theme-monochrome._sepia-accent label.btn,
  .theme-monochrome._sepia-accent button.btn, .theme-monochrome._sepia-accent .policy-name__text[contenteditable=true] + button {
    background: #472312 !important; }
  .theme-monochrome._black-ground a.btn,
  .theme-monochrome._black-ground span.btn,
  .theme-monochrome._black-ground label.btn,
  .theme-monochrome._black-ground button.btn, .theme-monochrome._black-ground .policy-name__text[contenteditable=true] + button {
    color: #000 !important; }
  .theme-monochrome._white-ground a.btn,
  .theme-monochrome._white-ground span.btn,
  .theme-monochrome._white-ground label.btn,
  .theme-monochrome._white-ground button.btn, .theme-monochrome._white-ground .policy-name__text[contenteditable=true] + button {
    color: #fff !important; }
  .theme-monochrome._sepia-ground a.btn,
  .theme-monochrome._sepia-ground span.btn,
  .theme-monochrome._sepia-ground label.btn,
  .theme-monochrome._sepia-ground button.btn, .theme-monochrome._sepia-ground .policy-name__text[contenteditable=true] + button {
    color: #F5F5DC !important; }
  .big-font-size a.btn,
  .big-font-size span.btn,
  .big-font-size label.btn,
  .big-font-size button.btn, .big-font-size .policy-name__text[contenteditable=true] + button {
    white-space: normal; }
  a.btn:hover,
  span.btn:hover,
  label.btn:hover,
  button.btn:hover, .policy-name__text[contenteditable=true] + button:hover {
    cursor: pointer;
    background: #f26123 linear-gradient(-180deg, rgba(241, 241, 241, 0) 0%, rgba(230, 230, 230, 0) 100%); }
    .theme-monochrome._black-accent a.btn:hover,
    .theme-monochrome._black-accent span.btn:hover,
    .theme-monochrome._black-accent label.btn:hover,
    .theme-monochrome._black-accent button.btn:hover, .theme-monochrome._black-accent .policy-name__text[contenteditable=true] + button:hover {
      background: #000 !important; }
    .theme-monochrome._white-accent a.btn:hover,
    .theme-monochrome._white-accent span.btn:hover,
    .theme-monochrome._white-accent label.btn:hover,
    .theme-monochrome._white-accent button.btn:hover, .theme-monochrome._white-accent .policy-name__text[contenteditable=true] + button:hover {
      background: #fff !important; }
    .theme-monochrome._sepia-accent a.btn:hover,
    .theme-monochrome._sepia-accent span.btn:hover,
    .theme-monochrome._sepia-accent label.btn:hover,
    .theme-monochrome._sepia-accent button.btn:hover, .theme-monochrome._sepia-accent .policy-name__text[contenteditable=true] + button:hover {
      background: #472312 !important; }
  a.btn:after,
  span.btn:after,
  label.btn:after,
  button.btn:after, .policy-name__text[contenteditable=true] + button:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 32px;
    height: 32px;
    background: transparent url("/static/img/loader_white2.png") left center no-repeat;
    animation: play_loader 0.6s steps(18) infinite;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s, visibility 0.2s; }

a.btn.btn--secondary,
span.btn.btn--secondary,
label.btn.btn--secondary,
button.btn.btn--secondary {
  background: #F2F2F2 linear-gradient(-180deg, rgba(242, 242, 242, 0.1) 0%, rgba(242, 242, 242, 0.1) 100%);
  color: #58595b; }
  .theme-monochrome._black-accent a.btn.btn--secondary, .theme-monochrome._black-accent
  span.btn.btn--secondary, .theme-monochrome._black-accent
  label.btn.btn--secondary, .theme-monochrome._black-accent
  button.btn.btn--secondary {
    color: #000 !important; }
  .theme-monochrome._white-accent a.btn.btn--secondary, .theme-monochrome._white-accent
  span.btn.btn--secondary, .theme-monochrome._white-accent
  label.btn.btn--secondary, .theme-monochrome._white-accent
  button.btn.btn--secondary {
    color: #fff !important; }
  .theme-monochrome._sepia-accent a.btn.btn--secondary, .theme-monochrome._sepia-accent
  span.btn.btn--secondary, .theme-monochrome._sepia-accent
  label.btn.btn--secondary, .theme-monochrome._sepia-accent
  button.btn.btn--secondary {
    color: #472312 !important; }
  .theme-monochrome._black-ground a.btn.btn--secondary, .theme-monochrome._black-ground
  span.btn.btn--secondary, .theme-monochrome._black-ground
  label.btn.btn--secondary, .theme-monochrome._black-ground
  button.btn.btn--secondary {
    background: #000 !important; }
  .theme-monochrome._white-ground a.btn.btn--secondary, .theme-monochrome._white-ground
  span.btn.btn--secondary, .theme-monochrome._white-ground
  label.btn.btn--secondary, .theme-monochrome._white-ground
  button.btn.btn--secondary {
    background: #fff !important; }
  .theme-monochrome._sepia-ground a.btn.btn--secondary, .theme-monochrome._sepia-ground
  span.btn.btn--secondary, .theme-monochrome._sepia-ground
  label.btn.btn--secondary, .theme-monochrome._sepia-ground
  button.btn.btn--secondary {
    background: #F5F5DC !important; }
  a.btn.btn--secondary:hover,
  span.btn.btn--secondary:hover,
  label.btn.btn--secondary:hover,
  button.btn.btn--secondary:hover {
    background: #E6E6E6 linear-gradient(-180deg, rgba(242, 242, 242, 0) 0%, rgba(242, 242, 242, 0) 100%); }
    .theme-monochrome._black-ground a.btn.btn--secondary:hover, .theme-monochrome._black-ground
    span.btn.btn--secondary:hover, .theme-monochrome._black-ground
    label.btn.btn--secondary:hover, .theme-monochrome._black-ground
    button.btn.btn--secondary:hover {
      background: #000 !important; }
    .theme-monochrome._white-ground a.btn.btn--secondary:hover, .theme-monochrome._white-ground
    span.btn.btn--secondary:hover, .theme-monochrome._white-ground
    label.btn.btn--secondary:hover, .theme-monochrome._white-ground
    button.btn.btn--secondary:hover {
      background: #fff !important; }
    .theme-monochrome._sepia-ground a.btn.btn--secondary:hover, .theme-monochrome._sepia-ground
    span.btn.btn--secondary:hover, .theme-monochrome._sepia-ground
    label.btn.btn--secondary:hover, .theme-monochrome._sepia-ground
    button.btn.btn--secondary:hover {
      background: #F5F5DC !important; }
  a.btn.btn--secondary.is-loading:hover,
  span.btn.btn--secondary.is-loading:hover,
  label.btn.btn--secondary.is-loading:hover,
  button.btn.btn--secondary.is-loading:hover {
    background: #F2F2F2 linear-gradient(-180deg, rgba(242, 242, 242, 0.1) 0%, rgba(242, 242, 242, 0.1) 100%); }
    .theme-monochrome._black-ground a.btn.btn--secondary.is-loading:hover, .theme-monochrome._black-ground
    span.btn.btn--secondary.is-loading:hover, .theme-monochrome._black-ground
    label.btn.btn--secondary.is-loading:hover, .theme-monochrome._black-ground
    button.btn.btn--secondary.is-loading:hover {
      background: #000 !important; }
    .theme-monochrome._white-ground a.btn.btn--secondary.is-loading:hover, .theme-monochrome._white-ground
    span.btn.btn--secondary.is-loading:hover, .theme-monochrome._white-ground
    label.btn.btn--secondary.is-loading:hover, .theme-monochrome._white-ground
    button.btn.btn--secondary.is-loading:hover {
      background: #fff !important; }
    .theme-monochrome._sepia-ground a.btn.btn--secondary.is-loading:hover, .theme-monochrome._sepia-ground
    span.btn.btn--secondary.is-loading:hover, .theme-monochrome._sepia-ground
    label.btn.btn--secondary.is-loading:hover, .theme-monochrome._sepia-ground
    button.btn.btn--secondary.is-loading:hover {
      background: #F5F5DC !important; }
  a.btn.btn--secondary:after,
  span.btn.btn--secondary:after,
  label.btn.btn--secondary:after,
  button.btn.btn--secondary:after {
    background: transparent url("/static/img/loader_white2.png") left center no-repeat;
    animation: play_loader 0.6s steps(18) infinite; }

a.btn.btn--iconic,
span.btn.btn--iconic,
label.btn.btn--iconic,
button.btn.btn--iconic {
  height: 48px;
  width: 48px;
  text-align: center;
  line-height: 4.8rem;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0; }
  a.btn.btn--iconic + .btn--iconic,
  span.btn.btn--iconic + .btn--iconic,
  label.btn.btn--iconic + .btn--iconic,
  button.btn.btn--iconic + .btn--iconic {
    margin-left: 8px; }

a.btn svg,
span.btn svg,
label.btn svg,
button.btn svg {
  display: inline-block;
  vertical-align: middle;
  margin-top: -2px; }
  a.btn svg + span,
  span.btn svg + span,
  label.btn svg + span,
  button.btn svg + span {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px; }

a.btn.is-loading,
span.btn.is-loading,
label.btn.is-loading,
button.btn.is-loading {
  color: transparent; }
  a.btn.is-loading:hover,
  span.btn.is-loading:hover,
  label.btn.is-loading:hover,
  button.btn.is-loading:hover {
    color: transparent;
    background: #f26123 linear-gradient(-180deg, rgba(241, 241, 241, 0.1) 0%, rgba(230, 230, 230, 0.1) 100%);
    cursor: default; }
    .theme-monochrome._black-accent a.btn.is-loading:hover, .theme-monochrome._black-accent
    span.btn.is-loading:hover, .theme-monochrome._black-accent
    label.btn.is-loading:hover, .theme-monochrome._black-accent
    button.btn.is-loading:hover {
      background: #000 !important; }
    .theme-monochrome._white-accent a.btn.is-loading:hover, .theme-monochrome._white-accent
    span.btn.is-loading:hover, .theme-monochrome._white-accent
    label.btn.is-loading:hover, .theme-monochrome._white-accent
    button.btn.is-loading:hover {
      background: #fff !important; }
    .theme-monochrome._sepia-accent a.btn.is-loading:hover, .theme-monochrome._sepia-accent
    span.btn.is-loading:hover, .theme-monochrome._sepia-accent
    label.btn.is-loading:hover, .theme-monochrome._sepia-accent
    button.btn.is-loading:hover {
      background: #472312 !important; }
  a.btn.is-loading:after,
  span.btn.is-loading:after,
  label.btn.is-loading:after,
  button.btn.is-loading:after {
    opacity: 1;
    visibility: visible; }

a.btn:disabled, a.btn.disabled,
span.btn:disabled,
span.btn.disabled,
label.btn:disabled,
label.btn.disabled,
button.btn:disabled,
button.btn.disabled {
  background: #CCCCCC;
  color: rgba(255, 255, 255, 0.25);
  letter-spacing: -0.5px;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.5);
  pointer-events: none; }
  .theme-monochrome._black-ground a.btn:disabled, .theme-monochrome._black-ground a.btn.disabled, .theme-monochrome._black-ground
  span.btn:disabled, .theme-monochrome._black-ground
  span.btn.disabled, .theme-monochrome._black-ground
  label.btn:disabled, .theme-monochrome._black-ground
  label.btn.disabled, .theme-monochrome._black-ground
  button.btn:disabled, .theme-monochrome._black-ground
  button.btn.disabled {
    background: #000 !important; }
  .theme-monochrome._white-ground a.btn:disabled, .theme-monochrome._white-ground a.btn.disabled, .theme-monochrome._white-ground
  span.btn:disabled, .theme-monochrome._white-ground
  span.btn.disabled, .theme-monochrome._white-ground
  label.btn:disabled, .theme-monochrome._white-ground
  label.btn.disabled, .theme-monochrome._white-ground
  button.btn:disabled, .theme-monochrome._white-ground
  button.btn.disabled {
    background: #fff !important; }
  .theme-monochrome._sepia-ground a.btn:disabled, .theme-monochrome._sepia-ground a.btn.disabled, .theme-monochrome._sepia-ground
  span.btn:disabled, .theme-monochrome._sepia-ground
  span.btn.disabled, .theme-monochrome._sepia-ground
  label.btn:disabled, .theme-monochrome._sepia-ground
  label.btn.disabled, .theme-monochrome._sepia-ground
  button.btn:disabled, .theme-monochrome._sepia-ground
  button.btn.disabled {
    background: #F5F5DC !important; }
  .theme-monochrome._black-ground a.btn:disabled, .theme-monochrome._black-ground a.btn.disabled, .theme-monochrome._black-ground
  span.btn:disabled, .theme-monochrome._black-ground
  span.btn.disabled, .theme-monochrome._black-ground
  label.btn:disabled, .theme-monochrome._black-ground
  label.btn.disabled, .theme-monochrome._black-ground
  button.btn:disabled, .theme-monochrome._black-ground
  button.btn.disabled {
    color: #000 !important; }
  .theme-monochrome._white-ground a.btn:disabled, .theme-monochrome._white-ground a.btn.disabled, .theme-monochrome._white-ground
  span.btn:disabled, .theme-monochrome._white-ground
  span.btn.disabled, .theme-monochrome._white-ground
  label.btn:disabled, .theme-monochrome._white-ground
  label.btn.disabled, .theme-monochrome._white-ground
  button.btn:disabled, .theme-monochrome._white-ground
  button.btn.disabled {
    color: #fff !important; }
  .theme-monochrome._sepia-ground a.btn:disabled, .theme-monochrome._sepia-ground a.btn.disabled, .theme-monochrome._sepia-ground
  span.btn:disabled, .theme-monochrome._sepia-ground
  span.btn.disabled, .theme-monochrome._sepia-ground
  label.btn:disabled, .theme-monochrome._sepia-ground
  label.btn.disabled, .theme-monochrome._sepia-ground
  button.btn:disabled, .theme-monochrome._sepia-ground
  button.btn.disabled {
    color: #F5F5DC !important; }
  a.btn:disabled:hover, a.btn.disabled:hover,
  span.btn:disabled:hover,
  span.btn.disabled:hover,
  label.btn:disabled:hover,
  label.btn.disabled:hover,
  button.btn:disabled:hover,
  button.btn.disabled:hover {
    background: #CCCCCC; }
    .theme-monochrome._black-ground a.btn:disabled:hover, .theme-monochrome._black-ground a.btn.disabled:hover, .theme-monochrome._black-ground
    span.btn:disabled:hover, .theme-monochrome._black-ground
    span.btn.disabled:hover, .theme-monochrome._black-ground
    label.btn:disabled:hover, .theme-monochrome._black-ground
    label.btn.disabled:hover, .theme-monochrome._black-ground
    button.btn:disabled:hover, .theme-monochrome._black-ground
    button.btn.disabled:hover {
      background: #000 !important; }
    .theme-monochrome._white-ground a.btn:disabled:hover, .theme-monochrome._white-ground a.btn.disabled:hover, .theme-monochrome._white-ground
    span.btn:disabled:hover, .theme-monochrome._white-ground
    span.btn.disabled:hover, .theme-monochrome._white-ground
    label.btn:disabled:hover, .theme-monochrome._white-ground
    label.btn.disabled:hover, .theme-monochrome._white-ground
    button.btn:disabled:hover, .theme-monochrome._white-ground
    button.btn.disabled:hover {
      background: #fff !important; }
    .theme-monochrome._sepia-ground a.btn:disabled:hover, .theme-monochrome._sepia-ground a.btn.disabled:hover, .theme-monochrome._sepia-ground
    span.btn:disabled:hover, .theme-monochrome._sepia-ground
    span.btn.disabled:hover, .theme-monochrome._sepia-ground
    label.btn:disabled:hover, .theme-monochrome._sepia-ground
    label.btn.disabled:hover, .theme-monochrome._sepia-ground
    button.btn:disabled:hover, .theme-monochrome._sepia-ground
    button.btn.disabled:hover {
      background: #F5F5DC !important; }

a.btn.btn--white,
span.btn.btn--white,
label.btn.btn--white,
button.btn.btn--white {
  background: #ffffff;
  color: #58595b; }
  .theme-monochrome._black-accent a.btn.btn--white, .theme-monochrome._black-accent
  span.btn.btn--white, .theme-monochrome._black-accent
  label.btn.btn--white, .theme-monochrome._black-accent
  button.btn.btn--white {
    color: #000 !important; }
  .theme-monochrome._white-accent a.btn.btn--white, .theme-monochrome._white-accent
  span.btn.btn--white, .theme-monochrome._white-accent
  label.btn.btn--white, .theme-monochrome._white-accent
  button.btn.btn--white {
    color: #fff !important; }
  .theme-monochrome._sepia-accent a.btn.btn--white, .theme-monochrome._sepia-accent
  span.btn.btn--white, .theme-monochrome._sepia-accent
  label.btn.btn--white, .theme-monochrome._sepia-accent
  button.btn.btn--white {
    color: #472312 !important; }
  .theme-monochrome._black-ground a.btn.btn--white, .theme-monochrome._black-ground
  span.btn.btn--white, .theme-monochrome._black-ground
  label.btn.btn--white, .theme-monochrome._black-ground
  button.btn.btn--white {
    background: #000 !important; }
  .theme-monochrome._white-ground a.btn.btn--white, .theme-monochrome._white-ground
  span.btn.btn--white, .theme-monochrome._white-ground
  label.btn.btn--white, .theme-monochrome._white-ground
  button.btn.btn--white {
    background: #fff !important; }
  .theme-monochrome._sepia-ground a.btn.btn--white, .theme-monochrome._sepia-ground
  span.btn.btn--white, .theme-monochrome._sepia-ground
  label.btn.btn--white, .theme-monochrome._sepia-ground
  button.btn.btn--white {
    background: #F5F5DC !important; }
  a.btn.btn--white:hover,
  span.btn.btn--white:hover,
  label.btn.btn--white:hover,
  button.btn.btn--white:hover {
    background: #f26123 linear-gradient(-180deg, rgba(241, 241, 241, 0) 0%, rgba(230, 230, 230, 0) 100%);
    color: #ffffff; }
    .theme-monochrome._black-accent a.btn.btn--white:hover, .theme-monochrome._black-accent
    span.btn.btn--white:hover, .theme-monochrome._black-accent
    label.btn.btn--white:hover, .theme-monochrome._black-accent
    button.btn.btn--white:hover {
      color: #000 !important; }
    .theme-monochrome._white-accent a.btn.btn--white:hover, .theme-monochrome._white-accent
    span.btn.btn--white:hover, .theme-monochrome._white-accent
    label.btn.btn--white:hover, .theme-monochrome._white-accent
    button.btn.btn--white:hover {
      color: #fff !important; }
    .theme-monochrome._sepia-accent a.btn.btn--white:hover, .theme-monochrome._sepia-accent
    span.btn.btn--white:hover, .theme-monochrome._sepia-accent
    label.btn.btn--white:hover, .theme-monochrome._sepia-accent
    button.btn.btn--white:hover {
      color: #472312 !important; }
    .theme-monochrome._black-ground a.btn.btn--white:hover, .theme-monochrome._black-ground
    span.btn.btn--white:hover, .theme-monochrome._black-ground
    label.btn.btn--white:hover, .theme-monochrome._black-ground
    button.btn.btn--white:hover {
      background: #000 !important; }
    .theme-monochrome._white-ground a.btn.btn--white:hover, .theme-monochrome._white-ground
    span.btn.btn--white:hover, .theme-monochrome._white-ground
    label.btn.btn--white:hover, .theme-monochrome._white-ground
    button.btn.btn--white:hover {
      background: #fff !important; }
    .theme-monochrome._sepia-ground a.btn.btn--white:hover, .theme-monochrome._sepia-ground
    span.btn.btn--white:hover, .theme-monochrome._sepia-ground
    label.btn.btn--white:hover, .theme-monochrome._sepia-ground
    button.btn.btn--white:hover {
      background: #F5F5DC !important; }

a.btn.hidden,
span.btn.hidden,
label.btn.hidden,
button.btn.hidden {
  opacity: 0;
  visibility: hidden; }

a.btn + br + .lk__action,
span.btn + br + .lk__action,
label.btn + br + .lk__action,
button.btn + br + .lk__action {
  margin-top: 16px; }

a.btn.btn--small,
span.btn.btn--small,
label.btn.btn--small,
button.btn.btn--small {
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-transform: initial;
  font-size: 1.6rem; }
  a.btn.btn--small + .btn--small,
  span.btn.btn--small + .btn--small,
  label.btn.btn--small + .btn--small,
  button.btn.btn--small + .btn--small {
    margin-left: 8px; }

a.btn._block,
span.btn._block,
label.btn._block,
button.btn._block {
  display: block; }

@media screen and (max-width: 600px) {
  .btn--form {
    width: 100%; } }

.btns {
  margin: -16px;
  margin-right: 0;
  margin-bottom: 0; }
  .btns > a.btn,
  .btns > span.btn,
  .btns > label.btn,
  .btns > button.btn {
    margin: 16px !important;
    margin-right: 0 !important;
    margin-bottom: 0 !important; }
  .btns--lk {
    padding: 32px 40px;
    margin: 0;
    background-color: #ffffff;
    border-radius: 8px;
    display: flex;
    flex-direction: column; }
    .theme-monochrome._black-ground .btns--lk {
      background-color: #000 !important; }
    .theme-monochrome._white-ground .btns--lk {
      background-color: #fff !important; }
    .theme-monochrome._sepia-ground .btns--lk {
      background-color: #F5F5DC !important; }
    .btns--lk > a.btn-new_secondary {
      margin: 8px 0 0 !important; }
      .btns--lk > a.btn-new_secondary:first-child {
        margin: 0 !important; }
    @media screen and (max-width: 500px) {
      .btns--lk {
        padding: 24px 16px; } }

.btn--add {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px; }
  .btn--add svg {
    display: block; }

.action-button {
  display: flex;
  align-items: center;
  margin-top: 16px;
  background: #ffffff;
  border-radius: 4px;
  padding: 12px 6px 12px 22px;
  transition: background-color 0.3s, color 0.3s; }
  .theme-monochrome._black-ground .action-button {
    background: #000 !important; }
  .theme-monochrome._white-ground .action-button {
    background: #fff !important; }
  .theme-monochrome._sepia-ground .action-button {
    background: #F5F5DC !important; }
  .action-button:hover {
    background: #fefefe; }
    .theme-monochrome._black-ground .action-button:hover {
      background: #000 !important; }
    .theme-monochrome._white-ground .action-button:hover {
      background: #fff !important; }
    .theme-monochrome._sepia-ground .action-button:hover {
      background: #F5F5DC !important; }
  .action-button svg {
    margin-right: 12px;
    display: block;
    transition: stroke 0.3s, fill 0.3s; }
  .action-button.disabled, .action-button:disabled {
    opacity: .5;
    pointer-events: none; }

.action-button--no-icon {
  padding-left: 0;
  text-align: center;
  justify-content: center; }

.action-button--fit-content {
  display: inline-flex;
  width: fit-content;
  width: auto;
  padding-left: 36px;
  padding-right: 36px; }

.action-button--primary {
  background: #f26123 linear-gradient(-180deg, rgba(241, 241, 241, 0.1) 0%, rgba(230, 230, 230, 0.1) 100%) !important;
  color: #ffffff; }
  .theme-monochrome._black-accent .action-button--primary {
    background: #000 !important; }
  .theme-monochrome._white-accent .action-button--primary {
    background: #fff !important; }
  .theme-monochrome._sepia-accent .action-button--primary {
    background: #472312 !important; }
  .theme-monochrome._black-ground .action-button--primary {
    color: #000 !important; }
  .theme-monochrome._white-ground .action-button--primary {
    color: #fff !important; }
  .theme-monochrome._sepia-ground .action-button--primary {
    color: #F5F5DC !important; }
  .action-button--primary:hover {
    background: #f26123 linear-gradient(-180deg, rgba(241, 241, 241, 0) 0%, rgba(230, 230, 230, 0) 100%) !important;
    color: #ffffff; }
    .theme-monochrome._black-accent .action-button--primary:hover {
      background: #000 !important; }
    .theme-monochrome._white-accent .action-button--primary:hover {
      background: #fff !important; }
    .theme-monochrome._sepia-accent .action-button--primary:hover {
      background: #472312 !important; }
    .theme-monochrome._black-ground .action-button--primary:hover {
      color: #000 !important; }
    .theme-monochrome._white-ground .action-button--primary:hover {
      color: #fff !important; }
    .theme-monochrome._sepia-ground .action-button--primary:hover {
      color: #F5F5DC !important; }

.action-button--secondary {
  background: transparent;
  color: #58595b; }
  .theme-monochrome._black-accent .action-button--secondary {
    color: #000 !important; }
  .theme-monochrome._white-accent .action-button--secondary {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .action-button--secondary {
    color: #472312 !important; }
  .theme-monochrome._black-ground .action-button--secondary {
    background: #000 !important; }
  .theme-monochrome._white-ground .action-button--secondary {
    background: #fff !important; }
  .theme-monochrome._sepia-ground .action-button--secondary {
    background: #F5F5DC !important; }
  .action-button--secondary:hover {
    background: transparent;
    color: #f26123; }
    .theme-monochrome._black-accent .action-button--secondary:hover {
      color: #000 !important; }
    .theme-monochrome._white-accent .action-button--secondary:hover {
      color: #fff !important; }
    .theme-monochrome._sepia-accent .action-button--secondary:hover {
      color: #472312 !important; }
    .theme-monochrome._black-ground .action-button--secondary:hover {
      background: #000 !important; }
    .theme-monochrome._white-ground .action-button--secondary:hover {
      background: #fff !important; }
    .theme-monochrome._sepia-ground .action-button--secondary:hover {
      background: #F5F5DC !important; }

.input-button {
  -webkit-appearance: none;
  border: none;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  padding: 0;
  margin: 0;
  background: transparent;
  right: 0;
  top: 24px; }
  .input-button svg {
    display: block;
    margin-left: auto;
    margin-right: auto; }

@keyframes play_loader {
  100% {
    background-position: -576px; } }

@media screen and (max-width: 600px) {
  .btn--width {
    width: 100%; } }

.btn-new {
  width: 100%;
  display: inline-block;
  padding: 12px 14px;
  box-sizing: border-box;
  transition: background-color .3s,background-image .3s,color .3s;
  color: #ffffff;
  background: #E58324 linear-gradient(-180deg, rgba(241, 241, 241, 0.1), rgba(230, 230, 230, 0.1));
  font-size: 1.6rem;
  line-height: 1.5em;
  position: relative;
  cursor: pointer;
  border-radius: 4px;
  border: none;
  text-align: center; }
  .theme-monochrome._black-accent .btn-new {
    background: #000 !important; }
  .theme-monochrome._white-accent .btn-new {
    background: #fff !important; }
  .theme-monochrome._sepia-accent .btn-new {
    background: #472312 !important; }
  .theme-monochrome._black-ground .btn-new {
    color: #000 !important; }
  .theme-monochrome._white-ground .btn-new {
    color: #fff !important; }
  .theme-monochrome._sepia-ground .btn-new {
    color: #F5F5DC !important; }
  .btn-new:hover {
    background: #E58324 linear-gradient(-180deg, rgba(241, 241, 241, 0) 0%, rgba(230, 230, 230, 0) 100%);
    color: #ffffff; }
    .theme-monochrome._black-accent .btn-new:hover {
      background: #000 !important; }
    .theme-monochrome._white-accent .btn-new:hover {
      background: #fff !important; }
    .theme-monochrome._sepia-accent .btn-new:hover {
      background: #472312 !important; }
    .theme-monochrome._black-ground .btn-new:hover {
      color: #000 !important; }
    .theme-monochrome._white-ground .btn-new:hover {
      color: #fff !important; }
    .theme-monochrome._sepia-ground .btn-new:hover {
      color: #F5F5DC !important; }
  .btn-new:focus {
    background: #E58324 linear-gradient(-180deg, rgba(241, 241, 241, 0) 0%, rgba(230, 230, 230, 0) 100%);
    outline: none; }
    .theme-monochrome._black-accent .btn-new:focus {
      background: #000 !important; }
    .theme-monochrome._white-accent .btn-new:focus {
      background: #fff !important; }
    .theme-monochrome._sepia-accent .btn-new:focus {
      background: #472312 !important; }
  .btn-new::-moz-focus-inner {
    border: 0; }
  .btn-new:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 32px;
    height: 32px;
    background: transparent url("/static/img/loader_white2.png") left center no-repeat;
    animation: play_loader 0.6s steps(18) infinite;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s, visibility 0.2s; }
  .btn-new_secondary {
    background: #F7F7F7;
    color: #262626; }
    .theme-monochrome._black-accent .btn-new_secondary {
      background: #000 !important; }
    .theme-monochrome._white-accent .btn-new_secondary {
      background: #fff !important; }
    .theme-monochrome._sepia-accent .btn-new_secondary {
      background: #472312 !important; }
    .theme-monochrome._black-ground .btn-new_secondary {
      color: #000 !important; }
    .theme-monochrome._white-ground .btn-new_secondary {
      color: #fff !important; }
    .theme-monochrome._sepia-ground .btn-new_secondary {
      color: #F5F5DC !important; }
    .btn-new_secondary:hover {
      background: linear-gradient(180deg, rgba(241, 241, 241, 0.1) 0%, rgba(0, 0, 0, 0.02) 100%), #F7F7F7;
      color: #ED7634; }
      .theme-monochrome._black-ground .btn-new_secondary:hover {
        background: #000 !important; }
      .theme-monochrome._white-ground .btn-new_secondary:hover {
        background: #fff !important; }
      .theme-monochrome._sepia-ground .btn-new_secondary:hover {
        background: #F5F5DC !important; }
      .theme-monochrome._black-ground .btn-new_secondary:hover {
        color: #000 !important; }
      .theme-monochrome._white-ground .btn-new_secondary:hover {
        color: #fff !important; }
      .theme-monochrome._sepia-ground .btn-new_secondary:hover {
        color: #F5F5DC !important; }
    .btn-new_secondary:focus {
      background: linear-gradient(180deg, rgba(241, 241, 241, 0.1) 0%, rgba(0, 0, 0, 0.04) 100%), #F7F7F7;
      color: #ED7634;
      outline: none;
      border: 0; }
    .btn-new_secondary::-moz-focus-inner {
      border: 0; }
  .btn-new:disabled, .btn-new.disabled {
    background: #CCCCCC;
    color: rgba(255, 255, 255, 0.25);
    letter-spacing: -0.5px;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.5);
    pointer-events: none; }
    .theme-monochrome._black-ground .btn-new:disabled, .theme-monochrome._black-ground .btn-new.disabled {
      background: #000 !important; }
    .theme-monochrome._white-ground .btn-new:disabled, .theme-monochrome._white-ground .btn-new.disabled {
      background: #fff !important; }
    .theme-monochrome._sepia-ground .btn-new:disabled, .theme-monochrome._sepia-ground .btn-new.disabled {
      background: #F5F5DC !important; }
    .theme-monochrome._black-ground .btn-new:disabled, .theme-monochrome._black-ground .btn-new.disabled {
      color: #000 !important; }
    .theme-monochrome._white-ground .btn-new:disabled, .theme-monochrome._white-ground .btn-new.disabled {
      color: #fff !important; }
    .theme-monochrome._sepia-ground .btn-new:disabled, .theme-monochrome._sepia-ground .btn-new.disabled {
      color: #F5F5DC !important; }
    .btn-new:disabled:hover, .btn-new.disabled:hover {
      background: #CCCCCC; }
      .theme-monochrome._black-ground .btn-new:disabled:hover, .theme-monochrome._black-ground .btn-new.disabled:hover {
        background: #000 !important; }
      .theme-monochrome._white-ground .btn-new:disabled:hover, .theme-monochrome._white-ground .btn-new.disabled:hover {
        background: #fff !important; }
      .theme-monochrome._sepia-ground .btn-new:disabled:hover, .theme-monochrome._sepia-ground .btn-new.disabled:hover {
        background: #F5F5DC !important; }

.btn-mini {
  width: auto;
  padding: 8px 10px;
  font-size: 1.2rem;
  background-color: #f36c21; }

.btn--nt {
  border-radius: 15px !important; }

.form__checkbox {
  overflow: hidden;
  position: relative;
  transition: opacity 0.3s; }
  .form__checkbox + .form__checkbox {
    margin-top: 1.6rem; }
  .form__checkbox:disabled {
    opacity: 0.5; }
    .form__checkbox:disabled:after {
      display: none; }

input[type=checkbox] {
  width: 1px;
  height: 1px;
  opacity: 0.1;
  position: absolute;
  left: -1rem;
  z-index: -1; }

input[type=checkbox] + label {
  display: inline-block;
  min-height: 24px;
  padding-left: 34px;
  position: relative;
  font-size: 1.4rem;
  line-height: 1.71429em; }
  input[type=checkbox] + label:not(:first-of-type) {
    margin-left: 1.5em; }
  input[type=checkbox] + label:before {
    content: '';
    position: absolute;
    left: 0;
    width: 24px;
    height: 24px;
    background: #ffffff;
    border: 1px solid #D9D9D9;
    box-shadow: inset 0 -2px 0 0 #F2F2F2;
    border-radius: 3px;
    box-sizing: border-box;
    transition: background-color 0.3s, border-color 0.3s, box-shadow 0.3s; }
    .theme-monochrome._black-accent input[type=checkbox] + label:before {
      border-color: #000 !important; }
    .theme-monochrome._white-accent input[type=checkbox] + label:before {
      border-color: #fff !important; }
    .theme-monochrome._sepia-accent input[type=checkbox] + label:before {
      border-color: #472312 !important; }
  input[type=checkbox] + label:after {
    content: '';
    width: 15px;
    height: 10px;
    position: absolute;
    left: 4px;
    top: 6px;
    transform: translateY(-4px);
    opacity: 0;
    transition: transform 0.2s, opacity 0.2s;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDE0IDEwIj4gIDxwb2x5bGluZSBmaWxsPSJub25lIiBzdHJva2U9IiNGRkYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIiBwb2ludHM9IjAgNCA0LjU2MyA4IDEyIDAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEgMSkiLz48L3N2Zz4=); }
  input[type=checkbox] + label:hover {
    cursor: pointer; }

input[type=checkbox]:checked + label:before {
  background: #f26123;
  border-color: #f26123;
  box-shadow: inset 0 -3px 0 0 #f26123; }
  .theme-monochrome._black-accent input[type=checkbox]:checked + label:before {
    box-shadow: none;
    border-color: #000 !important; }
  .theme-monochrome._white-accent input[type=checkbox]:checked + label:before {
    box-shadow: none;
    border-color: #fff !important; }
  .theme-monochrome._sepia-accent input[type=checkbox]:checked + label:before {
    box-shadow: none;
    border-color: #472312 !important; }

input[type=checkbox]:checked + label:after {
  opacity: 1;
  transform: translateY(0); }

input[type=checkbox]:disabled + label:hover {
  cursor: default; }

input[type=checkbox]:disabled:checked + label:before {
  background: #D9D9D9;
  border: 1px solid #ffffff;
  box-shadow: none; }
  .theme-monochrome._black-ground input[type=checkbox]:disabled:checked + label:before {
    border-color: #000 !important; }
  .theme-monochrome._white-ground input[type=checkbox]:disabled:checked + label:before {
    border-color: #fff !important; }
  .theme-monochrome._sepia-ground input[type=checkbox]:disabled:checked + label:before {
    border-color: #F5F5DC !important; }

.form__radio {
  overflow: hidden;
  position: relative; }
  .form__radio + .form__radio {
    margin-top: 1.6rem; }

input[type=radio] {
  width: 1px;
  height: 1px;
  opacity: 0.1;
  position: absolute;
  left: -1rem;
  z-index: -1; }

input[type=radio] + label {
  display: inline-block;
  min-height: 24px;
  padding-left: 34px;
  position: relative;
  font-size: 1.4rem;
  line-height: 1.71429em; }
  input[type=radio] + label:not(:first-of-type) {
    margin-left: 1.5em; }
  input[type=radio] + label:before {
    content: '';
    position: absolute;
    left: 0;
    width: 24px;
    height: 24px;
    background: #ffffff;
    border: 1px solid #D9D9D9;
    box-shadow: inset 0 -2px 0 0 #F2F2F2;
    border-radius: 50%;
    box-sizing: border-box;
    transition: background-color 0.3s, border-color 0.3s, box-shadow 0.3s; }
    .theme-monochrome._black-ground input[type=radio] + label:before {
      background: #000 !important; }
    .theme-monochrome._white-ground input[type=radio] + label:before {
      background: #fff !important; }
    .theme-monochrome._sepia-ground input[type=radio] + label:before {
      background: #F5F5DC !important; }
    .theme-monochrome._black-ground input[type=radio] + label:before {
      border-color: #000 !important; }
    .theme-monochrome._white-ground input[type=radio] + label:before {
      border-color: #fff !important; }
    .theme-monochrome._sepia-ground input[type=radio] + label:before {
      border-color: #F5F5DC !important; }
  input[type=radio] + label:after {
    content: '';
    width: 8px;
    height: 8px;
    position: absolute;
    left: 8px;
    top: 8px;
    border-radius: 50%;
    transform-origin: 50% 50%;
    transform: scale(0.4);
    opacity: 0;
    transition: transform 0.2s, opacity 0.2s;
    background: #ffffff; }
    .theme-monochrome._black-ground input[type=radio] + label:after {
      background: #000 !important; }
    .theme-monochrome._white-ground input[type=radio] + label:after {
      background: #fff !important; }
    .theme-monochrome._sepia-ground input[type=radio] + label:after {
      background: #F5F5DC !important; }
  input[type=radio] + label:hover {
    cursor: pointer; }
  input[type=radio] + label a {
    border-bottom: 1px solid #5B5B5B; }
    .theme-monochrome._black-accent input[type=radio] + label a {
      border-bottom-color: #000 !important; }
    .theme-monochrome._white-accent input[type=radio] + label a {
      border-bottom-color: #fff !important; }
    .theme-monochrome._sepia-accent input[type=radio] + label a {
      border-bottom-color: #472312 !important; }

input[type=radio]:checked + label:before {
  background: #f26123;
  border-color: #f26123;
  box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.04); }
  .theme-monochrome._black-accent input[type=radio]:checked + label:before {
    background: #000 !important; }
  .theme-monochrome._white-accent input[type=radio]:checked + label:before {
    background: #fff !important; }
  .theme-monochrome._sepia-accent input[type=radio]:checked + label:before {
    background: #472312 !important; }
  .theme-monochrome._black-accent input[type=radio]:checked + label:before {
    border-color: #000 !important; }
  .theme-monochrome._white-accent input[type=radio]:checked + label:before {
    border-color: #fff !important; }
  .theme-monochrome._sepia-accent input[type=radio]:checked + label:before {
    border-color: #472312 !important; }

input[type=radio]:checked + label:after {
  opacity: 1;
  transform: scale(1); }

input[type=radio]:disabled + label:hover {
  cursor: default; }

input[type=radio]:disabled:checked + label:before {
  background: #D9D9D9;
  border: 1px solid #ffffff;
  box-shadow: none; }
  .theme-monochrome._black-ground input[type=radio]:disabled:checked + label:before {
    background: #000 !important; }
  .theme-monochrome._white-ground input[type=radio]:disabled:checked + label:before {
    background: #fff !important; }
  .theme-monochrome._sepia-ground input[type=radio]:disabled:checked + label:before {
    background: #F5F5DC !important; }
  .theme-monochrome._black-ground input[type=radio]:disabled:checked + label:before {
    border-color: #000 !important; }
  .theme-monochrome._white-ground input[type=radio]:disabled:checked + label:before {
    border-color: #fff !important; }
  .theme-monochrome._sepia-ground input[type=radio]:disabled:checked + label:before {
    border-color: #F5F5DC !important; }

.attachment-zone {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-left: -1.6rem;
  margin-right: -1.6rem; }

.attachment-item {
  font-size: 1.2rem;
  line-height: 1.33333em;
  width: calc((100% - 12.8rem) / 4);
  margin-left: 1.6rem;
  margin-right: 1.6rem;
  margin-top: 3.2rem; }
  .attachment-item .attachment-item__icon--error {
    display: none; }
  .attachment-item.dz-error .attachment-item__icon--error {
    display: block; }
  .attachment-item.dz-error .attachment-item__icon--done {
    display: none; }

.attachment-item--empty {
  width: calc(100% - 3.2rem);
  margin-top: 0; }

.attachment-item__label {
  display: inline-block;
  overflow: hidden;
  cursor: pointer; }
  .attachment-item__label [type=file] {
    position: absolute;
    width: 1px;
    height: 1px;
    outline: none;
    left: -10px;
    opacity: 0; }

.attachment-item__label--empty {
  display: flex;
  align-items: center;
  flex: 0 0 auto; }

.attachment-item__add {
  margin-left: 1.2rem;
  font-size: 1.6rem; }

.attachment-item_docs {
  border: none !important;
  background: none !important; }

.attachment-item__icon {
  display: flex; }
  .attachment-item__icon svg {
    display: block; }
  .attachment-item__icon img {
    display: block; }

.attachment-item__name {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis; }

.attachment-item__action {
  margin-top: 0.8rem; }

.attachment-item__filename {
  color: #7B7B7B; }
  .theme-monochrome._black-accent .attachment-item__filename {
    color: #000 !important; }
  .theme-monochrome._white-accent .attachment-item__filename {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .attachment-item__filename {
    color: #472312 !important; }
  .attachment-item__filename + .attachment-item__remove {
    margin-left: 1.2rem; }

.attachment-item__remove {
  color: #A0A0A0;
  transition: color 0.3s; }
  .theme-monochrome._black-accent .attachment-item__remove {
    color: #000 !important; }
  .theme-monochrome._white-accent .attachment-item__remove {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .attachment-item__remove {
    color: #472312 !important; }
  .attachment-item__remove:hover {
    color: #58595b; }
    .theme-monochrome._black-accent .attachment-item__remove:hover {
      color: #000 !important; }
    .theme-monochrome._white-accent .attachment-item__remove:hover {
      color: #fff !important; }
    .theme-monochrome._sepia-accent .attachment-item__remove:hover {
      color: #472312 !important; }

@media screen and (max-width: 740px) {
  .attachment-zone {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0; }
  .attachment-item {
    width: 100%;
    margin-left: 0;
    margin-right: 0; }
  .attachment-item__label {
    margin-bottom: 1.6rem; } }

.form__switcher {
  display: flex;
  align-items: center;
  overflow: hidden; }
  .form__switcher input[type=checkbox] {
    visibility: hidden; }
  .form__switcher input[type=checkbox] ~ label {
    padding-left: 0;
    font-size: 1.6rem;
    display: block;
    transition: color 0.3s; }
    .form__switcher input[type=checkbox] ~ label:before, .form__switcher input[type=checkbox] ~ label:after {
      display: none; }
    .form__switcher input[type=checkbox] ~ label:hover {
      cursor: pointer; }
  .form__switcher input[type=checkbox] ~ label {
    color: #58595b; }
    .theme-monochrome._black-accent .form__switcher input[type=checkbox] ~ label {
      color: #000 !important; }
    .theme-monochrome._white-accent .form__switcher input[type=checkbox] ~ label {
      color: #fff !important; }
    .theme-monochrome._sepia-accent .form__switcher input[type=checkbox] ~ label {
      color: #472312 !important; }
  .form__switcher input[type=checkbox] + label {
    color: #7B7B7B; }
    .theme-monochrome._black-accent .form__switcher input[type=checkbox] + label {
      color: #000 !important; }
    .theme-monochrome._white-accent .form__switcher input[type=checkbox] + label {
      color: #fff !important; }
    .theme-monochrome._sepia-accent .form__switcher input[type=checkbox] + label {
      color: #472312 !important; }
  .form__switcher input[type=checkbox]:checked ~ label {
    color: #7B7B7B; }
    .theme-monochrome._black-accent .form__switcher input[type=checkbox]:checked ~ label {
      color: #000 !important; }
    .theme-monochrome._white-accent .form__switcher input[type=checkbox]:checked ~ label {
      color: #fff !important; }
    .theme-monochrome._sepia-accent .form__switcher input[type=checkbox]:checked ~ label {
      color: #472312 !important; }
  .form__switcher input[type=checkbox]:checked + label {
    color: #58595b; }
    .theme-monochrome._black-accent .form__switcher input[type=checkbox]:checked + label {
      color: #000 !important; }
    .theme-monochrome._white-accent .form__switcher input[type=checkbox]:checked + label {
      color: #fff !important; }
    .theme-monochrome._sepia-accent .form__switcher input[type=checkbox]:checked + label {
      color: #472312 !important; }
  .form__switcher .form-switcher__switch {
    display: block;
    width: 4rem;
    height: 2.4rem;
    border-radius: 1.2rem;
    background: #ffffff;
    position: relative;
    transition: background-color 0.3s;
    margin-left: 0.8rem;
    margin-right: 0.8rem;
    border: 1px solid #D9D9D9;
    box-sizing: border-box; }
    .theme-monochrome._black-ground .form__switcher .form-switcher__switch {
      background: #000 !important; }
    .theme-monochrome._white-ground .form__switcher .form-switcher__switch {
      background: #fff !important; }
    .theme-monochrome._sepia-ground .form__switcher .form-switcher__switch {
      background: #F5F5DC !important; }
    .theme-monochrome._black-ground .form__switcher .form-switcher__switch {
      border-color: #000 !important; }
    .theme-monochrome._white-ground .form__switcher .form-switcher__switch {
      border-color: #fff !important; }
    .theme-monochrome._sepia-ground .form__switcher .form-switcher__switch {
      border-color: #F5F5DC !important; }
    .form__switcher .form-switcher__switch:before {
      content: '';
      width: 1.6rem;
      height: 1.6rem;
      box-sizing: border-box;
      background-color: #f26123;
      position: absolute;
      border-radius: 50%;
      left: 0.4rem;
      top: 0.3rem;
      transform: translateX(1.4rem);
      transition: transform 0.3s, background-color 0.3s; }
      .theme-monochrome._black-accent .form__switcher .form-switcher__switch:before {
        background-color: #000 !important; }
      .theme-monochrome._white-accent .form__switcher .form-switcher__switch:before {
        background-color: #fff !important; }
      .theme-monochrome._sepia-accent .form__switcher .form-switcher__switch:before {
        background-color: #472312 !important; }
    .form__switcher .form-switcher__switch:hover {
      cursor: pointer; }
  .form__switcher input[type=checkbox] ~ .form-switcher__switch:before {
    display: block; }
  .form__switcher input[type=checkbox]:checked ~ .form-switcher__switch:before {
    transform: translateX(0); }

.fs-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #F7F7F7;
  z-index: 1000;
  padding-top: 96px;
  text-transform: none; }
  .theme-monochrome._black-ground .fs-modal {
    background: #000 !important; }
  .theme-monochrome._white-ground .fs-modal {
    background: #fff !important; }
  .theme-monochrome._sepia-ground .fs-modal {
    background: #F5F5DC !important; }
  @media screen and (max-width: 800px) {
    .fs-modal {
      padding-top: 72px; } }
  .fs-modal .wrapper {
    height: 100%; }

.fs-modal-scroller {
  background: #FFFFFF;
  border-radius: 4px;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }
  .theme-monochrome._black-ground .fs-modal-scroller {
    background: #000 !important; }
  .theme-monochrome._white-ground .fs-modal-scroller {
    background: #fff !important; }
  .theme-monochrome._sepia-ground .fs-modal-scroller {
    background: #F5F5DC !important; }

.fs-modal-content {
  background: #FFFFFF;
  padding-top: 56px;
  margin-left: 72px;
  margin-right: 72px;
  position: relative; }
  .theme-monochrome._black-ground .fs-modal-content {
    background: #000 !important; }
  .theme-monochrome._white-ground .fs-modal-content {
    background: #fff !important; }
  .theme-monochrome._sepia-ground .fs-modal-content {
    background: #F5F5DC !important; }
  .big-font-size .fs-modal-content {
    margin-left: 10px;
    margin-right: 10px; }
  @media screen and (max-width: 800px) {
    .fs-modal-content {
      margin: 0;
      padding: 24px 16px 0; } }

.fs-modal-header {
  font-family: 'FFMetaWebProNormal', sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #262626;
  letter-spacing: -0.75px;
  text-transform: none;
  text-align: center;
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid rgba(160, 160, 160, 0.24);
  font-size: 6rem;
  line-height: 0.93333em; }
  .theme-monochrome._black-accent .fs-modal-header {
    color: #000 !important; }
  .theme-monochrome._white-accent .fs-modal-header {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .fs-modal-header {
    color: #472312 !important; }
  @media screen and (max-width: 800px) {
    .fs-modal-header {
      font-size: 3.4rem;
      line-height: 1.41176em; } }

.fs-modal-header--small {
  font-family: 'FFMetaWebProNormal', sans-serif;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -1px;
  color: #58595b;
  text-align: left;
  font-size: 4.8rem;
  line-height: 1em; }
  .theme-monochrome._black-accent .fs-modal-header--small {
    color: #000 !important; }
  .theme-monochrome._white-accent .fs-modal-header--small {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .fs-modal-header--small {
    color: #472312 !important; }

.fs-modal-header--center {
  text-align: center;
  font-size: 3.8rem; }

.fs-modal-close {
  position: absolute;
  width: 14px;
  height: 14px;
  left: 50%;
  top: 32px;
  padding: 14px;
  transform: translateX(-50%);
  border: 1px solid rgba(160, 160, 160, 0.25);
  border-radius: 50%;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDE0IDE0Ij48cGF0aCBkPSJNMTMgMUwxIDEzTTEgMWwxMiAxMiIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2U9IiMyNjI2MjYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIi8+PC9zdmc+);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  transition: transform 0.4s; }
  .theme-monochrome._black-ground .fs-modal-close {
    filter: invert(1);
    border-color: #000 !important; }
  .theme-monochrome._white-ground .fs-modal-close {
    filter: invert(1);
    border-color: #fff !important; }
  .theme-monochrome._sepia-ground .fs-modal-close {
    filter: invert(1);
    border-color: #F5F5DC !important; }
  @media screen and (max-width: 800px) {
    .fs-modal-close {
      top: 16px;
      width: 40px;
      height: 40px;
      box-sizing: border-box;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0); } }
  .fs-modal-close:hover {
    transform: translateX(-50%) rotate(180deg); }

.fs-modal-footer {
  padding-bottom: 48px; }

.sweet-modal .sweet-box-actions .sweet-action-close {
  transition: background-color .3s, color .3s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.sweet-modal-overlay {
  z-index: 9999999999 !important; }

.sweet-modal .sweet-box-actions .sweet-action-close:hover {
  background: #f26123 !important; }
  .theme-monochrome._black-ground .sweet-modal .sweet-box-actions .sweet-action-close:hover {
    background: #000 !important; }
  .theme-monochrome._white-ground .sweet-modal .sweet-box-actions .sweet-action-close:hover {
    background: #fff !important; }
  .theme-monochrome._sepia-ground .sweet-modal .sweet-box-actions .sweet-action-close:hover {
    background: #F5F5DC !important; }

.multiselect__option--highlight {
  background: #f26123 !important; }
  .theme-monochrome._black-accent .multiselect__option--highlight {
    color: #000 !important; }
  .theme-monochrome._white-accent .multiselect__option--highlight {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .multiselect__option--highlight {
    color: #472312 !important; }

.multiselect__option--highlight:after {
  background: #f26123 !important; }
  .theme-monochrome._black-accent .multiselect__option--highlight:after {
    color: #000 !important; }
  .theme-monochrome._white-accent .multiselect__option--highlight:after {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .multiselect__option--highlight:after {
    color: #472312 !important; }

.multiselect__tags {
  border-radius: 0;
  border: none;
  border-bottom: 2px solid #58595b;
  background: none;
  margin-top: -3px;
  padding: 8px 40px 0px 8px !important; }
  .theme-monochrome._black-accent .multiselect__tags {
    border-bottom-color: #000 !important; }
  .theme-monochrome._white-accent .multiselect__tags {
    border-bottom-color: #fff !important; }
  .theme-monochrome._sepia-accent .multiselect__tags {
    border-bottom-color: #472312 !important; }

.multiselect__tags:focus {
  border-bottom-color: #f26123; }
  .theme-monochrome._black-accent .multiselect__tags:focus {
    border-bottom-color: #000 !important; }
  .theme-monochrome._white-accent .multiselect__tags:focus {
    border-bottom-color: #fff !important; }
  .theme-monochrome._sepia-accent .multiselect__tags:focus {
    border-bottom-color: #472312 !important; }

.multiselect__single {
  background: none;
  font-family: 'FFMetaWebProMedium', sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #262626;
  margin-bottom: 2px;
  font-size: 1.6rem;
  line-height: 1.5em; }
  .theme-monochrome._black-accent .multiselect__single {
    color: #000 !important; }
  .theme-monochrome._white-accent .multiselect__single {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .multiselect__single {
    color: #472312 !important; }

.multiselect .filled {
  border-bottom: 2px solid #D4D4D4; }
  .theme-monochrome._black-ground .multiselect .filled {
    border-bottom-color: #000 !important; }
  .theme-monochrome._white-ground .multiselect .filled {
    border-bottom-color: #fff !important; }
  .theme-monochrome._sepia-ground .multiselect .filled {
    border-bottom-color: #F5F5DC !important; }
  .multiselect .filled + .form__label {
    top: 0.6rem;
    font-size: 1.4rem;
    line-height: 1.42857em; }
  .multiselect .filled:focus {
    border-bottom-color: #f26123; }
    .theme-monochrome._black-accent .multiselect .filled:focus {
      color: #000 !important; }
    .theme-monochrome._white-accent .multiselect .filled:focus {
      color: #fff !important; }
    .theme-monochrome._sepia-accent .multiselect .filled:focus {
      color: #472312 !important; }

.multiselect__input {
  padding: 0 !important; }

.multiselect__input:focus {
  border-bottom-color: transparent !important; }

.multiselect__content-wrapper {
  min-width: 100%;
  max-width: 400px;
  overflow-x: scroll;
  scrollbar-width: thin;
  font-size: 1.6rem; }

.popup .sweet-modal,
.popup .sweet-modal.is-alert {
  max-width: 100%;
  width: initial; }
  .theme-monochrome._black-ground .popup .sweet-modal, .theme-monochrome._black-ground
  .popup .sweet-modal.is-alert {
    background-color: #000 !important; }
  .theme-monochrome._white-ground .popup .sweet-modal, .theme-monochrome._white-ground
  .popup .sweet-modal.is-alert {
    background-color: #fff !important; }
  .theme-monochrome._sepia-ground .popup .sweet-modal, .theme-monochrome._sepia-ground
  .popup .sweet-modal.is-alert {
    background-color: #F5F5DC !important; }
  .popup .sweet-modal .sweet-box-actions .sweet-action-close,
  .popup .sweet-modal.is-alert .sweet-box-actions .sweet-action-close {
    border-radius: 50%;
    border: 1px solid #a0a0a0;
    width: 40px;
    height: 40px; }
    .theme-monochrome._black-accent .popup .sweet-modal .sweet-box-actions .sweet-action-close, .theme-monochrome._black-accent
    .popup .sweet-modal.is-alert .sweet-box-actions .sweet-action-close {
      border-color: #000 !important; }
    .theme-monochrome._white-accent .popup .sweet-modal .sweet-box-actions .sweet-action-close, .theme-monochrome._white-accent
    .popup .sweet-modal.is-alert .sweet-box-actions .sweet-action-close {
      border-color: #fff !important; }
    .theme-monochrome._sepia-accent .popup .sweet-modal .sweet-box-actions .sweet-action-close, .theme-monochrome._sepia-accent
    .popup .sweet-modal.is-alert .sweet-box-actions .sweet-action-close {
      border-color: #472312 !important; }
    .theme-monochrome._black-accent .popup .sweet-modal .sweet-box-actions .sweet-action-close svg *, .theme-monochrome._black-accent
    .popup .sweet-modal.is-alert .sweet-box-actions .sweet-action-close svg * {
      fill: #000 !important; }
    .theme-monochrome._white-accent .popup .sweet-modal .sweet-box-actions .sweet-action-close svg *, .theme-monochrome._white-accent
    .popup .sweet-modal.is-alert .sweet-box-actions .sweet-action-close svg * {
      fill: #fff !important; }
    .theme-monochrome._sepia-accent .popup .sweet-modal .sweet-box-actions .sweet-action-close svg *, .theme-monochrome._sepia-accent
    .popup .sweet-modal.is-alert .sweet-box-actions .sweet-action-close svg * {
      fill: #472312 !important; }
  .popup .sweet-modal .sweet-content,
  .popup .sweet-modal.is-alert .sweet-content {
    padding: 40px 0;
    text-align: initial; }

#app .sweet-modal {
  display: block;
  transform: none;
  position: relative;
  top: 0;
  left: 0;
  max-width: 80%;
  width: 100%;
  max-height: 100%;
  height: auto; }
  @media screen and (max-width: 600px) {
    #app .sweet-modal {
      width: auto;
      max-width: 100%; } }
  #app .sweet-modal-overlay {
    display: flex;
    justify-content: center;
    align-items: center; }

#app .message-modal--nt .sweet-modal {
  max-width: 300px;
  border-radius: 8px;
  border: 1px solid #828284; }
  #app .message-modal--nt .sweet-modal .sweet-box-actions {
    display: none; }
  #app .message-modal--nt .sweet-modal .btn--nt {
    margin-top: 16px; }
  #app .message-modal--nt .sweet-modal .sweet-content {
    padding-top: 32px;
    padding-bottom: 32px; }

.theme-monochrome._black-ground .sweet-modal {
  background-color: #000 !important; }

.theme-monochrome._white-ground .sweet-modal {
  background-color: #fff !important; }

.theme-monochrome._sepia-ground .sweet-modal {
  background-color: #F5F5DC !important; }

.product-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between; }
  .product-list__item {
    display: inline-block;
    width: 280px; }
    @media screen and (max-width: 590px) {
      .product-list__item:not(:first-child) {
        margin-top: 40px; } }
  .product-list__img {
    display: block;
    width: 280px;
    height: 280px;
    object-fit: cover; }
  .product-list__name {
    color: #262626;
    font-size: 24px;
    letter-spacing: -0.3px;
    line-height: 1.20833em; }
    .theme-monochrome._black-accent .product-list__name {
      color: #000 !important; }
    .theme-monochrome._white-accent .product-list__name {
      color: #fff !important; }
    .theme-monochrome._sepia-accent .product-list__name {
      color: #472312 !important; }
    .product-list__name:not(:first-child) {
      margin-top: 16px; }
  .product-list__description {
    color: #a0a0a0;
    font-size: 14px;
    line-height: 1.42857em; }
    .theme-monochrome._black-accent .product-list__description {
      color: #000 !important; }
    .theme-monochrome._white-accent .product-list__description {
      color: #fff !important; }
    .theme-monochrome._sepia-accent .product-list__description {
      color: #472312 !important; }
    .product-list__description:not(:first-child) {
      margin-top: 11px; }
  .product-list__actions {
    display: block;
    font-size: 17px; }
    .product-list__actions:not(:first-child) {
      margin-top: 8px; }

.courses-list {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between; }
  .courses-list__item {
    display: inline-block;
    width: 280px;
    margin-bottom: 35px; }
  .courses-list__img {
    display: block;
    object-fit: cover; }
  .courses-list__name {
    color: #262626;
    font-size: 24px;
    letter-spacing: -0.3px;
    line-height: 1.20833em; }
    .courses-list__name:not(:first-child) {
      margin-top: 16px; }
  .courses-list__description {
    color: #a0a0a0;
    font-size: 14px;
    line-height: 1.42857em; }
    .courses-list__description:not(:first-child) {
      margin-top: 11px; }
  .courses-list__actions {
    display: block;
    font-size: 17px; }
    .courses-list__actions:not(:first-child) {
      margin-top: 8px; }

.index-page {
  height: 100vh;
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 450px;
  align-items: center;
  transition: background-image 1s; }
  .theme-monochrome._black-ground .index-page {
    background: #000 !important; }
  .theme-monochrome._white-ground .index-page {
    background: #fff !important; }
  .theme-monochrome._sepia-ground .index-page {
    background: #F5F5DC !important; }
  @media screen and (max-width: 800px) {
    .index-page {
      height: 100%;
      min-height: 0;
      padding-top: 100px; } }
  .theme-images .index-page {
    background-image: none !important; }
  .index-page:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #F7F7F7;
    transition: opacity 0.6s, visibility 0.6s; }
  .index-page.page-loaded:before {
    opacity: 0;
    visibility: hidden; }

.index__content {
  width: 800px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative; }
  @media screen and (max-width: 800px) {
    .index__content {
      padding: 0 16px;
      box-sizing: border-box; } }
  .big-font-size .index__content {
    width: 100%; }

.index__search-block {
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 40px;
  transition: opacity 0.5s 0.3s, visibility 0.5s 0.3s, transform 0.5s 0.3s;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-40px); }
  .theme-monochrome._black-ground .index__search-block {
    background-color: #000 !important; }
  .theme-monochrome._white-ground .index__search-block {
    background-color: #fff !important; }
  .theme-monochrome._sepia-ground .index__search-block {
    background-color: #F5F5DC !important; }
  @media screen and (max-width: 800px) {
    .index__search-block {
      padding: 24px 16px;
      max-width: 100%; } }
  .page-loaded .index__search-block {
    opacity: 1;
    visibility: visible;
    transform: translateY(0); }

@supports (backdrop-filter: blur(3px)) {
  .index__search-block {
    background-color: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(3px); } }

.isb__text {
  color: #58595b;
  margin-bottom: 20px;
  font-size: 1.6rem;
  line-height: 1.5em; }
  .theme-monochrome._black-accent .isb__text {
    color: #000 !important; }
  .theme-monochrome._white-accent .isb__text {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .isb__text {
    color: #472312 !important; }
  @media screen and (max-width: 800px) {
    .isb__text {
      margin: 0; } }
  .isb__text a {
    font-family: 'FFMetaWebProMedium', sans-serif; }

.isb__form {
  position: relative; }
  @media screen and (max-width: 800px) {
    .isb__form {
      margin-top: 15px; } }
  .isb__form.is-empty {
    animation: gh-search-bounce 300ms ease-out both; }

input[type=text].isb__text-input {
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  letter-spacing: -0.3px;
  color: #58595b;
  padding: 25px 64px 18px 24px;
  border: none;
  box-sizing: border-box;
  font-size: 2.4rem;
  line-height: 1.16667em; }
  @media screen and (max-width: 800px) {
    input[type=text].isb__text-input {
      padding: 10px 48px 10px 16px;
      opacity: 1; } }
  input[type=text].isb__text-input::placeholder {
    color: #a0a0a0;
    opacity: 1; }
    @media screen and (max-width: 800px) {
      input[type=text].isb__text-input::placeholder {
        color: transparent; } }

@keyframes gh-search-bounce {
  0%, 100% {
    transform: translateX(0); }
  20%, 60% {
    transform: translateX(-5px); }
  40%, 80% {
    transform: translateX(5px); } }

button[type=submit].isb__submit {
  position: absolute;
  border: none;
  background: transparent;
  box-shadow: none;
  -webkit-appearance: none;
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxkZWZzPjxwYXRoIGQ9Ik05OTcgMzU5Ljk3YzAtNC40MDIgMy41MzItNy45NyA3Ljg5LTcuOTdzNy44OSAzLjU2OCA3Ljg5IDcuOTdjMCA0LjQwMi0zLjUzMiA3Ljk3LTcuODkgNy45N3MtNy44OS0zLjU2OC03Ljg5LTcuOTd6IiBpZD0iYSIvPjxwYXRoIGQ9Ik0xMDE1LjMxMyAzNzEuMzA0bC00Ljg1My00LjkwNCIgaWQ9ImIiLz48L2RlZnM+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTk5NiAtMzUxKSI+PHVzZSB4bGluazpocmVmPSIjYSIgZmlsbC1vcGFjaXR5PSIwIiBmaWxsPSIjZmZmIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZT0iI2YzNmMyMSIgc3Ryb2tlLW1pdGVybGltaXQ9IjUwIiBzdHJva2Utd2lkdGg9IjIiLz48Zz48dXNlIHhsaW5rOmhyZWY9IiNiIiBmaWxsLW9wYWNpdHk9IjAiIGZpbGw9IiNmZmYiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlPSIjZjM2YzIxIiBzdHJva2UtbWl0ZXJsaW1pdD0iNTAiIHN0cm9rZS13aWR0aD0iMiIvPjwvZz48L2c+PC9zdmc+) 50% 50% no-repeat;
  width: 64px;
  height: 64px;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.2s; }
  @media screen and (max-width: 800px) {
    button[type=submit].isb__submit {
      padding: 16px;
      width: 16px;
      height: 16px;
      box-sizing: content-box;
      background-size: 16px 16px;
      outline: none;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0); } }
  button[type=submit].isb__submit:hover {
    cursor: pointer;
    transform: translateY(-50%) scale(1.2); }
  button[type=submit].isb__submit.is-loading::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 32px;
    height: 32px;
    background: #fff url(/static/img/loader_brand2.png) left center no-repeat;
    -webkit-animation: play_loader 0.6s steps(18) infinite;
    animation: play_loader 0.6s steps(18) infinite; }

.isb__footer {
  margin-top: 20px;
  color: #58595b;
  display: flex;
  justify-content: space-between;
  font-size: 1.4rem;
  line-height: 1.14286em; }
  .theme-monochrome._black-accent .isb__footer {
    color: #000 !important; }
  .theme-monochrome._white-accent .isb__footer {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .isb__footer {
    color: #472312 !important; }
  @media screen and (max-width: 800px) {
    .isb__footer {
      margin-top: 24px; } }

@media screen and (max-width: 800px) {
  .isb__links {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -14px;
    margin-top: -10px; }
    .isb__links span {
      width: 100%;
      flex-grow: 1; }
    .isb__links * {
      margin-top: 10px;
      margin-left: 14px; } }

.isb__link {
  color: #58595b;
  margin-left: 12px;
  font-family: 'FFMetaWebProMedium', sans-serif; }
  .theme-monochrome._black-accent .isb__link {
    color: #000 !important; }
  .theme-monochrome._white-accent .isb__link {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .isb__link {
    color: #472312 !important; }

.index__actions {
  text-align: center;
  margin-top: 40px; }
  .index__actions a {
    display: inline-flex;
    vertical-align: middle;
    margin-top: 0;
    padding-right: 2.4rem;
    transition: opacity 0.5s 0.3s, visibility 0.5s 0.3s, transform 0.5s 0.3s;
    opacity: 0;
    visibility: hidden;
    transform: translateX(-40px); }
    .page-loaded .index__actions a {
      opacity: 1;
      visibility: visible;
      transform: translateX(0); }
  .index__actions a + a {
    margin-left: 2.4rem;
    transform: translateX(40px); }

@keyframes play_loader {
  100% {
    background-position: -576px; } }

.content_lk {
  background: none;
  font-size: 1.6rem;
  align-items: flex-start;
  box-shadow: none;
  padding: 8px 16px 80px; }
  @media screen and (max-width: 800px) {
    .content_lk {
      padding: 8px 0 32px; } }

.lk__section {
  padding-top: 3.2rem;
  padding-bottom: 3.2rem;
  border-bottom: 1px solid #DEDEDE; }

.lk__message {
  background-color: #FFFFFF;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  color: #828382;
  font-size: 2.4rem;
  line-height: 1.58333em;
  letter-spacing: -0.3px;
  margin-top: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 98px;
  padding-bottom: 98px; }
  .lk__message p {
    text-align: center; }

.lk__icon {
  margin-right: 0.6rem; }

.lk__right {
  width: 23.2rem;
  flex: 0 0 auto; }
  .lk__right.is-bottom {
    align-self: flex-end; }

.lk__left {
  /*flex: 1;*/
  width: calc(100% - 25.2rem);
  padding-right: 2rem; }

.lk__actions {
  margin-top: 0.4rem;
  margin-left: -1.2rem; }
  .lk__actions > .btn.lk__actions-item {
    margin-left: 1.2rem;
    margin-top: 1.2rem; }

.lk__action {
  display: inline-flex;
  align-items: center;
  transition: color 0.3s; }
  @media screen and (max-width: 800px) {
    .lk__action {
      font-size: 1.4rem;
      width: 30%; } }
  .lk__action svg {
    stroke: #58595b;
    margin-left: 0.6rem;
    transition: stroke 0.3s, transform 0.2s;
    position: relative;
    bottom: 1px;
    flex-shrink: 0; }
  .lk__action:hover {
    color: #f26123; }
    .lk__action:hover svg {
      stroke: #f26123;
      transform: translateX(0.6rem); }
  .product__hero .lk__action svg {
    stroke: #ffffff; }
  .product__hero .lk__action:hover svg {
    stroke: #f26123; }

.lk__action--backlink svg {
  margin-left: 0;
  margin-right: 0.6rem; }

.lk__action--backlink:hover svg {
  transform: translateX(-0.6rem); }

.lk__action--active {
  color: #f26123; }
  .lk__action--active svg {
    stroke: #f26123; }
  .lk__action--active:hover {
    color: #58595b; }
    .lk__action--active:hover svg {
      stroke: #58595b; }

.lk__action--active-search {
  color: #f26123; }
  @media screen and (max-width: 800px) {
    .lk__action--active-search {
      font-size: 1.4rem;
      width: 100%; } }
  .lk__action--active-search svg {
    stroke: #f26123; }
  .lk__action--active-search:hover {
    color: #58595b; }
    .lk__action--active-search:hover svg {
      stroke: #58595b; }

.lk__action--has-badge {
  position: relative;
  padding-right: 3.4rem; }
  .lk__action--has-badge .badge {
    position: absolute;
    right: 0;
    top: 0.4rem; }

.lk__header {
  font-family: 'FFMetaWebProNormal', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 3.2rem;
  line-height: 3.2rem;
  letter-spacing: -0.4px; }
  .lk__header + .lk__subheader {
    margin-top: 0.8rem;
    color: #5B5B5B; }
    .lk__header + .lk__subheader .lk-entity__status:not(.lk__subheader--no-offset) {
      margin-left: 1.6rem; }

.lk__header--gray {
  color: #A0A0A0; }

.lk-entity__status--red {
  color: #F32121; }

.lk-entity__status--gray {
  color: #A0A0A0; }

.lk__entity {
  margin-top: 2.4rem; }

.lk-entity__name {
  font-family: 'FFMetaWebProMedium', sans-serif;
  font-weight: normal;
  font-style: normal;
  margin-bottom: 0.8rem; }

.lk-entity__info {
  color: #5B5B5B; }
  .lk-entity__info + .lk-entity__action {
    margin-top: 1.6rem; }
  .lk-entity__info + .lk-entity__info {
    margin-top: 0.8rem; }
  .lk-entity__info .lk-entity__status {
    margin-left: 1.6rem; }
    .lk-entity__info .lk-entity__status + .lk-entity__status {
      margin-left: 0.8rem; }

.lk-entity__action {
  margin-top: 0.8rem; }
  .lk-entity__action:first-child {
    margin-top: 0; }

.lk-entity__action--main + .lk-entity__action {
  margin-top: 1.6rem; }

.lk-entity__row {
  margin-top: 1.6rem; }
  .lk-entity__row .lk-entity__name + .lk-entity__info {
    margin-left: 0.8rem; }

.text--meta {
  color: #A0A0A0; }

.text--error {
  color: #F32121; }

.lk-incident__header svg {
  display: block; }

.lk-incident__header .lk__icon {
  display: inline-block;
  width: 2.4rem;
  height: 2.4rem;
  line-height: 2.4rem;
  margin-right: 1.2rem;
  vertical-align: bottom; }
  .lk-incident__header .lk__icon svg {
    display: block; }

.lk-incident__text {
  font-size: 1.4rem;
  line-height: 1.6rem; }
  .lk-incident__text p, .lk-incident__text p + p {
    margin-top: 0.8rem; }

.lk__products {
  position: relative; }

.lk__filter-select {
  display: none; }

@media screen and (max-width: 740px) {
  .lk__dropdown {
    display: none; }
  .lk__filter-select {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    line-height: 0;
    overflow: hidden;
    width: 1.8rem;
    height: 1.6rem; }
    .lk__filter-select select {
      position: absolute;
      padding: 0;
      border: 0;
      margin: 0; }
  .lk__left,
  .lk__right {
    width: 100%;
    order: 2; }
    .lk__left.is-mobile-first,
    .lk__right.is-mobile-first {
      order: 1; }
  .lk-incident__text,
  .lk-entity__status {
    display: none; }
  .lk-incident__status {
    margin-bottom: 16px; }
  .lk__header {
    font-size: 2.4rem;
    line-height: 2.4rem;
    letter-spacing: -0.03rem; }
  .lk-entity__action .btn,
  .extra-info__action .btn {
    width: fit-content; }
  /*
  .lk__action{
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.4rem;
    letter-spacing: -0.047rem;
    padding: 12px 24px;
    color: #262626;
    border-radius: 0.4rem;
    background-color: #f2f2f2;
    svg{
      display: none;
    }
  }
  */
  /*
  .lk__action--active{
    color: #fff;
    background-color: #f36b20;
    background-image: linear-gradient(180deg, rgba(241, 241, 241, 0.1) 0%, rgba(230, 230, 230, 0.1) 100%);
  }
  */
  /*
  .lk__action--has-badge{
    padding-right: 50px;
    .badge{
      top: 1.4rem;
      right: auto;
      left: calc(100% - 4.2rem);
    }
  }
  */
  .lk-entity__action + .lk-entity__action {
    margin-top: 1.6em; }
  .lk__right__with-mobile-offset .lk__right {
    margin-top: 1.6em; } }

.form-input--new .input-field__input {
  border: 1px solid #f1f1f1 !important;
  border-radius: 15px !important;
  padding: 1rem !important;
  text-align: center;
  box-sizing: border-box; }

.form-input--new .input-field__label {
  text-align: center;
  left: 50% !important;
  transform: translateX(-50%);
  width: 100%; }

.form-input--new .input-field__eye {
  top: 4rem; }

.form-input--new .input-field__error {
  text-align: center; }

.form-input--new .input-field__input:not(:focus):not(._filled) + .input-field__label {
  top: 3.4rem !important; }

.form-input--new .input-field__eye {
  top: 3rem !important; }

.fg-input--new {
  padding-bottom: 8px !important;
  padding-top: 2.4rem !important; }
  .fg-input--new .input-field__error {
    display: none !important; }
  .fg-input--new .input-field__input:focus + .input-field__label,
  .fg-input--new ._filled + .input-field__label,
  .fg-input--new ._focus + .input-field__label,
  .fg-input--new ._radio .input-field__label {
    top: 0 !important; }

.auth__input--email .input-field__label:before {
  content: "* ";
  color: red; }

.fg-input--new.input-field.is-loading:before {
  top: 75px !important; }

.policy__name {
  letter-spacing: -1px;
  margin-bottom: 1.6rem;
  display: flex;
  align-items: center;
  font-size: 4.8rem;
  line-height: 1em; }

.policy-name__edit {
  border: 0;
  background: transparent;
  margin-left: 2rem; }
  .policy-name__edit:hover {
    cursor: pointer; }
    .policy-name__edit:hover use {
      fill: #58595b; }
  .policy-name__edit use {
    transition: fill 0.3s;
    fill: #f26123; }

.policy-name__text {
  position: relative;
  z-index: 0; }
  .policy-name__text:after {
    content: '';
    position: absolute;
    z-index: -1;
    left: -1rem;
    right: -1rem;
    top: -1rem;
    bottom: -1rem;
    border: 2px dashed rgba(242, 97, 35, 0.6);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;
    border-radius: 4px; }
  .policy-name__text[contenteditable=true]:after {
    opacity: 1;
    visibility: visible; }
  .policy-name__text[contenteditable=true] + button {
    margin-bottom: 0; }
    .policy-name__text[contenteditable=true] + button:disabled, .policy-name__text[contenteditable=true] + button.disabled {
      background: #CCCCCC;
      color: rgba(255, 255, 255, 0.25);
      letter-spacing: -0.5px;
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.5); }
      .policy-name__text[contenteditable=true] + button:disabled:hover, .policy-name__text[contenteditable=true] + button.disabled:hover {
        background: #CCCCCC; }

.policy__type {
  letter-spacing: -0.3px;
  margin-bottom: 0.8rem;
  font-size: 2.4rem;
  line-height: 1em; }

.policy-header__actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.4rem; }
  .policy-header__actions a {
    transition: color 0.3s;
    font-size: 1.4rem; }
    .policy-header__actions a:hover {
      color: #f26123; }
  .policy-header__actions div {
    display: flex;
    justify-content: flex-end;
    align-items: center; }
    .policy-header__actions div > a {
      font-size: 1.7rem;
      margin-left: 2.4rem; }

.policy__info {
  margin-top: 3.2rem;
  padding: 4rem 3.2rem;
  background: #F7F7F7; }

.policy-info__header h4 {
  margin-top: 0.8rem; }

.policy-info__body {
  padding-top: 2.4rem;
  margin-top: 3.2rem;
  border-top: 1px solid rgba(160, 160, 160, 0.24); }
  .policy-info__body > table {
    width: 100%; }
    .policy-info__body > table td {
      padding-right: 4rem;
      vertical-align: top;
      color: #595959; }
      .policy-info__body > table td:last-child {
        text-align: right;
        padding-right: 0; }
      .policy-info__body > table td p.text--error {
        margin-top: 0.8rem; }

.policy-info__footer {
  position: relative; }
  .policy-info__footer > table {
    margin-top: 3.6rem; }
    .policy-info__footer > table td {
      vertical-align: top;
      width: 45%; }
  .policy-info__footer .action-button {
    margin-top: 0;
    padding-left: 0; }

.policy__extra {
  position: absolute;
  right: 0;
  top: 1.4rem; }
  .policy__extra a.trigger {
    width: 2.4rem;
    height: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: center; }
    .policy__extra a.trigger svg {
      display: block; }
    .policy__extra a.trigger use {
      fill: #f26123;
      transition: fill 0.3s; }
    .policy__extra a.trigger:hover use {
      fill: #58595b; }
  .policy__extra:hover .actions {
    transform: translateX(-50%) scale(1);
    opacity: 1; }
  .policy__extra .actions {
    position: absolute;
    white-space: nowrap;
    padding: 2.4rem;
    background: #fff;
    border-radius: 0.4rem;
    box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s, transform 0.3s;
    left: 50%;
    transform: translateX(-50%) scale(0.4);
    opacity: 0; }
  .policy__extra a.action {
    transition: color 0.3s; }
    .policy__extra a.action:hover {
      color: #f26123; }
    .policy__extra a.action + a.action {
      margin-top: 2.4rem; }

.policy__services {
  display: flex;
  justify-content: space-between;
  margin-top: 4rem; }
  .policy__services .swiper-container {
    width: 100%; }

.policy-services__item {
  width: 28rem;
  box-sizing: border-box;
  padding: 2.4rem;
  background: #fff;
  border-radius: 4px; }

.psi__header {
  margin-bottom: 1.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.psi__icon svg {
  display: block; }

.psi__footer {
  margin-top: 2.4rem; }

.psi__actions {
  margin-top: 2.4rem;
  display: flex;
  flex-direction: column; }
  .psi__actions > a.psi__action,
  .psi__actions > button.psi__action {
    width: 100%;
    margin: 0; }
    .psi__actions > a.psi__action:not(:first-child),
    .psi__actions > button.psi__action:not(:first-child) {
      margin-top: 10px; }

.policy__section {
  margin-top: 8rem; }
  .policy__section h3 + h4 {
    margin-bottom: 0; }

.policy__content {
  position: relative;
  margin-top: 2.4rem; }
  .policy__content table {
    width: 100%;
    margin-left: 2.4rem; }
    .policy__content table td {
      padding-top: 0.8rem;
      padding-bottom: 0.8rem;
      vertical-align: middle; }
    .policy__content table tbody {
      border-bottom: 1px solid rgba(160, 160, 160, 0.24); }
      .policy__content table tbody:first-child {
        border-bottom: 0; }
        .policy__content table tbody:first-child p.policy__subject {
          margin-left: -2.4rem; }
    .policy__content table tr:last-child td {
      padding-bottom: 2.4rem; }
    .policy__content table tr:first-child td {
      padding-top: 1.6rem; }
    .policy__content table td[colspan] {
      padding-bottom: 1.6rem;
      vertical-align: top; }

.policy-subject__row + .policy-subject__row {
  margin-top: 1.6rem; }

.policy__subject {
  padding-left: 2.4rem;
  position: relative; }
  .policy__subject .icon {
    position: absolute;
    left: 0;
    top: 0; }
  .policy__subject p + p {
    margin-top: 0.8rem; }
  .policy__subject p > span {
    margin-left: 1.6rem; }

.policy-subject__image {
  position: absolute;
  width: 20rem;
  height: 20rem;
  display: flex;
  align-items: center;
  right: 7.2rem;
  top: -4rem; }
  .policy-subject__image img {
    display: block;
    width: 100%; }

.policy__options {
  padding-top: 4rem;
  padding-bottom: 0.8rem;
  margin-left: 2.4rem; }
  .policy__options .form__checkbox {
    margin-top: 0;
    margin-right: 4.8rem; }

.policy-options__items {
  display: flex;
  align-items: center; }

.policy__total {
  margin-top: 3.2rem; }
  .policy__total .sum {
    float: right; }
  .policy__total .text--darken {
    float: left; }
  .policy__total .fill {
    display: block;
    overflow: hidden;
    border-left: 8px solid #ffffff;
    border-right: 8px solid #ffffff;
    height: 1px;
    margin-top: 1.4rem;
    background: linear-gradient(to right, transparent 75%, #A0A0A0 75%);
    background-size: 4px 1px; }
  .policy__total .sum-row {
    overflow: hidden; }

.policy-info__table.table-cnt {
  margin-bottom: 5.6rem; }

.policy-info__table table {
  width: 100%; }
  .policy-info__table table thead th {
    font-weight: normal;
    font-family: 'FFMetaWebProMedium', sans-serif;
    font-weight: normal;
    font-style: normal;
    white-space: nowrap;
    padding: 1.6rem; }
    .policy-info__table table thead th:first-child {
      text-align: left; }
    .policy-info__table table thead th:last-child {
      text-align: right; }
  .policy-info__table table tbody tr:nth-child(odd) td {
    background: #F2F2F2; }
  .policy-info__table table tbody td {
    padding: 1.6rem; }
    .policy-info__table table tbody td:last-child {
      text-align: right; }

.policy__customer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 2.4rem; }
  .policy__customer dd {
    width: 20%; }
  .policy__customer dt {
    width: 80%;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem; }

.mobile-icon {
  display: none; }

.slider-btn {
  position: relative;
  display: block;
  border: 0.1rem solid rgba(160, 160, 160, 0.15);
  background-color: transparent;
  width: 3.2rem;
  height: 3.2rem;
  box-sizing: border-box;
  border-radius: 100%;
  transition: opacity .2s; }
  .slider-btn::after {
    content: '';
    position: absolute;
    width: .6rem;
    height: 1rem;
    top: 1rem;
    left: 1.3rem;
    background: no-repeat center;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSI2cHgiIGhlaWdodD0iMTBweCIgdmlld0JveD0iMCAwIDYgMTAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+ICAgICAgICA8dGl0bGU+U2hhcGU8L3RpdGxlPiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4gICAgPGRlZnM+PC9kZWZzPiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPiAgICAgICAgPGcgaWQ9ImFsbC1jb250cm9sIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMjU0LjAwMDAwMCwgLTY3NS4wMDAwMDApIiBzdHJva2U9IiM1OTU5NTkiIHN0cm9rZS13aWR0aD0iMiI+ICAgICAgICAgICAgPGcgaWQ9Im1lZGljaW5lIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyNC4wMDAwMDAsIDYzMi4wMDAwMDApIj4gICAgICAgICAgICAgICAgPGcgaWQ9ImNvbnRlbnQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDI0LjAwMDAwMCwgMjQuMDAwMDAwKSI+ICAgICAgICAgICAgICAgICAgICA8ZyBpZD0iYXJyb3ctciIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjA4LjAwMDAwMCwgMjQuMDAwMDAwKSByb3RhdGUoLTkwLjAwMDAwMCkgdHJhbnNsYXRlKC0yMDguMDAwMDAwLCAtMjQuMDAwMDAwKSB0cmFuc2xhdGUoMTkyLjAwMDAwMCwgOC4wMDAwMDApIj4gICAgICAgICAgICAgICAgICAgICAgICA8ZyBpZD0iY2hldnJvbi1kb3duLWNvcHkiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEyLjgwMDAwMCwgMTUuMjAwMDAwKSI+ICAgICAgICAgICAgICAgICAgICAgICAgICAgIDxwb2x5bGluZSBpZD0iU2hhcGUiIHBvaW50cz0iMCAwIDMuMiAzLjIgNi40IDguODgxNzg0MmUtMTciPjwvcG9seWxpbmU+ICAgICAgICAgICAgICAgICAgICAgICAgPC9nPiAgICAgICAgICAgICAgICAgICAgPC9nPiAgICAgICAgICAgICAgICA8L2c+ICAgICAgICAgICAgPC9nPiAgICAgICAgPC9nPiAgICA8L2c+PC9zdmc+); }
  .slider-btn.slider-btn--prev {
    transform: scale(-1, 1); }
  .slider-btn.swiper-button-disabled {
    opacity: .5; }

.policy-slider-btn {
  display: none;
  position: absolute;
  top: 3.2rem;
  z-index: 2; }
  .policy-slider-btn.policy-slider-btn--prev {
    left: calc(50% - 11.2rem); }
  .policy-slider-btn.policy-slider-btn--next {
    right: calc(50% - 11.2rem); }

@media screen and (max-width: 740px) {
  .policy__name {
    font-weight: 400;
    letter-spacing: -0.04rem;
    font-size: 3.2rem;
    line-height: 1em; }
  .policy__name {
    flex-wrap: wrap;
    width: 100%; }
  .policy-name__text {
    width: 100%; }
    .policy-name__text[contenteditable=true] {
      text-overflow: clip;
      white-space: normal;
      overflow: visible;
      width: 100%;
      margin-bottom: 2rem; }
  .policy-name__edit {
    display: block;
    /*margin-left: 0;*/
    /*margin-top: 2rem;*/
    padding: 0;
    margin-left: 1.2rem; }
  .mobile-icon {
    display: inline-block;
    margin-right: .8rem; }
  .policy-header__actions {
    display: block;
    margin-bottom: 0; }
    .policy-header__actions .text--larger {
      border-top: 1px solid #f2f2f2;
      margin-top: 2.4rem;
      width: calc(100% + 4.8rem);
      margin-left: -2.4rem;
      justify-content: flex-start; }
      .policy-header__actions .text--larger a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        height: 56px;
        margin-left: 0;
        text-align: center;
        letter-spacing: -0.05rem;
        box-sizing: border-box;
        font-size: 1.6rem;
        line-height: 1.5em; }
        .policy-header__actions .text--larger a:nth-child(2n) {
          border-left: 1px solid #f2f2f2; }
  .policy__info {
    margin-top: 0;
    width: calc(100% + 4.8rem);
    margin-left: -2.4rem;
    box-sizing: border-box;
    padding: 3.2rem 2.4rem; }
    .policy__info + .policy__info {
      margin-top: 3.2rem; }
  .policy-info__header h4 {
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
  .policy-info__body {
    margin-top: 1.6rem;
    padding-top: 0;
    border-top: none; }
    .policy-info__body > table tr {
      display: block; }
    .policy-info__body > table td {
      display: block;
      padding-right: 0;
      margin-bottom: 1.6rem; }
  .policy-info__footer > table {
    width: 100%; }
    .policy-info__footer > table tr {
      display: block; }
    .policy-info__footer > table td {
      display: block;
      text-align: left;
      width: 100%; }
  .policy-info__footer .action-button {
    display: inline-flex;
    width: auto; }
  .policy__extra {
    position: relative;
    width: 32px;
    margin-left: auto;
    margin-right: auto; }
  .policy__services {
    width: calc(100vw - 4.8rem); }
  .policy-services__item {
    margin-left: auto;
    margin-right: auto; }
  .policy__section {
    margin-top: 5.6rem; }
  .policy__subject p > span {
    display: block;
    margin-left: 0; }
  .sum-row span {
    display: block; }
    .sum-row span.fill {
      display: none; }
    .sum-row span.sum {
      margin-top: .8rem; }
  .policy__total .text--darken,
  .policy__total .sum {
    float: none; }
  .policy-subject__image {
    display: none; }
  .policy__content > table {
    width: calc(100% - 2.4rem); }
    .policy__content > table tr {
      display: block; }
    .policy__content > table td {
      display: block; }
  .policy-options__items {
    display: block; }
    .policy-options__items .form__checkbox {
      margin-top: 2rem;
      margin-right: 0; }
  .policy__options {
    padding-top: 0; }
  .policy__customer {
    display: block; }
    .policy__customer dd,
    .policy__customer dt {
      width: 100%; }
    .policy__customer dt {
      margin-bottom: 16px; }
  .psi__header {
    display: block; }
  .psi__icon,
  .psi__status,
  .psi__body,
  .psi__footer {
    text-align: center; }
  .psi__icon {
    margin-bottom: 1.6rem; }
  .policy-slider-btn {
    display: block;
    top: 50%;
    margin-top: -16px;
    background: #fff;
    border-radius: 50%; }
    .policy-slider-btn.swiper-button-disabled {
      display: none; }
    .policy-slider-btn.policy-slider-btn--prev {
      left: 4px; }
    .policy-slider-btn.policy-slider-btn--next {
      right: 4px; }
  .policy__services .swiper-container {
    width: calc(100% + 4.8rem);
    margin-left: -2.4rem;
    margin-right: -2.4rem; } }

.specialists__form {
  margin-bottom: 4rem; }

.specialists__list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: -2.8rem;
  margin-right: -2.8rem;
  overflow: hidden; }

.specialists__item {
  width: 32rem;
  margin-left: 2.8rem;
  margin-right: 2.8rem;
  padding-bottom: 4rem;
  border-bottom: 1px solid rgba(160, 160, 160, 0.24);
  margin-bottom: 5.6rem;
  transition: all .4s;
  display: flex;
  flex-direction: column; }

.specialist__image {
  width: 100%;
  height: 24rem;
  margin-bottom: 4rem;
  display: block;
  box-sizing: border-box;
  text-align: center; }
  .specialist__image div {
    width: 100%;
    height: 100%; }
  .specialist__image img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    object-fit: contain;
    display: block; }
  .specialist__image svg {
    width: 100%;
    height: 100%;
    display: block; }

.specialist__info {
  flex-grow: 1;
  display: flex;
  flex-direction: column; }

.specialist__name {
  font-family: 'FFMetaWebProMedium', sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #262626;
  letter-spacing: -0.23px;
  font-size: 1.8rem;
  line-height: 1.11111em; }

.specialist__job {
  margin-top: 0.8rem;
  color: #5B5B5B; }

.specialist__exp {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem; }

.specialist__about {
  color: #5B5B5B;
  font-size: 1.7rem; }

.specialist__footer {
  margin-top: auto; }

.specialist__actions {
  margin-top: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.specialist__meta {
  color: #7B7B7B;
  margin-top: 1.6rem;
  font-size: 1.4rem; }

@media screen and (max-width: 740px) {
  .specialists__list {
    margin-left: 0;
    margin-right: 0; }
  .specialists__item {
    width: 100%;
    margin-left: 0;
    margin-right: 0; }
  .specialist__info .specialist__exp {
    text-align: left; }
  .specialist__actions {
    display: block; } }

.specialist-wrapper {
  width: 65.6rem; }
  .specialist-wrapper h1 {
    width: 100%;
    max-width: inherit; }

.specialist-detail .specialist__info {
  margin-top: 3.2rem;
  margin-bottom: 0.8rem; }

.specialist-header {
  margin-bottom: 1.6rem; }

.specialist-resume {
  margin-top: 4.8rem;
  padding-bottom: 4rem;
  border-bottom: 1px solid rgba(160, 160, 160, 0.24); }

.spr__row + .spr__row {
  margin-top: 2.4rem; }

.spr__header {
  margin-bottom: 2.4rem; }

.spr__list {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start; }

.spr__item {
  width: calc(50% - 40px);
  margin-left: 40px;
  margin-right: 20px;
  margin-bottom: 16px; }
  .spr__item + .spr__item {
    margin-top: 0; }

.specialist-photo {
  position: absolute;
  right: 7.2rem;
  top: 12rem;
  width: 27.2rem;
  text-align: center; }

.specialist-photo-container {
  margin-bottom: 4rem;
  height: 27.2rem;
  padding-top: 2rem;
  box-sizing: border-box; }
  .specialist-photo-container img {
    width: 100%;
    height: 100%;
    object-fit: contain; }

.specialist__action .btn {
  width: 100%; }

.specialist-certificates {
  margin-top: 5.6rem; }

.cert-list {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: -1rem;
  margin-right: -1rem; }

.cert-item {
  margin-left: 1rem;
  margin-right: 1rem; }
  .cert-item img {
    max-width: 160px; }

@media screen and (max-width: 740px) {
  .specialist-wrapper {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column; }
    .specialist-wrapper h1 {
      order: 1;
      text-align: center; }
  .specialist-detail {
    order: 4;
    width: 100%; }
    .specialist-detail.is-top {
      order: 2; }
  .specialist-photo {
    position: static;
    order: 3;
    margin-left: auto;
    margin-right: auto; }
  .spr__list {
    display: block; }
  .specialist-header,
  .specialist__exp {
    text-align: center; }
  .specialist-photo-container {
    border-bottom: 1px solid #e8e8e8; }
  .spr__item {
    width: calc(100% - 4rem); }
  .cert-item {
    display: inline-block;
    width: calc(50% - 2rem);
    min-width: 12rem;
    max-width: 14.8rem;
    margin-bottom: 2.4rem; }
    .cert-item a, .cert-item img {
      width: 100%;
      height: auto; } }

.content__inner--consultation {
  display: flex;
  flex-direction: column; }
  @media screen and (max-width: 800px) {
    .content__inner--consultation {
      padding-left: 16px;
      padding-right: 16px; } }
  @media screen and (max-width: 800px) {
    .content__inner--consultation iframe {
      height: 90vh; } }
  .content__inner--consultation .consultation-content {
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch; }

.consultation-content-chat {
  width: 100%;
  overflow: auto;
  display: flex; }

.cons-item {
  padding-top: 3.2rem;
  padding-bottom: 3.2rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #DDDDDD; }

.cons-photo {
  width: 9.6rem;
  margin-right: 4rem; }

.cons-image {
  width: 9.6rem;
  height: 9.6rem;
  border-radius: 50%;
  overflow: hidden;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat; }

.cons-info {
  flex-grow: 1; }

.cons-name {
  font-family: 'FFMetaWebProNormal', sans-serif;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.4px;
  margin-bottom: 8px;
  font-size: 3.2rem;
  line-height: 1em; }
  .cons-name + .cons-job {
    margin-top: 0; }

.cons-job {
  font-family: 'FFMetaWebProMedium', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 1.6rem;
  line-height: 1.5em; }

.cons-actions {
  width: 262px;
  flex-grow: 0; }

.cons-button + .cons-progress {
  margin-top: 16px; }

.cons-progress {
  position: relative; }
  .cons-progress p + p {
    margin-top: 0; }
  .cons-progress:before {
    content: '';
    width: 22px;
    height: 22px;
    position: absolute;
    left: -32px;
    top: 0;
    background-position: 0 50%;
    background-repeat: no-repeat; }

.cons-progress--processing:before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAUCAYAAACEYr13AAABcElEQVQ4T5WUzVECQRCFX/cWXOWocJAM1AiUAs8Swm4ErgEAwxqAEAGYAd7dEjPADLBKwCNeoXbaGthdfhQY5jg173tvunuG6mF+AOCENXvq9quPIxfVw7wkGgK1iDNNVRpObTkbgFg0ZMGDqox7NpAUwExXWksXwIURCtBzOOsdSpMCgvKYjLAWnvkEUqYuAKYQ8oPK6HlXmj8Ac1C9nRa1drqAXC/SkPQdEk+VvofboH8ByaFaWHAJ0lqlERVUJu11yF7AMk0xp6NZF4S7hVAwYIc8VRqZ9uMgIHFTr/mqJpg05/GeCsrjpjUgTaPnCpD7JM3RANHzhkD8OMWHNSC+whOAYixuBuWxOggwRYz0rENAdSmkd2b4VkWM22hccwB+BKIeyxNTyHTtHKRIqENCNyvXyLUapHiUG4krC9x9D2vzMUXSAeEybtELO1nX+jGtXeuTNbu2n8vWf0Bt5ow65LpVxEIfkBxr9m1d1wG/zyDSXT90UyQAAAAASUVORK5CYII=); }

.cons-progress--planned:before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAACMklEQVRIS6WV0W0aQRCGv5HvnCdiU0E2FRhXYKgguILYb1FAMh3EqcBEAilvSQfBFcSpIFBBrgPA5CVmyUazt0eOy92ZyCutkOaWf//9Z/4ZoWLNB+Y4trx2v2mL0HJg9KhA4hxTESbrmNvmMFmUQUgxqIDRA1cIA+C46uIQX+AY2kM+FC/YAfaga74CLf9H4Rtwp9tGTDUUWf+t7bfjLFwwtTGdPPgWOID+CCyXTrh4PkomdYzv+6Yrjs/AEbCwMS8zcA9cYDqzG7rNj0mSB131zR0O1xgnnXx8/saY6AAlcAJsmXvgVd9c43gHLO2GVhHUn+kZp7+NcfJvXlJwleoI4X1jlFxLXgInnFc9vw5YLwyyfMkkkZ89M3Bwo4lqjBJNSul6DDi8XOU6c45LWfWM6vMqe8ITgTNJb+W+Z1S0FwidxijR0noKYy1BLdepMvZJsTHNKhflk1d3LuRr7i3wH8Ca9Z2SKnXtmhR4XykCG5WqEnzVN5kUs72TlzPSFrwxTk4LJsol7625EOGT9oOiq8qymGOuZkl7SljBnWm5hYNq36M6g9T1DG+QvwSXNsYULb2wG07LLF0HHPrFd9/AMkuXaWc3nO8LHkDVyirLzMa0tWyLbdNLon53wuWjbTN9/k3WalWCnbaZPbNQUhpW7/ttnzHTQPSLE4S232nD17VlmmGVjybLAOdHk7KvW0uEoY0Y1o6mPIKyP3igK0KXdJCqMTw70oE62RwyqWoDfwCnakntzgEL0AAAAABJRU5ErkJggg==); }

.consultation-content iframe {
  width: 100%; }

.cons-result {
  margin-bottom: 40px; }

@media screen and (max-width: 740px) {
  .cons-item {
    flex-direction: column; }
  .cons-photo {
    margin-right: 0;
    margin-bottom: 2.4rem; }
  .cons-info {
    margin-bottom: 2.4rem; }
  .cons-progress {
    margin-left: 32px; } }

.incident-action {
  margin-top: 40px;
  display: flex;
  align-items: center; }
  .incident-action .tooltip--right {
    margin-left: 16px;
    margin-right: -40px; }
    .incident-action .tooltip--right span {
      display: block; }

.care-service {
  padding-bottom: 3.2rem;
  border-bottom: 1px solid rgba(160, 160, 160, 0.24);
  margin-bottom: 2.4rem; }

.care-service--big-mb {
  margin-bottom: 4rem; }

.care-service-header {
  font-family: 'FFMetaWebProMedium', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 1.8rem; }

.care-service-phone svg {
  margin-right: 0.8rem;
  stroke: #f26123; }

@media screen and (max-width: 740px) {
  .incident-action {
    flex-direction: column;
    align-items: flex-start; }
  .incident-action .tooltip--right {
    margin-right: 0;
    margin-left: 0;
    margin-top: 1.6rem; } }

.dissolution-form {
  margin-top: 3.2rem; }

@media screen and (max-width: 740px) {
  .dissolution-form {
    padding-left: 2.4rem;
    padding-right: 2.4rem; }
    .dissolution-form .lk__action {
      color: #f26123;
      padding: 0;
      background: inherit;
      font-size: inherit;
      line-height: inherit; } }

.damage-content .subheader {
  margin-bottom: 0; }
  .damage-content .subheader + p {
    margin-top: 0; }

.dw-steps-header {
  display: flex;
  justify-content: space-between;
  counter-reset: cnt;
  margin-top: 2.4rem;
  margin-bottom: 2.4rem; }

.dw-steps__item {
  counter-increment: cnt;
  position: relative;
  padding-left: 40px;
  font-size: 1.4rem;
  line-height: 1.14286em;
  color: #A0A0A0; }
  .dw-steps__item:before {
    content: counter(cnt);
    width: 32px;
    height: 32px;
    box-sizing: border-box;
    border: 1px solid #A0A0A0;
    color: #A0A0A0;
    line-height: 30px;
    border-radius: 50%;
    background-color: #ffffff;
    font-family: 'FFMetaWebProMedium', sans-serif;
    font-weight: normal;
    font-style: normal;
    text-align: center;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%); }

.dw-steps__item--filled {
  color: #A0A0A0; }
  .dw-steps__item--filled:before {
    border-color: #f26123;
    background-color: #f26123;
    color: #ffffff; }

.dw-steps__item--active {
  color: #58595b; }
  .dw-steps__item--active:before {
    border-color: #f26123;
    background-color: #f26123;
    color: #ffffff; }

.dw-content {
  background: #F7F7F7;
  border-radius: 4px;
  padding: 4rem 3.2rem;
  margin-bottom: 40px;
  margin-top: 40px; }

.dw-status-message {
  padding-bottom: 2.4rem;
  border-bottom: 1px solid rgba(160, 160, 160, 0.24);
  padding-right: 7.2rem;
  position: relative; }
  .dw-status-message:after {
    content: '';
    width: 50px;
    height: 50px;
    position: absolute;
    right: 0;
    top: 0;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MCIgaGVpZ2h0PSI1MCI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2U9IiNGMzZDMjEiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxIDEpIj48Y2lyY2xlIGN4PSIyNCIgY3k9IjI0IiByPSIyNCIvPjxwYXRoIGQ9Ik0yNCAxMy40VjI4bTAgNi40djEiLz48L2c+PC9zdmc+);
    background-position: 50% 50%;
    background-repeat: no-repeat; }

.dwsm__header {
  color: #262626;
  letter-spacing: -0.3px;
  margin-bottom: 1.6rem;
  font-size: 2.4rem;
  line-height: 1em; }

.dwsm__text {
  color: #595959; }

.dfi-section {
  margin-top: 2.4rem; }

.dfi-section-header {
  font-family: 'FFMetaWebProMedium', sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #262626;
  margin-bottom: 20px;
  font-size: 1.7rem;
  line-height: 1.41176em; }

.dfi-list .row {
  display: flex; }
  .dfi-list .row + .row {
    margin-top: 16px; }

.dfi-list dt {
  width: 136px;
  flex-grow: 0;
  color: #7B7B7B;
  font-size: 1.4rem;
  line-height: 1.14286em; }

.dfi-attachments {
  display: flex;
  align-items: flex-start; }

.dfi-attachment-item {
  width: 117px;
  margin-right: 19px; }

.dfi-ai-image {
  height: 64px;
  margin-bottom: 16px; }
  .dfi-ai-image img {
    height: 100%;
    width: auto; }

.dfi-ai-name {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #7B7B7B;
  font-size: 1.2rem;
  line-height: 1.33333em; }

@media screen and (max-width: 740px) {
  .dw-steps__item {
    padding-left: 0;
    position: relative; }
    .dw-steps__item:before {
      position: static;
      display: block;
      transform: none; }
    .dw-steps__item span {
      position: absolute;
      display: none; }
  .dw-steps__item--active span {
    display: block;
    margin-top: 5px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center; }
  .dw-content {
    margin-left: -1.6rem;
    margin-right: -1.6rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem; }
    .dw-content .lk__action {
      color: #f26123;
      padding: 0;
      background: inherit;
      font-size: inherit;
      line-height: inherit; }
  .dfi-attachments {
    flex-wrap: wrap; }
  .dfi-attachment-item {
    margin-bottom: 2.4rem; } }

.record-content {
  margin-top: -4rem; }
  .record-content .lk__section {
    border-bottom: 0; }
  .record-content .lk__header, .record-content .patient-picker-cnt {
    width: 100%; }
  .record-content .lk__left {
    width: calc(100% - 30.4rem); }
  .record-content .lk__right {
    margin-left: 5.2rem; }
  .record-content .row + .row {
    margin-top: 3.2rem; }
  .record-content .patient-picker-cnt {
    margin-top: 3.2rem; }
    .record-content .patient-picker-cnt .col:first-child {
      margin-right: 2.4rem; }

.record-extra__item {
  padding: 3.2rem 4rem;
  background: #f7f7f7;
  border-radius: 8px; }
  .record-extra__item + .record-extra__item {
    margin-top: 3.2rem; }

.record-ectra-status {
  opacity: 0.5;
  margin-bottom: 0.8rem; }

.records-extra-header {
  opacity: 0.5;
  letter-spacing: -0.4px;
  margin-bottom: 16px;
  font-size: 3.2rem;
  line-height: 1em; }

.record-extra-body {
  display: flex;
  align-items: flex-start; }

.record-extra-description {
  margin-right: 7.2rem;
  opacity: 0.5; }

@media screen and (max-width: 740px) {
  .record-content {
    margin-top: -4rem; }
    .record-content .lk__section {
      border-bottom: 0; }
    .record-content .lk__left {
      width: 100%; }
    .record-content .lk__right {
      margin-left: 0; }
  .record-extra {
    margin-left: -0.8rem;
    margin-right: -0.8rem; }
  .record-extra-body {
    flex-direction: column; }
  .record-extra-description {
    margin-right: 0; }
  .record-extra__item {
    padding-left: 2.4rem;
    padding-right: 2.4rem; }
  .record-extra-action {
    margin-top: 1.6rem; } }

.rate-content {
  text-align: center; }

.rate-header1 {
  font-family: 'FFMetaWebProNormal', sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #262626;
  text-transform: uppercase;
  margin-bottom: 24px;
  font-size: 1.6rem;
  line-height: 1.5em; }

.rate-header2 {
  font-family: 'PFCentroSlabPro-Thin', serif;
  font-weight: normal;
  font-style: normal;
  font-size: 4rem;
  line-height: 1em;
  color: #595959;
  text-align: center;
  margin-bottom: 24px; }

.rate-header3 {
  font-family: 'FFMetaWebProNormal', sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #595959;
  font-size: 1.6rem;
  line-height: 1.5em; }

.rate-vote {
  margin-top: 40px; }

.rv__row + .rv__row {
  margin-top: 24px; }

.rv__header {
  font-family: 'FFMetaWebProMedium', sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #262626;
  margin-bottom: 24px;
  font-size: 1.7rem;
  line-height: 1.41176em; }

.rv__rating input[type=radio] + label {
  padding-left: 0;
  line-height: inherit;
  font-size: inherit;
  min-height: inherit; }
  .rv__rating input[type=radio] + label:before, .rv__rating input[type=radio] + label:after {
    display: none; }

.rv__rating .vue-stars label {
  width: 40px;
  padding-left: 8px;
  padding-right: 8px; }

.rv__nums {
  font-family: 'FFMetaWebProNormal', sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #A0A0A0;
  font-size: 1.4rem;
  line-height: 1.71429em; }

.rate-success-content {
  margin-top: 112px; }

.rate-success-icon {
  margin-bottom: 16px; }

@media screen and (max-width: 740px) {
  .wrapper--thin {
    width: 100%; }
  .rate-header2 {
    font-size: 3rem;
    line-height: 1em; }
  .rv__rating .vue-stars label {
    width: 20px; }
    .rv__rating .vue-stars label svg {
      width: 100%;
      height: 100%; }
  .rv__row + .rv__row {
    margin-top: 1.6rem; }
  .rate-success-content {
    margin-top: 6rem; } }

.contacts-cols {
  display: flex; }
  .contacts-cols .col {
    width: 240px; }
  .contacts-cols .col + .col {
    margin-left: 72px; }
  .contacts-cols .col:first-child, .contacts-cols .col:last-child {
    width: 344px; }

.contact-header {
  font-family: 'FFMetaWebProMedium', sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #262626;
  letter-spacing: -0.22px;
  margin-bottom: 16px;
  font-size: 1.8rem;
  line-height: 1.11111em; }
  .theme-monochrome._black-accent .contact-header {
    color: #000 !important; }
  .theme-monochrome._white-accent .contact-header {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .contact-header {
    color: #472312 !important; }

.contact-row + .contact-row {
  margin-top: 16px; }

.contact-row p + p {
  margin-top: 8px; }

.contact-row + .contact-header {
  margin-top: 32px; }

.contact-action {
  margin-top: 32px; }

.contact-map {
  margin-top: 48px; }

@media screen and (max-width: 740px) {
  .contacts-cols {
    flex-direction: column; }
    .contacts-cols .col + .col {
      margin-left: 0;
      margin-top: 2.4rem; }
    .contacts-cols .col:last-child, .contacts-cols .col:first-child {
      width: 100%; } }

.regional-actions {
  position: relative; }

.region-select {
  display: inline-flex;
  align-items: center;
  color: #262626;
  letter-spacing: -0.4px;
  transition: color 0.3s;
  font-size: 3.2rem;
  line-height: 1em; }
  .region-select svg {
    stroke: #f26123;
    margin-left: 10px;
    transition: transform 0.3s; }
  .region-select:hover {
    color: #f26123; }
    .region-select:hover svg {
      transform: translateX(1rem); }

.regional-map {
  padding-top: 32px; }

#map {
  height: 600px; }

.regional-map, .regional-map-cnt {
  position: relative; }

.filial-info {
  width: 416px;
  background: #ffffff;
  border-radius: 4px;
  position: absolute;
  top: 56px;
  bottom: 56px;
  right: 64px;
  transition: transform 0.3s, opacity 0.3s, visibility 0.3s;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.4);
  overflow: auto; }
  .filial-info.is-visible {
    opacity: 1;
    visibility: visible;
    transform: scale(1); }
  .filial-info:after {
    content: '';
    position: absolute;
    left: 10px;
    right: 10px;
    top: 70px;
    bottom: 70px;
    background: rgba(255, 255, 255, 0.75);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s; }
  .filial-info:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 32px;
    height: 32px;
    background: url("/static/img/loader_brand2.png") left center no-repeat;
    -webkit-animation: play_loader 0.6s steps(18) infinite;
    animation: play_loader 0.6s steps(18) infinite;
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s; }
  .filial-info.is-loading:after, .filial-info.is-loading:before {
    opacity: 1;
    visibility: visible; }

.filial-info-content {
  padding: 72px 40px 40px; }

.filial-info-close {
  position: absolute;
  width: 14px;
  height: 14px;
  right: 24px;
  top: 24px;
  padding: 14px;
  border: 1px solid rgba(160, 160, 160, 0.25);
  border-radius: 50%;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDE0IDE0Ij48cGF0aCBkPSJNMTMgMUwxIDEzTTEgMWwxMiAxMiIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2U9IiMyNjI2MjYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIi8+PC9zdmc+);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  transition: transform 0.4s; }
  .filial-info-close:hover {
    transform: rotate(180deg); }

.fic-name {
  color: #262626;
  letter-spacing: -0.3px;
  margin-bottom: 16px;
  font-size: 2.4rem;
  line-height: 1em; }
  .theme-monochrome._black-accent .fic-name {
    color: #000 !important; }
  .theme-monochrome._white-accent .fic-name {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .fic-name {
    color: #472312 !important; }

.fic-address {
  margin-bottom: 32px; }

.filials__item {
  padding-top: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid rgba(160, 160, 160, 0.24);
  position: relative; }

.fi__body {
  display: flex; }
  @media screen and (max-width: 700px) {
    .fi__body {
      flex-direction: column; } }
  @media screen and (max-width: 900px) {
    .fi__body {
      justify-content: space-between;
      width: 65%; } }

@media screen and (max-width: 700px) {
  .fi__col {
    width: auto;
    margin-right: 0; } }

.fi__col:not(:first-child) {
  margin-left: 50px; }
  @media screen and (max-width: 700px) {
    .fi__col:not(:first-child) {
      margin-top: 10px; } }
  @media screen and (max-width: 800px) {
    .fi__col:not(:first-child) {
      margin-left: 0; } }

.fi__action {
  position: absolute;
  top: 48px;
  right: 16px; }

@media screen and (max-width: 740px) {
  .region-selector {
    margin-bottom: 2.4rem; }
  .region-select {
    width: calc(100% - 16px);
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
    padding-right: 16px;
    font-size: 2.4rem;
    line-height: 1em; }
    .region-select svg {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      margin-left: 0; }
  .regional-map {
    padding-top: 0;
    margin-top: -24px;
    margin-left: -24px;
    margin-right: -24px; }
  #map {
    height: 400px; }
  .filial-info {
    top: auto;
    bottom: auto;
    right: auto;
    width: auto;
    margin-top: -40vh;
    margin-left: 24px;
    margin-right: 24px; }
    .filial-info.is-visible {
      position: relative; }
  .filial-info-content {
    padding-left: 24px;
    padding-right: 24px; }
  .filial-info-close {
    right: auto;
    top: 16px;
    left: 50%;
    transform: translateX(-50%); }
    .filial-info-close:hover {
      transform: translateX(-50%); }
  .fi__action {
    position: static;
    margin-top: 24px; } }

.filial-detail__content {
  display: flex; }

.filial-detail__info {
  flex-grow: 1; }

.filial-detail__aside {
  width: 44.8rem;
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 7.2rem; }
  .filial-detail__aside .map {
    height: 36rem; }

.filial-detail__block + .filial-detail__block {
  margin-top: 3.2rem; }

.filial-detail__block--important {
  position: relative;
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
  z-index: 0; }
  .filial-detail__block--important:before {
    content: '';
    position: absolute;
    left: -2.4rem;
    right: -2.4rem;
    top: 0;
    bottom: 0;
    background: #F7F7F7;
    border-radius: 4px;
    z-index: -1; }

@media screen and (max-width: 740px) {
  .filial-detail__content {
    flex-direction: column; }
  .filial-detail__aside {
    width: auto;
    margin-top: 3.2rem;
    margin-left: -2.4rem;
    margin-right: -2.4rem; }
    .filial-detail__aside .map {
      height: 28rem; } }

.products__item {
  display: flex;
  align-items: center; }
  .products__item + .products__item {
    margin-top: 80px; }
    @media screen and (max-width: 800px) {
      .products__item + .products__item {
        margin-top: 40px; } }
  .products__item:nth-child(even) {
    flex-direction: row-reverse; }
    .products__item:nth-child(even) .products__description {
      margin-right: 0;
      margin-left: 64px;
      transform: translateX(32px); }
    .products__item:nth-child(even) .products__image {
      transform: translateX(-32px); }
  .products__item.view-in .products__description {
    opacity: 1;
    transform: translateX(0); }
  .products__item.view-in .products__image {
    opacity: 1;
    transform: translateX(0); }
  @media screen and (max-width: 800px) {
    .products__item {
      flex-direction: column-reverse; }
      .products__item:nth-child(even) {
        flex-direction: column-reverse; }
        .products__item:nth-child(even) .products__description {
          margin-left: 0;
          transform: translateY(24px); }
        .products__item:nth-child(even) .products__image {
          transform: translateY(-24px); }
      .products__item.view-in .products__description {
        transform: translateY(0); }
      .products__item.view-in .products__image {
        transform: translateY(0); } }

.products__description {
  margin-right: 64px;
  padding-left: 64px;
  position: relative;
  transition: opacity 0.5s, transform 0.5s;
  opacity: 0;
  transform: translateX(-32px); }
  @media screen and (max-width: 800px) {
    .products__description {
      margin-top: 32px;
      margin-right: 0;
      padding-left: 0;
      transform: translateY(24px); } }

.products__image {
  width: 560px;
  flex-shrink: 0;
  flex-grow: 0;
  transition: opacity 0.5s, transform 0.5s;
  opacity: 0;
  transform: translateX(32px); }
  @media screen and (max-width: 800px) {
    .products__image {
      width: 100%;
      transform: translateY(-24px); } }
  .products__image picture, .products__image img {
    display: block;
    width: 100%; }

.products__icon {
  width: 48px;
  height: 48px;
  position: absolute;
  left: 0;
  top: -12px; }
  .products__icon img {
    width: 100%;
    display: block; }

.products__type {
  font-size: 3.2rem;
  line-height: 1em;
  color: #262626;
  letter-spacing: -0.4px;
  margin-bottom: 16px; }
  .theme-monochrome._black-accent .products__type {
    color: #000 !important; }
  .theme-monochrome._white-accent .products__type {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .products__type {
    color: #472312 !important; }
  @media screen and (max-width: 800px) {
    .products__type {
      padding-left: 64px; } }

.products__text {
  color: #595959;
  font-size: 1.4rem;
  line-height: 1.71429em; }
  .theme-monochrome._black-accent .products__text {
    color: #000 !important; }
  .theme-monochrome._white-accent .products__text {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .products__text {
    color: #472312 !important; }

.products__links {
  margin-top: 24px; }

.products__link {
  color: #262626;
  letter-spacing: -0.5px;
  margin-right: 20px;
  font-size: 1.7rem;
  line-height: 1.41176em; }
  .theme-monochrome._black-accent .products__link {
    color: #000 !important; }
  .theme-monochrome._white-accent .products__link {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .products__link {
    color: #472312 !important; }
  @media screen and (max-width: 800px) {
    .products__link {
      font-size: 1.7rem;
      line-height: 1.41176em;
      margin-bottom: 8px;
      width: auto; } }

[data-page="news"] h1 {
  margin-bottom: 0; }

.years-filter {
  position: relative; }

.years-filter__list {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -3px;
  text-align: right; }

.years-filter__item {
  margin-right: 24px;
  box-shadow: inset 0 -4px 0 0 transparent;
  transition: box-shadow 0.3s;
  display: inline-block; }
  .years-filter__item.active, input:-webkit-autofill + label.years-filter__item {
    box-shadow: inset 0 -4px 0 0 #F36C21; }
    .years-filter__item.active:hover, input:-webkit-autofill + label.years-filter__item:hover {
      box-shadow: inset 0 -4px 0 0 #F36C21; }
  .years-filter__item:hover {
    box-shadow: inset 0 -4px 0 0 rgba(243, 108, 33, 0.2); }
  .years-filter__item:last-child {
    margin-right: 0; }

.years-filter__link {
  text-transform: uppercase;
  padding-bottom: 16px;
  display: block;
  font-size: 1.6rem; }

.news__list.is-loading {
  position: relative; }
  .news__list.is-loading::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(255, 255, 255, 0.75);
    z-index: 1; }
  .news__list.is-loading:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    bottom: 0;
    right: 0;
    transform: translate(-50%, -50%);
    width: 32px;
    height: 32px;
    background: rgba(255, 255, 255, 0.75) url("/static/img/loader_brand2.png") left center no-repeat;
    animation: play_loader 0.6s steps(18) infinite;
    z-index: 2; }

.news__list .no-results {
  min-height: 240px; }

.news__item {
  display: flex;
  align-items: flex-start;
  position: relative;
  padding-top: 32px;
  padding-bottom: 32px;
  border-bottom: 1px solid rgba(160, 160, 160, 0.24);
  transition: opacity 0.6s, transform 0.6s;
  opacity: 0;
  transform: translateX(-32px); }
  .news__item.view-in {
    opacity: 1;
    transform: translateX(0); }
  .news__item:after {
    content: '';
    width: 40px;
    height: 40px;
    position: absolute;
    border-radius: 50%;
    right: 16px;
    top: 72px;
    box-sizing: border-box;
    background-position: calc(50% + 1px) 50%;
    background-repeat: no-repeat;
    transform-origin: 50% 50%;
    transition: border-width 0.2s, border-color 0.2s, background-color 0.2s, background-image 0.2s;
    border: 1px solid rgba(160, 160, 160, 0.15);
    background-color: transparent;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSIxMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMSA5bDQtNC00LTQiIHN0cm9rZT0iIzU5NTk1OSIgc3Ryb2tlLXdpZHRoPSIyIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPjwvc3ZnPg==); }
  .news__item:hover:after {
    border-color: #f26123;
    background-color: #f26123;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSIxMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMSA5bDQtNC00LTQiIHN0cm9rZT0iI0ZGRiIgc3Ryb2tlLXdpZHRoPSIyIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPjwvc3ZnPg==); }

.news__image {
  width: 284px;
  flex-shrink: 0;
  flex-grow: 0;
  margin-right: 40px; }
  .news__image picture, .news__image img {
    display: block;
    width: 100%; }
  .news__image + .news__preview {
    width: 508px;
    padding-right: 0; }

.news__preview {
  width: auto;
  padding-right: 64px; }

.news-preview__date {
  color: #A0A0A0;
  letter-spacing: 0;
  margin-bottom: 16px;
  font-size: 1.4rem;
  line-height: 1.14286em; }
  .theme-monochrome._black-accent .news-preview__date {
    color: #000 !important; }
  .theme-monochrome._white-accent .news-preview__date {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .news-preview__date {
    color: #472312 !important; }

.news-preview__header {
  margin-bottom: 16px;
  color: #262626;
  letter-spacing: -0.3px;
  font-size: 2.4rem;
  line-height: 1em; }
  .theme-monochrome._black-accent .news-preview__header {
    color: #000 !important; }
  .theme-monochrome._white-accent .news-preview__header {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .news-preview__header {
    color: #472312 !important; }

.news-preview__text {
  font-size: 1.6rem;
  line-height: 1.5em;
  color: #595959;
  letter-spacing: 0; }
  .theme-monochrome._black-accent .news-preview__text {
    color: #000 !important; }
  .theme-monochrome._white-accent .news-preview__text {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .news-preview__text {
    color: #472312 !important; }

.news__date {
  color: #262626; }

.news-inner__content {
  margin-top: 4rem;
  color: #595959;
  transition: opacity 0.6s, transform 0.6s;
  opacity: 0; }
  .news-inner__content.view-in {
    opacity: 1; }

.news-inner__action {
  margin-top: 40px;
  padding-top: 32px;
  border-top: 1px solid rgba(160, 160, 160, 0.24); }

@media screen and (max-width: 740px) {
  .years-filter {
    margin-top: 32px; }
  .years-filter__list {
    position: static;
    text-align: left;
    white-space: nowrap;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    direction: rtl; }
  .years-filter__item {
    margin-left: 24px;
    margin-right: 0; }
  .news__item {
    flex-direction: column; }
    .news__item:after {
      position: static;
      margin-top: 16px;
      content: 'Подробнее';
      background-image: none;
      width: auto;
      height: auto;
      padding: 12px 24px;
      background-color: #f26123;
      color: #ffffff;
      border-radius: 4px; }
    .news__item:hover:after {
      background-image: none; }
  .news__image {
    width: 100%;
    margin-right: 0;
    margin-bottom: 32px; }
    .news__image + .news__preview {
      width: 100%; }
  .news__preview {
    width: 100%;
    padding-right: 0; } }

.documents-group + .documents-group {
  margin-top: 48px; }

.documents-group__header {
  color: #262626;
  letter-spacing: -0.4px;
  font-size: 3.2rem;
  line-height: 1em; }
  .theme-monochrome._black-accent .documents-group__header {
    color: #000 !important; }
  .theme-monochrome._white-accent .documents-group__header {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .documents-group__header {
    color: #472312 !important; }
  @media screen and (max-width: 800px) {
    .documents-group__header {
      font-size: 2.4rem; }
      .documents-group__header .lk__action {
        font-size: inherit; } }

.documents-group__content {
  margin-top: 24px; }

.documents-group__section {
  margin-top: 40px;
  border-bottom: 1px solid rgba(160, 160, 160, 0.24); }
  .theme-monochrome._black-accent .documents-group__section {
    border-bottom-color: #000 !important; }
  .theme-monochrome._white-accent .documents-group__section {
    border-bottom-color: #fff !important; }
  .theme-monochrome._sepia-accent .documents-group__section {
    border-bottom-color: #472312 !important; }
  @media screen and (max-width: 800px) {
    .documents-group__section {
      border-bottom: none; } }

.documents-group__section:last-child {
  border-bottom: none; }

.documents__item {
  display: flex;
  margin-bottom: 56px;
  transition: opacity 0.6s, transform 0.6s;
  opacity: 0;
  transform: scale(0.9); }
  @media screen and (max-width: 800px) {
    .documents__item {
      margin-bottom: 0; } }
  .documents__item.view-in {
    opacity: 1;
    transform: scale(1); }
  .documents__item:hover .document__name {
    color: #58595b; }
    .theme-monochrome._black-accent .documents__item:hover .document__name {
      color: #000 !important; }
    .theme-monochrome._white-accent .documents__item:hover .document__name {
      color: #fff !important; }
    .theme-monochrome._sepia-accent .documents__item:hover .document__name {
      color: #472312 !important; }
  .documents__item:hover .document__date {
    color: #595959; }
    .theme-monochrome._black-accent .documents__item:hover .document__date {
      color: #000 !important; }
    .theme-monochrome._white-accent .documents__item:hover .document__date {
      color: #fff !important; }
    .theme-monochrome._sepia-accent .documents__item:hover .document__date {
      color: #472312 !important; }

.documents__preview {
  width: 120px;
  flex-grow: 0;
  flex-shrink: 0; }
  .documents__preview + .documents__info {
    position: relative;
    padding-left: 24px; }
    .documents__preview + .documents__info .document__date {
      position: absolute;
      bottom: 24px; }
      @media screen and (max-width: 800px) {
        .documents__preview + .documents__info .document__date {
          position: static; } }
  .documents__preview picture, .documents__preview img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover; }

.documents-list__preview {
  width: 50px; }

.documents__preview__image {
  width: 53px !important;
  height: 66px !important; }

.documents__info {
  max-width: 100%; }

.document__name {
  color: #f26123;
  transition: color 0.3s; }
  .theme-monochrome._black-accent .document__name {
    color: #000 !important; }
  .theme-monochrome._white-accent .document__name {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .document__name {
    color: #472312 !important; }

.document__size {
  color: #6B7B84;
  letter-spacing: 0;
  margin-top: 8px;
  font-size: 1.1rem;
  line-height: 2.18182em; }
  .theme-monochrome._black-accent .document__size {
    color: #000 !important; }
  .theme-monochrome._white-accent .document__size {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .document__size {
    color: #472312 !important; }

.document-list__size {
  line-height: 10px; }

.document__date {
  color: #595959;
  letter-spacing: 0;
  margin-top: 8px;
  font-size: 1.4rem;
  line-height: 1.28571em; }
  .theme-monochrome._black-accent .document__date {
    color: #000 !important; }
  .theme-monochrome._white-accent .document__date {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .document__date {
    color: #472312 !important; }

.document-list__date {
  bottom: 34px !important;
  width: max-content;
  position: static !important; }

.corp__hero {
  margin-left: -72px;
  margin-right: -72px;
  position: relative;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 64px;
  opacity: 0;
  transition: opacity 0.5s; }
  @media screen and (max-width: 600px) {
    .corp__hero {
      background: none !important; } }
  @media screen and (max-width: 800px) {
    .corp__hero {
      margin-left: -24px;
      margin-right: -24px;
      margin-bottom: 40px; } }
  .corp__hero * {
    transform: translateY(-20px);
    transition: opacity 0.6s, transform 0.6s;
    transition-delay: 0.5s;
    opacity: 0; }
  .corp__hero.view-in {
    opacity: 1; }
    .corp__hero.view-in * {
      transform: translateY(0);
      opacity: 1; }
  .corp__hero:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4); }
    @media screen and (max-width: 600px) {
      .corp__hero:before {
        display: none; } }
  @media screen and (max-width: 800px) {
    .corp__hero--margin {
      margin-left: -16px;
      margin-right: -16px;
      margin-bottom: 0; } }

.corp-hero__header {
  color: #FFFFFF;
  letter-spacing: -0.4px;
  margin-bottom: 48px;
  font-size: 2.4rem;
  line-height: 1.33333em; }
  .theme-monochrome._black-accent .corp-hero__header {
    color: #000 !important; }
  .theme-monochrome._white-accent .corp-hero__header {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .corp-hero__header {
    color: #472312 !important; }
  @media screen and (max-width: 600px) {
    .corp-hero__header {
      color: #58595b;
      font-size: 2.4rem;
      line-height: 1.33333em; } }
  @media screen and (max-width: 800px) {
    .corp-hero__header {
      font-size: 2rem;
      line-height: 1.2em; } }

.corp-hero__content {
  color: #ffffff;
  position: relative; }
  .theme-monochrome._black-accent .corp-hero__content {
    color: #000 !important; }
  .theme-monochrome._white-accent .corp-hero__content {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .corp-hero__content {
    color: #472312 !important; }
  @media screen and (max-width: 600px) {
    .corp-hero__content {
      color: #58595b; } }
  @media screen and (max-width: 800px) {
    .corp-hero__content {
      padding: 16px; } }
  .corp-hero__content p {
    max-width: 65.6rem; }
    .corp-hero__content p.header {
      color: #FFFFFF;
      letter-spacing: -0.4px;
      margin-bottom: 48px;
      font-size: 2.4rem;
      line-height: 1.33333em; }
      .theme-monochrome._black-accent .corp-hero__content p.header {
        color: #000 !important; }
      .theme-monochrome._white-accent .corp-hero__content p.header {
        color: #fff !important; }
      .theme-monochrome._sepia-accent .corp-hero__content p.header {
        color: #472312 !important; }
    .corp-hero__content p.header--larger {
      font-size: 3.2rem; }
    @media screen and (max-width: 600px) {
      .corp-hero__content p.header--border {
        border-top: 1px solid #f1f1f1;
        padding-top: 24px;
        margin-bottom: 24px; } }
  .corp-hero__content h1 {
    color: #ffffff; }
    .theme-monochrome._black-accent .corp-hero__content h1 {
      color: #000 !important; }
    .theme-monochrome._white-accent .corp-hero__content h1 {
      color: #fff !important; }
    .theme-monochrome._sepia-accent .corp-hero__content h1 {
      color: #472312 !important; }

.corp-feature {
  padding-left: 72px;
  position: relative;
  transition: opacity 0.6s, transform 0.6s;
  opacity: 0;
  transform: scale(0.9); }
  .corp-feature + .corp-feature {
    margin-top: 48px; }
  .corp-feature.view-in {
    opacity: 1;
    transform: scale(1); }

.cf-icon {
  width: 56px;
  height: 56px;
  position: absolute;
  left: 0;
  top: -8px; }
  .cf-icon img {
    display: block; }

.cf-content h5 {
  margin-bottom: 16px; }

.cf-content ul {
  margin-top: 16px; }

.corp__tabs {
  margin-top: 80px;
  margin-bottom: 80px; }

#floating-button {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  z-index: 99999999;
  background: #f26123;
  position: fixed;
  bottom: 30px;
  right: 30px;
  cursor: pointer;
  box-shadow: 0 2px 5px #666; }

.plus {
  color: white;
  position: absolute;
  top: 3px;
  display: block;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 0;
  margin: 0;
  font-weight: 300;
  animation: plus-out 0.3s;
  transition: all 0.3s;
  font-size: 3.2rem;
  line-height: 1.71875em; }

#container-floating {
  position: fixed;
  width: 70px;
  height: 70px;
  z-index: 99999999;
  bottom: 30px;
  right: 30px; }

#container-floating:hover {
  height: 400px;
  width: 90px;
  padding: 30px; }

#container-floating:hover .plus {
  animation: plus-in 0.15s linear;
  animation-fill-mode: forwards; }

.edit {
  position: absolute;
  top: 0;
  display: block;
  bottom: 0;
  left: 0;
  display: block;
  right: 0;
  padding: 0;
  opacity: 0;
  margin: auto;
  line-height: 65px;
  transform: rotateZ(-70deg);
  transition: all 0.3s;
  animation: edit-out 0.3s; }

#container-floating:hover .edit {
  animation: edit-in 0.2s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards; }

@keyframes edit-in {
  from {
    opacity: 0;
    transform: rotateZ(-70deg); }
  to {
    opacity: 1;
    transform: rotateZ(0deg); } }

@keyframes edit-out {
  from {
    opacity: 1;
    transform: rotateZ(0deg); }
  to {
    opacity: 0;
    transform: rotateZ(-70deg); } }

@keyframes plus-in {
  from {
    opacity: 1;
    transform: rotateZ(0deg); }
  to {
    opacity: 0;
    transform: rotateZ(180deg); } }

@keyframes plus-out {
  from {
    opacity: 0;
    transform: rotateZ(180deg); }
  to {
    opacity: 1;
    transform: rotateZ(0deg); } }

.nds {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: fixed;
  z-index: 99999999;
  transform: scale(0);
  cursor: pointer; }

.nd1 {
  background: #f26123;
  right: 40px;
  bottom: 120px;
  animation-delay: 0.2s;
  animation: bounce-out-nds 0.3s linear;
  animation-fill-mode: forwards; }

.nd3 {
  background: #f7f7f7;
  right: 40px;
  bottom: 180px;
  animation-delay: 0.15s;
  animation: bounce-out-nds 0.15s linear;
  animation-fill-mode: forwards; }

.nd4 {
  background: #f26123;
  right: 40px;
  bottom: 240px;
  animation-delay: 0.1s;
  animation: bounce-out-nds 0.1s linear;
  animation-fill-mode: forwards; }

.nd5 {
  background-image: url("https://lh3.googleusercontent.com/-X-aQXHatDQY/Uy86XLOyEdI/AAAAAAAAAF0/TBEZvkCnLVE/w140-h140-p/fb3a11ae-1fb4-4c31-b2b9-bf0cfa835c27");
  background-size: 100%;
  right: 40px;
  bottom: 300px;
  animation-delay: 0.08s;
  animation: bounce-out-nds 0.1s linear;
  animation-fill-mode: forwards; }

@keyframes bounce-nds {
  from {
    opacity: 0; }
  to {
    opacity: 1;
    transform: scale(1); } }

@keyframes bounce-out-nds {
  from {
    opacity: 1;
    transform: scale(1); }
  to {
    opacity: 0;
    transform: scale(0); } }

#container-floating:hover .nds {
  animation: bounce-nds 0.1s linear;
  animation-fill-mode: forwards; }

#container-floating:hover .nd3 {
  animation-delay: 0.08s; }

#container-floating:hover .nd4 {
  animation-delay: 0.15s; }

#container-floating:hover .nd5 {
  animation-delay: 0.2s; }

.letter {
  font-family: 'Roboto', sans-serif;
  color: white;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  font-size: 2.3rem;
  line-height: 1.73913em; }

.reminder {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  line-height: 40px; }

.profile {
  border-radius: 50%;
  width: 40px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 20px; }

.nds .tooltiptext {
  visibility: hidden;
  background-color: #f7f7f7;
  color: #898989;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 99999999;
  top: -5px;
  right: 105%;
  width: max-content;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px;
  margin-right: 10px; }

.nds:hover .tooltiptext {
  visibility: visible; }

.main_tooltip .tooltiptext {
  visibility: hidden;
  background-color: #f7f7f7;
  color: #898989;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 99999999;
  right: 105%;
  width: max-content;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px;
  margin-right: 10px; }

.main_tooltip:hover .tooltiptext {
  visibility: visible; }

.header--white {
  color: #ffffff;
  letter-spacing: -1px;
  margin-bottom: 32px;
  font-size: 4.8rem;
  line-height: 1em; }
  .theme-monochrome._black-accent .header--white {
    color: #000 !important; }
  .theme-monochrome._white-accent .header--white {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .header--white {
    color: #472312 !important; }
  @media screen and (max-width: 600px) {
    .header--white {
      font-size: 3rem;
      line-height: 1em; } }

@media screen and (max-width: 740px) {
  .corp-feature {
    padding-left: 0; }
  .cf-icon {
    position: static;
    margin-bottom: 24px; }
  .corp__tabs {
    margin-top: 56px;
    margin-bottom: 56px; } }

.vacancies__intro {
  transition: opacity 0.6s, transform 0.6s;
  transition-delay: 0.3s;
  opacity: 0;
  transform: translateY(10px); }
  .vacancies__intro.view-in {
    opacity: 1;
    transform: translateY(0); }

.vacancies__features {
  margin-bottom: 48px;
  margin-top: 32px; }

.vacancies__extra {
  border: 4px solid #f7f7f7 !important;
  padding-left: 10px !important;
  width: calc((94% - 6.4rem) / 2) !important; }
  .theme-monochrome._black-ground .vacancies__extra {
    border-color: #000 !important; }
  .theme-monochrome._white-ground .vacancies__extra {
    border-color: #fff !important; }
  .theme-monochrome._sepia-ground .vacancies__extra {
    border-color: #F5F5DC !important; }
  .vacancies__extra .vac-item__actions {
    padding-left: 10px !important; }

.vacancies__feature {
  transition: opacity 0.6s, transform 0.6s;
  opacity: 0;
  transform: scale(0.9); }
  .vacancies__feature.view-in {
    opacity: 1;
    transform: scale(1); }

.vac-feature__icon {
  margin-bottom: 16px; }

.vac-feature__text {
  color: #262626;
  text-transform: uppercase;
  font-size: 1.6rem;
  line-height: 1.5em; }
  .theme-monochrome._black-accent .vac-feature__text {
    color: #000 !important; }
  .theme-monochrome._white-accent .vac-feature__text {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .vac-feature__text {
    color: #472312 !important; }

.vac-search {
  position: relative;
  margin-bottom: 0; }

.vac-search__submit {
  position: absolute;
  border: none;
  background: none;
  box-shadow: none;
  padding: 10px;
  right: 0;
  top: 28px;
  cursor: pointer;
  transition: transform 0.3s; }
  .vac-search__submit:hover {
    transform: scale(1.2); }

.vac-search__clear {
  width: 20px;
  height: 20px;
  position: absolute;
  border: 1px solid rgba(160, 160, 160, 0.25);
  border-radius: 50%;
  right: 40px;
  top: 33px;
  transition: transform .3s, opacity .3s, visibility .3s;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDE0IDE0Ij48cGF0aCBkPSJNMTMgMUwxIDEzTTEgMWwxMiAxMiIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2U9IiMyNjI2MjYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIi8+PC9zdmc+);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 40%;
  opacity: 0;
  visibility: hidden;
  transform: translateX(100%);
  background-color: transparent;
  display: block; }
  .theme-monochrome._black-accent .vac-search__clear {
    border-color: #000 !important; }
  .theme-monochrome._white-accent .vac-search__clear {
    border-color: #fff !important; }
  .theme-monochrome._sepia-accent .vac-search__clear {
    border-color: #472312 !important; }
  .vac-search__clear.active, input:-webkit-autofill + label.vac-search__clear {
    opacity: 1;
    visibility: visible;
    transform: translateX(0) scale(1); }
  .vac-search__clear:hover {
    cursor: pointer;
    transform: translateX(0) scale(1.2); }

.vac-regions-select {
  position: absolute;
  right: 32px;
  top: 32px; }
  .vac-regions-select select {
    opacity: 0;
    padding: 0;
    position: absolute;
    margin-top: 0;
    font-size: 1.7rem;
    line-height: 1.41176em; }
  .vac-regions-select label {
    position: relative;
    padding-right: 16px;
    font-size: 1.7rem;
    line-height: 1.41176em; }
    .vac-regions-select label:hover {
      cursor: pointer; }
    .vac-regions-select label:after {
      content: '';
      position: absolute;
      right: 0;
      top: 4px;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSI1IiB2aWV3Qm94PSIwIDAgMTAgNSI+ICA8cG9seWxpbmUgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjRjM2QzIxIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMiIgcG9pbnRzPSIyMCAxNSAxNiAxOCAxMiAxNSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTExIC0xNCkiLz48L3N2Zz4=);
      background-position: 50% 50%;
      background-repeat: no-repeat;
      width: 10px;
      height: 6px; }

.vacancies__list.is-loading {
  position: relative; }
  .vacancies__list.is-loading:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.75);
    z-index: 1; }
  .vacancies__list.is-loading:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 32px;
    height: 32px;
    background: url("/static/img/loader_brand2.png") left center no-repeat;
    -webkit-animation: play_loader 0.6s steps(18) infinite;
    animation: play_loader 0.6s steps(18) infinite;
    z-index: 2; }

.vacancies__list .no-results {
  min-height: 240px; }

.no-results {
  margin-top: 60px; }

.vacancies__item {
  border-top: 1px solid rgba(160, 160, 160, 0.24);
  padding-top: 48px;
  position: relative;
  transition: opacity 0.6s, transform 0.6s;
  opacity: 0;
  transform: scale(0.9); }
  .theme-monochrome._black-accent .vacancies__item {
    border-top-color: #000 !important; }
  .theme-monochrome._white-accent .vacancies__item {
    border-top-color: #fff !important; }
  .theme-monochrome._sepia-accent .vacancies__item {
    border-top-color: #472312 !important; }
  .vacancies__item.view-in {
    opacity: 1;
    transform: scale(1); }
  .vacancies__item:nth-child(1), .vacancies__item:nth-child(2) {
    border-top: none; }

.vac-item {
  display: flex;
  flex-direction: column; }

.vac-item__region {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  color: #595959;
  letter-spacing: -0.2px;
  font-size: 1.6rem;
  line-height: 1.25em; }
  .theme-monochrome._black-accent .vac-item__region {
    color: #000 !important; }
  .theme-monochrome._white-accent .vac-item__region {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .vac-item__region {
    color: #472312 !important; }
  .vac-item__region p + p {
    margin-top: 0.8rem; }
  .vac-item__region svg {
    display: block;
    stroke: #f26123;
    margin-right: 12px; }
    .theme-monochrome._black-accent .vac-item__region svg {
      stroke: #000 !important; }
    .theme-monochrome._white-accent .vac-item__region svg {
      stroke: #fff !important; }
    .theme-monochrome._sepia-accent .vac-item__region svg {
      stroke: #472312 !important; }

.vac-item__name {
  color: #262626;
  letter-spacing: -0.3px;
  margin-bottom: 16px;
  font-size: 2.4rem;
  line-height: 1em; }
  .theme-monochrome._black-accent .vac-item__name {
    color: #000 !important; }
  .theme-monochrome._white-accent .vac-item__name {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .vac-item__name {
    color: #472312 !important; }

.vac-item__type {
  color: #262626;
  letter-spacing: -0.2px;
  margin-bottom: 24px;
  font-size: 1.6rem;
  line-height: 1.25em; }
  .theme-monochrome._black-accent .vac-item__type {
    color: #000 !important; }
  .theme-monochrome._white-accent .vac-item__type {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .vac-item__type {
    color: #472312 !important; }

.vac-item__income {
  color: #595959;
  letter-spacing: 0;
  font-size: 1.7rem;
  line-height: 1.41176em; }
  .theme-monochrome._black-accent .vac-item__income {
    color: #000 !important; }
  .theme-monochrome._white-accent .vac-item__income {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .vac-item__income {
    color: #472312 !important; }

.vac-item__actions {
  padding-bottom: 32px;
  padding-top: 32px;
  margin-top: auto; }

.vac-for-students {
  border: 1px solid #E8E8E8;
  padding: 24px; }
  .theme-monochrome._black-accent .vac-for-students {
    border-color: #000 !important; }
  .theme-monochrome._white-accent .vac-for-students {
    border-color: #fff !important; }
  .theme-monochrome._sepia-accent .vac-for-students {
    border-color: #472312 !important; }

.vfs__header {
  margin-top: 16px;
  margin-bottom: 16px;
  color: #262626;
  letter-spacing: -0.22px;
  font-size: 1.8rem;
  line-height: 1.11111em; }
  .theme-monochrome._black-accent .vfs__header {
    color: #000 !important; }
  .theme-monochrome._white-accent .vfs__header {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .vfs__header {
    color: #472312 !important; }

.vfs__text {
  color: #595959;
  letter-spacing: -0.2px;
  font-size: 1.6rem;
  line-height: 1.25em; }
  .theme-monochrome._black-accent .vfs__text {
    color: #000 !important; }
  .theme-monochrome._white-accent .vfs__text {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .vfs__text {
    color: #472312 !important; }

.vacancy__geo {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  transition: opacity 0.6s, transform 0.6s;
  transition-delay: 0.3s;
  opacity: 0; }
  .vacancy__geo .vac-item__region {
    margin-right: 32px;
    margin-bottom: 0; }
  .vacancy__geo.view-in {
    opacity: 1; }

.vacancy-geo__action {
  color: #262626;
  letter-spacing: -0.5px;
  display: block;
  font-size: 1.7rem;
  line-height: 1em; }
  .theme-monochrome._black-accent .vacancy-geo__action {
    color: #000 !important; }
  .theme-monochrome._white-accent .vacancy-geo__action {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .vacancy-geo__action {
    color: #472312 !important; }

.vacancy__hero {
  margin-top: 24px;
  transition: opacity 0.6s, transform 0.6s;
  transition-delay: 0.3s;
  opacity: 0;
  transform: translateY(10px); }
  .vacancy__hero.view-in {
    opacity: 1;
    transform: translateY(0); }

.vac-hero__header {
  margin-bottom: 24px; }

.vac-hero__footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end; }

.vacancy__type {
  color: #262626;
  letter-spacing: -0.2px;
  font-size: 1.6rem;
  line-height: 1.25em; }
  .theme-monochrome._black-accent .vacancy__type {
    color: #000 !important; }
  .theme-monochrome._white-accent .vacancy__type {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .vacancy__type {
    color: #472312 !important; }

.vacancy__income {
  color: #262626;
  letter-spacing: -0.3px;
  margin-bottom: 8px;
  font-size: 2.4rem;
  line-height: 1em; }
  .theme-monochrome._black-accent .vacancy__income {
    color: #000 !important; }
  .theme-monochrome._white-accent .vacancy__income {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .vacancy__income {
    color: #472312 !important; }

.vacancy__time {
  color: #A0A0A0;
  font-size: 1.4rem;
  line-height: 1.71429em; }
  .theme-monochrome._black-accent .vacancy__time {
    color: #000 !important; }
  .theme-monochrome._white-accent .vacancy__time {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .vacancy__time {
    color: #472312 !important; }

.vac-hero__action {
  margin-left: 32px;
  flex-grow: 0;
  flex-shrink: 0; }

.vacancy__section {
  margin-bottom: 40px;
  transition: opacity 0.6s, transform 0.6s;
  opacity: 0;
  transform: translateY(10px); }
  .vacancy__section.view-in {
    opacity: 1;
    transform: translateY(0); }

.vacancy__footer {
  border-top: 1px solid rgba(160, 160, 160, 0.24);
  padding-top: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 32px; }

@media screen and (max-width: 740px) {
  .vacancies__feature {
    display: flex;
    align-items: center; }
  .vac-feature__icon {
    margin-bottom: 0;
    margin-right: 12px; }
  .vac-search__form {
    display: flex;
    flex-direction: column-reverse;
    align-items: stretch; }
  .vac-regions-select {
    position: static; }
  .vacancies__item {
    padding-top: 40px; }
  .vac-item__actions {
    position: static;
    display: flex;
    align-items: center;
    margin-top: 32px; }
    .vac-item__actions a.btn + .lk__action {
      margin-top: 0;
      margin-left: 24px; }
  .vacancies__item:nth-child(2) {
    border-top: 1px solid rgba(160, 160, 160, 0.24); }
  .vacancies__list > .col + .col {
    margin-top: 0; }
  .vacancy__geo {
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch; }
  .vac-item__region {
    margin-right: 0; }
  .vacancy-geo__action {
    margin-top: 24px;
    padding-left: 24px; }
  .vacancies__extra {
    border: 4px solid #f7f7f7 !important;
    padding-left: 10px !important;
    margin-bottom: 10px !important;
    width: 100% !important; }
    .theme-monochrome._black-ground .vacancies__extra {
      border-color: #000 !important; }
    .theme-monochrome._white-ground .vacancies__extra {
      border-color: #fff !important; }
    .theme-monochrome._sepia-ground .vacancies__extra {
      border-color: #F5F5DC !important; }
  .vacancy__hero {
    margin-left: -24px;
    margin-right: -24px; }
  .vac-hero__footer {
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch; }
  .vac-hero__action {
    margin-left: 0;
    margin-top: 24px; }
  .vacancy__footer {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; }
    .vacancy__footer .btn {
      margin-top: 24px; } }

.product__hero {
  margin-left: -72px;
  margin-right: -72px;
  position: relative;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0;
  transition: opacity 0.5s; }
  .theme-monochrome._black-ground .product__hero {
    background: #000 !important; }
  .theme-monochrome._white-ground .product__hero {
    background: #fff !important; }
  .theme-monochrome._sepia-ground .product__hero {
    background: #F5F5DC !important; }
  @media screen and (max-width: 600px) {
    .product__hero {
      background: none !important; } }
  @media screen and (max-width: 800px) {
    .product__hero {
      margin-left: -16px;
      margin-right: -16px; } }
  .theme-images .product__hero {
    background-image: none !important; }
  .product__hero * {
    transform: translateY(-20px);
    transition: opacity 0.6s, transform 0.6s;
    transition-delay: 0.5s;
    opacity: 0;
    position: relative; }
  .product__hero.view-in {
    opacity: 1; }
    .product__hero.view-in * {
      transform: translateY(0);
      opacity: 1; }
  .product__hero:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: linear-gradient(-90deg, rgba(0, 0, 0, 0) 24%, rgba(0, 0, 0, 0.03) 28%, rgba(0, 0, 0, 0.06) 33%, rgba(0, 0, 0, 0.09) 39%, rgba(0, 0, 0, 0.13) 44%, rgba(0, 0, 0, 0.17) 50%, rgba(0, 0, 0, 0.21) 56%, rgba(0, 0, 0, 0.25) 62%, rgba(0, 0, 0, 0.29) 68%, rgba(0, 0, 0, 0.33) 74%, rgba(0, 0, 0, 0.37) 80%, rgba(0, 0, 0, 0.4) 85%, rgba(0, 0, 0, 0.44) 91%, rgba(0, 0, 0, 0.47) 96%, rgba(0, 0, 0, 0.5) 100%); }
    @media screen and (max-width: 600px) {
      .product__hero:before {
        background-image: none; } }

.product__hero--minh {
  min-height: 560px; }
  @media screen and (max-width: 800px) {
    .product__hero--minh {
      min-height: inherit; } }

.product__complex-slider {
  margin-left: -72px;
  margin-right: -72px;
  padding-top: 64px;
  padding-bottom: 80px;
  background: #F7F7F7;
  border: 16px solid #ffffff; }
  .product__complex-slider .swiper-button-prev, .product__complex-slider .swiper-button-next {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #fff;
    border: 1px solid rgba(160, 160, 160, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    top: 98px; }
    .product__complex-slider .swiper-button-prev svg, .product__complex-slider .swiper-button-next svg {
      display: block; }
    .product__complex-slider .swiper-button-prev use, .product__complex-slider .swiper-button-next use {
      transition: stroke 0.3s;
      stroke: #595959; }
    .product__complex-slider .swiper-button-prev:hover use, .product__complex-slider .swiper-button-next:hover use {
      stroke: #f26123; }
  .product__complex-slider .swiper-slide {
    transition: transform 0.6s, opacity 0.6s;
    transform: scale(0.9);
    opacity: 0; }
    .product__complex-slider .swiper-slide.view-in {
      transform: scale(1);
      opacity: 1; }

.product__image {
  position: relative;
  padding-bottom: 100%;
  margin-bottom: 40px;
  display: block;
  overflow: hidden; }
  .product__image img {
    position: absolute;
    width: auto;
    height: 100%;
    transition: opacity .3s;
    left: 50%;
    transform: translateX(-50%); }
  .product__image:hover img {
    opacity: .6; }

.product__header {
  color: #262626;
  letter-spacing: -0.3px;
  margin-bottom: 24px;
  font-size: 2.4rem;
  line-height: 1em; }
  .theme-monochrome._black-accent .product__header {
    color: #000 !important; }
  .theme-monochrome._white-accent .product__header {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .product__header {
    color: #472312 !important; }

.product__type {
  color: #595959;
  letter-spacing: 0;
  margin-bottom: 8px;
  font-size: 1.6rem;
  line-height: 1.5em; }

.product__risks {
  list-style-type: none; }

.product__risk {
  color: #A0A0A0;
  font-size: 1.4rem;
  line-height: 1.71429em; }
  .theme-monochrome._black-accent .product__risk {
    color: #000 !important; }
  .theme-monochrome._white-accent .product__risk {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .product__risk {
    color: #472312 !important; }

.complex-advantages-cnt {
  margin-top: 64px; }
  .complex-advantages-cnt h5 {
    max-width: 312px;
    text-align: center;
    margin-left: auto;
    margin-right: auto; }

.complex-advantages {
  display: flex;
  margin-top: 56px; }

.ca__list {
  display: flex; }

.ca__item {
  padding: 16px 24px 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 208px;
  align-items: center;
  transition: opacity 0.6s, transform 0.6s;
  opacity: 0;
  transform: scale(0.9); }
  .ca__item.view-in {
    opacity: 1;
    transform: scale(1); }
  .ca__item + .ca__item {
    border-left: 1px solid rgba(160, 160, 160, 0.24); }

.ca-item__icon {
  margin-bottom: 16px; }

.ca-item__text {
  color: #595959;
  letter-spacing: 0;
  text-align: center;
  width: 100%;
  font-size: 1.6rem;
  line-height: 1.5em; }

.activation-advantage {
  background: #7EBD1B;
  border-radius: 4px;
  padding: 32px;
  margin-left: 32px;
  text-align: center;
  transition: opacity 0.6s, transform 0.6s;
  opacity: 0;
  transform: scale(0.9); }
  .activation-advantage.view-in {
    opacity: 1;
    transform: scale(1); }
  .activation-advantage .text {
    font-family: 'FFMetaWebProMedium', sans-serif;
    font-weight: normal;
    font-style: normal;
    color: #FFFFFF;
    margin-bottom: 24px; }
    .theme-monochrome._black-ground .activation-advantage .text {
      color: #000 !important; }
    .theme-monochrome._white-ground .activation-advantage .text {
      color: #fff !important; }
    .theme-monochrome._sepia-ground .activation-advantage .text {
      color: #F5F5DC !important; }

.product__incident {
  text-align: center;
  padding-top: 80px;
  padding-bottom: 80px; }

.product-incident__list {
  list-style-type: none;
  display: flex;
  counter-reset: cnt;
  justify-content: center; }

.product-incident__item {
  counter-increment: cnt;
  text-align: center;
  padding-bottom: 72px;
  position: relative;
  width: 280px;
  transition: opacity 0.6s, transform 0.6s;
  opacity: 0;
  transform: scale(0.9); }
  .product-incident__item.view-in {
    opacity: 1;
    transform: scale(1); }
  .product-incident__item + .product-incident__item {
    margin-left: 84px; }
  .product-incident__item:before {
    content: counter(cnt);
    display: block;
    width: 32px;
    margin-left: auto;
    margin-right: auto;
    height: 32px;
    text-align: center;
    font-family: 'FFMetaWebProMedium', sans-serif;
    font-weight: normal;
    font-style: normal;
    color: #A0A0A0;
    border-radius: 50%;
    background: #ffffff;
    margin-bottom: 16px;
    font-size: 1.4rem;
    line-height: 2.28571em; }

.pii__header {
  color: #262626;
  letter-spacing: -0.3px;
  text-align: center;
  margin-bottom: 24px;
  font-size: 2.4rem;
  line-height: 1em; }

.pii__text {
  color: #A0A0A0;
  font-size: 1.4rem;
  line-height: 1.71429em; }

.pii__action {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center; }

.faq__section {
  padding-bottom: 80px;
  border-bottom: 1px solid rgba(160, 160, 160, 0.24); }

.seotext-section {
  padding-top: 80px; }

.seotext-content {
  column-count: 2;
  column-gap: 32px; }

@media screen and (max-width: 600px) {
  .corp-hero__content p.header {
    color: #58595b;
    font-size: 2.4rem;
    line-height: 1.33333em; } }

@media screen and (max-width: 800px) {
  .corp-hero__content p.header {
    font-size: 2rem;
    line-height: 1.2em; } }

@media screen and (max-width: 800px) {
  .product__complex-slider {
    margin-left: -24px;
    margin-right: -24px;
    border: 0;
    padding-top: 56px;
    padding-bottom: 64px; }
  .complex-advantages {
    flex-direction: column;
    margin-top: 24px; }
  .activation-advantage {
    margin-left: 0; }
  .ca__list {
    flex-wrap: wrap; }
  .ca__item {
    width: 50%;
    margin-bottom: 32px; }
    .ca__item:nth-child(odd) {
      border-left: 0;
      padding-left: 0; }
    .ca__item:nth-child(even) {
      padding-right: 0; }
  .product__incident {
    padding-top: 56px; }
  .product-incident__list {
    flex-direction: column;
    margin-left: 24px;
    margin-right: 24px; }
  .product-incident__item {
    width: auto;
    margin-left: 0;
    padding-bottom: 0;
    padding-left: 48px;
    text-align: left; }
    .product-incident__item:before {
      position: absolute;
      left: 0;
      top: 0; }
    .product-incident__item + .product-incident__item {
      margin-left: 0;
      margin-top: 40px; }
  .pii__header {
    text-align: left;
    font-size: 2rem; }
  .pii__action {
    position: static;
    text-align: center;
    margin-left: -48px;
    margin-top: 24px; }
  .faq__section {
    padding-bottom: 48px;
    padding-top: 48px; }
    .faq__section h3 {
      margin-bottom: 0; }
  .seotext-section {
    padding-top: 56px; }
  .seotext-content {
    columns: 1; } }

.new-kasko .content__inner {
  /* padding: 43px 72px 43px 72px; */
  padding: 70px;
  flex: 1 1 auto;
  width: calc(100% - 416px);
  box-sizing: border-box; }

.new-kasko {
  color: #58595B; }

@media screen and (min-width: 800px) {
  .content.new-kasko.header-block {
    margin-top: -15px; } }

.new-kasko.header-block .new_kasko_header .content__inner {
  padding: 40px 80px 0px 72px;
  width: 45%;
  min-height: 500px; }

.new-kasko.header-block .content__inner h1 {
  font-size: 70px;
  padding-bottom: 30px;
  padding-top: 60px;
  margin-bottom: 0px; }

.new-kasko.header-block .content__inner .subtitle {
  font-size: 32px;
  line-height: 1.2; }

.new-kasko.detail-block .content__inner {
  /* padding: 37px 70px 52px; */
  padding: 55px 70px 70px; }

.new-kasko.detail-block .new-kasko-detail-box {
  display: flex; }

.new-kasko.detail-block .new-kasko-detail-box .new-kasko-detail-text {
  width: 70%;
  padding-right: 70px; }

.new-kasko.header-block .content__inner {
  padding: 0px 72px 17px 72px;
  flex: 1 1 auto;
  width: calc(100% - 416px);
  box-sizing: border-box; }

.new-kasko.benefits-block .content__inner {
  /* padding: 43px 70px 43px; */
  padding: 70px 70px 30px; }

.new-kasko .content__inner {
  padding: 70px; }

/* .new-kasko .vpki-grid {
	margin-left: 0;
	margin-right: 0;
} */
.new-kasko .new-kasko-detail-box .new-kasko-detail-banner {
  font-weight: bold;
  text-align: center;
  min-width: 315px;
  min-height: 255px;
  height: 255px;
  background-color: #ECECEC;
  border: 1px solid #d9d9d9;
  margin: 0px 0px 0px 25px;
  padding-top: 25px; }

.new-kasko .new-kasko-detail-box .new-kasko-detail-banner {
  font-size: 22px;
  color: #F26123; }

.new-kasko .landing-button {
  color: #fff;
  background-color: #F26123 !important;
  font-weight: 500;
  transition: .2s; }

.new-kasko .landing-button:hover {
  background-color: #F68B28 !important; }

.new-kasko .new-kasko-detail-box .new-kasko-detail-banner .landing-button {
  padding: 10px 22px !important; }

.new-kasko.quest-block .landing-button {
  text-transform: none; }

.new-kasko.benefits-block {
  /* background-color: #8DC63F; */
  /* background: linear-gradient(90deg, #8DC63F 0%, #FFE040 100%); */
  background: #f7f7f7; }

.new-kasko .new-product-content-header {
  text-align: center; }

.new-kasko .new-product-content-header h3 {
  font-size: 26px;
  font-weight: bold; }

.new-kasko.benefits-block .benefit-header {
  display: inline; }

.new-kasko.benefits-block .benefit-header .vpki-icon {
  float: left;
  height: 44px; }

.new-kasko.benefits-block .vpki-grid {
  justify-content: center; }

.new-kasko.ins-registration .content__inner {
  /* background: linear-gradient(90deg, #8DC63F 0%, #FFE040 100%); */
  background: #f7f7f7; }

.new-kasko.benefits-block .benefit-header-text {
  color: #F26123;
  font-size: 18px !important;
  font-weight: bold;
  text-transform: none;
  padding-top: 9px;
  padding-bottom: 2px;
  padding-left: 60px; }

.new-kasko.benefits-block .benefit-body {
  padding-left: 60px; }

.new-kasko.benefits-block .benefit-body .benefit-preview {
  color: #58595B;
  font-size: 15px !important;
  line-height: 1.4; }

ul.sk-list,
.new-kasko.benefits-block .benefit-body .benefit-preview ul {
  padding-left: 15px; }

ul.sk-list li,
.new-kasko.benefits-block .benefit-body .benefit-preview ul li {
  list-style: none;
  padding: 0.15em; }

ul.sk-list li:before,
.new-kasko.benefits-block .benefit-body .benefit-preview ul li::before {
  content: "\2022";
  color: #F26123;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em; }

.new-kasko.restrictions-block .content__inner {
  padding: 70px 70px 14px; }

.new-kasko.restrictions-block .restriction-header {
  display: inline; }

.new-kasko.restrictions-block .restriction-header .vpki-icon {
  float: left;
  padding-right: 20px; }

.new-kasko.restrictions-block .restriction-header-text {
  text-transform: none;
  display: flex; }

.new-kasko.restrictions-block .vpki-grid {
  padding-top: 20px;
  justify-content: space-around;
  padding-left: 40px;
  padding-right: 40px; }

.new-kasko.restrictions-block .vpki-grid .vpki-item {
  margin-left: 5px;
  margin-right: 5px;
  width: calc(33.33333% - 18px);
  margin-bottom: 20px; }

.new-kasko.benefits-block .benefit-item {
  width: calc(49% - 92px);
  margin-bottom: 40px;
  margin-left: 40px;
  margin-right: 40px; }

.new-kasko.compare-block .compare-table table {
  width: 100%;
  height: 500px; }

.new-kasko.compare-block .compare-table table tr {
  /* border: 1px dashed #BEBEC4;  */
  border: 1px solid #FFFAFA;
  height: 20px;
  text-align: center; }

.new-kasko.compare-block .compare-table table .tr-head-first td:first-child {
  /* background-color: #C6E0B4; */
  font-weight: bold;
  padding: 10px 5px; }

.new-kasko.compare-block .compare-table table .tr-head {
  /* background-color: #C6E0B4; */ }

.new-kasko.compare-block .compare-table table .tr-head td:first-child {
  font-weight: bold;
  /* border: 1px dashed #BEBEC4;  */
  border: 1px solid #FFFAFA;
  /* background-color: #C6E0B4; */ }

.new-kasko.compare-block .compare-table table .tr-head td {
  border: 0px;
  padding: 10px 5px; }

.new-kasko.compare-block .compare-table table tr td {
  /* border: 1px dashed #BEBEC4;  */
  border: 1px solid #FFFAFA;
  vertical-align: middle;
  padding: 0 5px;
  position: relative; }

.new-kasko.compare-block .compare-table table tr th {
  /* border: 1px dashed #BEBEC4;  */
  border: 1px solid #FFFAFA;
  color: #F26123;
  font-weight: bold;
  padding: 10px 5px; }

.new-kasko.compare-block .compare-table table tr th:first-child {
  /* border: none;  */
  background-color: #fff; }

.new-kasko.compare-block .compare-table table tr td:first-child {
  /* border: 1px dashed #BEBEC4; */
  border: 1px solid #FFFAFA;
  min-width: 130px;
  text-align: left;
  /* background-color: #E2EFDA; */
  color: #58595B; }

.new-kasko.compare-block .compare-table table tr:last-child {
  border: 0px;
  background-color: transparent; }

.new-kasko.compare-block .compare-table table tr:last-child td {
  border: 0px;
  background-color: transparent; }

.new-kasko.compare-block .compare-table table tr td .approved {
  color: #ea5e20; }

.new-kasko.compare-block .compare-table table tr td .approved-remark {
  color: #000;
  font-size: 11px;
  position: relative;
  top: -8px; }

.new-kasko.compare-block .remark-box {
  color: #000;
  font-size: 11px;
  padding-top: 32px; }

.new-kasko.compare-block .remark-box p {
  line-height: 15px;
  margin: 0px; }

.new-kasko.compare-block .remark-box .approved-remark {
  color: #000;
  font-size: 8px;
  position: relative;
  top: -8px; }

.new-kasko.compare-block .compare-table table tr:last-child td .landing-button {
  padding: 4px 8px !important; }

.new-kasko.compare-block .compare-table table .compare-table-result-row td {
  padding: 10px 5px;
  background-color: #DDEBF7; }

.new-kasko.docs-block {
  background: #f7f7f7; }

.documents__info .document__name {
  color: #58595B !important;
  transition: .2s;
  line-height: 1.4; }

.documents__info .document__size {
  margin-top: 8px;
  color: rgba(66, 68, 90, 0.59);
  font-size: 14px;
  line-height: 1; }

.new-kasko .documents__item {
  margin-bottom: 32px; }

.new-kasko .documents__item .documents__preview {
  transition: .2s; }

.new-kasko .documents__item:hover .documents__preview {
  opacity: .8; }

.documents__item:hover .documents__info .document__name {
  color: #f36c21 !important; }

#accordion-docs {
  background: #fff;
  margin-top: 22px; }

#accordion-docs .documents-group__section {
  margin-top: 32px; }

.new-kasko.products-info-block, .new-kasko.kasko-info-block {
  background: #ea6021;
  background: linear-gradient(90deg, #ea6021 0%, #f49b2d 50%, #fdd339 100%); }

.new-kasko.products-info-block .slider-container {
  padding-top: 20px; }

.new-kasko.products-info-block .slider-container .product__complex-slider {
  margin: 0px;
  padding: 0px 40px;
  background: transparent;
  border: none;
  position: relative; }

.new-kasko.products-info-block .slider-container .product__complex-slider .swiper-slide {
  /* width: 300px !important; */
  min-height: 220px;
  /* margin-right: 65px !important; */
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 1px 1px 3px 2px #53535338; }

.new-kasko.products-info-block .slider-container .product__complex-slider .swiper-slide .slide-header {
  text-align: center;
  color: #F26123;
  font-size: 20px;
  padding-top: 20px;
  padding-bottom: 12px; }

.new-kasko.products-info-block .slider-container .product__complex-slider .swiper-slide .slide-text {
  text-align: left;
  padding-bottom: 12px; }

.new-kasko.products-info-block .slider-container .product__complex-slider .swiper-slide .slide-text p {
  margin-top: 10px;
  margin-left: 33px; }

.new-kasko.products-info-block .slider-container .product__complex-slider .swiper-slide .slide-button {
  text-align: center; }

.new-kasko.products-info-block .slider-container .product__complex-slider .swiper-slide .slide-button .landing-button {
  padding: 7px 23px !important; }

.new-kasko.products-info-block .slider-container .swiper-button-next,
.new-kasko.products-info-block .slider-container .swiper-button-prev {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  top: 50% !important;
  transform: translateY(-50%);
  transition: .2s; }

.new-kasko.products-info-block .slider-container .swiper-container {
  padding: 0 15px; }

.new-kasko.products-info-block .slider-container .swiper-button-next {
  background-image: url(/static/icons/arrow-in-circle-right.svg);
  background-repeat: no-repeat;
  background-size: 48px; }

.new-kasko.products-info-block .slider-container .swiper-button-next:hover {
  background-image: url(/static/icons/arrow-in-circle-right-fill.svg); }

.new-kasko.products-info-block .slider-container .swiper-button-prev {
  background-image: url(/static/icons/arrow-in-circle-green-left.svg);
  background-repeat: no-repeat;
  background-size: 48px; }

.new-kasko.products-info-block .slider-container .swiper-button-prev:hover {
  background-image: url(/static/icons/arrow-in-circle-green-left-fill.svg); }

.new-kasko.products-info-block .slider-container .swiper-button-prev {
  left: -5px; }

.new-kasko.products-info-block .slider-container .swiper-button-next {
  right: -5px; }

.new-kasko.products-info-block .slider__wrapper {
  overflow: hidden; }

.new-kasko.products-info-block .slider__item {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.8);
  font-size: 7rem; }

.new-kasko.products-info-block .slider__item:nth-child(1) {
  background-color: #f44336; }

.new-kasko.info-block .info-addon .info-addon-image {
  background-image: url(/static/img/kasko-new/ins-kasko-reg.jpg);
  width: 33%;
  background-size: cover; }

.new-kasko.info-block-2 .info-addon .info-addon-image {
  background-image: url(/static/img/kasko-new/ins-kasko-regul.jpg);
  width: 33%;
  background-size: cover; }

.new-kasko.info-block .info-addon .info-addon-content, .new-kasko.info-block-2 .info-addon .info-addon-content {
  text-align: center;
  width: 67%;
  /* padding: 60px 70px 50px; */
  padding: 70px 70px 62px; }

.new-kasko.info-block .info-addon .info-addon-content .info-addon-item, .new-kasko.info-block-2 .info-addon .info-addon-content .info-addon-item {
  text-align: left;
  height: 52px;
  display: flex;
  align-items: center;
  padding: 8px 0; }

.new-kasko.info-block .info-addon .info-addon-content .info-addon-item p, .new-kasko.info-block-2 .info-addon .info-addon-content .info-addon-item p {
  /* padding-top: 10px; */ }

.new-kasko .info-addon {
  --list-item-icon: url('/static/icons/helios-sun.svg'); }

.new-kasko.info-block .info-addon .info-addon-content .info-addon-item::before, .new-kasko.info-block-2 .info-addon .info-addon-content .info-addon-item::before {
  flex-shrink: 0;
  width: 44px;
  height: 44px;
  background-image: var(--list-item-icon);
  background-repeat: no-repeat;
  background-size: 40px;
  background-position: center;
  content: "";
  margin-right: 12px;
  display: block;
  float: left; }

.new-kasko.kasko-info-block .kasko-info-content {
  display: flex;
  justify-content: center;
  padding-top: 25px; }

.new-kasko.kasko-info-block .kasko-info-content .kasko-info-item {
  width: 265px;
  height: 160px;
  background-color: #ececec;
  margin: 10px 22px 10px 22px;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 1px 1px 3px 2px #53535338; }

.new-kasko.kasko-info-block .kasko-info-content .kasko-info-item .item-header {
  margin: 12px 5px 5px 12px; }

.new-kasko.kasko-info-block .kasko-info-content .kasko-info-item .item-header img {
  float: left;
  width: 32px;
  height: 32px;
  object-fit: contain;
  margin-left: 2px; }

.new-kasko.kasko-info-block .kasko-info-content .kasko-info-item .item-header p {
  line-height: 30px;
  color: #F26123;
  font-size: 18px;
  margin-left: 40px; }

.new-kasko.kasko-info-block .kasko-info-content .kasko-info-item input[type=text] {
  margin: 14px 0px 23px 16px;
  width: 85%;
  line-height: 1;
  padding: 5px 8px;
  font-size: 16px;
  box-sizing: border-box;
  color: #58595B; }

.new-kasko.kasko-info-block .kasko-info-content .kasko-info-item input::placeholder {
  opacity: 1;
  font-size: 16px;
  color: lightgray;
  font-weight: 500; }

.new-kasko.kasko-info-block .kasko-info-content .kasko-info-item select {
  margin: 14px 0px 23px 16px;
  width: 85%;
  line-height: 1;
  padding: 5px 8px;
  font-size: 16px;
  opacity: 1;
  border-color: #96C22B;
  color: #F26123;
  box-sizing: border-box; }

.new-kasko.kasko-info-block .kasko-info-content .kasko-info-item .landing-button {
  padding: 5px 10px !important;
  margin: 0px;
  width: 85%; }

.accordion__item {
  margin-bottom: 0.8rem;
  border-radius: 8px;
  box-shadow: 0 0.125rem 0.55rem rgba(0, 0, 0, 0.15); }

.accordion__header {
  display: flex;
  align-items: center;
  padding: 0.95rem 1rem;
  color: #58595B;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease-out;
  border-radius: 8px;
  transition: .3s; }

.docs-block .accordion__header {
  background-color: #fff; }

.accordion__header::before {
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  background-image: url(/static/icons/arrow-in-circle-right.svg);
  background-repeat: no-repeat;
  background-size: 32px;
  content: "";
  margin-right: 12px;
  transition: .2s; }

.accordion__header:hover::before {
  background-image: url(/static/icons/arrow-in-circle-right-fill.svg); }

.accordion__item_show .accordion__header {
  color: #f26123; }

.accordion__item_show .accordion__header::before {
  transform: rotate(90deg); }

.accordion__header:hover {
  opcaity: 0.8; }

.accordion__item_hidden .accordion__header {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.accordion__body {
  /* padding: 0.75rem 1rem; */
  padding: 0.75rem 2rem 1.25rem;
  overflow: hidden;
  border-radius: 0 0 8px 8px;
  transition: .2s ease-in-out; }

.accordion__item:not(.accordion__item_show) .accordion__body {
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0; }

.td-remark-tooltip {
  visibility: visible;
  opacity: 1;
  min-width: 150px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  font-size: 12px;
  line-height: 1.4;
  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 103%;
  left: 50%;
  transform: translate(-50%, 0); }

.remark-box {
  display: none; }

/* Tooltip arrow */
.td-remark-tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent; }

@media screen and (max-width: 900px) {
  .new-kasko.restrictions-block .content__inner,
  .new-kasko .content__inner {
    width: 100% !important;
    padding: 43px 30px; }
  .new-kasko.products-info-block {
    overflow: hidden; }
  .new-kasko.header-block .content__inner {
    width: 100% !important; }
  .new-kasko .new-kasko-detail-box .new-kasko-detail-banner {
    margin: 20px 0px 0px 0px; }
  .new-kasko.detail-block .content__inner {
    padding: 27px 30px 42px; }
  .vpki-grid {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .new-kasko.compare-block .compare-table {
    overflow-x: scroll;
    margin-right: -20px;
    padding-right: 40px;
    padding-left: 40px;
    margin-left: -36px; }
  .new-kasko.info-block .info-addon .info-addon-content, .new-kasko.info-block-2 .info-addon .info-addon-content {
    padding: 43px 30px; }
  .new-kasko.header-block .new_kasko_header .content__inner {
    background-color: transparent;
    padding: 30px;
    width: 100%;
    min-height: 0; }
  .new-kasko.benefits-block .benefit-item {
    width: calc(100% - 0px);
    margin-left: 0px;
    margin-bottom: 10px; }
  .new-kasko.kasko-info-block .kasko-info-content {
    display: block;
    text-align: center; }
  .new-kasko.kasko-info-block .kasko-info-content .kasko-info-item {
    display: inline-block; }
  .new-kasko.kasko-info-block .kasko-info-content .kasko-info-item .item-header {
    text-align: left; }
  .new-kasko.info-block .info-addon .info-addon-content, .new-kasko.info-block-2 .info-addon .info-addon-content {
    width: 100%; }
  .new-kasko.info-block .info-addon .info-addon-content .info-addon-item, .new-kasko.info-block-2 .info-addon .info-addon-content .info-addon-item {
    height: auto; }
  .new-kasko.restrictions-block .vpki-grid .vpki-item {
    width: 100%;
    margin-bottom: 20px; }
  .new-kasko.restrictions-block .restriction-header {
    display: flex;
    align-items: center; } }

@media screen and (max-width: 800px) {
  .new-kasko.header-block .content__inner {
    padding: 0px 16px 17px; }
  .new-kasko.docs-block .documents-group {
    margin-bottom: 30px; } }

@media screen and (max-width: 767px) {
  .new-kasko-detail-box {
    display: block !important; }
  .new-kasko.detail-block .new-kasko-detail-box .new-kasko-detail-text {
    width: 100%;
    padding-right: 0; }
  .new-kasko.products-info-block .slider-container .product__complex-slider {
    padding: 0 20px; } }

@media screen and (max-width: 700px) {
  .new-kasko.products-info-block .slider-container .product__complex-slider .swiper-slide .slide-text p {
    margin: 10px 15px 0;
    text-align: center; } }

@media screen and (max-width: 600px) {
  .new-kasko.restrictions-block .vpki-grid .vpki-item {
    margin-bottom: 0; }
  .new-kasko.restrictions-block .vpki-grid .vpki-item + .vpki-item {
    margin-top: 20px; } }

@media screen and (max-width: 420px) {
  .new-kasko.restrictions-block .content__inner,
  .new-kasko .content__inner {
    padding: 32px 16px; }
  .new-kasko.header-block .content__inner h1 {
    font-size: 40px;
    padding-bottom: 20px;
    padding-top: 40px; }
  .new-kasko.header-block .content__inner .subtitle {
    font-size: 24px; }
  .new-kasko.header-block .corp-hero__content p.header--larger {
    display: none; }
  .new-kasko.detail-block .content__inner {
    padding: 20px 16px 32px; }
  .new-kasko.header-block .new_kasko_header .content__inner {
    padding: 20px 16px; }
  .new-kasko .new-kasko-detail-box .new-kasko-detail-banner {
    min-width: initial; }
  .new-kasko.benefits-block .content__inner {
    padding: 32px 16px; }
  .new-kasko.benefits-block .benefit-header-text {
    height: auto; }
  .new-kasko.benefits-block .benefit-body,
  .new-kasko.benefits-block .benefit-header-text {
    padding-left: 52px; }
  .new-kasko .vpki-icon img {
    width: 36px; }
  .new-kasko .vpki-item + .vpki-item {
    margin-top: 18px; }
  .new-kasko .vpk-includes h3 {
    margin-bottom: 1em; }
  .new-kasko.compare-block .compare-table table .compare-table-result-row td,
  .new-kasko.compare-block .compare-table table .tr-head-first td:first-child,
  .new-kasko.compare-block .compare-table table tr th {
    padding: 5px 3px; }
  .new-kasko.compare-block .compare-table table tr td:first-child {
    min-width: 110px; }
  .new-kasko.kasko-info-block .kasko-info-content .kasko-info-item {
    margin-left: 0;
    margin-right: 0; }
  .new-kasko.info-block .info-addon .info-addon-content, .new-kasko.info-block-2 .info-addon .info-addon-content {
    padding: 32px 16px; }
  .new-kasko.products-info-block .slider-container .swiper-button-next,
  .new-kasko.products-info-block .slider-container .swiper-button-prev {
    width: 32px;
    height: 32px;
    background-size: cover; }
  .new-kasko.products-info-block .slider-container .product__complex-slider .swiper-slide {
    min-height: 180px; }
  .new-kasko.products-info-block .slider-container .swiper-container {
    padding: 0; }
  .new-kasko.compare-block .compare-table table {
    font-size: 14px;
    line-height: 1.4; }
  .new-kasko.restrictions-block .vpki-grid {
    padding: 10px 0 0; } }

.reminders {
  margin-top: 40px; }
  .reminders .col {
    padding-left: 64px;
    position: relative; }
  .reminders .products__link {
    margin-right: 0;
    display: inline; }

.rg-header {
  height: 32px;
  color: #000000;
  letter-spacing: -0.4px;
  margin-bottom: 24px;
  font-size: 2.4rem;
  line-height: 1.29167em; }

.reminders__docs {
  padding-top: 40px;
  border-top: 1px solid rgba(160, 160, 160, 0.24);
  margin-top: 40px; }

.reminders-docs__section + .reminders-docs__section {
  margin-top: 40px; }

.rd-header {
  margin-bottom: 24px;
  font-size: 2rem;
  line-height: 1.2em; }

.rd-docs .documents__item {
  margin-bottom: 0; }
  .rd-docs .documents__item + .documents__item {
    margin-top: 16px; }

@media screen and (max-width: 740px) {
  .reminders .col {
    padding-left: 0; }
  .rg-header {
    padding-left: 60px; } }

.get-offers {
  margin-top: 64px;
  margin-bottom: 24px; }

.bank__introduction {
  padding-bottom: 48px;
  border-bottom: 1px solid rgba(160, 160, 160, 0.24);
  margin-bottom: 53px; }
  @media screen and (max-width: 800px) {
    .bank__introduction {
      padding-bottom: 0; } }
  .bank__introduction._no-border {
    border: none; }

.bank__introduction-btn {
  margin-top: 32px; }

.bank__accordion {
  margin-top: 64px; }
  .bank__accordion .accordion {
    margin-right: -48px; }
  .bank__accordion .accordion-trigger {
    width: calc(100% - 48px);
    box-sizing: border-box; }
  .bank__accordion .accordion-content {
    width: 100%;
    box-sizing: border-box;
    padding-right: 48px; }
  .bank__accordion .accordion-content-inner {
    padding-bottom: 48px !important; }

.bank__examples-title {
  margin-top: 52px;
  margin-bottom: 32px;
  color: #58595b;
  font-family: 'FFMetaWebProMedium', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 1.8rem;
  line-height: 1.11111em;
  letter-spacing: -0.022rem; }
  .theme-monochrome._black-accent .bank__examples-title {
    color: #000 !important; }
  .theme-monochrome._white-accent .bank__examples-title {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .bank__examples-title {
    color: #472312 !important; }

.bank__slider {
  margin-right: -48px; }
  .bank__slider .swiper-wrapper {
    align-items: stretch; }
  .bank__slider .swiper-slide {
    height: auto;
    max-width: 206px;
    margin-right: 40px; }
  .bank__slider .swiper-button-prev, .bank__slider .swiper-button-next {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #fff;
    border: 1px solid rgba(160, 160, 160, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    top: 90px; }
    .bank__slider .swiper-button-prev svg, .bank__slider .swiper-button-next svg {
      display: block; }
    .bank__slider .swiper-button-prev use, .bank__slider .swiper-button-next use {
      transition: stroke 0.3s;
      stroke: #595959; }
    .bank__slider .swiper-button-prev:hover use, .bank__slider .swiper-button-next:hover use {
      stroke: #f26123; }

.bank__slide {
  position: relative;
  height: 100%;
  box-sizing: border-box;
  padding-bottom: 48px; }
  .bank__slide .product__image {
    border-radius: 4px;
    overflow: hidden; }
  .bank__slide .product__header {
    margin-bottom: 22px; }

.bank__slide-more {
  position: absolute;
  bottom: 0;
  left: 0; }

.bank__slide-text {
  color: #a0a0a0;
  font-size: 1.4rem;
  line-height: 1.71429em; }
  .theme-monochrome._black-accent .bank__slide-text {
    color: #000 !important; }
  .theme-monochrome._white-accent .bank__slide-text {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .bank__slide-text {
    color: #472312 !important; }

.bank__all-products {
  margin-top: 48px;
  padding-bottom: 16px; }

.bank__small-text {
  max-width: 574px; }
  .bank__small-text.mar-40 {
    margin-bottom: 40px; }

.bank__vac-for-students {
  max-width: 409px;
  margin-top: 40px;
  opacity: 0;
  transform: translateY(32px);
  transition: opacity 0.5s, transform 0.5s; }
  @media screen and (max-width: 1000px) {
    .bank__vac-for-students {
      max-width: 100%; } }
  .bank__vac-for-students .vfs__header {
    margin-top: 0;
    font-family: 'FFMetaWebProMedium', sans-serif;
    font-weight: normal;
    font-style: normal; }
  .bank__vac-for-students .vfs__icon {
    line-height: 0;
    margin-bottom: 16px;
    overflow: hidden; }
  .bank__vac-for-students.view-in {
    opacity: 1;
    transform: translateY(0); }

.bank__form {
  margin-bottom: 56px;
  opacity: 0;
  transform: translateY(16px);
  transition: opacity 0.5s, transform 0.5s; }
  .bank__form.view-in {
    opacity: 1;
    transform: translateY(0); }

.bank__directions {
  margin-top: 32px; }

.attachment-zone--no-top {
  margin-top: 0;
  padding-top: 0;
  border-top: none; }

@media screen and (max-width: 740px) {
  .bank__accordion {
    margin-top: 40px; }
    .bank__accordion .accordion {
      margin-right: 0; }
    .bank__accordion .accordion-trigger {
      width: 100%; }
      .bank__accordion .accordion-trigger h4 {
        font-size: 1.6rem;
        line-height: 1.25em; }
    .bank__accordion .accordion-content {
      padding-right: 0; }
  .bank__slide .product__image {
    max-width: 280px;
    padding-bottom: 280px; }
  .bank__introduction-btn,
  .bank__all-products {
    max-width: 360px; }
    .bank__introduction-btn a.btn,
    .bank__all-products a.btn {
      padding-left: 0;
      padding-right: 0; }
  .bank__slider .swiper-slide {
    max-width: none; }
  .bank__slider {
    margin-right: 0; }
    .bank__slider .swiper-button-prev, .bank__slider .swiper-button-next {
      top: 125px; } }

.about {
  width: 100%;
  display: flex; }
  @media screen and (max-width: 800px) {
    .about {
      flex-direction: column; } }

.about__sidebar {
  width: 344px;
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 72px;
  box-sizing: border-box;
  border: 8px solid #F7F7F7;
  padding: 40px 32px; }
  @media screen and (max-width: 800px) {
    .about__sidebar {
      width: 100%;
      margin-left: 0;
      margin-top: 32px;
      padding: 24px 16px; } }

.as-text--type1 {
  font-family: 'FFMetaWebProMedium', sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #262626;
  font-size: 1.7rem;
  line-height: 1.41176em; }
  .theme-monochrome._black-accent .as-text--type1 {
    color: #000 !important; }
  .theme-monochrome._white-accent .as-text--type1 {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .as-text--type1 {
    color: #472312 !important; }
  .as-text--type1 + .as-text--type4 {
    margin-top: 8px; }

.as-text--type2 {
  color: #ED7D31;
  letter-spacing: -0.4px;
  margin-bottom: 8px;
  font-size: 2.4rem;
  line-height: 1.33333em; }
  .theme-monochrome._black-accent .as-text--type2 {
    color: #000 !important; }
  .theme-monochrome._white-accent .as-text--type2 {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .as-text--type2 {
    color: #472312 !important; }

.as-text--type3 {
  color: #595959;
  font-size: 1.6rem;
  line-height: 1.25em;
  max-width: 100%; }
  .theme-monochrome._black-accent .as-text--type3 {
    color: #000 !important; }
  .theme-monochrome._white-accent .as-text--type3 {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .as-text--type3 {
    color: #472312 !important; }

.as-text--type4 {
  color: #A0A0A0;
  font-size: 1.4rem;
  line-height: 1.42857em; }
  .theme-monochrome._black-accent .as-text--type4 {
    color: #000 !important; }
  .theme-monochrome._white-accent .as-text--type4 {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .as-text--type4 {
    color: #472312 !important; }

.as__section {
  text-align: center;
  padding-top: 16px;
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(160, 160, 160, 0.24); }
  .theme-monochrome._black-accent .as__section {
    border-color: #000 !important; }
  .theme-monochrome._white-accent .as__section {
    border-color: #fff !important; }
  .theme-monochrome._sepia-accent .as__section {
    border-color: #472312 !important; }

.as__section-cols {
  display: flex; }

.as__section-col {
  width: 50%;
  padding-left: 12px;
  padding-right: 12px;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .as__section-col + .as__section-col {
    border-left: 1px solid rgba(160, 160, 160, 0.24); }

.as__donut {
  position: relative; }

.as-legend {
  margin-top: 24px; }

.as-legend-item {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.as-legend-label {
  color: #A0A0A0;
  font-size: 1.4rem;
  line-height: 1.71429em; }
  .theme-monochrome._black-accent .as-legend-label {
    color: #000 !important; }
  .theme-monochrome._white-accent .as-legend-label {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .as-legend-label {
    color: #472312 !important; }

.as-legend-value {
  display: flex;
  justify-content: flex-end;
  align-items: center; }

.as-legend-count {
  font-size: 1.4rem;
  line-height: 1.71429em; }

.as-legend-color {
  width: 8px;
  height: 8px;
  margin-left: 8px; }

.about__map-container {
  position: relative;
  margin-top: 80px; }
  @media screen and (max-width: 800px) {
    .about__map-container {
      margin-top: 48px; } }

.about__map-counts {
  position: absolute;
  left: 0;
  top: 0;
  display: flex; }

.about__map-count + .about__map-count {
  margin-left: 40px; }

.amc-digit {
  color: #595959;
  letter-spacing: -0.67px;
  font-size: 4rem;
  line-height: 1em; }
  .theme-monochrome._black-accent .amc-digit {
    color: #000 !important; }
  .theme-monochrome._white-accent .amc-digit {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .amc-digit {
    color: #472312 !important; }
  @media screen and (max-width: 800px) {
    .amc-digit {
      font-size: 2.8rem;
      line-height: 1.14286em; } }

.amc-text {
  color: #262626;
  letter-spacing: 0;
  text-transform: uppercase;
  font-size: 1.7rem;
  line-height: 1.41176em; }
  .theme-monochrome._black-accent .amc-text {
    color: #000 !important; }
  .theme-monochrome._white-accent .amc-text {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .amc-text {
    color: #472312 !important; }
  @media screen and (max-width: 800px) {
    .amc-text {
      font-size: 1.2rem;
      line-height: 1.33333em; } }

.about__map {
  padding-top: 40px;
  padding-left: 18px; }
  @media screen and (max-width: 800px) {
    .about__map {
      padding-left: 0; } }
  .about__map img {
    width: 100%; }

.about__banner,
.banner {
  background: #f26123;
  display: flex;
  color: #ffffff;
  padding: 64px 72px;
  margin: 80px -72px 120px; }
  .theme-monochrome._black-accent .about__banner, .theme-monochrome._black-accent
  .banner {
    background: #000 !important; }
  .theme-monochrome._white-accent .about__banner, .theme-monochrome._white-accent
  .banner {
    background: #fff !important; }
  .theme-monochrome._sepia-accent .about__banner, .theme-monochrome._sepia-accent
  .banner {
    background: #472312 !important; }
  .theme-monochrome._black-ground .about__banner, .theme-monochrome._black-ground
  .banner {
    color: #000 !important; }
  .theme-monochrome._white-ground .about__banner, .theme-monochrome._white-ground
  .banner {
    color: #fff !important; }
  .theme-monochrome._sepia-ground .about__banner, .theme-monochrome._sepia-ground
  .banner {
    color: #F5F5DC !important; }
  .about__banner--feature,
  .banner--feature {
    flex-direction: column; }
  .about__banner__col,
  .banner__col {
    width: 33.333%; }
    .about__banner__col--list,
    .banner__col--list {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 35px; }
      @media screen and (max-width: 800px) {
        .about__banner__col--list,
        .banner__col--list {
          flex-direction: column;
          margin-top: 0; } }
  .about__banner__lead,
  .banner__lead {
    font-size: 32px;
    letter-spacing: -0.4px;
    line-height: 32px; }
  .about__banner__group,
  .banner__group {
    font-size: 2.4rem;
    line-height: 1em; }
    .about__banner__group:not(:first-child),
    .banner__group:not(:first-child) {
      margin-top: 32px; }
    .about__banner__group--list,
    .banner__group--list {
      list-style: disc;
      width: 40%;
      padding-left: 10px; }
      @media screen and (max-width: 800px) {
        .about__banner__group--list,
        .banner__group--list {
          flex-direction: column;
          margin-top: 15px; } }
      .about__banner__group--list:not(:first-child),
      .banner__group--list:not(:first-child) {
        margin-top: 0;
        margin-left: 30px; }
        @media screen and (max-width: 800px) {
          .about__banner__group--list:not(:first-child),
          .banner__group--list:not(:first-child) {
            margin-left: 0;
            margin-top: 15px; } }
      .about__banner__group--list:nth-child(2),
      .banner__group--list:nth-child(2) {
        width: 45%; }
        @media screen and (max-width: 800px) {
          .about__banner__group--list:nth-child(2),
          .banner__group--list:nth-child(2) {
            width: 100%; } }
      @media screen and (max-width: 800px) {
        .about__banner__group--list,
        .banner__group--list {
          list-style: none;
          width: 100%;
          padding-left: 0;
          font-size: 1.8rem;
          line-height: 1.33333em;
          letter-spacing: -0.225px; } }

@media screen and (max-width: 800px) {
  .about__banner {
    margin-left: -1.6rem;
    margin-right: -1.6rem;
    flex-direction: column;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    padding-top: 4rem;
    padding-bottom: 4rem;
    margin-bottom: 4rem; } }

.ab-col {
  margin-left: 64px;
  width: 280px;
  flex-shrink: 0;
  flex-grow: 0; }
  @media screen and (max-width: 800px) {
    .ab-col {
      margin-left: 0;
      margin-top: 3.2rem;
      width: 100%; } }

.ab-col--wide {
  margin-left: 0;
  margin-right: -32px;
  width: auto;
  flex: 1; }
  @media screen and (max-width: 800px) {
    .ab-col--wide {
      margin-right: 0;
      margin-top: 0; } }

.ab-lead {
  letter-spacing: -0.4px;
  font-size: 3.2rem;
  line-height: 1em; }

.ab-group strong {
  color: #ffffff; }

.ab-group p + p {
  margin-top: .8rem; }

.ab-group + .ab-group {
  margin-top: 3.2rem; }

.about__reliability {
  display: flex;
  justify-content: space-between; }
  @media screen and (max-width: 800px) {
    .about__reliability {
      overflow-x: scroll;
      -webkit-overflow-scrolling: touch; } }

.ar__item {
  width: 160px; }
  @media screen and (max-width: 800px) {
    .ar__item {
      flex-shrink: 0; } }

.ar__item-image {
  width: 120px;
  margin-left: auto;
  margin-right: auto;
  height: 120px; }
  .ar__item-image img {
    width: 100%; }

.ar__item-text {
  color: #262626;
  text-align: center;
  font-size: 1.4rem;
  line-height: 1.42857em; }
  .theme-monochrome._black-accent .ar__item-text {
    color: #000 !important; }
  .theme-monochrome._white-accent .ar__item-text {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .ar__item-text {
    color: #472312 !important; }

.about__nav {
  display: flex;
  flex-wrap: wrap;
  padding: 28px 54px;
  background: #F7F7F7;
  border-radius: 4px;
  margin-top: 80px; }
  .theme-monochrome._black-ground .about__nav {
    background: #000 !important; }
  .theme-monochrome._white-ground .about__nav {
    background: #fff !important; }
  .theme-monochrome._sepia-ground .about__nav {
    background: #F5F5DC !important; }
  @media screen and (max-width: 800px) {
    .about__nav {
      flex-direction: column;
      padding-left: 20px;
      padding-right: 20px;
      margin-top: 40px; } }

.about__nav-item {
  display: flex;
  align-items: center; }
  .about__nav-item + .about__nav-item {
    margin-left: 40px; }
    @media screen and (max-width: 800px) {
      .about__nav-item + .about__nav-item {
        margin-left: 0;
        margin-top: 24px; } }

.about__nav-icon {
  width: 24px;
  margin-right: 12px; }
  .about__nav-icon svg {
    margin-left: auto;
    margin-right: auto;
    display: block; }

.about__nav-link {
  color: #262626;
  letter-spacing: -0.5px;
  white-space: nowrap;
  font-size: 1.7rem;
  line-height: 1.41176em; }
  .theme-monochrome._black-accent .about__nav-link {
    color: #000 !important; }
  .theme-monochrome._white-accent .about__nav-link {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .about__nav-link {
    color: #472312 !important; }

.leaders {
  position: relative;
  transition: min-height .4s; }

.leaders__item {
  width: 344px; }
  .leaders__item + .leaders__item {
    border-top: 1px solid rgba(160, 160, 160, 0.24); }

.leaders__trigger {
  display: flex;
  padding-top: 24px;
  padding-bottom: 24px;
  align-items: center;
  transition: opacity .3s;
  opacity: .54; }
  .leaders__trigger._active {
    opacity: 1; }
    .leaders__trigger._active .leaders__name {
      font-family: 'FFMetaWebProMedium', sans-serif;
      font-weight: normal;
      font-style: normal; }
      .theme-monochrome._black-accent .leaders__trigger._active .leaders__name {
        color: #000 !important; }
      .theme-monochrome._white-accent .leaders__trigger._active .leaders__name {
        color: #fff !important; }
      .theme-monochrome._sepia-accent .leaders__trigger._active .leaders__name {
        color: #472312 !important; }

.leaders__pic {
  width: 12px;
  height: 12px;
  margin-right: 24px;
  flex-shrink: 0;
  overflow: hidden;
  border-radius: 50%; }
  .leaders__pic--extended {
    width: 120px;
    height: 120px; }
  .leaders__pic img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.leaders__intro {
  display: flex;
  flex-direction: column; }

.leaders__name {
  color: #262626;
  letter-spacing: -0.22px;
  margin-bottom: 8px;
  font-size: 1.8rem;
  line-height: 1.33333em; }
  .theme-monochrome._black-accent .leaders__name {
    color: #000 !important; }
  .theme-monochrome._white-accent .leaders__name {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .leaders__name {
    color: #472312 !important; }

.leaders__job {
  color: #595959;
  letter-spacing: -0.2px;
  font-size: 1.6rem;
  line-height: 1.25em; }
  .theme-monochrome._black-accent .leaders__job {
    color: #000 !important; }
  .theme-monochrome._white-accent .leaders__job {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .leaders__job {
    color: #472312 !important; }

.leaders__content {
  position: absolute;
  transition: opacity .3s, visibility .3s;
  top: 0;
  left: 416px;
  right: 0;
  opacity: 0;
  visibility: hidden;
  padding-top: 24px; }
  .leaders__content._active {
    opacity: 1;
    visibility: visible; }
  .leaders__content h4 {
    margin-bottom: 16px; }

.leaders__bio {
  margin-top: 32px; }

.leaders__history {
  margin-top: 8px; }
  .leaders__history .col {
    margin-top: 32px; }

.leaders__year {
  font-size: 1.7rem;
  line-height: 1.41176em; }

@media screen and (max-width: 740px) {
  .leaders__item {
    width: 100%; }
    .leaders__item + .leaders__item {
      border-top: 1px solid rgba(160, 160, 160, 0.24); }
  .leaders__intro {
    display: flex;
    flex-direction: column; }
  .leaders__name {
    font-family: 'FFMetaWebProMedium', sans-serif;
    font-weight: normal;
    font-style: normal;
    color: #262626;
    letter-spacing: -0.22px;
    margin-bottom: 8px;
    font-size: 1.8rem;
    line-height: 1.33333em; }
  .leaders__job {
    color: #595959;
    letter-spacing: -0.2px;
    font-size: 1.6rem;
    line-height: 1.25em; }
  .leaders__content {
    position: static;
    transition: height .4s;
    overflow: hidden;
    padding-top: 0;
    box-sizing: border-box; }
    .leaders__content h4 {
      margin-bottom: 16px; }
  .leaders__bio {
    margin-top: 32px; }
  .leaders__history {
    margin-top: 8px; }
    .leaders__history .col {
      margin-top: 32px; }
  .leaders__year {
    font-size: 1.7rem;
    line-height: 1.41176em; } }

.agents__search {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px; }
  .agents__search input[type=text] {
    width: 300px;
    border: 1px solid rgba(160, 160, 160, 0.48);
    padding: 0;
    border-radius: 2px;
    padding-top: 10px;
    padding-bottom: 6px;
    padding-right: 42px;
    padding-left: 10px; }
    .agents__search input[type=text]::-webkit-input-placeholder {
      opacity: 1; }
    .agents__search input[type=text]:-moz-placeholder {
      opacity: 1; }
    .agents__search input[type=text]:-ms-input-placeholder {
      opacity: 1; }
  .agents__search button[type=submit].isb__submit {
    height: 42px;
    width: 42px; }

.agents-content .vgt-table {
  font-size: 12px; }

.agents-content .vgt-global-search {
  padding-top: 8px;
  padding-bottom: 8px; }

.agents-content .vgt-input {
  width: 300px;
  padding: 10px 32px 8px 10px;
  height: auto;
  box-sizing: content-box; }
  .agents-content .vgt-input::-webkit-input-placeholder {
    opacity: .6; }
  .agents-content .vgt-input:-moz-placeholder {
    opacity: .6; }
  .agents-content .vgt-input:-ms-input-placeholder {
    opacity: .6; }

.agents-content .vgt-global-search__input {
  display: flex;
  justify-content: flex-end; }

.agents-content .vgt-global-search__input .input__icon {
  right: 0;
  left: auto;
  top: 50%;
  transform: translateY(-50%);
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxkZWZzPjxwYXRoIGQ9Ik05OTcgMzU5Ljk3YzAtNC40MDIgMy41MzItNy45NyA3Ljg5LTcuOTdzNy44OSAzLjU2OCA3Ljg5IDcuOTdjMCA0LjQwMi0zLjUzMiA3Ljk3LTcuODkgNy45N3MtNy44OS0zLjU2OC03Ljg5LTcuOTd6IiBpZD0iYSIvPjxwYXRoIGQ9Ik0xMDE1LjMxMyAzNzEuMzA0bC00Ljg1My00LjkwNCIgaWQ9ImIiLz48L2RlZnM+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTk5NiAtMzUxKSI+PHVzZSB4bGluazpocmVmPSIjYSIgZmlsbC1vcGFjaXR5PSIwIiBmaWxsPSIjZmZmIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZT0iI2YzNmMyMSIgc3Ryb2tlLW1pdGVybGltaXQ9IjUwIiBzdHJva2Utd2lkdGg9IjIiLz48Zz48dXNlIHhsaW5rOmhyZWY9IiNiIiBmaWxsLW9wYWNpdHk9IjAiIGZpbGw9IiNmZmYiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlPSIjZjM2YzIxIiBzdHJva2UtbWl0ZXJsaW1pdD0iNTAiIHN0cm9rZS13aWR0aD0iMiIvPjwvZz48L2c+PC9zdmc+) 50% 50% no-repeat; }
  .agents-content .vgt-global-search__input .input__icon div {
    display: none; }

.agents-content .vgt-table th {
  font-weight: normal;
  font-family: 'FFMetaWebProMedium', sans-serif;
  font-weight: normal;
  font-style: normal; }

.agents-content .vgt-table th.sorting:hover:after {
  border-bottom-color: #f26123; }

.agents-content .vgt-table th.sorting:after {
  border-bottom-color: #f26123; }

.agents-content .vgt-table tbody td ul {
  margin-left: 20px;
  list-style: square outside; }

.agents-content .vgt-table tbody td ul li {
  list-style: square outside; }

.agents-content .vgt-table thead th.sorting-desc:hover:after {
  border-top-color: #f26123; }

.agents-content .vgt-table thead th.sorting-desc:after {
  border-top-color: #f26123; }

@media screen and (max-width: 740px) {
  .agents-content .vgt-input {
    box-sizing: border-box;
    width: 100%; }
  .agents-content .vgt-global-search__input {
    padding-left: 12px; } }

.vpk-content {
  padding-top: 80px; }
  @media screen and (max-width: 800px) {
    .vpk-content {
      padding-top: 40px; } }
  .vpk-content--about {
    padding-top: 0; }

.vpk-more {
  display: flex;
  margin-bottom: 80px; }
  @media screen and (max-width: 800px) {
    .vpk-more {
      flex-direction: column;
      margin-bottom: 40px; } }

.vpk-more-sticky {
  justify-content: space-around;
  align-items: flex-start; }

.vpk-more-text {
  color: #595959;
  letter-spacing: -0.4px;
  width: 100%;
  font-size: 2.4rem;
  line-height: 1.33333em; }
  .theme-monochrome._black-accent .vpk-more-text {
    color: #000 !important; }
  .theme-monochrome._white-accent .vpk-more-text {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .vpk-more-text {
    color: #472312 !important; }
  @media screen and (max-width: 800px) {
    .vpk-more-text {
      font-size: 2rem;
      line-height: 1.3em; } }

.vpk-more-actions {
  width: 280px;
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 100px; }
  @media screen and (max-width: 800px) {
    .vpk-more-actions {
      margin-left: 0;
      margin-top: 40px; } }
  .vpk-more-actions .action-button {
    margin-top: 0;
    background: #f7f7f7; }
    .theme-monochrome._black-ground .vpk-more-actions .action-button {
      background: #000 !important; }
    .theme-monochrome._white-ground .vpk-more-actions .action-button {
      background: #fff !important; }
    .theme-monochrome._sepia-ground .vpk-more-actions .action-button {
      background: #F5F5DC !important; }
    .vpk-more-actions .action-button + .action-button {
      margin-top: 8px; }

.vpk-more-sticky-buttons {
  position: -webkit-sticky;
  position: sticky;
  top: 40px; }

@media screen and (max-width: 600px) {
  .vpk-includes {
    margin-bottom: 40px; } }

.vpk-includes h3 {
  margin-bottom: 40px; }

.vpki-grid {
  display: flex;
  flex-wrap: wrap;
  margin-left: -46px;
  margin-right: -46px; }
  @media screen and (max-width: 600px) {
    .vpki-grid {
      flex-direction: column; } }
  @media screen and (max-width: 800px) {
    .vpki-grid {
      margin-left: 0;
      margin-right: 0; } }

.vpki-item {
  width: calc(33.33333% - 92px);
  box-sizing: border-box;
  margin-bottom: 56px;
  margin-left: 46px;
  margin-right: 46px;
  border-radius: 2px;
  position: relative;
  cursor: pointer; }
  @media screen and (max-width: 600px) {
    .vpki-item + .vpki-item {
      margin-top: 32px; } }
  @media screen and (max-width: 600px) {
    .vpki-item {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 0; } }
  @media screen and (max-width: 800px) {
    .vpki-item {
      width: 27%;
      margin-left: 0; } }
  .vpki-item:hover .vpki-item-hover {
    opacity: 1;
    visibility: visible; }
  .vpki-item.vpki-item--no-content {
    cursor: default; }
  .vpki-item._c-4 {
    width: calc(25% - 92px); }
    @media screen and (max-width: 600px) {
      .vpki-item._c-4 {
        width: 100%; } }

.vpki-item-inner {
  transition: opacity .4s, visibility .4s, transform .4s;
  opacity: 0;
  visibility: hidden;
  transform: translateY(40px); }
  @media screen and (max-width: 800px) {
    .vpki-item-inner {
      display: none; } }
  .vpki-item-inner.view-in {
    opacity: 1;
    transform: translateY(0);
    visibility: visible; }

.vpki-item-hover {
  position: absolute;
  padding: 40px;
  left: -40px;
  top: -40px;
  right: -40px;
  background: #FFFFFF;
  box-shadow: 0 14px 40px 0 rgba(0, 0, 0, 0.16);
  opacity: 0;
  visibility: hidden;
  z-index: 2;
  transition: opacity .3s, visibility .3s; }
  .theme-monochrome._black-ground .vpki-item-hover {
    box-shadow: none;
    background: #000 !important; }
  .theme-monochrome._white-ground .vpki-item-hover {
    box-shadow: none;
    background: #fff !important; }
  .theme-monochrome._sepia-ground .vpki-item-hover {
    box-shadow: none;
    background: #F5F5DC !important; }

.vpki-header {
  color: #262626;
  text-transform: uppercase;
  margin-bottom: 8px;
  font-size: 1.6rem;
  line-height: 1.5em; }
  .theme-monochrome._black-accent .vpki-header {
    color: #000 !important; }
  .theme-monochrome._white-accent .vpki-header {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .vpki-header {
    color: #472312 !important; }

.vpki-preview {
  color: #A0A0A0; }
  .theme-monochrome._black-accent .vpki-preview {
    color: #000 !important; }
  .theme-monochrome._white-accent .vpki-preview {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .vpki-preview {
    color: #472312 !important; }

.vpki-price {
  font-size: 2rem;
  line-height: 1.2em; }

.vpki-content {
  color: #A0A0A0;
  font-size: 1.4rem;
  line-height: 1.42857em; }
  .theme-monochrome._black-accent .vpki-content {
    color: #000 !important; }
  .theme-monochrome._white-accent .vpki-content {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .vpki-content {
    color: #472312 !important; }
  .vpki-content ol {
    margin-top: 8px;
    counter-reset: cnt; }
    .vpki-content ol li {
      counter-increment: cnt; }
      .vpki-content ol li:before {
        content: counter(cnt) ".";
        margin-right: 8px;
        opacity: .5; }

.vpk-services {
  padding: 88px 72px;
  margin-bottom: 72px;
  position: relative;
  width: 100%; }
  .theme-monochrome._black-accent .vpk-services {
    background: #000 !important; }
  .theme-monochrome._white-accent .vpk-services {
    background: #fff !important; }
  .theme-monochrome._sepia-accent .vpk-services {
    background: #472312 !important; }
  @media screen and (max-width: 800px) {
    .vpk-services {
      padding: 40px 16px;
      margin-bottom: 92px;
      box-sizing: border-box; } }

.vpk-cols11 {
  margin-left: 14px;
  margin-right: 14px; }
  @media screen and (max-width: 800px) {
    .vpk-cols11 {
      margin-left: 4px;
      margin-right: 84px; } }

.vpks-content {
  text-align: center;
  color: #ffffff; }
  .theme-monochrome._black-ground .vpks-content {
    color: #000 !important; }
  .theme-monochrome._white-ground .vpks-content {
    color: #fff !important; }
  .theme-monochrome._sepia-ground .vpks-content {
    color: #F5F5DC !important; }
  .vpks-content h3 {
    color: #ffffff; }
    .theme-monochrome._black-ground .vpks-content h3 {
      color: #000 !important; }
    .theme-monochrome._white-ground .vpks-content h3 {
      color: #fff !important; }
    .theme-monochrome._sepia-ground .vpks-content h3 {
      color: #F5F5DC !important; }
    @media screen and (max-width: 800px) {
      .vpks-content h3 {
        font-size: 2.4rem;
        line-height: 1.33333em;
        letter-spacing: -0.3px; } }

.vpks-cards {
  margin-top: 40px;
  margin-bottom: -120px;
  position: relative;
  display: flex;
  justify-content: center; }
  @media screen and (max-width: 800px) {
    .vpks-cards {
      overflow-x: scroll;
      -webkit-overflow-scrolling: touch;
      justify-content: flex-start;
      margin-left: -16px;
      margin-right: -16px;
      padding-bottom: 50px; }
      .vpks-cards:after {
        content: '';
        flex: 0 0 16px; } }

.vpks-card {
  background: #ffffff;
  padding: 30px;
  border-radius: 15px;
  box-sizing: border-box;
  box-shadow: 0 24px 40px 0 rgba(0, 0, 0, 0.16);
  position: relative;
  margin-left: 16px;
  margin-right: 16px;
  width: calc(30% - 32px); }
  @media screen and (max-width: 800px) {
    .vpks-card {
      width: 75vw;
      padding: 24px;
      box-sizing: border-box;
      flex-shrink: 0;
      margin-left: 8px;
      margin-right: 8px; } }
  .theme-monochrome._black-ground .vpks-card {
    box-shadow: none;
    background: #000 !important; }
  .theme-monochrome._white-ground .vpks-card {
    box-shadow: none;
    background: #fff !important; }
  .theme-monochrome._sepia-ground .vpks-card {
    box-shadow: none;
    background: #F5F5DC !important; }
  .vpks-card p + p {
    margin-top: 8px; }
  .vpks-card .lk__action {
    letter-spacing: -.5px;
    padding: 10px; }

.vpks-card-header {
  margin-bottom: 16px; }

.vpks-card-body__container {
  width: inherit !important;
  margin-left: 0;
  margin-bottom: 15px; }

.vpks-card-body__button {
  position: absolute;
  bottom: 0;
  left: 10px;
  right: 10px; }
  .big-font-size .vpks-card-body__button {
    position: static; }

.vpks-card-body__container a.btn {
  padding: 12px;
  width: 100%;
  border-radius: 10px; }
  .vpks-card-body__container a.btn:hover {
    color: #f36c21; }

.vpks-card-footer {
  position: absolute;
  right: 40px;
  top: 40px; }

.vpks-card-body__full {
  height: 100%; }

.vpks-card-body__info {
  margin-bottom: 50px; }

.vpk-questions {
  display: flex; }
  @media screen and (max-width: 800px) {
    .vpk-questions {
      flex-direction: column; } }

.vpkq-main h3 {
  margin-bottom: 40px; }

.vpkq-row + .vpkq-row {
  margin-top: 48px; }

.vpkq-aside {
  width: 320px;
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 96px; }
  @media screen and (max-width: 800px) {
    .vpkq-aside {
      margin-left: 0;
      margin-top: 40px;
      width: 100%; } }

.vpkq-item {
  padding: 24px;
  border: 1px solid #E8E8E8;
  border-radius: 4px;
  margin-bottom: 16px; }
  @media screen and (max-width: 800px) {
    .vpkq-item {
      box-sizing: border-box; } }
  .theme-monochrome._black-ground .vpkq-item {
    border-color: #000 !important; }
  .theme-monochrome._white-ground .vpkq-item {
    border-color: #fff !important; }
  .theme-monochrome._sepia-ground .vpkq-item {
    border-color: #F5F5DC !important; }
  .vpkq-item p + p {
    margin-top: 8px;
    letter-spacing: -.5px; }
  .vpkq-item .vpkq-action {
    margin-top: 18px;
    letter-spacing: -.5px; }

.vpk-includes .vpki-item {
  border: 1px solid #f26123;
  border-radius: 15px;
  padding: 16px 16px;
  width: calc(33.33333% - 40px);
  margin-left: 20px;
  margin-right: 20px; }
  @media screen and (max-width: 800px) {
    .vpk-includes .vpki-item {
      width: 30%; } }
  @media screen and (max-width: 600px) {
    .vpk-includes .vpki-item {
      width: 100%;
      margin-left: 0;
      margin-right: 0; } }

.vpk-info__block {
  padding: 40px; }

.vpk-info__detail {
  margin-top: 20px;
  list-style-type: disc;
  -webkit-columns: 2;
  -moz-columns: 2;
  columns: 2;
  margin-left: -20px;
  margin-right: -20px;
  list-style-position: inside; }
  @media screen and (max-width: 800px) {
    .vpk-info__detail {
      margin-top: 20px;
      list-style-type: disc;
      -webkit-columns: 1;
      -moz-columns: 1;
      columns: 1; } }
  .vpk-info__detail .col {
    margin-bottom: 20px;
    margin-right: 30px;
    width: fit-content !important;
    display: -webkit-box; }
    .vpk-info__detail .col span {
      content: '';
      display: block;
      width: 32px;
      height: 32px;
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIzMnB4IiBoZWlnaHQ9IjMycHgiIHZpZXdCb3g9IjAgMCAzMiAzMiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5jaGVjazwvdGl0bGU+ICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPiAgICA8ZGVmcz48L2RlZnM+ICAgIDxnIGlkPSJQdWJsaWMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPGcgaWQ9ImdlbGlvc19wcm9kdWN0cy1WUEsiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xNzYuMDAwMDAwLCAtMjI2NS4wMDAwMDApIj4gICAgICAgICAgICA8ZyBpZD0iY29udGVudCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNzIuMDAwMDAwLCA3MC4wMDAwMDApIj4gICAgICAgICAgICAgICAgPGcgaWQ9InRlbGVtZWQtcG9wdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDM4LjAwMDAwMCwgMTk3MS4wMDAwMDApIj4gICAgICAgICAgICAgICAgICAgIDxnIGlkPSJjaGVjayIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNjYuMDAwMDAwLCAyMjQuMDAwMDAwKSI+ICAgICAgICAgICAgICAgICAgICAgICAgPHJlY3QgaWQ9IlJlY3RhbmdsZS0xNCIgZmlsbD0iI0Y3RjdGNyIgeD0iMCIgeT0iMCIgd2lkdGg9IjMyIiBoZWlnaHQ9IjMyIiByeD0iMTYiPjwvcmVjdD4gICAgICAgICAgICAgICAgICAgICAgICA8cG9seWxpbmUgaWQ9IlNoYXBlIiBzdHJva2U9IiM3RUJEMUIiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBwb2ludHM9IjEyIDE3LjMzMzMzMzMgMTQuMSAxOS4zMzMzMzMzIDE5LjggMTMiPjwvcG9seWxpbmU+ICAgICAgICAgICAgICAgICAgICA8L2c+ICAgICAgICAgICAgICAgIDwvZz4gICAgICAgICAgICA8L2c+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=);
      background-position: 50% 50%;
      background-repeat: no-repeat;
      margin-bottom: 8px;
      margin-right: 10px; }
    .vpk-info__detail .col p {
      margin-top: 8px;
      line-height: 20px; }

.vpks-popups {
  position: absolute;
  height: 0;
  top: 0;
  left: 0;
  right: 0; }

.vpks-popup {
  position: absolute;
  top: 160px;
  z-index: 1;
  background: #fff;
  padding: 80px 66px;
  border-radius: 2px;
  left: 38px;
  right: 38px;
  box-shadow: 0 24px 40px 0 rgba(0, 0, 0, 0.16);
  transition: transform .4s, opacity .4s, visibility .4s;
  transform: scaleY(0);
  opacity: 0;
  visibility: hidden; }
  .theme-monochrome._black-ground .vpks-popup {
    box-shadow: none;
    background: #000 !important; }
  .theme-monochrome._white-ground .vpks-popup {
    box-shadow: none;
    background: #fff !important; }
  .theme-monochrome._sepia-ground .vpks-popup {
    box-shadow: none;
    background: #F5F5DC !important; }
  .vpks-popup.active, input:-webkit-autofill + label.vpks-popup {
    transform: scaleY(1);
    opacity: 1;
    visibility: visible; }
  .vpks-popup ._body {
    margin-bottom: 40px; }
  .vpks-popup ._footer .text--smaller {
    line-height: 20px; }
  .vpks-popup ._footer .col:before {
    content: '';
    display: block;
    width: 32px;
    height: 32px;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIzMnB4IiBoZWlnaHQ9IjMycHgiIHZpZXdCb3g9IjAgMCAzMiAzMiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5jaGVjazwvdGl0bGU+ICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPiAgICA8ZGVmcz48L2RlZnM+ICAgIDxnIGlkPSJQdWJsaWMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPGcgaWQ9ImdlbGlvc19wcm9kdWN0cy1WUEsiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xNzYuMDAwMDAwLCAtMjI2NS4wMDAwMDApIj4gICAgICAgICAgICA8ZyBpZD0iY29udGVudCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNzIuMDAwMDAwLCA3MC4wMDAwMDApIj4gICAgICAgICAgICAgICAgPGcgaWQ9InRlbGVtZWQtcG9wdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDM4LjAwMDAwMCwgMTk3MS4wMDAwMDApIj4gICAgICAgICAgICAgICAgICAgIDxnIGlkPSJjaGVjayIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNjYuMDAwMDAwLCAyMjQuMDAwMDAwKSI+ICAgICAgICAgICAgICAgICAgICAgICAgPHJlY3QgaWQ9IlJlY3RhbmdsZS0xNCIgZmlsbD0iI0Y3RjdGNyIgeD0iMCIgeT0iMCIgd2lkdGg9IjMyIiBoZWlnaHQ9IjMyIiByeD0iMTYiPjwvcmVjdD4gICAgICAgICAgICAgICAgICAgICAgICA8cG9seWxpbmUgaWQ9IlNoYXBlIiBzdHJva2U9IiM3RUJEMUIiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBwb2ludHM9IjEyIDE3LjMzMzMzMzMgMTQuMSAxOS4zMzMzMzMzIDE5LjggMTMiPjwvcG9seWxpbmU+ICAgICAgICAgICAgICAgICAgICA8L2c+ICAgICAgICAgICAgICAgIDwvZz4gICAgICAgICAgICA8L2c+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    margin-bottom: 8px; }

.vpks-popup-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end; }

.vpks-popup-close {
  width: 56px;
  height: 56px;
  position: absolute;
  right: 24px;
  top: 24px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDE0IDE0Ij48cGF0aCBkPSJNMTMgMUwxIDEzTTEgMWwxMiAxMiIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2U9IiMyNjI2MjYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIi8+PC9zdmc+);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  transition: transform .3s; }
  .vpks-popup-close:hover {
    transform: rotate(180deg); }

@media screen and (max-width: 800px) {
  .vpki-item-hover {
    display: block;
    box-shadow: none;
    position: static;
    opacity: 1;
    visibility: visible;
    padding: 0; } }

@media screen and (max-width: 800px) {
  .vpki-item-inner.vpki-item-inner--m-show {
    display: block; } }

.kbm-form {
  margin-top: 32px; }
  .kbm-form .form__radios {
    margin-top: 24px;
    display: flex;
    align-items: center; }
    .kbm-form .form__radios .form__radio + .form__radio {
      margin-top: 0;
      margin-left: 40px; }
    .kbm-form .form__radios + .form__row {
      margin-top: 40px; }

.validate-form {
  margin-top: 32px; }
  .validate-form .form__radios {
    margin-top: 24px;
    display: flex;
    align-items: center; }
    .validate-form .form__radios .form__radio + .form__radio {
      margin-top: 0;
      margin-left: 40px; }
    .validate-form .form__radios + .form__row {
      margin-top: 40px; }
  .validate-form .input-field select {
    padding-bottom: 24px !important; }

:root {
  --clr-primary: #f26123;
  --clr-green: #96c22b;
  --clr-text: #58595b;
  scroll-behavior: smooth; }

body {
  scroll-behavior: smooth; }

.text-orange {
  color: #f26123 !important; }

.text-center {
  text-align: center; }

.bg-grey {
  background-color: #f7f7f7 !important; }

.bg-white {
  background: #fff; }

.landing-button--grey {
  background: #ccc !important;
  color: var(--clr-text) !important; }
  .landing-button--grey:hover {
    color: #e2e2e2 !important; }

ul.sk-list {
  padding-left: 15px; }

ul.sk-list li {
  list-style: none;
  padding: 0.15em; }

ul.sk-list li:before {
  content: "\2022";
  color: #F26123;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em; }

.sp-page {
  --section-padding: 72px;
  --sp-title-fs: 70px;
  --sp-title-color: #fff;
  color: #58595b; }
  .sp-page h3 {
    font-size: 2.6rem !important;
    color: #000000;
    font-weight: bold;
    margin-bottom: 1.4em; }
    @media screen and (max-width: 767px) {
      .sp-page h3 {
        font-size: 2.2rem !important; } }
  .sp-page h4 {
    font-size: 2.2rem !important;
    line-height: 1.2;
    margin-bottom: 1em; }
    @media screen and (max-width: 700px) {
      .sp-page h4 {
        font-size: 2rem !important; } }
    @media screen and (max-width: 420px) {
      .sp-page h4 {
        font-size: 1.8rem !important; } }
  @media screen and (max-width: 900px) {
    .sp-page {
      --section-padding: 62px; } }
  @media screen and (max-width: 767px) {
    .sp-page {
      --section-padding: 52px; } }
  @media screen and (max-width: 420px) {
    .sp-page {
      --section-padding: 32px; } }
  @media screen and (max-width: 900px) {
    .sp-page .content__inner {
      padding: var(--section-padding) calc(var(--section-padding)/2); } }
  @media screen and (max-width: 767px) {
    .sp-page .new-kasko.header-block .content__inner h1 {
      font-size: 42px !important; } }
  @media screen and (max-width: 420px) {
    .sp-page .new-kasko.header-block .content__inner h1 {
      font-size: 32px !important; } }
  @media screen and (max-width: 767px) {
    .sp-page .corp-hero__content p.header {
      margin-bottom: 1em; } }

.sp-p {
  font-size: 2rem;
  line-height: 1.4; }
  @media screen and (max-width: 700px) {
    .sp-p {
      font-size: 1.8rem; } }
  @media screen and (max-width: 420px) {
    .sp-p {
      font-size: 1.6rem; } }
  @media screen and (max-width: 340px) {
    .sp-p {
      font-size: 1.4rem; } }

.sp-sign {
  font-size: 1.4rem; }

.sp-hero {
  display: flex;
  align-items: flex-end;
  padding: 40px var(--section-padding) var(--section-padding); }
  @media screen and (max-width: 600px) {
    .sp-hero {
      padding: 0; } }
  @media screen and (max-width: 767px) {
    .sp-hero__content {
      width: 100%; } }
  @media screen and (max-width: 900px) {
    .sp-hero__title {
      padding-top: 40px !important; } }
  .sp-hero__subtitle {
    display: block !important; }
    @media screen and (max-width: 400px) {
      .sp-hero__subtitle {
        font-size: 18px !important; } }
  .sp-hero__img {
    margin-bottom: -32px; }
    @media screen and (max-width: 767px) {
      .sp-hero__img {
        display: none; } }
    .sp-hero__img img {
      width: 100%; }

.sp-hero--orig {
  background: #f7f7f7; }
  .sp-hero--orig .sp-hero::before {
    display: none; }
  .sp-hero--orig .sp-hero__content {
    flex-shrink: 0; }
    @media screen and (min-width: 768px) {
      .sp-hero--orig .sp-hero__content {
        width: 50%; } }
    @media screen and (max-width: 600px) {
      .sp-hero--orig .sp-hero__content {
        box-sizing: border-box; } }
    .sp-hero--orig .sp-hero__content__inner {
      display: flex;
      flex-direction: column;
      align-items: center; }
  .sp-hero--orig .sp-hero__title {
    color: var(--clr-primary);
    text-align: center;
    font-size: 50px !important;
    text-align: center; }
  .sp-hero--orig .sp-hero__subtitle {
    color: var(--clr-text) !important;
    text-align: center;
    font-size: 26px !important; }
    @media screen and (max-width: 767px) {
      .sp-hero--orig .sp-hero__subtitle {
        font-size: 22px !important; } }
    @media screen and (max-width: 400px) {
      .sp-hero--orig .sp-hero__subtitle {
        font-size: 18px !important; } }

.sp-content--bg-figure {
  position: relative;
  overflow: hidden;
  --bg-side-length: 440px; }
  .sp-content--bg-figure .content__inner {
    position: relative; }
  .sp-content--bg-figure .bg-figure-item {
    display: block;
    width: var(--bg-side-length);
    height: var(--bg-side-length);
    background: url("/static/img/bg-figure.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute; }

.sp-second {
  text-align: center; }
  .sp-second .sp-p {
    margin-bottom: var(--section-padding); }
  .sp-second h4 {
    margin-left: auto;
    margin-right: auto;
    max-width: 520px;
    display: block; }
    @media screen and (max-width: 420px) {
      .sp-second h4 {
        margin-bottom: 0; } }
  .sp-second .bg-figure-item {
    bottom: -220px;
    right: -220px; }

.sp-what {
  min-height: 420px;
  box-sizing: border-box;
  padding: var(--section-padding) 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }
  @media screen and (max-width: 900px) {
    .sp-what {
      min-height: 400px; } }
  @media screen and (max-width: 767px) {
    .sp-what {
      min-height: 220px;
      padding: var(--section-padding) 20px; } }
  .sp-what__content {
    background: #eeeef2;
    max-width: 520px;
    width: 40%;
    padding: var(--section-padding) calc(var(--section-padding)/2);
    border-radius: 0 10px 10px 0;
    box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2); }
    @media screen and (max-width: 767px) {
      .sp-what__content {
        margin: auto;
        width: 90%;
        background: rgba(238, 238, 242, 0.9);
        border-radius: 10px; } }

.sp-list__item {
  display: flex;
  align-items: center; }
  .sp-list__item img {
    margin-right: 32px;
    width: 59px;
    height: 59px; }
    @media screen and (max-width: 767px) {
      .sp-list__item img {
        width: 42px;
        height: 42px;
        margin-right: 24px; } }
    @media screen and (max-width: 400px) {
      .sp-list__item img {
        width: 36px;
        height: 36px;
        margin-right: 18px; } }
  .sp-list__item + .sp-list__item {
    margin-top: 32px; }

.sp-list--grid-3 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  --grip-gap-half: 10px;
  margin: 32px calc(var(--grip-gap-half) * -1); }
  .sp-list--grid-3 .sp-list__item {
    width: 33.33%;
    padding: 0 var(--grip-gap-half) 24px;
    box-sizing: border-box; }
  .sp-list--grid-3 .sp-list__item + .sp-list__item {
    margin-top: 0; }

.sp-list--img-middle .sp-list__item img {
  width: 45px;
  height: 45px;
  margin-right: 12px; }

@media screen and (max-width: 900px) {
  .sp-list--grid-3 .sp-list__item {
    width: 50%; } }

@media screen and (max-width: 500px) {
  .sp-list--grid-3 .sp-list__item {
    width: 100%;
    padding: 0 var(--grip-gap-half) 12px; }
  .sp-list--img-middle .sp-list__item img {
    width: 36px;
    height: 36px;
    margin-right: 8px; }
  .sp-list--grid-3 {
    --grip-gap-half: 0;
    margin: 28px 0 0; } }

.sp-page .accordion__item {
  background: #fff; }

@media screen and (min-width: 900px) {
  .accordion--with-columns {
    display: flex; }
  .accordion-column {
    width: 50%;
    padding: 0 10px; } }

.sp-events__list {
  max-width: 920px;
  margin-left: auto;
  margin-right: auto; }

.sp-auto {
  display: flex !important;
  position: relative; }
  @media screen and (min-width: 900px) {
    .sp-auto h3 {
      text-align: center; } }
  .sp-auto__img {
    width: 40%;
    position: relative;
    overflow: hidden;
    display: flex;
    padding-top: 32px;
    align-items: center; }
    .sp-auto__img img {
      position: relative;
      width: 160%;
      left: -60%; }
    @media screen and (max-width: 1200px) {
      .sp-auto__img {
        width: 30%;
        margin-top: auto;
        margin-bottom: auto; } }
    @media screen and (max-width: 767px) {
      .sp-auto__img {
        position: absolute;
        bottom: 0;
        padding-bottom: 12px; } }
  .sp-auto__content {
    padding: var(--section-padding); }
    @media screen and (max-width: 767px) {
      .sp-auto__content {
        position: relative; } }
  @media screen and (max-width: 767px) {
    .sp-auto__list {
      flex-direction: column;
      align-items: center;
      padding-left: 30%; } }
  .sp-auto__list .sp-list__item + .sp-list__item {
    margin-top: 12px; }
  .sp-auto__list .sp-list__item__img {
    width: 37px;
    height: 37px;
    margin-right: 20px; }
    @media screen and (max-width: 420px) {
      .sp-auto__list .sp-list__item__img {
        width: 28px;
        height: 28px;
        margin-right: 12px; } }

.sp-benefits {
  --bg-side-length: 700px; }
  .sp-benefits .bg-figure-item {
    right: -300px;
    bottom: -300px; }
  .sp-benefits__list {
    max-width: 920px;
    margin-left: auto;
    margin-right: auto; }

.sp-choice {
  --bg-side-length: 480px; }
  .sp-choice__list {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-left: 20%; }
    @media screen and (max-width: 767px) {
      .sp-choice__list {
        padding-left: 0; } }
  .sp-choice .bg-figure-item {
    left: -200px;
    bottom: -200px; }
  .sp-choice h3 + p {
    max-width: 720px;
    margin-left: auto;
    margin-right: auto; }
  .sp-choice .sp-list__item {
    align-items: center;
    text-align: right; }
    .sp-choice .sp-list__item + .sp-list__item {
      margin-top: 22px; }
  .sp-choice__item__img {
    width: 150px;
    height: 90px;
    display: flex;
    margin-left: 50px;
    flex-shrink: 0; }
    @media screen and (max-width: 500px) {
      .sp-choice__item__img {
        width: 90px;
        height: 70px;
        margin-left: 20px; } }
    .sp-choice__item__img img {
      margin-right: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
      flex-shrink: 0; }

.sp-ordering h3 {
  margin-bottom: 1em; }

.sp-ordering__steps {
  display: flex;
  justify-content: center;
  margin: 45px 0; }
  @media screen and (max-width: 650px) {
    .sp-ordering__steps {
      flex-direction: column;
      align-items: flex-start;
      max-width: 280px;
      margin: 22px auto; } }

.sp-ordering__step {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 90px;
  position: relative; }
  @media screen and (max-width: 900px) {
    .sp-ordering__step {
      margin: 0 60px; } }
  @media screen and (max-width: 650px) {
    .sp-ordering__step {
      flex-direction: row;
      margin: 25px 0; } }
  .sp-ordering__step:not(:last-child)::before {
    content: "";
    display: block;
    width: 91px;
    height: 20px;
    position: absolute;
    left: calc(100% + 45px);
    top: 32px;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhcGFfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmlld0JveD0iMCAwIDkxLjAgMjAuMCIgd2lkdGg9IjkxLjAiIGhlaWdodD0iMjAuMCI+DQoJPHBhdGggZD0iTSAwLDEwIEwgOTEuMCwxMCIgbWFya2VyLXN0YXJ0PSIiIG1hcmtlci1lbmQ9InVybCgjZW5kYXJyb3doZWFkOERDNjNGKSIgc3R5bGU9ImZpbGw6bm9uZTsgc3Ryb2tlLXdpZHRoOjJweDsiIHN0cm9rZT0iIzhEQzYzRiI+PC9wYXRoPg0KPC9zdmc+DQo=);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center; }
    @media screen and (max-width: 900px) {
      .sp-ordering__step:not(:last-child)::before {
        left: calc(100% + 15px); } }
    @media screen and (max-width: 650px) {
      .sp-ordering__step:not(:last-child)::before {
        transform: rotate(90deg);
        left: 5px;
        top: calc(100% + 20px);
        width: 36px;
        height: 10px; } }
  @media screen and (max-width: 650px) {
    .sp-ordering__step:first-child {
      margin-top: 10px; } }
  .sp-ordering__step__numb {
    --width-size: 65px;
    border: 1px solid var(--clr-primary);
    border-radius: 50%;
    width: var(--width-size);
    height: var(--width-size);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--clr-primary);
    font-size: 42px;
    margin-bottom: 10px; }
    @media screen and (max-width: 650px) {
      .sp-ordering__step__numb {
        flex-direction: row;
        margin-right: 12px;
        --width-size: 40px;
        font-size: 28px;
        flex-shrink: 0;
        margin-bottom: 0; } }
  .sp-ordering__step__title {
    font-size: 1.6rem;
    max-width: 165px;
    text-align: center; }
    @media screen and (max-width: 650px) {
      .sp-ordering__step__title {
        text-align: left;
        max-width: 220px; } }
    @media screen and (max-width: 400px) {
      .sp-ordering__step__title {
        font-size: 1.4rem; } }

.sp-ordering__block {
  margin: 0 auto;
  max-width: 650px;
  padding: calc(var(--section-padding) / 2) var(--section-padding);
  border-radius: 10px;
  box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  position: relative; }
  @media screen and (max-width: 500px) {
    .sp-ordering__block {
      padding: 20px 12px; } }
  @media screen and (max-width: 600px) {
    .sp-ordering__block__head {
      padding: 0 25px; } }
  @media screen and (max-width: 400px) {
    .sp-ordering__block__head .sp-p {
      font-size: 1.6rem; } }
  .sp-ordering__block__view {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .sp-ordering__block__view--first .action-button {
      margin: 10px auto 12px; }
    .sp-ordering__block__view .form__action .btn + .btn {
      margin-left: 15px; }
    .sp-ordering__block__view .landing-button--grey {
      letter-spacing: -.5px; }
  .sp-ordering__block.data-preloader::before {
    z-index: 20;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    content: "";
    display: block;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(1.5px); }
  .sp-ordering__block.data-preloader::after {
    z-index: 22; }
  .sp-ordering__block .select-label,
  .sp-ordering__block .input-field__input {
    background: transparent !important; }
  .sp-ordering__block .premium-sum-input input {
    pointer-events: none; }
  .sp-ordering__block .sp-form_request {
    margin-top: 28px;
    width: 100%; }
  .sp-ordering__block__back {
    position: absolute;
    left: 24px;
    top: 24px;
    width: 32px;
    height: 24px;
    cursor: pointer;
    color: var(--clr-text);
    opacity: .66;
    transition: .2s; }
    .sp-ordering__block__back :hover {
      opacity: 1; }
    @media screen and (max-width: 600px) {
      .sp-ordering__block__back {
        left: 6px;
        top: 14px;
        width: 28px; } }

.sp-ordering__table {
  margin: 3.2rem auto 1rem;
  display: flex;
  align-items: center; }
  @media screen and (max-width: 600px) {
    .sp-ordering__table {
      width: 100%;
      margin: 2rem auto 1rem; } }
  .sp-ordering__table__titles {
    margin-right: 40px; }
    @media screen and (max-width: 600px) {
      .sp-ordering__table__titles {
        display: none; } }

.sp-ordering__vars input[type='radio']:nth-child(1):checked ~ .sp-ordering__vars__list .sp-ordering__var:nth-child(1),
.sp-ordering__vars input[type='radio']:nth-child(2):checked ~ .sp-ordering__vars__list .sp-ordering__var:nth-child(2),
.sp-ordering__vars input[type='radio']:nth-child(3):checked ~ .sp-ordering__vars__list .sp-ordering__var:nth-child(3),
.sp-ordering__vars input[type='radio']:nth-child(4):checked ~ .sp-ordering__vars__list .sp-ordering__var:nth-child(4),
.sp-ordering__vars input[type='radio']:nth-child(5):checked ~ .sp-ordering__vars__list .sp-ordering__var:nth-child(5),
.sp-ordering__vars input[type='radio']:nth-child(6):checked ~ .sp-ordering__vars__list .sp-ordering__var:nth-child(6) {
  opacity: 1;
  border-color: var(--clr-primary);
  background: #FFEAE0;
  transform: scale(1); }

.sp-ordering__vars__list {
  display: flex;
  flex-wrap: wrap;
  display: flex;
  max-width: 472px; }
  @media screen and (max-width: 600px) {
    .sp-ordering__vars__list {
      justify-content: center; } }

@media screen and (max-width: 600px) {
  .sp-ordering__vars {
    width: 100%; } }

.sp-ordering__var {
  padding: 20px;
  box-sizing: border-box;
  width: 140px;
  opacity: 0.7;
  border: 1px solid #989898;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.8px;
  margin: 1rem;
  transition: .2s;
  cursor: pointer;
  transform: scale(0.95);
  background: #fff; }
  @media screen and (max-width: 600px) {
    .sp-ordering__var {
      width: 50%;
      max-width: 200px;
      padding: 12px;
      margin: 0 0.6rem; } }
  .sp-ordering__var .sign {
    color: var(--clr-text);
    font-size: 12px;
    line-height: 1.2;
    margin-bottom: 5px;
    font-weight: normal; }
    @media screen and (min-width: 601px) {
      .sp-ordering__var .sign {
        display: none; } }
  .sp-ordering__var:hover {
    transform: scale(1); }
  .sp-ordering__var__summ {
    color: var(--clr-green);
    font-size: 2.2rem; }
    @media screen and (max-width: 400px) {
      .sp-ordering__var__summ {
        font-size: 1.8rem; } }
  .sp-ordering__var__price {
    margin-top: 16px;
    color: var(--clr-primary);
    font-size: 2.6rem;
    font-weight: bold; }
    @media screen and (max-width: 400px) {
      .sp-ordering__var__price {
        font-size: 2.2rem; } }
  @media screen and (max-width: 600px) {
    .sp-ordering__var__summ, .sp-ordering__var__price {
      display: flex;
      flex-direction: column;
      align-items: center; } }

.sp-ordering .sp-sign {
  margin-top: 0.8em; }

.sp-ordering__check {
  width: 520px;
  margin: auto;
  max-width: 100%;
  margin: 28px auto 0px; }
  .sp-ordering__check__field {
    display: flex;
    white-space: nowrap; }
    .sp-ordering__check__field__value {
      white-space: normal; }
    .sp-ordering__check__field__name {
      padding-right: 20px;
      width: 50%;
      flex-shrink: 0; }
      @media screen and (max-width: 600px) {
        .sp-ordering__check__field__name {
          width: auto; }
          .sp-ordering__check__field__name::after {
            content: ':'; } }
  @media screen and (max-width: 450px) {
    .sp-ordering__check ~ .form__action {
      flex-direction: column;
      align-items: center;
      display: flex; } }

.sp-quest {
  overflow: hidden;
  position: relative; }
  .sp-quest .bg-figure-item {
    --bg-side-length: 465px;
    left: 15%;
    top: -170px; }

input[type=checkbox].checkbox--error + label:before {
  border-color: #f32121; }

input[type=checkbox] ~ .input-field__error {
  color: #f32121;
  font-size: 1.4rem;
  line-height: 1.14em;
  margin: 8px auto 4px; }

.sp-form_request .form__action {
  display: flex;
  justify-content: center; }

.sp-form_request input[type=checkbox] + label {
  margin-top: 0.8em; }

.sp-form_request input[type=checkbox] + label {
  font-size: 1.2rem; }

.sp-form__field {
  padding-bottom: 24px;
  max-width: 100%; }
  .sp-form__field--chip {
    display: flex;
    align-items: center;
    padding-top: 14px;
    padding-bottom: 21px;
    justify-content: space-between;
    padding-right: 10px; }
    @media screen and (max-width: 600px) {
      .sp-form__field--chip {
        padding-top: 14px;
        padding-bottom: 12px; } }
    @media screen and (max-width: 400px) {
      .sp-form__field--chip {
        flex-direction: column;
        align-items: flex-start; } }
    .sp-form__field--chip .sp-form__field__label {
      margin-right: 18px; }
      @media screen and (max-width: 600px) {
        .sp-form__field--chip .sp-form__field__label {
          margin-right: 10px; } }
    .sp-form__field--chip .w-chips__chip {
      text-align: center;
      min-width: 45px; }
    .sp-form__field--chip .w-chips__error {
      font-size: 14px;
      position: absolute;
      left: 0; }
  .sp-form__field--checkbox {
    padding-top: 4px;
    padding-bottom: 8px; }
    .sp-form__field--checkbox label {
      font-size: initial !important; }

.sp-form .form__group--fields {
  width: 100%; }

.sp-form .form__group + .form__group {
  padding-top: 16px; }

@media screen and (min-width: 900px) {
  .sp-form_construct.sp-form--columns-2 .sp-form_construct__fields {
    display: flex;
    flex-wrap: wrap;
    margin-left: -16px;
    margin-right: -16px; }
    .sp-form_construct.sp-form--columns-2 .sp-form_construct__fields .input-field {
      width: calc(50% - 32px);
      margin-left: 16px;
      margin-right: 16px;
      box-sizing: border-box; }
  .sp-form_construct.sp-form--columns-2 .sp-form__field--chip {
    padding-right: 0; } }

.sp-form_construct .form__group:first-child {
  padding: 20px 0; }

.sp-form_construct .sp-form__checkbox--agreement {
  margin-top: 16px; }

.sp-page .accordion__header {
  position: relative; }

.sp-page .accordion__header::after {
  position: absolute;
  content: "";
  display: block;
  left: 54px;
  bottom: 1px;
  width: 0;
  height: 1px;
  transition: .3s ease-in-out;
  background-color: #f36c21; }

.sp-page .accordion__item_show .accordion__header::after {
  width: 68px;
  transition: .6s ease-in-out; }

.reviews-list {
  display: flex;
  flex-wrap: wrap; }

.review-item {
  width: calc(50% - 30px);
  margin: 15px;
  border: 2px solid #ebebeb;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 20px;
  justify-content: space-between; }
  @media screen and (max-width: 800px) {
    .review-item {
      width: 100%;
      padding: 15px;
      margin: 10px 0; } }
  .review-item .vue-star-rating-rating-text {
    display: none; }

.ri {
  display: flex;
  flex-direction: column; }
  .ri-row {
    display: flex;
    justify-content: space-between; }
  .ri-text {
    margin-top: 12px; }
  .ri-bottom {
    margin-top: 14px;
    display: flex;
    justify-content: space-between; }
    @media screen and (max-width: 500px) {
      .ri-bottom {
        flex-direction: column-reverse;
        font-size: 14px; } }
  .ri-category {
    color: #8dc63f;
    font-weight: bold; }
  .ri-imgs {
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0 2px; }
    .ri-imgs img {
      max-width: 90px;
      max-height: 90px;
      padding: 5px;
      border-radius: 5px;
      border: 1px solid #eee;
      margin-bottom: 6px;
      cursor: pointer; }
      .ri-imgs img + img {
        margin-left: 8px; }

.vel-toolbar {
  display: none !important; }

.ri-select {
  width: 250px;
  margin: 12px 0 22px; }

.v-view--fade-in {
  transition-delay: 1s;
  transition: .5s ease-in; }

.v-view--in-step {
  transition: .4s; }

.view-out.v-view--fade-in {
  opacity: 0;
  transform: translateY(20px); }

.view-in.v-view--fade-in {
  opacity: 1;
  transform: translateY(0); }

.view-out.v-view--in-step {
  opacity: 0;
  /* visibility: hidden; */
  transform: translateY(-40px); }

.view-in.v-view--in-step {
  opacity: 1;
  /* visibility: visible; */
  transform: translateY(0); }

.v-view--in-step-left {
  transition: .5s; }

.view-out.v-view--in-step-left {
  /* opacity: 0; */
  transform: translateX(150px); }

.view-in.v-view--in-step-left {
  opacity: 1;
  transform: translateY(0); }

.v-view-quare {
  opacity: 0;
  transform: translateY(-40px);
  transition: .3s; }

.v-view-quare:nth-child(1) {
  transition-delay: 0; }

.v-view-quare:nth-child(2) {
  transition-delay: .5s; }

.v-view-quare:nth-child(3) {
  transition-delay: 1s; }

.view-in.v-view-quare {
  opacity: 1;
  transform: translateY(0); }

.v-view--in-step-down {
  transition: .5s;
  opacity: 0; }

.view-out.v-view--in-step-down {
  transform: translateY(40px); }

.view-in.v-view--in-step-down {
  opacity: 1;
  transform: translateY(0); }

.v-view--to-center,
.v-view--to-center--inverse {
  opacity: 0;
  transition: .4s; }

.v-view--to-center:nth-child(even) {
  transform: translateX(-80px); }

.v-view--to-center:nth-child(odd) {
  transform: translateX(80px); }

.v-view--to-center--inverse:nth-child(even) {
  transform: translateX(80px); }

.v-view--to-center--inverse:nth-child(odd) {
  transform: translateX(-80px); }

.view-in.v-view--to-center,
.view-in.v-view--to-center--inverse {
  opacity: 1;
  transform: translateX(0); }

.v-view--in-step-right {
  transition: .7s ease-in-out;
  opacity: 0;
  transform: translateX(-50px); }

.view-in.v-view--in-step-right {
  opacity: 1;
  transform: translateX(0); }

.bg-figure-item {
  transition: 6s ease-in-out; }

.view-out.bg-figure-item {
  transform: scale(0.8); }

.view-in.bg-figure-item {
  transform: scale(1.1); }

.figure--floating {
  animation: float 8s ease-in-out infinite; }

.figure--floating-scale {
  animation: float-scale 12s ease-in-out infinite; }

.sp-content--bg-figure--top-left {
  --bg-side-length: 480px; }

.sp-content--bg-figure--top-left .bg-figure-item {
  left: -200px;
  top: 100px; }

.sp-content--bg-figure--bottom-right .bg-figure-item {
  bottom: -170px;
  right: -200px; }

@keyframes float {
  0% {
    transform: translatey(0px); }
  50% {
    transform: translatey(-10px); }
  100% {
    transform: translatey(0px); } }

@keyframes float-scale {
  0% {
    transform: translate(0px) scale(1); }
  25% {
    transform: scale(1.15); }
  50% {
    transform: translate(-10px, 0px) scale(1.1); }
  75% {
    transform: scale(1.02); }
  100% {
    transform: translate(0px) scale(1); } }

@keyframes float-xy {
  0% {
    transform: translatey(0px); }
  50% {
    transform: translatey(-10px); }
  100% {
    transform: translatey(0px); } }

.site-footer {
  color: #58595b;
  padding-top: 40px;
  padding-bottom: 40px;
  font-size: 1.4rem;
  line-height: 1.14286em; }
  .theme-monochrome._black-accent .site-footer {
    color: #000 !important; }
  .theme-monochrome._white-accent .site-footer {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .site-footer {
    color: #472312 !important; }
  @media screen and (max-width: 900px) {
    .site-footer {
      padding: 40px 16px 10px; } }
  .site-footer .index .site-footer {
    background: #ffffff; }
    .theme-monochrome._black-ground .site-footer .index .site-footer {
      background: #000 !important; }
    .theme-monochrome._white-ground .site-footer .index .site-footer {
      background: #fff !important; }
    .theme-monochrome._sepia-ground .site-footer .index .site-footer {
      background: #F5F5DC !important; }
  @media screen and (max-width: 900px) {
    .site-footer .wrapper--footer {
      padding: 0; } }
  @media screen and (max-width: 800px) {
    .site-footer .wrapper--footer {
      display: flex;
      flex-direction: column; } }

.footer__nav {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 24px; }
  @media screen and (max-width: 800px) {
    .footer__nav {
      display: none; } }

.footer-nav__item {
  font-family: 'FFMetaWebProMedium', sans-serif;
  font-weight: normal;
  font-style: normal;
  margin-right: 24px;
  transition: color 0.3s;
  vertical-align: middle; }
  .footer-nav__item svg {
    display: inline-block;
    vertical-align: middle; }
  .footer-nav__item:hover {
    color: #f26123; }
    .theme-monochrome._black-accent .footer-nav__item:hover {
      color: #000 !important; }
    .theme-monochrome._white-accent .footer-nav__item:hover {
      color: #fff !important; }
    .theme-monochrome._sepia-accent .footer-nav__item:hover {
      color: #472312 !important; }
  .footer-nav__item:last-child {
    margin-left: auto;
    margin-right: 0; }

.footer__copyright {
  font-family: 'FFMetaWebProNormal', sans-serif;
  font-weight: normal;
  font-style: normal; }
  .footer__copyright .copyright {
    margin-bottom: 8px; }
  .footer__copyright .licenses {
    color: #5B5B5B; }
  .footer__copyright .soc_icon {
    padding-right: 8px; }
    .footer__copyright .soc_icon img {
      width: 30px;
      height: 3opx; }
  @media screen and (max-width: 800px) {
    .footer__copyright {
      order: 2;
      margin-top: 20px;
      color: #A0A0A0;
      font-size: 1.2rem;
      line-height: 1.66667em; } }

.footer__extra {
  font-family: 'FFMetaWebProNormal', sans-serif;
  font-weight: normal;
  font-style: normal;
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  color: #5B5B5B;
  font-size: 1.1rem; }
  .theme-monochrome._black-accent .footer__extra {
    color: #000 !important; }
  .theme-monochrome._white-accent .footer__extra {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .footer__extra {
    color: #472312 !important; }
  @media screen and (max-width: 800px) {
    .footer__extra {
      font-size: 1.4rem;
      line-height: 1.42857em;
      order: 1;
      margin: 0;
      color: #58595b; } }

@media screen and (max-width: 800px) {
  .footer-extra__docs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -16px;
    margin-top: -13px; } }

.footer-extra__link {
  margin-left: 16px;
  transition: color 0.3s; }
  @media screen and (max-width: 800px) {
    .footer-extra__link {
      margin-top: 13px; } }
  .footer-extra__link:hover {
    color: #f26123; }
    .theme-monochrome._black-accent .footer-extra__link:hover {
      color: #000 !important; }
    .theme-monochrome._white-accent .footer-extra__link:hover {
      color: #fff !important; }
    .theme-monochrome._sepia-accent .footer-extra__link:hover {
      color: #472312 !important; }
  .footer-extra__link:first-child {
    margin-left: 0; }
    @media screen and (max-width: 800px) {
      .footer-extra__link:first-child {
        margin-left: 16px; } }
  @media screen and (max-width: 800px) {
    .footer-extra__link:nth-child(3) {
      order: 4; } }

.footer-extra__developers {
  opacity: 0.5; }

.cookies-disclaimer {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1008px;
  padding: 16px 40px;
  display: flex;
  align-items: center;
  background: #ffffff;
  box-sizing: border-box;
  width: 100%;
  justify-content: space-between;
  border-radius: 4px 4px 0 0;
  z-index: 1; }
  .theme-monochrome._black-ground .cookies-disclaimer {
    background: #000 !important; }
  .theme-monochrome._white-ground .cookies-disclaimer {
    background: #fff !important; }
  .theme-monochrome._sepia-ground .cookies-disclaimer {
    background: #F5F5DC !important; }
  @media screen and (max-width: 900px) {
    .cookies-disclaimer {
      flex-direction: column; } }

.cookies-disclaimer-actions {
  display: flex;
  align-items: center; }
  @media screen and (max-width: 900px) {
    .cookies-disclaimer-actions {
      justify-content: space-between;
      margin-top: 10px;
      width: 100%; } }
  .cookies-disclaimer-actions .btn {
    margin-left: 32px; }

.cookies-disclaimer-text {
  color: #A0A0A0;
  max-width: 65rem;
  margin-right: 32px;
  flex-grow: 1;
  flex-shrink: 0;
  font-size: 1.2rem;
  line-height: 1.33333em; }
  .theme-monochrome._black-accent .cookies-disclaimer-text {
    color: #000 !important; }
  .theme-monochrome._white-accent .cookies-disclaimer-text {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .cookies-disclaimer-text {
    color: #472312 !important; }
  @media screen and (max-width: 900px) {
    .cookies-disclaimer-text {
      margin-right: 0; } }

.polit-disclaimer {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1008px;
  padding: 16px 40px;
  display: flex;
  align-items: center;
  background: #ffffff;
  box-sizing: border-box;
  width: 100%;
  justify-content: space-between;
  border-radius: 4px 4px 0 0;
  z-index: 1; }
  .theme-monochrome._black-ground .polit-disclaimer {
    background: #000 !important; }
  .theme-monochrome._white-ground .polit-disclaimer {
    background: #fff !important; }
  .theme-monochrome._sepia-ground .polit-disclaimer {
    background: #F5F5DC !important; }
  @media screen and (max-width: 900px) {
    .polit-disclaimer {
      flex-direction: column; } }

.polit-disclaimer-actions {
  display: flex;
  align-items: center; }
  @media screen and (max-width: 900px) {
    .polit-disclaimer-actions {
      justify-content: space-between;
      margin-top: 10px;
      width: 100%; } }
  .polit-disclaimer-actions .btn {
    margin-left: 32px; }

.polit-disclaimer-text {
  color: #A0A0A0;
  max-width: 65rem;
  margin-right: 32px;
  flex-grow: 1;
  flex-shrink: 0;
  font-size: 1.2rem;
  line-height: 1.33333em; }
  .theme-monochrome._black-accent .polit-disclaimer-text {
    color: #000 !important; }
  .theme-monochrome._white-accent .polit-disclaimer-text {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .polit-disclaimer-text {
    color: #472312 !important; }
  @media screen and (max-width: 900px) {
    .polit-disclaimer-text {
      margin-right: 0; } }

.info-disclaimer {
  top: 30%;
  bottom: auto;
  z-index: 100;
  height: 300px;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1008px;
  padding: 16px 40px;
  display: flex;
  align-items: center;
  background: #ffffff;
  box-sizing: border-box;
  width: 100%;
  justify-content: space-between;
  border-radius: 4px 4px 0 0; }
  .theme-monochrome._black-ground .info-disclaimer {
    background: #000 !important; }
  .theme-monochrome._white-ground .info-disclaimer {
    background: #fff !important; }
  .theme-monochrome._sepia-ground .info-disclaimer {
    background: #F5F5DC !important; }
  @media screen and (max-width: 900px) {
    .info-disclaimer {
      flex-direction: column; } }

.info-disclaimer-actions {
  display: flex;
  align-items: center; }
  @media screen and (max-width: 900px) {
    .info-disclaimer-actions {
      justify-content: space-between;
      margin-top: 10px;
      width: 100%; } }
  .info-disclaimer-actions .btn {
    margin-left: 32px; }

.info-disclaimer-text {
  color: #A0A0A0;
  max-width: 65rem;
  margin-right: 32px;
  flex-grow: 1;
  flex-shrink: 0;
  font-size: 1.2rem;
  line-height: 1.33333em; }
  .theme-monochrome._black-accent .info-disclaimer-text {
    color: #000 !important; }
  .theme-monochrome._white-accent .info-disclaimer-text {
    color: #fff !important; }
  .theme-monochrome._sepia-accent .info-disclaimer-text {
    color: #472312 !important; }
  @media screen and (max-width: 900px) {
    .info-disclaimer-text {
      margin-right: 0; } }

@media print {
  @page {
    size: A4;
    margin: 20mm 15mm 20mm 15mm; }
  *,
  *:before,
  *:after {
    background: transparent !important;
    color: #000 !important;
    /* Black prints faster */
    box-shadow: none !important;
    text-shadow: none !important; }
  /*
   * Don't show links that are fragment identifiers,
   * or use the `javascript:` pseudo protocol
   */
  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: ""; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  /*
   * Printing Tables:
   * http://css-discuss.incutio.com/wiki/Printing_Tables
   */
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  body {
    min-width: 100%;
    filter: grayscale(100%);
    -webkit-print-color-adjust: exact; }
  .breadcrumbs__item[href]:after {
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2IiBoZWlnaHQ9IjgiIHZpZXdCb3g9IjAgMCA2IDgiPjxwYXRoIGZpbGw9Im5vbmUiIHN0cm9rZT0iI0EwQTBBMCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBkPSJNMSA4bDQtNC00LTQiLz48L3N2Zz4=) 50% 50% no-repeat !important; }
  .print-hidden {
    display: none; }
  .page-content {
    margin-top: 3.2rem; }
  .page-content > .wrapper {
    max-width: inherit; }
  .content__aside {
    width: 30%;
    padding-right: 0;
    padding-top: 0;
    padding-left: 1rem; }
    .content__aside .aside__section {
      margin-top: 0; }
  .aside-manager__info picture {
    display: none; }
  .content__inner {
    width: 70%;
    padding: 0; }
  .aside__section {
    margin-top: 0; }
    .aside__section .action-button {
      display: none; }
  .site-header__menu {
    display: none; }
  .site-header__logo {
    margin-left: 0; }
  .site-header {
    border-bottom: 1px solid #efefef;
    padding: 0; }
  .site-header__item {
    font-size: 1.4rem; }
  .site-header__item:not([href^="tel:"]) {
    display: none; }
  .site-header__icon {
    stroke: #a0a0a0; }
  .policy-name__edit {
    display: none; }
  .policy-header__actions {
    display: none; }
  .policy__info {
    border: 1px solid #ccc;
    border-radius: 4px;
    page-break-inside: avoid; }
  .policy-services__item {
    background: #fff !important;
    width: 100%; }
  .psi__footer {
    display: none; }
  .policy-info__footer {
    display: none; }
  .policy__section {
    page-break-inside: avoid; }
  .policy__total .fill {
    border-bottom: 1px solid #ccc;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent; }
  .policy-info__table table tbody tr:nth-child(odd) td {
    background: #eee !important; }
  .site-footer {
    display: none; }
  .policy__options .form__checkbox input[type=checkbox] + label {
    display: none; }
  .policy__options .form__checkbox input[type=checkbox]:checked + label {
    display: inline-block;
    padding-left: 0; } }

.to-partners__request {
  display: flex;
  flex-direction: row; }
  @media screen and (max-width: 1000px) {
    .to-partners__request {
      flex-direction: column; } }
  .to-partners__request > * {
    margin: 0; }
    .to-partners__request > *:not(:first-child) {
      margin-left: 72px; }
      @media screen and (max-width: 1000px) {
        .to-partners__request > *:not(:first-child) {
          margin-left: 0; } }

.to-partners__form {
  width: 656px;
  min-width: 656px; }
  @media screen and (max-width: 1000px) {
    .to-partners__form {
      width: 100%;
      min-width: 100%; } }

/* 09.2022 Новые цвета, остальные цвета сохраняются для поддержки*/
.mobile-menu {
  display: block;
  width: auto;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  max-width: 100vw;
  min-width: 288px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  transform: translateX(-100%);
  transition-property: transform;
  transition-duration: .3s;
  pointer-events: none; }
  .mobile-menu__header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f1f1f1; }
  .mobile-menu__close {
    display: inline-block;
    background: none;
    padding: 26px 19px;
    border: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    outline: none; }
  .mobile-menu__body {
    display: block;
    padding: 0 16px; }
  .mobile-menu__actions {
    display: flex;
    flex-direction: column;
    padding: 16px 16px 0; }
    .mobile-menu__actions:last-child {
      padding-bottom: 28px; }
  .mobile-menu__action:not(:first-child) {
    margin-top: 8px; }
  .mobile-menu__btn-search {
    background: none;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    margin-left: auto;
    padding: 25px 28px;
    box-sizing: content-box;
    outline: none; }
    .mobile-menu__btn-search img {
      display: block;
      max-width: 100%;
      max-height: 100%; }
  .mobile-menu .accordion-item {
    border-bottom: 1px solid #f1f1f1; }
  .mobile-menu .accordion-trigger {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 2.4rem;
    line-height: 1.33333em;
    text-transform: initial; }
  .mobile-menu .accordion-content-inner {
    padding: 0; }
  .mobile-menu .header-nav__subnav {
    margin: 0; }
  .menu-visible .mobile-menu {
    transform: translateX(0);
    pointer-events: auto; }
  .mobile-menu .header-nav__subcol:not(:first-child) {
    margin-top: 16px; }
  .mobile-menu__telephones {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #F1F1F1;
    padding: 16px 16px 0;
    margin-top: 22px; }
  .mobile-menu__item {
    color: #7B7B7B;
    opacity: 0.7;
    font-size: 1.4rem;
    line-height: 1.71429em; }
  .mobile-menu__telephone {
    color: #58595b;
    font-size: 1.6rem;
    line-height: 1.5em;
    margin-top: 0; }

.clearfix {
  *zoom: 1; }

.clearfix:before, .clearfix:after {
  content: " ";
  display: table; }

.clearfix:after {
  clear: both; }

.clear {
  overflow: hidden; }

.left {
  float: left; }

.right {
  float: right; }

.cl {
  clear: left; }

.cr {
  clear: right; }

.cntr {
  text-align: center; }

.no-mar {
  margin: 0 !important; }

.mar-5 {
  margin-bottom: 5px !important; }

.mar-10 {
  margin-bottom: 10px !important; }

.mar-20 {
  margin-bottom: 20px !important; }

.mar-30 {
  margin-bottom: 30px !important; }

.mar-40 {
  margin-bottom: 40px !important; }

.no-pad {
  padding: 0 !important; }

.pad-5 {
  padding: 5px !important; }

.pad-10 {
  padding: 10px !important; }

.pad-20 {
  padding: 20px !important; }

.pad-30 {
  padding: 30px !important; }

.pad-40 {
  padding: 40px !important; }

.left {
  float: left; }
  .left.hf {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 50%;
    padding-right: 15px; }

.right {
  float: right; }
  .right.hf {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 50%;
    padding-left: 15px; }

.hidden {
  display: none; }

.hidden-content {
  display: none !important; }

.popup-content {
  display: none !important; }

.overflow-hidden {
  overflow: hidden; }

.at-middle {
  text-align: center; }
  .at-middle:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    width: 1px; }
  .at-middle .middle {
    display: inline-block;
    vertical-align: middle;
    max-width: 95%; }

.h-no-wrap {
  white-space: nowrap; }
