.form__checkbox {
  overflow: hidden;
  position: relative;
  transition: opacity 0.3s;

  + .form__checkbox {
    margin-top: 1.6rem;
  }

  &:disabled {
    opacity: 0.5;

    &:after {
      display: none;
    }

  }
}

input[type=checkbox] {
  width: 1px;
  height: 1px;
  opacity: 0.1;
  position: absolute;
  left: -1rem;
  z-index: -1;
}

input[type=checkbox] + label {
  display: inline-block;
  min-height: 24px;
  padding-left: 34px;
  position: relative;
  @include mix-font-size(14px, 24px);

  &:not(:first-of-type) {
    margin-left: 1.5em;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    width: 24px;
    height: 24px;
    background: $cWhite;
    border: 1px solid #D9D9D9;
    box-shadow: inset 0 -2px 0 0 #F2F2F2;
    border-radius: 3px;
    box-sizing: border-box;
    transition: background-color 0.3s, border-color 0.3s, box-shadow 0.3s;
    //@include theme-monochrome-ground(background);
    @include theme-monochrome-accent(border-color);
  }

  &:after {
    content: '';
    width: 15px;
    height: 10px;
    position: absolute;
    left: 4px;
    top: 6px;
    transform: translateY(-4px);
    opacity: 0;
    transition: transform 0.2s, opacity 0.2s;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDE0IDEwIj4gIDxwb2x5bGluZSBmaWxsPSJub25lIiBzdHJva2U9IiNGRkYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIiBwb2ludHM9IjAgNCA0LjU2MyA4IDEyIDAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEgMSkiLz48L3N2Zz4=);

  }

  &:hover {
    cursor: pointer;
  }


}

input[type=checkbox]:checked + label {
  &:before {
    background: $cBrand;
    border-color: $cBrand;
    box-shadow: inset 0 -3px 0 0 $cBrand;
    //@include theme-monochrome-accent(background);
    @include theme-monochrome-accent(border-color) {
      box-shadow: none;
    }
  }

  &:after {
    opacity: 1;
    transform: translateY(0);
  }
}

input[type=checkbox]:disabled + label {
  &:hover {
    cursor: default;
  }
}

input[type=checkbox]:disabled:checked + label {
  &:before {
    background: #D9D9D9;
    border: 1px solid $cWhite;
    box-shadow: none;
    //@include theme-monochrome-ground(background);
    @include theme-monochrome-ground(border-color);
  }

  &:after {

  }

}
