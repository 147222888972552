.reminders {
  margin-top: 40px;

  .col {
    padding-left: 64px;
    position: relative;
  }

  .products__link {
    //line-height: 2.4rem;
    margin-right: 0;
    display: inline;
  }
}

.reminders__group {
}

.rg-header {
  height: 32px;
  color: #000000;
  letter-spacing: -0.4px;
  margin-bottom: 24px;
  @include mix-font-size(24px, 31px);
}

.rg-links {

}

.reminders__docs {
  padding-top: 40px;
  border-top: 1px solid rgba(#a0a0a0, 0.24);
  margin-top: 40px;
}

.reminders-docs__section + .reminders-docs__section {
  margin-top: 40px;
}

.rd-header {
  margin-bottom: 24px;
  @include mix-font-size(20px, 24px);
}

.rd-docs {
  .documents__item {
    margin-bottom: 0;

    + .documents__item {
      margin-top: 16px;
    }
  }
}

@media screen and (max-width: $breakpoint) {
  .reminders .col {
    padding-left: 0;
  }
  .rg-header {
    padding-left: 60px;
  }
}
