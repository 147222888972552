.incident-action {
  margin-top: 40px;
  display: flex;
  align-items: center;

  .tooltip--right {
    margin-left: 16px;
    margin-right: -40px;

    span {
      display: block;
    }
  }
}

.care-service {
  padding-bottom: 3.2rem;
  border-bottom: 1px solid rgba(#a0a0a0, 0.24);
  margin-bottom: 2.4rem;
}

.care-service--big-mb {
  margin-bottom: 4rem;
}

.care-service-header {
  @include metam;
  @include mix-font-size(18px);
}

.care-service-phone {
  svg {
    margin-right: 0.8rem;
    stroke: $cBrand;
  }
}

@media screen and (max-width: $breakpoint) {
  .incident-action {
    flex-direction: column;
    align-items: flex-start;
  }
  .incident-action .tooltip--right {
    margin-right: 0;
    margin-left: 0;
    margin-top: 1.6rem;
  }
}
