.vpk-content {
  padding-top: 80px;
  @include respond-to(800) {
    padding-top: 40px;
  }
  &--about {
    padding-top: 0;
  }
}

.vpk-more {
  display: flex;
  margin-bottom: 80px;
  @include respond-to(800) {
    flex-direction: column;
    margin-bottom: 40px;
  }
}

.vpk-more-sticky {
  justify-content: space-around;
  align-items: flex-start;
}

.vpk-more-text {
  color: #595959;
  letter-spacing: -0.4px;
  width: 100%;
  @include mix-font-size(24px, 32px);
  @include theme-monochrome-accent(color);
  @include respond-to(800) {
    @include mix-font-size(20px, 26px);
  }
}

.vpk-more-actions {
  width: 280px;
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 100px;
  @include respond-to(800) {
    margin-left: 0;
    margin-top: 40px;
  }
  .action-button {
    margin-top: 0;
    background: #f7f7f7;
    @include theme-monochrome-ground(background);

    + .action-button {
      margin-top: 8px;
    }
  }
}

.vpk-more-sticky-buttons {
  position: -webkit-sticky;
  position: sticky;
  top: 40px;
}

.vpk-includes {
  @include respond-to(600) {
    margin-bottom: 40px;
  }
  h3 {
    margin-bottom: 40px;
  }
}

.vpki-grid {
  display: flex;
  flex-wrap: wrap;
  margin-left: -46px;
  margin-right: -46px;
  @include respond-to(600) {
    flex-direction: column;
  }
  @include respond-to(800) {
    margin-left: 0;
    margin-right: 0;
  }
}

.vpki-item {
  width: calc(33.33333% - 92px);
  box-sizing: border-box;
  margin-bottom: 56px;
  margin-left: 46px;
  margin-right: 46px;
  border-radius: 2px;
  position: relative;
  cursor: pointer;
  +.vpki-item {
    @include respond-to(600) {
      margin-top: 32px;
    }
  }
  @include respond-to(600) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
  }
  @include respond-to(800) {
    width: 27%;
    margin-left: 0;
  }
  &:hover {
    .vpki-item-hover {
      opacity: 1;
      visibility: visible;
    }
  }

  &.vpki-item--no-content {
    cursor: default;
  }
  &._c-4{
    width: calc(25% - 92px);
    @include respond-to(600) {
      width: 100%;
    }
  }
}

.vpki-item-inner {
  transition: opacity .4s, visibility .4s, transform .4s;
  opacity: 0;
  visibility: hidden;
  transform: translateY(40px);
  @include respond-to(800) {
    display: none;
  }
  &.view-in {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
  }
}

.vpki-item-hover {
  position: absolute;
  padding: 40px;
  left: -40px;
  top: -40px;
  right: -40px;
  background: #FFFFFF;
  box-shadow: 0 14px 40px 0 rgba(0, 0, 0, 0.16);
  opacity: 0;
  visibility: hidden;
  z-index: 2;
  transition: opacity .3s, visibility .3s;
  @include theme-monochrome-ground(background) {
    box-shadow: none;
  }
}

.vpki-icon {

}

.vpki-header {
  color: #262626;
  text-transform: uppercase;
  margin-bottom: 8px;
  @include mix-font-size(16px, 24px);
  @include theme-monochrome-accent(color);
}

.vpki-body {

}

.vpki-preview {
  color: $cLightGrey;
  @include theme-monochrome-accent(color);
}

.vpki-price {
  @include mix-font-size(20px, 24px);
}

.vpki-content {
  color: $cLightGrey;
  @include mix-font-size(14px, 20px);
  @include theme-monochrome-accent(color);

  ol {
    margin-top: 8px;
    counter-reset: cnt;

    li {
      counter-increment: cnt;

      &:before {
        content: counter(cnt) '.';
        margin-right: 8px;
        opacity: .5;
      }
    }
  }
}

.vpk-services {
  padding: 88px 72px;
  margin-bottom: 72px;
  position: relative;
  width: 100%;
  @include theme-monochrome-accent(background);
  @include respond-to(800) {
    padding: 40px 16px;
    margin-bottom: 92px;
    box-sizing: border-box;
  }
}

.vpk-cols11 {
  margin-left: 14px;
  margin-right: 14px;
  @include respond-to(800) {
    margin-left: 4px;
    margin-right: 84px;
  }
}

.vpks-content {
  text-align: center;
  color: $cWhite;
  @include theme-monochrome-ground(color);

  h3 {
    color: $cWhite;
    @include theme-monochrome-ground(color);
    @include respond-to(800) {
      @include mix-font-size(24px, 32px);
      letter-spacing: -0.3px;
    }
  }
}

.vpks-cards {
  margin-top: 40px;
  margin-bottom: -120px;
  position: relative;
  display: flex;
  justify-content: center;
  @include respond-to(800) {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    justify-content: flex-start;
    margin-left: -16px;
    margin-right: -16px;
    padding-bottom: 50px;
    &:after {
      content: '';
      flex: 0 0 16px;
    }
  }
}

.vpks-card {
  background: $cWhite;
  padding: 30px;
  border-radius: 15px;
  box-sizing: border-box;
  box-shadow: 0 24px 40px 0 rgba(0, 0, 0, 0.16);
  position: relative;
  margin-left: 16px;
  margin-right: 16px;
  width: calc(30% - 32px);
  @include respond-to(800) {
    width: 75vw;
    padding: 24px;
    box-sizing: border-box;
    flex-shrink: 0;
    margin-left: 8px;
    margin-right: 8px;
  }
  @include theme-monochrome-ground(background) {
    box-shadow: none;
  }

  p + p {
    margin-top: 8px;
  }

  .lk__action {
    letter-spacing: -.5px;
    padding: 10px;
  }
}

.vpks-card-header {
  margin-bottom: 16px;
}


.vpks-card-icon {

}

.vpks-card-body {

}

.vpks-card-body__container {
  width: inherit !important;
  margin-left: 0;
  margin-bottom: 15px;
}

.vpks-card-body__button {
  position: absolute;
  bottom: 0;
  left: 10px;
  right: 10px;

  .big-font-size & {
    position: static;
  }
}

.vpks-card-body__container a.btn {
  padding: 12px;
  width: 100%;
  border-radius: 10px;

  &:hover {
    color:#f36c21;
  }
}

.vpks-card-footer {
  position: absolute;
  right: 40px;
  top: 40px;
}

.vpks-card-body__full {
  height: 100%;
}

.vpks-card-body__info {
  margin-bottom: 50px;
}

.vpk-questions {
  display: flex;
  @include respond-to(800) {
    flex-direction: column;
  }
}

.vpkq-main {
  h3 {
    margin-bottom: 40px;
  }
}

.vpkq-row {
  + .vpkq-row {
    margin-top: 48px;
  }
}

.vpkq-aside {
  width: 320px;
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 96px;
  @include respond-to(800) {
    margin-left: 0;
    margin-top: 40px;
    width: 100%;
  }
}

.vpkq-item {
  padding: 24px;
  border: 1px solid #E8E8E8;
  border-radius: 4px;
  margin-bottom: 16px;
  @include respond-to(800) {
    box-sizing: border-box;
  }
  @include theme-monochrome-ground(border-color);

  p + p {
    margin-top: 8px;
    letter-spacing: -.5px;
  }

  .vpkq-action {
    margin-top: 18px;
    letter-spacing: -.5px;
  }
}

.vpk-includes .vpki-item {
  border: 1px solid #f26123;
  border-radius: 15px;
  padding: 16px 16px;
  width: calc(33.33333% - 40px);
  margin-left: 20px;
  margin-right: 20px;

  @include respond-to(800) {
    width: 30%;
  }
  @include respond-to(600) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

.vpk-info__block {
  padding: 40px;
}

.vpk-info__detail {
  margin-top: 20px;
  list-style-type: disc;
  -webkit-columns: 2;
  -moz-columns: 2;
  columns: 2;
  margin-left: -20px;
  margin-right: -20px;
  list-style-position: inside;
  @include respond-to(800) {
    margin-top: 20px;
    list-style-type: disc;
    -webkit-columns: 1;
    -moz-columns: 1;
    columns: 1;
  }
  .col {
    margin-bottom: 20px;
    margin-right: 30px;
    width: fit-content !important;
    display: -webkit-box;

    span {
      content: '';
      display: block;
      width: 32px;
      height: 32px;
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIzMnB4IiBoZWlnaHQ9IjMycHgiIHZpZXdCb3g9IjAgMCAzMiAzMiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5jaGVjazwvdGl0bGU+ICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPiAgICA8ZGVmcz48L2RlZnM+ICAgIDxnIGlkPSJQdWJsaWMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPGcgaWQ9ImdlbGlvc19wcm9kdWN0cy1WUEsiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xNzYuMDAwMDAwLCAtMjI2NS4wMDAwMDApIj4gICAgICAgICAgICA8ZyBpZD0iY29udGVudCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNzIuMDAwMDAwLCA3MC4wMDAwMDApIj4gICAgICAgICAgICAgICAgPGcgaWQ9InRlbGVtZWQtcG9wdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDM4LjAwMDAwMCwgMTk3MS4wMDAwMDApIj4gICAgICAgICAgICAgICAgICAgIDxnIGlkPSJjaGVjayIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNjYuMDAwMDAwLCAyMjQuMDAwMDAwKSI+ICAgICAgICAgICAgICAgICAgICAgICAgPHJlY3QgaWQ9IlJlY3RhbmdsZS0xNCIgZmlsbD0iI0Y3RjdGNyIgeD0iMCIgeT0iMCIgd2lkdGg9IjMyIiBoZWlnaHQ9IjMyIiByeD0iMTYiPjwvcmVjdD4gICAgICAgICAgICAgICAgICAgICAgICA8cG9seWxpbmUgaWQ9IlNoYXBlIiBzdHJva2U9IiM3RUJEMUIiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBwb2ludHM9IjEyIDE3LjMzMzMzMzMgMTQuMSAxOS4zMzMzMzMzIDE5LjggMTMiPjwvcG9seWxpbmU+ICAgICAgICAgICAgICAgICAgICA8L2c+ICAgICAgICAgICAgICAgIDwvZz4gICAgICAgICAgICA8L2c+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=);
      background-position: 50% 50%;
      background-repeat: no-repeat;
      margin-bottom: 8px;
      margin-right: 10px;
    }

    p {
      margin-top: 8px;
      line-height: 20px;
    }
  }
}

.vpks-popups {
  position: absolute;
  height: 0;
  top: 0;
  left: 0;
  right: 0;
}

.vpks-popup {
  position: absolute;
  top: 160px;
  z-index: 1;
  background: #fff;
  padding: 80px 66px;
  border-radius: 2px;
  left: 38px;
  right: 38px;
  box-shadow: 0 24px 40px 0 rgba(0, 0, 0, 0.16);
  transition: transform .4s, opacity .4s, visibility .4s;
  transform: scaleY(0);
  opacity: 0;
  visibility: hidden;
  @include theme-monochrome-ground(background) {
    box-shadow: none;
  }

  &.active {
    transform: scaleY(1);
    opacity: 1;
    visibility: visible;
  }

  ._header {

  }

  ._body {
    margin-bottom: 40px;
  }

  ._footer {
    .text--smaller {
      line-height: 20px;
    }

    .col {
      &:before {
        content: '';
        display: block;
        width: 32px;
        height: 32px;
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIzMnB4IiBoZWlnaHQ9IjMycHgiIHZpZXdCb3g9IjAgMCAzMiAzMiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5jaGVjazwvdGl0bGU+ICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPiAgICA8ZGVmcz48L2RlZnM+ICAgIDxnIGlkPSJQdWJsaWMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPGcgaWQ9ImdlbGlvc19wcm9kdWN0cy1WUEsiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xNzYuMDAwMDAwLCAtMjI2NS4wMDAwMDApIj4gICAgICAgICAgICA8ZyBpZD0iY29udGVudCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNzIuMDAwMDAwLCA3MC4wMDAwMDApIj4gICAgICAgICAgICAgICAgPGcgaWQ9InRlbGVtZWQtcG9wdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDM4LjAwMDAwMCwgMTk3MS4wMDAwMDApIj4gICAgICAgICAgICAgICAgICAgIDxnIGlkPSJjaGVjayIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNjYuMDAwMDAwLCAyMjQuMDAwMDAwKSI+ICAgICAgICAgICAgICAgICAgICAgICAgPHJlY3QgaWQ9IlJlY3RhbmdsZS0xNCIgZmlsbD0iI0Y3RjdGNyIgeD0iMCIgeT0iMCIgd2lkdGg9IjMyIiBoZWlnaHQ9IjMyIiByeD0iMTYiPjwvcmVjdD4gICAgICAgICAgICAgICAgICAgICAgICA8cG9seWxpbmUgaWQ9IlNoYXBlIiBzdHJva2U9IiM3RUJEMUIiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBwb2ludHM9IjEyIDE3LjMzMzMzMzMgMTQuMSAxOS4zMzMzMzMzIDE5LjggMTMiPjwvcG9seWxpbmU+ICAgICAgICAgICAgICAgICAgICA8L2c+ICAgICAgICAgICAgICAgIDwvZz4gICAgICAgICAgICA8L2c+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=);
        background-position: 50% 50%;
        background-repeat: no-repeat;
        margin-bottom: 8px;
      }
    }
  }
}

.vpks-popup-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;

}

.vpks-popup-close {
  width: 56px;
  height: 56px;
  position: absolute;
  right: 24px;
  top: 24px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDE0IDE0Ij48cGF0aCBkPSJNMTMgMUwxIDEzTTEgMWwxMiAxMiIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2U9IiMyNjI2MjYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIi8+PC9zdmc+);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  transition: transform .3s;

  &:hover {
    transform: rotate(180deg);
  }
}

.vpki-item-hover {
  @include respond-to(800) {
    display: block;
    box-shadow: none;
    position: static;
    opacity: 1;
    visibility: visible;
    padding: 0;
  }
}
  .vpki-item-inner.vpki-item-inner--m-show {
    @include respond-to(800) {
      display: block;
    }
  }