.breadcrumbs {
  @include mix-font-size(0px);
  margin-bottom: 24px;

  + h1, + h2, + h3, + h4, + h5, + h6 {
    margin-top: 0;
  }
}

.breadcrumbs__item {
  &:last-child {
    .breadcrumbs__link {
      cursor: default;

      &:hover {
        color: $cLightGrey;
      }

      &:after {
        display: none;
      }
    }
  }
}

.breadcrumbs__link {
  @include mix-font-size(14px);
  color: $cLightGrey;
  @include theme-monochrome-accent(color);
  transition: color 0.3s;
  display: inline-block;
  margin-right: 14px;

  &:after {
    content: '';
    width: 4px;
    height: 24px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2IiBoZWlnaHQ9IjgiIHZpZXdCb3g9IjAgMCA2IDgiPjxwYXRoIGZpbGw9Im5vbmUiIHN0cm9rZT0iI0EwQTBBMCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBkPSJNMSA4bDQtNC00LTQiLz48L3N2Zz4=);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
    //margin-right: 10px;
    //position: absolute;
  }

  &:hover {
    color: $cBrand;
  }
  &:focus {
    color: $cBrand;
    outline: none;
  }
  &::-moz-focus-inner {
    border: 0;
  }
}

