// Основные цвета:
// #EA5E20 - темно-оранж.
// #EA5921 – оранж
// #5B5B5B - серый
// Доп.цвета:
// #FFDF3C - желтый
// #FFDF39- желтый другой
// #96C22B - зеленый
$cWhite: #ffffff;

/* 09.2022 Новые цвета, остальные цвета сохраняются для поддержки*/

$cBlack: rgb(88,89,91); // #58595B;
$cBrand: rgb(242,97,35); // #F26123;
$cGreen:  rgb(141,198,63); // #8DC63F;

// дополнительные цвета
$cGreyDark: rgb(160,156,162);
$cGreyLight: rgb(190,190,196);

$cGreenDark: rgb(88, 185, 71);
$cGreenLight: rgb(214, 224, 63);

$cBrandDark: rgb(246, 139, 40);
$cBrandLight: rgb(250, 166, 52);

// END */

//$cGrey: #5B5B5B;
$cGrey: #5B5B5B;


$cLightGrey: #A0A0A0;
$cError: #F32121;
//$cLightGrey: #A0A0A0;
//$cLightGrey: #A0A0A0;
//$cError: #F32121;
//$cGreen: #7EBD1B;
$cBtn: $cBrand;
$cBtnSec: #F2F2F2;
$cBtnSecHov: #E6E6E6;
$cBtnDis: #CCCCCC;

$breakpoint: 740px;

$width-tablet: 900px;

$notebookScreen: 1400px;

$font-size-document: 10px;
