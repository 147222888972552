.to-partners {
  &__request {
    display: flex;
    flex-direction: row;
    @include respond-to(1000) {
      flex-direction: column;
    }
    > * {
      margin: 0;

      &:not(:first-child) {
        margin-left: 72px;
        @include respond-to(1000) {
          margin-left: 0;
        }
      }
    }
  }

  &__form {
    width: 656px;
    min-width: 656px;
    @include respond-to(1000) {
      width: 100%;
      min-width: 100%;
    }
  }
}