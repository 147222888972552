@font-face {
  font-family: 'PFCentroSlabPro-Thin';
  src: url('/static/fonts/355078_0_0.woff2') format('woff2'),
  url('/static/fonts/355078_0_0.woff') format('woff');
}

@font-face {
  font-family: 'FFMetaWebProNormal';
  src: url('/static/fonts/355078_1_0.woff2') format('woff2'),
  url('/static/fonts/355078_1_0.woff') format('woff'), url('/static/fonts/355078_1_0.ttf') format('truetype');
}

@font-face {
  font-family: 'FFMetaWebProMedium';
  src: url('/static/fonts/355078_2_0.woff2') format('woff2'),
  url('/static/fonts/355078_2_0.woff') format('woff'), url('/static/fonts/355078_2_0.ttf') format('truetype');
}

@mixin pf {
  font-family: 'PFCentroSlabPro-Thin', serif;
  font-weight: normal;
  font-style: normal;
}

@mixin meta {
  font-family: 'FFMetaWebProNormal', sans-serif;
  font-weight: normal;
  font-style: normal;
}

@mixin metam {
  font-family: 'FFMetaWebProMedium', sans-serif;
  font-weight: normal;
  font-style: normal;
}
