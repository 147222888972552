@import "../base/abstract";

.mobile-menu {
  display: block;
  width: auto;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  max-width: 100vw;
  min-width: 288px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;

  &__header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f1f1f1;
  }

  &__close {
    display: inline-block;
    background: none;
    padding: 26px 19px;
    border: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    outline: none;
  }

  &__body {
    display: block;
    padding: 0 16px;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    padding: 16px 16px 0;

    &:last-child {
      padding-bottom: 28px;
    }
  }

  &__action {
    &:not(:first-child) {
      margin-top: 8px;
    }
  }

  &__btn-search {
    background: none;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    margin-left: auto;
    padding: 25px 28px;
    box-sizing: content-box;
    outline: none;
    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
  }

  .accordion-item {
    border-bottom: 1px solid #f1f1f1;
  }

  .accordion-trigger {
    padding-top: 20px;
    padding-bottom: 20px;
    @include mix-font-size(24px, 32px);
    text-transform: initial;
  }

  .accordion-content-inner {
    padding: 0;
  }

  .header-nav__subnav {
    margin: 0;
  }

  .header-nav__item {

  }

  transform: translateX(-100%);
  transition-property: transform;
  transition-duration: .3s;
  pointer-events: none;

  .menu-visible & {
    transform: translateX(0);
    pointer-events: auto;
  }

  .header-nav__subcol {
    &:not(:first-child) {
      margin-top: 16px;
    }
  }

  &__telephones {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #F1F1F1;
    padding: 16px 16px 0;
    margin-top: 22px;
  }

  &__item {
    color: #7B7B7B;
    opacity: 0.7;
    @include mix-font-size(14px, 24px);
  }
  &__telephone {
    color: $cBlack;
    @include mix-font-size(16px, 24px);
    margin-top: 0;
  }
}