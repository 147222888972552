.no-results {
  text-align: center;
}

.no-results__icon {
  margin-bottom: 1.6rem;
}

.no-results__text {
  margin-bottom: 1.6rem;
}

.no-results__action {

}
