.hero {
  padding: 32px;
  background: #F7F7F7;
  border-radius: 4px;
  margin-bottom: 32px;
  min-height: 50px;
  @include theme-monochrome-ground(background);

  .attachment-zone {
    margin-top: 24px;
    padding-top: 16px;
    border-top: 1px solid rgba(#a0a0a0, 0.24);
    @include theme-monochrome-ground(border-top-color);
  }
}

.hero_thin {
  padding: 32px;
  background: #F7F7F7;
  border-radius: 4px;
  margin-bottom: 32px;
  @include theme-monochrome-ground(background);

  .attachment-zone {
    margin-top: 24px;
    padding-top: 16px;
    border-top: 1px solid rgba(#a0a0a0, 0.24);
    @include theme-monochrome-ground(border-top-color);
  }
}

.hero--inverse {
  background: #ffffff;
  margin-top: 32px;
}

.hero__content {
  + .hero__content {
    margin-top: 24px;
  }
}

.hero__content--full-width {
  margin-right: -82px;
}

.hero-with-icon {
  padding-right: 114px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 50px;
    height: 50px;
    top: 32px;
    right: 32px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }
}

.hero--inform {
  &:before {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MCIgaGVpZ2h0PSI1MCI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2U9IiNGMzZDMjEiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxIDEpIj48Y2lyY2xlIGN4PSIyNCIgY3k9IjI0IiByPSIyNCIvPjxwYXRoIGQ9Ik0yNCAxMy40VjI4bTAgNi40djEiLz48L2c+PC9zdmc+);
  }
}

.hero--clock {
  &:before {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MCIgaGVpZ2h0PSI1MCI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2U9IiNBMEEwQTAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIiBvcGFjaXR5PSIuNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMSAxKSI+PGNpcmNsZSBjeD0iMjQiIGN5PSIyNCIgcj0iMjQiLz48cGF0aCBkPSJNMjQgOS42VjI0bDcuMiA3LjIiLz48L2c+PC9zdmc+);
  }
}

.hero--bubble {
  &:before {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MCIgaGVpZ2h0PSI1MCI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMiI+PHBhdGggc3Ryb2tlPSIjQTBBMEEwIiBkPSJNNDkgMzNhNS4zMzMgNS4zMzMgMCAwIDEtNS4zMzMgNS4zMzNoLTMyTDEgNDlWNi4zMzNBNS4zMzMgNS4zMzMgMCAwIDEgNi4zMzMgMWgzNy4zMzRBNS4zMzMgNS4zMzMgMCAwIDEgNDkgNi4zMzNWMzN6Ii8+PHBhdGggc3Ryb2tlPSIjRjM2QzIxIiBkPSJNMTMgMTRoMjQiLz48cGF0aCBzdHJva2U9IiM5Nzk3OTciIGQ9Ik0xMyAyMGgxNm0tMTYgNmgxNiIvPjwvZz48L3N2Zz4=);

  }
}


@media screen and (max-width: $breakpoint) {
  .hero {
    padding: 24px;
  }
  .hero-with-icon {
    padding-right: 24px;

    &:before {
      display: none;
    }
  }

  .hero__content--full-width {
    margin-right: 0;
  }
}
