.site-footer {
  color: $cBlack;
  padding-top: 40px;
  padding-bottom: 40px;
  @include mix-font-size(14px, 16px);
  @include theme-monochrome-accent(color);
  @include respond-to(900) {
    padding: 40px 16px 10px;
  }

  .index .site-footer {
    background: $cWhite;
    @include theme-monochrome-ground(background);
  }

  .wrapper--footer {
    @include respond-to(900) {
      padding: 0;
    }
    @include respond-to(800) {
      display: flex;
      flex-direction: column;
    }
  }
}

.footer__nav {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 24px;
  @include respond-to(800) {
    display: none;
  }
}

.footer-nav__item {
  @include metam;
  margin-right: 24px;
  transition: color 0.3s;
  vertical-align: middle;

  svg {
    display: inline-block;
    vertical-align: middle;
  }

  &:hover {
    color: $cBrand;
    @include theme-monochrome-accent(color);
  }

  &:last-child {
    margin-left: auto;
    margin-right: 0;
  }
}


.footer__copyright {
  @include meta;

  .copyright {
    margin-bottom: 8px;
  }

  .licenses {
    color: $cGrey;
  }

  .soc_icon {
    padding-right: 8px;

    img {
      width: 30px;
      height: 3opx;
    }
  }

  @include respond-to(800) {
    order: 2;
    margin-top: 20px;
    color: $cLightGrey;
    @include mix-font-size(12px, 20px);
  }
}


.footer__extra {
  @include meta;
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  color: $cGrey;
  @include mix-font-size(11px);
  @include theme-monochrome-accent(color);
  @include respond-to(800) {
    @include mix-font-size(14px, 20px);
    order: 1;
    margin: 0;
    color: $cBlack;
  }
}

.footer-extra__docs {
  @include respond-to(800) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -16px;
    margin-top: -13px;
  }
}

.footer-extra__link {
  margin-left: 16px;
  transition: color 0.3s;

  @include respond-to(800) {
    margin-top: 13px;
  }

  &:hover {
    color: $cBrand;
    @include theme-monochrome-accent(color);
  }

  &:first-child {
    margin-left: 0;
    @include respond-to(800) {
      margin-left: 16px;
    }
  }

  &:nth-child(3) {
    @include respond-to(800) {
      order: 4;
    }
  }
}

.footer-extra__developers {
  opacity: 0.5;
}

.cookies-disclaimer {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1008px;
  padding: 16px 40px;
  display: flex;
  align-items: center;
  background: #ffffff;
  box-sizing: border-box;
  width: 100%;
  justify-content: space-between;
  border-radius: 4px 4px 0 0;
  z-index: 1;
  @include theme-monochrome-ground(background);
  @include respond-to(900) {
    flex-direction: column;
  }
}

.cookies-disclaimer-actions {
  display: flex;
  align-items: center;
  @include respond-to(900) {
    justify-content: space-between;
    margin-top: 10px;
    width: 100%;
  }
  .btn {
    margin-left: 32px;
  }
}

.cookies-disclaimer-text {
  color: $cLightGrey;
  max-width: 65rem;
  margin-right: 32px;
  flex-grow: 1;
  flex-shrink: 0;
  @include mix-font-size(12px, 16px);
  @include theme-monochrome-accent(color);
  @include respond-to(900) {
    margin-right: 0;
  }
}

.polit-disclaimer {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1008px;
  padding: 16px 40px;
  display: flex;
  align-items: center;
  background: #ffffff;
  box-sizing: border-box;
  width: 100%;
  justify-content: space-between;
  border-radius: 4px 4px 0 0;
  z-index: 1;
  @include theme-monochrome-ground(background);
  @include respond-to(900) {
    flex-direction: column;
  }
}

.polit-disclaimer-actions {
  display: flex;
  align-items: center;
  @include respond-to(900) {
    justify-content: space-between;
    margin-top: 10px;
    width: 100%;
  }
  .btn {
    margin-left: 32px;
  }
}

.polit-disclaimer-text {
  color: $cLightGrey;
  max-width: 65rem;
  margin-right: 32px;
  flex-grow: 1;
  flex-shrink: 0;
  @include mix-font-size(12px, 16px);
  @include theme-monochrome-accent(color);
  @include respond-to(900) {
    margin-right: 0;
  }
}

.info-disclaimer {
  top: 30%;
  bottom: auto;
  z-index: 100;
  height: 300px;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1008px;
  padding: 16px 40px;
  display: flex;
  align-items: center;
  background: #ffffff;
  box-sizing: border-box;
  width: 100%;
  justify-content: space-between;
  border-radius: 4px 4px 0 0;
  @include theme-monochrome-ground(background);
  @include respond-to(900) {
    flex-direction: column;
  }
}

.info-disclaimer-actions {
  display: flex;
  align-items: center;
  @include respond-to(900) {
    justify-content: space-between;
    margin-top: 10px;
    width: 100%;
  }
  .btn {
    margin-left: 32px;
  }
}

.info-disclaimer-text {
  color: $cLightGrey;
  max-width: 65rem;
  margin-right: 32px;
  flex-grow: 1;
  flex-shrink: 0;
  @include mix-font-size(12px, 16px);
  @include theme-monochrome-accent(color);
  @include respond-to(900) {
    margin-right: 0;
  }
}